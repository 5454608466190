import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('administrationLogTypesTableHeader');

    return {
        LOG_TYPE: t('logType'),
        RISK_LEVEL: t('riskLevel'),
        VISIBLE_TO: t('visibleTo')
    };
};

export default useTranslation;
