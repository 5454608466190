import { conditionalSpreadArray } from 'utilities/common.util';
import { gqlMutations, gqlSortTypes } from 'utilities/gql/gql.util';
import { updateUserActivityMutationBodyGql } from './user-activity.gql';
import { insertActivityHistoryOneMutationBodyGql } from './activity-history.gql';

export const getActvitiesQueryKey = schema => `${schema}_activity`;
export const getUserQueryKey = schema => `users_${schema}`;

const findByUsersFullName = (usersFullName = []) => {
    return {
        _or: usersFullName.map(fullName => ({ full_name: { _eq: fullName } }))
    };
};

const getActivitiesForReportBySchemaGql = ({
    schema,
    variables: {
        activityAssignees = [],
        activityDueDateFrom,
        activityDueDateTo,
        activityName,
        activityRecurrence,
        activityReviewers = [],
        activityStatus,
        activityTypes,
        entityName,
        excludeIds = [],
        isAssignedToMe,
        isFlagged,
        limit,
        offset,
        userId
    }
}) => {
    const userGQL = {
        [getUserQueryKey(schema)]: {
            first_name: true,
            full_name: true,
            last_name: true
        }
    };

    const queryArgs = {
        order_by: [
            { status_order: gqlSortTypes.ASC },
            { due_date: gqlSortTypes.DESC },
            { activity_type: { name: gqlSortTypes.ASC } },
            { name: gqlSortTypes.DESC }
        ],
        where: {
            _and: [
                { deleted: { _neq: true } },
                ...conditionalSpreadArray({
                    condition: activityDueDateFrom,
                    value: { due_date: { _gte: activityDueDateFrom } }
                }),
                ...conditionalSpreadArray({
                    condition: activityDueDateTo,
                    value: { due_date: { _lte: activityDueDateTo } }
                }),
                ...conditionalSpreadArray({
                    condition: activityName,
                    value: { name: { _ilike: `%${activityName}%` } }
                }),
                ...conditionalSpreadArray({
                    condition: activityTypes.length > 0,
                    value: {
                        activity_type: {
                            name: { _in: activityTypes }
                        }
                    }
                }),
                ...conditionalSpreadArray({
                    condition: entityName.length > 0,
                    value: {
                        activity_entities: {
                            entity: {
                                name: { _in: entityName }
                            }
                        }
                    }
                }),
                ...conditionalSpreadArray({
                    condition: excludeIds.length > 0,
                    value: { id: { _nin: excludeIds } }
                }),
                ...conditionalSpreadArray({
                    condition: activityStatus.length > 0,
                    value: { status: { _in: activityStatus } }
                }),
                ...conditionalSpreadArray({
                    condition: activityRecurrence.length > 0,
                    value: {
                        activity_recurrences: {
                            activity_recurrence: {
                                recurrence_type: {
                                    _in: activityRecurrence
                                }
                            }
                        }
                    }
                }),
                ...conditionalSpreadArray({
                    condition: Boolean(isAssignedToMe),
                    value: {
                        user_activities: {
                            user_id: {
                                _eq: userId
                            }
                        }
                    }
                }),
                ...conditionalSpreadArray({
                    condition: Boolean(isFlagged),
                    value: {
                        activity_flags: {
                            id: { _is_null: false }
                        }
                    }
                }),
                ...conditionalSpreadArray({
                    condition: activityAssignees.length > 0,
                    value: {
                        user_activities: {
                            assignment_type: { _neq: 'reviewer' },
                            tenant_user: {
                                [getUserQueryKey(schema)]:
                                    findByUsersFullName(activityAssignees)
                            }
                        }
                    }
                }),
                ...conditionalSpreadArray({
                    condition: activityReviewers.length > 0,
                    value: {
                        user_activities: {
                            assignment_type: { _eq: 'reviewer' },
                            tenant_user: {
                                [getUserQueryKey(schema)]:
                                    findByUsersFullName(activityReviewers)
                            }
                        }
                    }
                })
            ]
        }
    };

    if (limit) {
        queryArgs.limit = limit;
    }
    if (offset) {
        queryArgs.offset = offset;
    }


    return {
        [getActvitiesQueryKey(schema)]: {
            __args: queryArgs,
            activity_attachments: {
                file: true,
                name: true,
            },
            activity_entities: {
                entity: {
                    name: true
                }
            },
            activity_flags: {
                activity_id: true
            },
            activity_forms: {
                assignment: true,
                assignment_type: true,
                current: true,
                due_date: true,
                form: {
                    created_by: true,
                    form_questions: {
                        activity_form_answers: {
                            activity_id: true,
                            answer: true,
                            question_id: true,
                            tenant_user: {
                                id: true,
                                ...userGQL
                            }
                        },
                        question: {
                            id: true,
                            name: true
                        },
                    },
                    is_active: true,
                    name: true,
                    notes: true,
                },
                id: true,
                locked: true,
            },
            activity_histories: {
                message: true,
                tenant_user: {
                    id: true,
                    ...userGQL
                }
            },
            activity_linkages: {
                activity: {
                    closed_date: true,
                    due_date: true,
                    instructions: true,
                    name: true,
                    start_date: true,
                    user_activities: {
                        assignment_type: true,
                        status: true,
                        tenant_user: {
                            id: true,
                            ...userGQL
                        },
                        user_id: true,
                    }
                }
            },
            activity_policies: {
                document: {
                    created_by: true,
                    description: true,
                    policy_sections: {
                        section: {
                            description: true,
                            file: true,
                            id: true,
                            name: true,
                        }
                    }
                },
                document_section_id: true,
            },
            activity_recurrences: {
                activity_recurrence: {
                    recurrence_type: true
                }
            },
            activity_regulatory_references: {
                regulatory_reference: {
                    approval_date: true,
                    created_by: true,
                    description: true,
                    is_active: true,
                    name: true,
                }
            },
            activity_type: {
                dropdown: true,
                name: true
            },
            activityLinkagesByLinkActivityId: {
                activity: {
                    closed_date: true,
                    due_date: true,
                    instructions: true,
                    name: true,
                    start_date: true,
                    user_activities: {
                        assignment_type: true,
                        status: true,
                        tenant_user: {
                            id: true,
                            ...userGQL
                        },
                        user_id: true
                    }
                }
            },
            cases: {
                case_name: true,
                case_type: true,
                closed: true,
                closed_date: true,
                created_by: true,
                created_date: true,
                creation_reason: true,
                due_date: true,
            },
            closed: true,
            closed_date: true,
            comments: {
                created_at: true,
                text: true,
                user_comments: {
                    tenant_user: {
                        ...userGQL
                    }
                }
            },
            dropdown_selections: true,
            due_date: true,
            id: true,
            instructions: true,
            is_hierarchical: true,
            log_activities: {
                log: {
                    created_by: true,
                    created_date: true,
                    date_of_occurrence: true,
                    description: true,
                    log_type: true
                }
            },
            name: true,
            risk_label: true,
            start_date: true,
            status: true,
            user_activities: {
                assignment_type: true,
                status: true,
                tenant_user: {
                    id: true,
                    ...userGQL
                },
                user_id: true
            },
        }
    };
};

const getActivitiesBySchemaGql = ({
    schema,
    variables: {
        activityAssignees = [],
        activityDueDateFrom,
        activityDueDateTo,
        activityName,
        activityRecurrence,
        activityReviewers = [],
        activityStatus,
        activityTypes,
        entityName,
        excludeIds = [],
        isAssignedToMe,
        isFlagged,
        limit,
        offset,
        userId
    }
}) => {
    const userGQL = {
        [getUserQueryKey(schema)]: {
            first_name: true,
            full_name: true,
            last_name: true
        }
    };

    return {
        [getActvitiesQueryKey(schema)]: {
            __args: {
                limit,
                offset,
                order_by: [
                    { status_order: gqlSortTypes.ASC },
                    { due_date: gqlSortTypes.DESC },
                    { activity_type: { name: gqlSortTypes.ASC } },
                    { name: gqlSortTypes.DESC }
                ],
                where: {
                    _and: [
                        { deleted: { _neq: true } },
                        ...conditionalSpreadArray({
                            condition: activityDueDateFrom,
                            value: { due_date: { _gte: activityDueDateFrom } }
                        }),
                        ...conditionalSpreadArray({
                            condition: activityDueDateTo,
                            value: { due_date: { _lte: activityDueDateTo } }
                        }),
                        ...conditionalSpreadArray({
                            condition: activityName,
                            value: { name: { _ilike: `%${activityName}%` } }
                        }),
                        ...conditionalSpreadArray({
                            condition: activityTypes.length > 0,
                            value: {
                                activity_type: {
                                    name: { _in: activityTypes }
                                }
                            }
                        }),
                        ...conditionalSpreadArray({
                            condition: entityName.length > 0,
                            value: {
                                activity_entities: {
                                    entity: {
                                        name: { _in: entityName }
                                    }
                                }
                            }
                        }),
                        ...conditionalSpreadArray({
                            condition: excludeIds.length > 0,
                            value: { id: { _nin: excludeIds } }
                        }),
                        ...conditionalSpreadArray({
                            condition: activityStatus.length > 0,
                            value: { status: { _in: activityStatus } }
                        }),
                        ...conditionalSpreadArray({
                            condition: activityRecurrence.length > 0,
                            value: {
                                activity_recurrences: {
                                    activity_recurrence: {
                                        recurrence_type: {
                                            _in: activityRecurrence
                                        }
                                    }
                                }
                            }
                        }),
                        ...conditionalSpreadArray({
                            condition: Boolean(isAssignedToMe),
                            value: {
                                user_activities: {
                                    user_id: {
                                        _eq: userId
                                    }
                                }
                            }
                        }),
                        ...conditionalSpreadArray({
                            condition: Boolean(isFlagged),
                            value: {
                                activity_flags: {
                                    id: { _is_null: false }
                                }
                            }
                        }),
                        ...conditionalSpreadArray({
                            condition: activityAssignees.length > 0,
                            value: {
                                user_activities: {
                                    assignment_type: { _neq: 'reviewer' },
                                    tenant_user: {
                                        [getUserQueryKey(schema)]:
                                            findByUsersFullName(
                                                activityAssignees
                                            )
                                    }
                                }
                            }
                        }),
                        ...conditionalSpreadArray({
                            condition: activityReviewers.length > 0,
                            value: {
                                user_activities: {
                                    assignment_type: { _eq: 'reviewer' },
                                    tenant_user: {
                                        [getUserQueryKey(schema)]:
                                            findByUsersFullName(
                                                activityReviewers
                                            )
                                    }
                                }
                            }
                        })
                    ]
                }
            },
            activity_entities: {
                entity: {
                    name: true
                }
            },
            activity_flags: {
                activity_id: true
            },
            activity_recurrences: {
                activity_recurrence: {
                    recurrence_type: true
                }
            },
            activity_type: {
                dropdown: true,
                name: true
            },
            closed: true,
            closed_date: true,
            due_date: true,
            id: true,
            is_hierarchical: true,
            name: true,
            start_date: true,
            status: true,
            user_activities: {
                assignment_type: true,
                status: true,
                tenant_user: {
                    id: true,
                    ...userGQL
                },
                user_id: true
            }
        }
    };
};

export const getActivitiesGql = ({
    activityAssignees = [],
    activityDueDateFrom,
    activityDueDateTo,
    activityName,
    activityRecurrence,
    activityReviewers = [],
    activityStatus,
    activityTypes,
    entityName,
    excludeIds = [],
    isAssignedToMe,
    isFlagged,
    limit = 50,
    offset = 0,
    schemas = [],
    schemasCursor = {},
    userId
}) => {
    const querySchemas = schemas.reduce((res, schema) => {
        return {
            ...res,
            ...getActivitiesBySchemaGql({
                schema,
                variables: {
                    activityAssignees,
                    activityDueDateFrom,
                    activityDueDateTo,
                    activityName,
                    activityRecurrence,
                    activityReviewers,
                    activityStatus,
                    activityTypes,
                    entityName,
                    excludeIds:
                        schemasCursor[schema]?.displayedIds ?? excludeIds,
                    isAssignedToMe,
                    isFlagged,
                    limit: schemasCursor[schema]?.limit ?? limit,
                    offset: schemasCursor[schema]?.offset ?? offset,
                    userId
                }
            })
        };
    }, {});

    return {
        query: {
            __name: 'getActivities',
            ...querySchemas
        }
    };
};

export const updateActivityName = schema => `update_${schema}_activity`;

export const closeActivityMutationBodyGql = ({
    activityId,
    closedDate,
    schema
}) => {
    return {
        [updateActivityName(schema)]: {
            __args: {
                _set: {
                    closed: true,
                    closed_date: closedDate
                },
                where: {
                    id: { _eq: activityId }
                }
            },
            returning: {
                id: true
            }
        }
    };
};

export const closeActivityMutationGql = ({
    activityId,
    completable,
    schema,
    groupId,
    userId
}) => {
    return gqlMutations({
        mutations: [
            updateUserActivityMutationBodyGql({
                activityId,
                groupId,
                schema,
                status: 'completed',
                userId
            }),
            completable
                ? closeActivityMutationBodyGql({
                      activityId,
                      closedDate: new Date().toISOString(),
                      schema
                  })
                : undefined,
            insertActivityHistoryOneMutationBodyGql({
                activityId,
                color: 'green',
                message: 'marked the activity complete and closed the activity',
                schema,
                userId
            })
        ],
        name: 'closeActivity'
    });
};

export const getAllActivitiesGql = ({
    activityAssignees = [],
    activityDueDateFrom,
    activityDueDateTo,
    activityName,
    activityRecurrence,
    activityReviewers = [],
    activityStatus,
    activityTypes,
    entityName,
    excludeIds = [],
    isAssignedToMe,
    isFlagged,
    schemas = [],
    userId
}) => {
    const querySchemas = schemas.reduce((res, schema) => {
        return {
            ...res,
            ...getActivitiesForReportBySchemaGql({
                schema,
                variables: {
                    activityAssignees,
                    activityDueDateFrom,
                    activityDueDateTo,
                    activityName,
                    activityRecurrence,
                    activityReviewers,
                    activityStatus,
                    activityTypes,
                    entityName,
                    excludeIds,
                    isAssignedToMe,
                    isFlagged,
                    userId
                }
            })
        };
    }, {});

    return {
        query: {
            __name: 'getAllActivities',
            ...querySchemas
        }
    };
};

