const translations = {
    actions: 'Actions',
    assignedTo: 'Assigned To',
    caseName: 'Case Name',
    caseType: 'Case Type',
    closed: 'Closed',
    company: 'Company',
    dateClosed: 'Date Closed',
    daysOutstanding: 'Days Outstanding',
    dueDate: 'Due Date',
    entities: 'Entities',
    originated: 'Originated',
    overdue: 'Overdue',
    pending: 'Pending',
    riskLabel: 'Risk Label',
    usersInvolved: 'Users Involved'
};

export default translations;
