import Tag from 'components/Tag';

import styles from './styles.module.scss';

const GroupTag = ({ className = '', onClose, text = '' }) => (
    <Tag
        className={`${className} ${styles.groupTag}`}
        onClose={onClose}
        text={text}
    />
);

export default GroupTag;
