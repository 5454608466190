import DatePicker from 'components/DatePicker';
import Editor from 'components/Editor';
import PolicyNameInput from 'components/PolicyNameInput';
import VisibleToDropdown from 'components/VisibleToDropdown';
import WithLabel from 'components/WithLabel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';
import { DragDropContext } from 'react-beautiful-dnd';
import BuildPolicyDetailsPanel from 'components/BuildPolicyDetailsPanel';
import BuildPolicyMetaPanel from 'components/BuildPolicyMetaPanel';
import { useState } from 'react';

const BuildPolicyForm = ({ form, visibleTo }) => {
    const {
        ADD_DESCRIPTION,
        DESCRIPTION,
        EFFECTIVE_DATE,
    } = useTranslation();

    const [formElements, setFormElements] = useState([]);

    const onDragEnd = (result) => {
        const formComponent = result?.destination?.droppableId;
        const formIndex = result?.destination?.index;
        const sourceComponent = result?.source?.droppableId;
        const sourceIndex = result?.source?.index;

        const resortedElements = [...formElements];

        let newFormElements;

        if(formComponent !== 'formElements'){
            if(sourceComponent === 'formElements'){
                for (let i = 0; i < resortedElements.length; i++) {
                    if(resortedElements[i]['index'] >= formIndex && resortedElements[i]['formComponent'] === formComponent){
                        resortedElements[i]['index'] = resortedElements[i]['index'] + 1;
                    }
                }
            }else{
                const modifiedElementIndex = resortedElements.map(e => e.id).indexOf(result?.draggableId);
                const modifiedElement = resortedElements[modifiedElementIndex];
                resortedElements.splice(modifiedElementIndex, 1);
                for (let i = 0; i < resortedElements.length; i++) {
                    if(resortedElements[i]['formComponent'] === formComponent){
                        if(resortedElements[i]['index'] > sourceIndex && resortedElements[i]['formIndex'] <= formIndex){
                            resortedElements[i]['index'] = resortedElements[i]['index'] - 1;
                        }else if(resortedElements[i]['index'] < sourceIndex && resortedElements[i]['index'] >= formIndex){
                            resortedElements[i]['index'] = resortedElements[i]['index'] + 1;
                        }
                    }
                }
                resortedElements.push({
                    formComponent: modifiedElement?.formComponent,
                    id: modifiedElement?.id,
                    index: formIndex,
                    options: modifiedElement?.options,
                    text: modifiedElement?.text,
                    type: modifiedElement?.type
                })
            }

            const elementsWithAdded = resortedElements
            elementsWithAdded.push({
                // id: pendingElement?.id,
                // name: pendingElement?.name,
            })
            newFormElements = elementsWithAdded;
        }
        setFormElements(newFormElements.sort((a, b) => a.index - b.index))
    };

    const { handleError, handleSubmit, handleValid } = form;

    return (
        <div className={styles.policyForm}>
            <PolicyNameInput
                className={styles.policyNameInput}
                onChange={handleValid('policyName')}
                onError={handleError('policyName')}
                onSubmit={handleSubmit('policyName')}
            />

            <DatePicker
                className={styles.effectiveDate}
                label={EFFECTIVE_DATE}
                onChange={handleValid('effectiveDate')}
            />

            <VisibleToDropdown
                className={styles.visibleTo}
                items={visibleTo}
                onChange={handleValid('visibleTo')}
            />

            <WithLabel className={styles.description} text={DESCRIPTION}>
                <Editor
                    onValid={handleValid('description')}
                    placeholder={ADD_DESCRIPTION}
                />
            </WithLabel>

            <PanelWithAsidePanel className={styles.doublePanel}>
                <DragDropContext
                    onDragEnd={result => onDragEnd(result)}
                >

                    <BuildPolicyDetailsPanel
                        formElements={formElements}
                        setFormElements={setFormElements}
                    />

                    <BuildPolicyMetaPanel />

                </DragDropContext>
            </PanelWithAsidePanel>

        </div>
    );
};

export default BuildPolicyForm;
