import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('flaggedToggle');

    return {
        ALL: t('all'),
        FLAGGED: t('flagged')
    };
};

export default useTranslation;
