import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { closeCaseMenuListItem } = state;

const { closeCaseMenuListItemAtom } = closeCaseMenuListItem.atoms;

const MUTATE_CLOSE = tenantSchema => gql`
mutation closeCase($caseId: Int!, $closedDate: timestamp!) {
    update_${tenantSchema}_case(where: {
        id: {
            _eq: $caseId
        }
    },

    _set: {
        closed: true,
        closed_date: $closedDate
    }) {
        returning {
            id
            closed
        }
    }
}
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_CLOSE, providedSchema);

    const closeCase = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            variables['closedDate'] = new Date();
            const res = await mutation(variables);
            
            set(closeCaseMenuListItemAtom, res?.data);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(closeCaseMenuListItemAtom, '');
    });

    return [useRecoilValue(closeCaseMenuListItemAtom), closeCase, reset];
};

export default useData;
