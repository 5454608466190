import PanelLoader from 'components/PanelLoader';

import styles from './styles.module.scss';

const PageBody = ({ children, className = '', isLoading = false }) => (
    <div className={`${className} ${styles.pageBody}`}>
        {isLoading ? <PanelLoader /> : children}
    </div>
);

export default PageBody;
