import ReportsPanel from 'components/ReportsPanel';
import WithPageTitle from 'components/WithPageTitle';

import styles from './styles.module.scss';

const Reports = () => (
    <div className={styles.reports}>
        <WithPageTitle className={styles.head} title="Reports" />

        <ReportsPanel className={styles.reportsPanel} />
    </div>
);

export default Reports;
