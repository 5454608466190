const data = {
    group: {
        description: 'Group 1 description',
        members: [
            {
                avatar: '',
                department: 'IT',
                id: 5,
                name: 'John Doe',
                position: 'Developer'
            },
            {
                avatar: '',
                department: 'IT',
                id: 2,
                name: 'John Doe',
                position: 'Developer'
            },
            {
                avatar: '',
                department: 'IT',
                id: 3,
                name: 'John Doe',
                position: 'Developer'
            },
            {
                avatar: '',
                department: 'IT',
                id: 4,
                name: 'John Doe',
                position: 'Developer'
            }
        ],
        name: 'Group 1',
        supervisors: [
            {
                id: '1',
                name: 'Supervisor 1',
                type: 'user'
            }
        ]
    },
    supervisorOptions: [
        {
            id: '1',
            name: 'Supervisor 1',
            type: 'user'
        },
        {
            id: '2',
            name: 'Supervisor 2',
            type: 'user'
        }
    ]
};

const useData = () => data;

export default useData;
