import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { deleteActivityModal } = state;

const { deleteActivityModalAtom } = deleteActivityModal.atoms;

const MUTATE_DELETE_ACTIVITY = tenantSchema => gql`
mutation deleteActivity($activityId: Int!) {
    update_${tenantSchema}_activity(where: {id: {_eq: $activityId}}, _set: {deleted: true}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history_deleted($activityId: Int!, $color: String = "red", $message: String = "deleted the activity", $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_DELETE_ACTIVITY, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const removeActivity = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);
            await mutationActivityHistory(variables);

            const id = res?.data[`update_${usedSchema}_activity`].returning[0]?.id;

            set(deleteActivityModalAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(deleteActivityModalAtom, '');
    });

    return [useRecoilValue(deleteActivityModalAtom), removeActivity, reset];
};

export default useData;
