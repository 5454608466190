import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { addPolicy } = state;

const { addPolicyAtom } = addPolicy.atoms;

const MUTATE_ADD_POLICY = tenantSchema => gql`
mutation addPolicy($createdBy: Int!, $description: String!, $isActive: Boolean!, $name: String!) {
    insert_${tenantSchema}_document(objects: {
        created_by: $createdBy,
        description: $description,
        is_active: $isActive,
        name: $name}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_ADD_POLICY_VERSION = tenantSchema => gql`
mutation addPolicyVersion(
    $documentId: Int!,
    $effectiveDate: timestamp!,
    $versionNumber: Int!,
    $modifiedByUserId: Int!,
    $file: String,
    $isActive: Boolean!,
    $isDraft: Boolean!,
    $isPublished: Boolean!,
    $description: String!) {
    insert_${tenantSchema}_document_version(objects: {
        document_id: $documentId,
        effective_date: $effectiveDate,
        version_number: $versionNumber,
        modified_by_user_id: $modifiedByUserId,
        file: $file,
        file_name: $file,
        is_active_version: $isActive,
        is_draft: $isDraft,
        is_published: $isPublished,
        description: $description}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_ADD_POLICY_APPLIED_TO = tenantSchema => gql`
mutation addPolicyVersion($documentId: Int!, $groupId: Int, $userId: Int) {
    insert_${tenantSchema}_applied_to(objects: {document_id: $documentId, group_id: $groupId, user_id: $userId}) {
      returning {
        id
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_ADD_POLICY, providedSchema);
    const mutationVersion = useGraphQL(MUTATE_ADD_POLICY_VERSION, providedSchema);
    const mutationAppliedTo = useGraphQL(MUTATE_ADD_POLICY_APPLIED_TO, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const addPolicy = useRecoilCallback(({ set }) => variables => {
        

        const runMutation = async () => {
            const res = await mutation(variables);

            const documentId =
                res?.data[`insert_${usedSchema}_document`]?.returning[0]
                    ?.id;
            
            variables['documentId'] = documentId;
            
            await mutationVersion(variables);
            
            const visibleToVariables = JSON.parse(variables['visibleTo']);
            
            for (let i = 0; i < visibleToVariables.length; i++) {
                const visibleTo = visibleToVariables[i];
                
                if (visibleTo?.type === 'group'){
                  await mutationAppliedTo({documentId, groupId: visibleTo?.id});
                } else if (visibleTo?.type === 'user'){
                  await mutationAppliedTo({documentId, userId: visibleTo?.id});
                }
            }

            set(addPolicyAtom, documentId);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addPolicyAtom, '');
    });

    return [useRecoilValue(addPolicyAtom), addPolicy, reset];
};

export default useData;
