import { useCallback, useEffect, useState } from 'react';

import BarListItems from 'components/BarListItems';
import MenuListItem from 'components/MenuListItem';

import isFunction from 'utilities/isFunction';

import DropdownMultiSelect from 'components/DropdownMultiSelect';
import ArrowIcon from 'components/icons/ArrowIcon';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import useData from './hooks/useData';
import normalizeData from './data';

const MenuListItems = ({
    activeItem,
    className = '',
    handleCollapse,
    handleExpand,
    isCollapsed,
    itemClassName = '',
    items = [],
    mainMenu = false,
    onClick,
    showCollapse = false
}) => {
    const { useActivities, useQuerySchemas, useSchema, useTenants } = useGlobalStateHooks()
    
    const [schema] = useSchema();
    const [_activities, setActivities] = useActivities();
    const [tenantOptions, setTenantOptions] = useState([]);
    const [tenants, setTenants] = useTenants();
    const [querySchemas, setQuerySchemas] = useQuerySchemas();

    for(const s of querySchemas){
        if(window.location.href.includes(s.value)){
            if(!tenants.includes(s.value)){
                setTenants([...tenants, s.value]);
            }
        }
    }

    const { loading, error, data } = useData();

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({ baseSchema:schema, data});

            setQuerySchemas(normalizedData.querySchemas)
            setTenantOptions(normalizedData.options);
        }
    }, [data, schema, setQuerySchemas, setTenants]);

    const handleClick = useCallback(
        index => event => {
            if (isFunction(onClick)) {
                onClick(event, index);
            }
        },

        [onClick]
    );

    const handleSetTenants = useCallback((clickedTenants) => {
        const newTenants = clickedTenants.filter(selected => selected);
        localStorage.setItem("tenants", newTenants);
        setTenants(newTenants);
        setActivities({});
    }, [setTenants, setActivities]);

    const collapseEnabled = showCollapse && handleCollapse && handleExpand;
    const collapseIcon = () => (
        <ArrowIcon
            className={isCollapsed ? styles.expandIcon : styles.collapseIcon}
        />
    );

    return (
        <BarListItems className={className}>
            {!loading && !error && tenantOptions.length > 1 && mainMenu &&
                <DropdownMultiSelect
                    className={`${styles.companiesDropdown} ${collapseEnabled && isCollapsed ? styles.hidden : ''}`}
                    items={tenantOptions}
                    label={"Companies"}
                    onChange={handleSetTenants}
                    selected={tenants}
                />
            }

            {items.map(({ icon, text }, index) => (
                <MenuListItem
                    className={itemClassName}
                    icon={icon}
                    isActive={activeItem === index}
                    isCollapsed={collapseEnabled && isCollapsed}
                    key={text}
                    onClick={handleClick(index)}
                    text={text}
                />
            ))}

            {collapseEnabled && (
                <MenuListItem
                    className={itemClassName}
                    collapseEnabled
                    icon={collapseIcon}
                    isCollapsed={isCollapsed}
                    key={'Collapse'}
                    onClick={isCollapsed ? handleExpand : handleCollapse}
                    text={'Collapse'}
                />
            )}
        </BarListItems>
    );
};

export default MenuListItems;
