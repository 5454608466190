const normalizeData = ({ data, schema }) =>
    data[`${schema}_case`].reduce((accumulator, group) => {
        const {
            id,
            user_cases: userCases
        } = group;

        const isActive = true;
        const name = 'HR Update - Profile Change';

        const notifyees = userCases.reduce((notifyeesAccumulator, notifyee) => {
            const { id: notifyeeId, tenant_user: tenantUser } = notifyee;
            const { username: notifyeeName = '', role } = tenantUser;
            const { name: type } = role;

            notifyeesAccumulator.push({
                notifyeeId,
                notifyeeName,
                type
            });

            return notifyeesAccumulator;
        }, []);

        const reason = 'Employee edited HR information from within their own profile';

        accumulator.cases.push({
            id,
            isActive,
            name,
            notifyees,
            reason
        });

        return accumulator;
    }, {
        cases: [],
        isRiskMonitoringActive: true,
        logCategories: [
            {
                isActive: true,
                name: 'Log Category 1',
                options: ['Option 1', 'Option 2', 'Option 3'],
                selectedOptions: ['Option 1', 'Option 2']
            },
            {
                isActive: true,
                name: 'Log Category 2',
                options: ['Option 1', 'Option 2', 'Option 3'],
                selectedOptions: ['Option 1', 'Option 3']
            },
            {
                isActive: true,
                name: 'Log Category 3',
                options: ['Option 1', 'Option 2', 'Option 3'],
                selectedOptions: ['Option 1', 'Option 2']
            },
            {
                isActive: true,
                name: 'Log Category 4',
                options: ['Option 1', 'Option 2', 'Option 3'],
                selectedOptions: ['Option 1', 'Option 2']
            }
        ],
        masterEmail: 'example@asd.com',
    });

export default normalizeData;

// Mock data:
// const data = {
//     cases: [
//         {
//             id: 1,
//             isActive: true,
//             name: 'HR Update - Profile Change',
//             notifyees: [
//                 {
//                     id: '123',
//                     name: 'Charles Steerman',
//                     type: 'user'
//                 },
//                 {
//                     id: '345',
//                     name: 'Supervisor Group',
//                     type: 'group'
//                 }
//             ],
//             reason: 'Employee edited HR information from within their own profile'
//         },
//         {
//             id: 2,
//             isActive: true,
//             name: 'HR Update - Profile Change',
//             notifyees: [
//                 {
//                     id: '123',
//                     name: 'Charles Steerman',
//                     type: 'user'
//                 },
//                 {
//                     id: '345',
//                     name: 'Supervisor Group',
//                     type: 'group'
//                 }
//             ],
//             reason: 'Employee edited HR information from within their own profile'
//         }
//     ],
//     isRiskMonitoringActive: true,
//     logCategories: [
//         {
//             isActive: true,
//             name: 'Log Category 1',
//             options: ['Option 1', 'Option 2', 'Option 3'],
//             selectedOptions: ['Option 1', 'Option 2']
//         },
//         {
//             isActive: true,
//             name: 'Log Category 2',
//             options: ['Option 1', 'Option 2', 'Option 3'],
//             selectedOptions: ['Option 1', 'Option 3']
//         },
//         {
//             isActive: true,
//             name: 'Log Category 3',
//             options: ['Option 1', 'Option 2', 'Option 3'],
//             selectedOptions: ['Option 1', 'Option 2']
//         },
//         {
//             isActive: true,
//             name: 'Log Category 4',
//             options: ['Option 1', 'Option 2', 'Option 3'],
//             selectedOptions: ['Option 1', 'Option 2']
//         }
//     ],
//     masterEmail: 'example@asd.com'
// };