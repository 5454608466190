import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateRiskLevel } = state;

const { updateRiskLevelAtom } = updateRiskLevel.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateUpdateCaseRiskLabel(
  $caseId: Int!,
  $riskLevel: String!
  ) {
    update_${tenantSchema}_case (
      _set: {risk_label: $riskLevel}      
      where: {
        id: {_eq: $caseId}
      }
    ) {
      returning {
        risk_label
      }
    }
  }
`;

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insertCaseHistoryDropdown($caseId: Int!, $color: String = "yellow", $message: String = "changed the case risk level", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);
    const mutationCaseHistory = useGraphQL(MUTATE_CASE_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const updateRiskLabel = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
              const res = await mutation(variables);

              const updatedRiskLabel = res?.data[`update_${usedSchema}_case`].returning[0]?.risk_label;

              await mutationCaseHistory(variables);

              set(updateRiskLevelAtom, updatedRiskLabel);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateRiskLevelAtom, '');
    });

    return [useRecoilValue(updateRiskLevelAtom), updateRiskLabel, reset];
};

export default useData;