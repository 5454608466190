import ActivitiesReportPanel from 'components/ActivitiesReportPanel';
import WithDownloadButton from 'components/WithDownloadButton';
import WithPageTitle from 'components/WithPageTitle';

import useActivitiesData from '../Activities/hooks/useData';

import styles from './styles.module.scss';

const Reports = () => {
    const { loading, error, data } = useActivitiesData();
    const disableDownload = Boolean(loading || error) || data.length === 0;
    return (
        <div className={styles.reports}>
            <WithPageTitle className={styles.head} title="Reports">
                <WithDownloadButton
                    data={data}
                    hasPDFexport={true}
                    hasToggle={false}
                    isDisabled={disableDownload}
                    name="Activities"
                />
            </WithPageTitle>

            <ActivitiesReportPanel className={styles.reportsPanel} />
        </div>
    );
};

export default Reports;
