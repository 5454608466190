import { useEffect, useState } from 'react';

import Buttonize from 'components/Buttonize';
import CountIcon from 'components/icons/CountIcon';
import DoughnutIcon from 'components/icons/DoughnutIcon';
import HorizontalBarIcon from 'components/icons/HorizontalBarIcon';
import NoChartIcon from 'components/icons/NoChartIcon';
import PieIcon from 'components/icons/PieIcon';
import PolarAreaIcon from 'components/icons/PolarAreaIcon';
import RadarIcon from 'components/icons/RadarIcon';
import ScatterIcon from 'components/icons/ScatterIcon';
import VerticalBarIcon from 'components/icons/VerticalBarIcon';

import styles from './styles.module.scss';

const ChartButtons = ({ charts, column, options, refreshCharts, setCharts, setRefreshCharts }) => {
    const [currentChart, setCurrentChart] = useState('none');

    function onClick(){
        const currentIndex = options.indexOf(currentChart);
        const newIndex = (currentIndex + 1) < options.length ? currentIndex + 1 : 0;
        const newChart = options[newIndex];
        const chartIndex = charts.findIndex(x => x.column === column);
        if (chartIndex >= 0){
            if(newChart === 'none'){
                const newCharts = charts;
                newCharts.splice(chartIndex, 1);
                setCharts(newCharts);
            }else{
                const oldChart = charts[chartIndex];
                oldChart.type = newChart;
                const newCharts = charts;
                newCharts[chartIndex] = oldChart;
                setCharts(newCharts);
            }
        }else{
            const newCharts = charts;
            newCharts.push({column, type: newChart});
            setCharts(newCharts);
        }
        setRefreshCharts(!refreshCharts);
    }

    useEffect(() => {
        const initialChart = charts.filter(c => c.column === column);
        const initialChartState = initialChart.length > 0 ? initialChart[0]?.type : 'none';
        setCurrentChart(initialChartState);

    }, [charts, column, refreshCharts]);

    const chartMap = {
        count: CountIcon,
        doughnut: DoughnutIcon,
        horizontalBar: HorizontalBarIcon,
        none: NoChartIcon,
        pie: PieIcon,
        polar: PolarAreaIcon,
        radar: RadarIcon,
        scatter: ScatterIcon,
        verticalBar: VerticalBarIcon
    };

    const ChartComponent = chartMap[currentChart];

    return(    
        <Buttonize
            className={styles.icon}
            onClick={onClick}
        >
            <ChartComponent className={styles.iconComponent} />
        </Buttonize>
    );
}

export default ChartButtons;
