import * as ExcelJS from "exceljs";

const generateXLSX = async (data, name, detailed=false) => {
    const workbook = new ExcelJS.Workbook();

    const headerFill = { fgColor:{argb:'219653'}, pattern:'solid', type: 'pattern' };
    const subHeaderFill = { fgColor:{argb:'def2e1'}, pattern:'solid', type: 'pattern' };
    const standardFill = { fgColor:{argb:'FFFFFF'}, pattern:'solid', type: 'pattern' };
    // const overdueHeaderFill = { fgColor:{argb:'eb5b5e'}, pattern:'solid', type: 'pattern' };
    const overdueSubHeaderFill = { fgColor:{argb:'f6b8b9'}, pattern:'solid', type: 'pattern' };
    const inProgressSubHeaderFill = { fgColor:{argb:'d6e0f9'}, pattern:'solid', type: 'pattern' };
    const upcomingSubHeaderFill = { fgColor:{argb:'d9d2fd'}, pattern:'solid', type: 'pattern' };
    // const overdueStandardFill = { fgColor:{argb:'FFFFFF'}, pattern:'solid', type: 'pattern' };
    const whiteText = { color: { argb: 'FFFFFFFF' } };
    const fontHyperlink = { color: { argb: '0000FF' }, underline: true };
    // const italicizeText = { italic: true };
    const headerBorder = { bottom: { color: {argb:'219653'}, style:'thin' }, left: { color: {argb:'219653'}, style:'thin' }, right: { color: {argb:'219653'}, style:'thin' }, top: { color: {argb:'219653'}, style:'thin' } };
    const standardBorder = { bottom: { color: {argb:'eeeeee'}, style:'thin' }, left: { color: {argb:'eeeeee'}, style:'thin' }, right: { color: {argb:'eeeeee'}, style:'thin' }, top: { color: {argb:'eeeeee'}, style:'thin' } };

    workbook.creator = 'Skematic';
    workbook.lastModifiedBy = 'Skematic';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.properties.date1904 = true;
    workbook.calcProperties.fullCalcOnLoad = true;
    const sheet = workbook.addWorksheet(name, {
        views: [{state: 'frozen', ySplit: 1}]
    });
    sheet.addRow(data?.headers);
    sheet.getRow(1).fill = headerFill;
    sheet.getRow(1).font = whiteText;
    sheet.getRow(1).border = headerBorder;
    const statusRowInt = detailed ? 2 : 1;

    for (let i = 0; i < data?.rows.length; i++) {
        const rowNumber = i+2;
        sheet.addRow(data?.rows[i]);
        sheet.getRow(rowNumber).fill = (data?.rows[i][statusRowInt] === 'Overdue') ? overdueSubHeaderFill : (data?.rows[i][statusRowInt] === 'In Progress' || data?.rows[i][statusRowInt] === 'Draft')
            ? inProgressSubHeaderFill : (data?.rows[i][statusRowInt] === 'Upcoming') ? upcomingSubHeaderFill : (data?.rows[i][statusRowInt] === 'Withdrawn') ? standardFill : (data?.rows[i][statusRowInt] === 'Complete') ? subHeaderFill : standardFill;
        sheet.getRow(rowNumber).border = standardBorder;
    }

    const headerRow = sheet.getRow(1)
    sheet.columns.forEach(function (column) {
        var maxLength = 0;
        const instructionsMinLength = 70;
        const otherMinLength = 30;
        const value = headerRow.getCell(column?._number).value;
        const minLength = (value === 'Instructions' || value === 'Description') ? instructionsMinLength : otherMinLength;

        column["eachCell"]({ includeEmpty: true }, function (cell) {
            if (cell.value?.toString().includes('\n')) {
                cell.alignment = { wrapText: true };
            }

            if(cell.value?.hyperlink) {
                cell.font = fontHyperlink;
            }
            
            var columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength ) {
                maxLength = columnLength;
            }
        });
        column.width = maxLength > minLength ? minLength : maxLength;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    return buffer
}

export default generateXLSX