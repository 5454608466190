import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('documentManagementPanel');

    return {
        CATEGORIES: t('categories'),
        DATE_RANGE_END: t('dateRangeEnd'),
        DATE_RANGE_START: t('dateRangeStart'),
        NAME: t('name'),
        SEARCH: t('search'),
        SECTIONS: t('sections'),
        STATUS: t('status'),
        VERSION: t('version'),
        VIEW_ALL: t('viewAll')
    };
};

export default useTranslation;
