import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { caseDetailsPanel } = state;

const { updateResolutionAtom } = caseDetailsPanel.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateCaseActionPlan($text: String, $caseId: Int!) {
    update_${tenantSchema}_case(where: {id: {_eq: $caseId}}, _set: {resolution: $text}) {
      returning {
        resolution
      }
    }
  }
`;

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insertCaseHistoryDropdown($caseId: Int!, $color: String = "yellow", $message: String = "changed the resolution", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);
    const mutationCaseHistory = useGraphQL(MUTATE_CASE_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveResolution = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if (variables?.text === '') { variables.text = null } 
            
            const res = await mutation(variables);

            const newResolution = res?.data[`update_${usedSchema}_case`].returning[0]?.resolution;

            await mutationCaseHistory(variables);

            if (!newResolution) {
                set(updateResolutionAtom, ' ');
            } else {
                set(updateResolutionAtom, newResolution);                
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateResolutionAtom, '');
    });

    return [useRecoilValue(updateResolutionAtom), saveResolution, reset];
};

export default useData;
