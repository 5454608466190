const translations = {
    description: 'Description',
    effectiveDate: 'Effective Date',
    policyDetails: 'Policy Details',
    policyName: 'Policy Name',
    policyReference: 'Reference',
    riskLabel: 'Risk Label'
};

export default translations;
