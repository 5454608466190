import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import AddButton from 'components/AddButton';

import useTranslation from './hooks/useTranslation';

const AddActivityButton = ({ className = '' }) => {
    const { ADD_ACTIVITY } = useTranslation();

    const { ADD_ACTIVITY_ROUTE } = useConfig();

    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(ADD_ACTIVITY_ROUTE);
    }, [ADD_ACTIVITY_ROUTE, navigate]);

    return (
        <AddButton
            className={className}
            onClick={handleClick}
            text={ADD_ACTIVITY}
        />
    );
};

export default AddActivityButton;
