import { gql } from '@apollo/client';

const generateQuery = (query, schema, tenants, variables, customGqlContainer=false) => {

    const queryStrings = [];
    if(tenants && Array.isArray(tenants) && tenants.length > 0){
        for(const t of tenants){
            queryStrings.push(query(t));
        }
    }else{
        queryStrings.push(query(schema));
    }
    const queryString = queryStrings.join("");
    if(customGqlContainer){
        return gql`
            query getActivities(${variables}) {
                ${queryString}
            }
        `;
    }else{
        return gql`
            query getActivities {
                ${queryString}
            }
        `;
    }
}

export default generateQuery;