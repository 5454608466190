import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';

const UPDATE_ATTACHMENT = tenantSchema => gql`
    mutation updateMarketingVersionAttachment($id: Int!, $versionNumber: Int!, $filename: String!) {
        update_${tenantSchema}_marketing_request_version(where: {id: {_eq: $id}, version_number: {_eq: $versionNumber}}, _set: {file_name: $filename}) {
            returning {
              id
            }
          }
        }        
`;

const DELETE_ATTACHMENT = tenantSchema => gql`
    mutation updateMarketingVersionAttachment($id: Int!, $versionNumber: Int!) {
        update_${tenantSchema}_marketing_request_version(where: {id: {_eq: $id}, version_number: {_eq: $versionNumber}}, _set: {deleted: true}) {
            returning {
              id
            }
          }
        }        
`;

const useAttachments = providedSchema => {
    const updateAttachmentMutation = useGraphQL(
        UPDATE_ATTACHMENT,
        providedSchema
    );
    const deleteAttachmentMutation = useGraphQL(
        DELETE_ATTACHMENT,
        providedSchema
    );

    const updateAttachment = async params => {
        const { filename, id, versionNumber } = params;
        await updateAttachmentMutation({ filename, id, versionNumber });
    };

    const deleteAttachment = async params => {
        const { id, versionNumber } = params;
        await deleteAttachmentMutation({ id, versionNumber });
    };

    return [updateAttachment, deleteAttachment];
};

export default useAttachments;
