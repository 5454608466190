const translations = {
    authentication: 'Authentication',
    emailSent:
        'An authentication email has been sent to your registered email address.  Please click the link contained in the email to complete login.',
    loginTimeout: 'The login attempt has timed out.',
    successfulLogin:
        'Successful login. This window will be closed in SECONDS seconds.'
};

export default translations;
