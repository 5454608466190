import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

import useData from './hooks/useData';

import styles from './styles.module.scss';

const UploadPhotoPanel = ({ className = '' }) => {
    const items = useData();

    return (
        <ContentPanel className={`${className} ${styles.uploadPhotoPanel}`}>
            <PanelBody className={styles.panelBody}>
                {items.map(() => (
                    <></>
                ))}
            </PanelBody>
        </ContentPanel>
    );
};

export default UploadPhotoPanel;
