import { useCallback, useEffect } from 'react';

import ContentPanel from 'components/ContentPanel';
import GroupUserList from 'components/GroupUserList';
import PanelBody from 'components/PanelBody';
import GroupSelectUsersDrawer from 'components/GroupSelectUsersDrawer';

import useAddMember from './hooks/useAddMember';
import useDeleteMember from './hooks/useDeleteMember';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const GroupMembersPanel = ({ groupId, memberOptions, selectedUsers, setSelectedUsers }) => {
    const { ADD_MEMBER } = useTranslation();

    const [addedGroupMember, addGroupMember, resetAddedGroupMember] = useAddMember();
    const [deletedGroupMember, deleteGroupMember, resetDeletedGroupMember] = useDeleteMember();

    const handleAddOrRemove = useCallback((clickedUser) => {
        const searchedUser = (selectedUsers ?? []).find(selectedUser => selectedUser.id === clickedUser.userId);

        if (searchedUser) {
            const remainingSelectedUsers = (selectedUsers ?? []).filter(selectedUser => selectedUser.id !== searchedUser.id);
            setSelectedUsers([...remainingSelectedUsers]);
            searchedUser.groupId = groupId;
            deleteGroupMember(searchedUser);
        } else {
            const addedMember = (memberOptions ?? []).find(member => member.id === clickedUser.userId);
            addedMember.groupId = groupId;
            setSelectedUsers([...selectedUsers, addedMember]);
            addGroupMember(addedMember);
        }
    }, [addGroupMember, deleteGroupMember, groupId, memberOptions, selectedUsers, setSelectedUsers]);

    useEffect(() => {
        if (!addedGroupMember) {
            return;
        }

        resetAddedGroupMember();
    }, [addedGroupMember, resetAddedGroupMember, selectedUsers, setSelectedUsers])

    useEffect(() => {
        if (!deletedGroupMember) {
            return;
        }

        resetDeletedGroupMember();
    }, [deletedGroupMember, resetDeletedGroupMember, selectedUsers, setSelectedUsers])

    return (
        <ContentPanel>
            <PanelBody className={styles.panelBody}>
                <div className={styles.groupMemberSection}>
                    {/* <BodyText className={styles.title} text={`${GROUP_MEMBERS} (${memberOptions.length})`} /> */}

                    <GroupSelectUsersDrawer 
                        assignmentType={''} 
                        onChange={handleAddOrRemove}
                        selectedUsers={selectedUsers}
                        users={memberOptions}
                    >
                        {({ toggle }) => (
                            <GroupUserList
                                className={styles.members}
                                key={ADD_MEMBER}
                                onEditClick={toggle}
                                title={ADD_MEMBER}
                                users={selectedUsers}
                            />
                        )}
                    </GroupSelectUsersDrawer>
                </div>
            </PanelBody>
        </ContentPanel>
    );
};

export default GroupMembersPanel;
