import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('adminRiskMonitoring');

    return {
        ACTIVE: t('active'),
        INACTIVE: t('inactive'),
        RISK_BASED_MONITORING: t('riskBasedMonitoring')
    };
};

export default useTranslation;
