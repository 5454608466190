const translations = {
    activityName: 'Activity Name',
    activityType: 'Activity Type',
    caseName: 'Case Name',
    caseType: 'Case Type',
    closeAndCreateLogEntry: 'Close and Create Log Entry',
    createLogEntry: 'Create Log Entry',
};

export default translations;
