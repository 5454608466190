import ClockIcon from 'components/icons/ClockIcon';
import IconWithText from 'components/IconWithText';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const Overdue = ({ className = '', count }) => {
    const { DAYS_OVERDUE } = useTranslation();

    return (
        <IconWithText
            className={`${className} ${styles.overdue}`}
            icon={ClockIcon}
            text={DAYS_OVERDUE.replace('COUNT', Math.abs(count))}
        />
    );
};

export default Overdue;
