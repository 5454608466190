import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('complianceLogEntryMetaPanel');

    return {
        CREATED_BY: t('createdBy'),
        DATE_OF_LOG_ENTRY: t('dateOfLogEntry'),
        USERS_INVOLVED: t('usersInvolved'),
        VISIBLE_BY: t('visibleBy')
    };
};

export default useTranslation;
