import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('activityMetaPanel');

    return {
        COMPANY: t('company'),
        DUE_DATE: t('dueDate'),
        FREQUENCY: t('frequency'),
        HIERARCHY: t('hierarchy'),
        SEND_EMAIL: t('sendEmail'),
        START_DATE: t('startDate'),
        USERS_INVOLVED: t('usersInvolved'),
        VISIBLE_BY: t('visibleBy')
    };
};

export default useTranslation;
