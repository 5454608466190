import { useRecoilCallback, useRecoilValue } from 'recoil';
// import { useMutation, gql } from '@apollo/client';

import state from 'app/state';

const { markCompleteButton } = state;

const { markCompleteButtonAtom } = markCompleteButton.atoms;

// const MUTATE_BUTTON = (/*tenantSchema*/) => gql`
//     mutation markAsComplete () {
//     }
// `;

const useData = () => {
    // const [markAsCompleteGql] = useMutation(MUTATE_BUTTON(schema));

    const markAsComplete = useRecoilCallback(
        (/* {  set } */) => (/*{ id }*/) => {
            const asyncUpdateDropdown = async () => {
                // const res = await markAsCompleteGql({
                //     variables: {
                //         // YOUR VARIABLES GO HERE
                //     }
                // });

                // 
                
                // set(markCompleteButtonAtom, WHATEVER_YOU_WANT_TO_PUT_IN_GLOBAL_STATE);
            };

            asyncUpdateDropdown();
        }
    );

    return [useRecoilValue(markCompleteButtonAtom), markAsComplete];
};

export default useData;
