import ChartButtons from 'components/ChartButtons';
import HeaderText from 'components/HeaderText';
import TableRow from 'components/TableRow';
import titleCase from 'utilities/titleCase';

import styles from './styles.module.scss';

const DynamicTableHeader = ({ charts, className = '',hasCustomDateDropdown,  refreshCharts, selectedColumns, setCharts, setRefreshCharts, styleType }) => {
    const headerElements = selectedColumns.map((item, index) => (
        <span key={`${item}.${index}`}>
            <HeaderText
                className={`${className} ${styleType === 'overdue' && index === 0 ? styles.overdueTitle : styleType === 'pending' && index === 0 ? styles.pendingTitle : styleType === 'closed' && index === 0 ? styles.closedTitle : styles.defaultTitle}`}
                text={titleCase(item)}
            />

            <ChartButtons 
                charts={charts}
                column={selectedColumns[index]}
                options={['none', 'count', 'doughnut', 'horizontalBar', 'pie', 'polar', 'radar', 'scatter', 'verticalBar']}
                refreshCharts={refreshCharts}
                setCharts={setCharts}
                setRefreshCharts={setRefreshCharts}
            />
        </span>
    ));

    const dateColumn = <HeaderText className={`${className} ${styles.defaultTitle} `} key="date-dropdown-header" text='Due date' />
    let allElements
    if(hasCustomDateDropdown){
        allElements = [dateColumn, ...headerElements];
    }else{
        allElements = [headerElements];
    }

    return(    
        <TableRow className={`${className} ${styles.caseTableHeader}`}>
            {allElements}
        </TableRow>
    );
}

export default DynamicTableHeader;
