const normalizeData = ({ data, schema }) =>
    data[`${schema}_form`].reduce((accumulator, group) => {
        const {
            form_visibilities: visibility,            
            id,
            // is_active: isActive,
            name: field
        } = group;

        accumulator.forms.push({
            field,
            id,
            status: 'activated',
            visibility,
        });

        return accumulator;
    }, {
        forms: [],
        workflows: [
            {
                approvingGroup: ['Compliance Supervisors'],
                id: 'workflow-1',
                status: 'active',
                submittingGroup: ['APAC', 'Global']
            },
            {
                approvingGroup: ['Compliance Supervisors'],
                id: 'workflow-2',
                status: 'active',
                submittingGroup: ['APAC', 'Global']
            },
            {
                approvingGroup: ['Compliance Supervisors'],
                id: 'workflow-3',
                status: 'active',
                submittingGroup: ['APAC', 'Global']
            }
        ]
    });

export default normalizeData;