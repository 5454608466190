import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addPersonButton');

    return {
        ADD_PERSON: t('addPerson')
    };
};

export default useTranslation;
