import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createCaseMenuListItemFromActivity');

    return {
        CREATE_A_CASE: t('createCase')
    };
};

export default useTranslation;
