import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('employeesPanel');

    return {
        EMAIL: t('email'),
        GROUP: t('group'),
        GROUPS: t('groups'),
        NAME: t('name'),
        REGISTRATION: t('registration'),
        ROLE: t('role'),
        ROLES: t('roles'),
        SEARCH: t('search'),
        STATUS: t('status'),
        TENANT: t('tenant'),
        USERNAME: t('username')
    };
};

export default useTranslation;
