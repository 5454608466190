import { useCallback, useEffect, useState } from 'react';
import AvatarWithTextLink from 'components/AvatarWithTextLink';
import BodyLink from 'components/BodyLink';
import ImportantLink from 'components/ImportantLink';
import ListItemDetailsButton from 'components/ListItemDetailsButton';
import NegativeBarListItem from 'components/NegativeBarListItem';
import WithTooltipAvatar from 'components/WithTooltipAvatar';

import useConfig from 'hooks/useConfig';
import useComplete from './hooks/useComplete';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { usePermissions } from 'hooks/usePermissions';

import { safeGetLocalStorage } from 'utilities/localStorage';

import styles from './styles.module.scss';

const ACTIVITIES_ACTIONS_PERMISSIONS = [
    'activities:actions',
];

const OverdueSummarizedListItem = ({
    active,
    assigned,
    className = '',
    completable,
    dueDate,
    flagged,
    id,
		item,
		onDownloadItem,
    reviewer,
    schema,
    text,
    type
}) => {
    const { ACTIVITY_ROUTE } = useConfig();

    const [complete, setComplete] = useState(false);
    const [completeStyle, setCompleteStyle] = useState(false);
    const { useActivities, useUserId } = useGlobalStateHooks();
    const [activities, setActivities] = useActivities();
    const [userId] = useUserId();

    const { hasPermissions } = usePermissions();

    const activitiesActionsPermission = hasPermissions(
        ACTIVITIES_ACTIONS_PERMISSIONS
    );

    const [completedActivity, completeActivity, resetCompleteActivity] = useComplete(schema);

    const handleComplete = useCallback(() => {
        setCompleteStyle(true);
        const formObject = {activityId:id, completable, groupId:null, status:'complete', userId:parseInt(userId)};
        completeActivity(formObject);
        }, [completable, completeActivity, id, setCompleteStyle, userId]
    );

    useEffect(() => {
    }, [completeStyle]);

    useEffect(() => {
        if (!completedActivity) {
            return;
        }
        if (id===completedActivity){
            resetCompleteActivity();
            const completed = [...activities?.completed];
            const inProgress = [...activities?.inProgress];
            const upcoming = [...activities?.upcoming];
            const overdueOriginal = [...activities?.overdue];
            const overdueUnfiltered = overdueOriginal.map(activity => {
                if(activity?.id !== completedActivity){
                    return activity;
                }else{
                    completed.push(activity);
                    return false;
                }
            })
            const overdue = overdueUnfiltered.filter(el => el)
            setActivities({completed, inProgress, overdue, upcoming});
            setComplete(true);
        }
    }, [activities, completedActivity, complete, setActivities, setComplete, id, resetCompleteActivity]);

    const newTab = safeGetLocalStorage('openActivitiesAndCasesInNewTab', false);

    return (
        <>{!complete &&
        <NegativeBarListItem
            className={`${className} ${completeStyle ? styles.completing : ''} ${styles.overdueSummarizedListItem}`}
        >
            <ImportantLink
                className={styles.text}
                newTab={newTab}
                text={text}
                to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
            />

            <BodyLink
                className={styles.date}
                newTab={newTab}
                text={dueDate}
                to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
            />

            <BodyLink
                className={styles.avatar}
                newTab={newTab}
                text={type}
                to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
            />

            <WithTooltipAvatar assigned={assigned} reviewer={reviewer} section={'overdue'}>
                <AvatarWithTextLink
                    assigned={[...assigned, ...reviewer]}
                    className={styles.link}
                    newTab={newTab}
                    section={'overdue'}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />
            </WithTooltipAvatar>

            {activitiesActionsPermission && (
                <ListItemDetailsButton active={active} activityName={text} flagged={flagged} handleComplete={handleComplete} id={id} onDownload={()=>onDownloadItem(item)} schema={schema}  />
            )}
        </NegativeBarListItem>
        }</>
    );
};

export default OverdueSummarizedListItem;