import Title from 'components/Title';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const InProgressItems = ({ children, className = '' }) => {
    const { IN_PROGRESS } = useTranslation();

    return (
        <div className={`${className} ${styles.inProgressItems}`}>
            <Title className={styles.title} text={IN_PROGRESS} />

            {children}
        </div>
    );
};

export default InProgressItems;
