import Link from 'components/Link';
import downloadFile from 'utilities/downloadFile';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useConfig from 'hooks/useConfig';

const StorageLink = ({ className = '', providedSchema=null, text, url }) => {
    const { API_KEY, GET_FILE_URL } = useConfig();
    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const handleClick = () => {
        const usedSchema = providedSchema ? providedSchema : schema;
        downloadFile(usedSchema, url, API_KEY, GET_FILE_URL);
    }

    return (
        <Link
            className={className}
            onClick={handleClick}
        >
            {text}
        </Link>
    );
}

export default StorageLink;
