import { useRef, useState, useEffect, useCallback } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


import DownloadButton from 'components/DownloadButton';
import { useGetFilteredActivitiesData } from 'components/Activities/hooks/useFilteredActivities';
import { ExportModal } from 'components/ExportModal';

import { useExportActivities } from 'hooks/useExportActivities';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import { getSchemaDataName } from 'utilities/schema';
import { getFileName } from 'utilities/files';

import styles from './styles.module.scss';

const WithDownloadButton = props => {
    const { className = '', children, hasPDFexport, data, isDisabled = false, name, hasToggle = false  } = props;
    const {
        useActivities,
        useIsAllFilter,
        useQuerySchemas,
        useSchema,
        useTenants,
        useUserId
    } = useGlobalStateHooks();
    const [activities] = useActivities();
    const [tenants] = useTenants();
    const [isAll] = useIsAllFilter();
    const [userId] = useUserId();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const { exportActivities, exportDetailedActivities } =
        useExportActivities();
    const filteredActivities = useGetFilteredActivitiesData();

    const [showExportModal, setShowExportModal] = useState(false);
    const [isCreatingPDF, setIsCreatingPDF] = useState(false);

    const exportActionRef = useRef({ action: () => ({}), type: '' });

    const getFilteredActivitiesRawData = () => {
        const activities = Object.values(filteredActivities)
            .flatMap(activity => activity)

        const rawData = activities.reduce((acc, activity) => {
            const schemaDataName = getSchemaDataName(activity.schema);
            if (!acc[schemaDataName]) {
                acc[schemaDataName] = [];
            }

            const detailedActivity = data[
                getSchemaDataName(activity.schema)
            ]?.find(item => item.id === activity.id);

            if (detailedActivity) {
                acc[schemaDataName].push(detailedActivity);
            }

            return acc;
        }, {});

        return rawData;
    };

    const onSimpleExport = () => {
        const action = ({ completed, inProgress, overdue, upcoming }) =>
            exportActivities({
                complete: completed,
                inprogress: inProgress,
                name,
                overdue,
                upcoming
            });

        exportActionRef.current = {
            action,
            type: 'simple'
        };

        onShowExportModal();
    };

    const onDetailedExport = () => {
        const action = ({ data }) =>
            exportDetailedActivities({
                data,
                isAll: hasToggle ? isAll : true,
                name,
                querySchemas,
                schema,
                tenants,
                userId
            });
        exportActionRef.current = {
            action,
            type: 'detailed'
        };

        onShowExportModal();
    };

    const onShowExportModal = () => {
        setShowExportModal(true);
    };

    const onHideExportModal = () => {
        setShowExportModal(false);
    };

    const onExportAll = () => {
        onHideExportModal();
        if (exportActionRef.current) {
            const { action, type } = exportActionRef.current;
            const actionArgs =
                type === 'detailed'
                    ? {
                          data
                      }
                    : type === 'simple'
                    ? activities
                    : undefined;

            if (actionArgs) {
                action(actionArgs);
            }
        }
    };

    const onExportFiltered = () => {
        onHideExportModal();
        if (exportActionRef.current) {
            const { action, type } = exportActionRef.current;

            const actionArgs =
                type === 'detailed'
                    ? {
                          data: getFilteredActivitiesRawData()
                      }
                    : type === 'simple'
                    ? filteredActivities
                    : undefined;

            if (actionArgs) {
                action(actionArgs);
            }
        }
    };

    const exportPDF = useCallback(async () => {
        try {
            const input = document.getElementById('capture');
            if (!input) {
                console.error('Element with ID "capture" not found.');
                return;
            }
    
            input.classList.add('pdf-export');
    
            const canvas = await html2canvas(input, {
                scale: 1,
                scrollX: 0,
                scrollY: 0,
                useCORS: true
            });
    
            const imgData = canvas.toDataURL('image/png');
            if (!imgData.startsWith('data:image/png')) {
                console.error('Invalid image data URL:', imgData);
                return;
            }
    
            const pdf = new jsPDF({
                format: 'a4',
                orientation: 'portrait',
                unit: 'mm',
            });
    
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
    
            const imgProps = pdf.getImageProperties(imgData);
            const imgWidth = pdfWidth;
            const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
            const pageHeightInCanvasPixels = pdfHeight * (imgProps.width / pdfWidth);
            const pageCount = Math.ceil(imgHeight / pdfHeight);
    
            const nameFile = getFileName('Activities');
    
            const spaceAtTop = 20;
    
            for (let i = 0; i < pageCount; i++) {
                if (i > 0) {
                    pdf.addPage();
                }
    
                const sourceY = i * pageHeightInCanvasPixels;
                const targetHeight = Math.min(pageHeightInCanvasPixels, imgProps.height - sourceY);
    
                const pageCanvas = document.createElement('canvas');
                pageCanvas.width = imgProps.width;
                pageCanvas.height = targetHeight;
                const ctx = pageCanvas.getContext('2d');
                ctx.drawImage(canvas, 0, sourceY, imgProps.width, targetHeight, 0, 0, imgProps.width, targetHeight);
    
                const pageImgData = pageCanvas.toDataURL('image/png');
                pdf.addImage(
                    pageImgData, 
                    'PNG', 
                    0, 
                    i === 0 ? spaceAtTop : 0,
                    pdfWidth, 
                    (targetHeight / imgProps.width) * pdfWidth
                );
            }
    
            pdf.save(nameFile + '.pdf');
        } catch (error) {
            console.error('Error exporting PDF:', error);
        } finally {
            setIsCreatingPDF(false);
        }
    }, []);

    useEffect(() => {
        if(isCreatingPDF){
            exportPDF();
        }
    }, [isCreatingPDF, exportPDF]);

    return (
        <>
            {showExportModal && (
                <ExportModal
                    hasPDFButton={hasPDFexport}
                    isOpen={showExportModal}
                    onClose={onHideExportModal}
                    onExportAll={onExportAll}
                    onExportFiltered={onExportFiltered}
                    title="Export Activities"
                />
            )}

            <div className={`${className} ${styles.withDownloadButton}`}>
                <DownloadButton
                    className={styles.downloadButton}
                    hasPDFButton={hasPDFexport}
                    isDisabled={isDisabled}
                    onExcelExport={onSimpleExport}
                    onExportPdfReport={() => setIsCreatingPDF(true)}
                    onPdfExport={onDetailedExport}
                />

                {children}
            </div>
        </>
    );
};

export default WithDownloadButton;
