import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('policyDetailsPanel');

    return {
        ADD_DESCRIPTION: t('addDescription'),
        CATEGORIES: t('categories'),
        DESCRIPTION: t('description'),
        DETAILS: t('details'),
        EFFECTIVE_DATE: t('effectiveDate'),
        POLICY_DOCUMENT: t('policyDocument'),
        POLICY_NAME: t('policyName'),
        SELECT_DOCUMENT: t('selectDocument'),
        UPLOAD: t('upload')
    };
};

export default useTranslation;
