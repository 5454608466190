import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('confirmationModal');

    return {
        ARE_YOU_SURE: t('areYouSure')
    };
};

export default useTranslation;
