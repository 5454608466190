import sendEmail from './sendEmail';
import { client } from 'graphql-client';
import { gql } from '@apollo/client';

async function sendMarketingDeniedEmail(requestId, requestName, groupId, schema, schemaDescription, userId, key){
  const GET_EMAIL = gql`query getEmail {tenant_user_by_pk(id: ${userId}) {email}}`;
  const GET_GROUP = gql`query getGroup {${schema}_group_user(where: {group_id: {_eq: ${groupId}}}) {tenant_user_id}}`;
  var emails = [];
  if (userId) {
    const emailObject = await client.query({query: GET_EMAIL});
    emails = [...emails, emailObject.data.tenant_user_by_pk.email];
  }

  if (groupId) {
    const groupEmailObject = await client.query({query: GET_GROUP});
    const groupEmails = await groupEmailObject.data.skematic_group_user.reduce(async (accumulator, tenant_user_id) => {
          const GET_EMAIL_IN_LOOP = gql`query getEmail {tenant_user_by_pk(id: ${tenant_user_id}) {email}}`;
          const emailObject = await client.query({query: GET_EMAIL_IN_LOOP});
          accumulator.push(emailObject.data.tenant_user_by_pk.email);

          return accumulator;
      }, Promise.resolve([]));
    emails = [...emails, ...groupEmails];
  }
  const siteName = window.location.origin.includes('skematic') ? 'Skematic' : 'Beacon';
  const subject = `Your request has been denied. ${requestName} - ${schemaDescription}`;
  const headerIn = `Your marketing request has been denied.`;
  const openingTextIn = `Head over to ${siteName} to view the finalized marketing request.`;
  const explanatoryTextIn = '';
  const buttonUrl = `${window.location.origin}/marketing-request/${schema}/${requestId}/:versionId`
  const buttons = [{'link': buttonUrl, 'style': 'solid', 'text': 'View the Marketing Request Here'}]
  for (const email of emails){
    sendEmail(email, subject, headerIn, openingTextIn, explanatoryTextIn, buttons, key);
  }
}

export default sendMarketingDeniedEmail;