import { useEffect, useState } from 'react';

import AdministrationLogTypesTable from 'components/AdministrationLogTypesTable';
import AdministrationPanel from 'components/AdministrationPanel';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useData from './hooks/useData';

import normalizeData from './data';

const AdministrationLogTypesTab = ({ className = '' }) => {
    console.debug('[AdministrationLogTypesTab]');

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const { loading, error, data } = useData();

    const [logTypes, setLogTypes] = useState([]);

    const [sortBy, setSortBy] = useState(null);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });

            if (normalizedData) {
                setLogTypes(normalizedData);
            };
        };
    }, [data, schema, setLogTypes]);

    if (loading) {
        return `Loading page...`;
    }
    if (error) {
        return `Error! ${error}`;
    };

    return (
        <AdministrationPanel className={className} loading={loading}>
            <AdministrationLogTypesTable
                items={logTypes}
                onSort={setSortBy}
                sortBy={sortBy}
            />
        </AdministrationPanel>
    );
};

export default AdministrationLogTypesTab;







// import { useEffect, useState } from 'react';

// import AdministrationActivityTypesTable from 'components/AdministrationActivityTypesTable';
// import AdministrationPanel from 'components/AdministrationPanel';

// import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
// import useData from './hooks/useData';

// import normalizeData from './data';

// const AdministrationActivityTypesTab = props => {
//     console.debug('[AdministrationActivityTypesTab]');

//     const { className = '' } = props;

//     const [schema] = useSchema();

//     const { loading, error, data } = useData();
    
//     const [activityTypes, setActivityTypes] = useState();

//     const [sortBy, setSortBy] = useState(null);

//     // const { activityTypes = [] } = useData();


//     useEffect(() => {
//         if (data) {
//             const normalizedData = normalizeData({
//                 data,
//                 schema
//             });
//             if (normalizedData) {
//                 setActivityTypes(normalizedData);
//             };
//         };
//     }, [data, schema, setActivityTypes])

//     if (loading) {
//         return `Error! ${error}`;
//     }
//     if (error) {
//         return `Error! ${error}`;
//     };

//     return (
//         <AdministrationPanel className={className}>
//             <AdministrationActivityTypesTable
//                 items={activityTypes}
//                 onSort={setSortBy}
//                 sortBy={sortBy}
//             />
//         </AdministrationPanel>
//     );
// };

// export default AdministrationActivityTypesTab;
