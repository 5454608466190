import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('administrationActivityTypesTableHeader');

    return {
        ACTIVITY_TYPE: t('activityType'),
        RISK_LEVEL: t('riskLevel'),
        VISIBLE_TO: t('visibleTo')
    };
};

export default useTranslation;
