import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { sideLettersListAtom } = state.sideLettersList.atoms;

const useSideLetters = () => [
    useRecoilValue(sideLettersListAtom),
    useSetRecoilState(sideLettersListAtom)
];

export default useSideLetters;