const translations = {
    categories: 'Categories',
    dateRangeEnd: 'Date Range End',
    dateRangeStart: 'Date Range Start',
    documentTags: 'Document Tags',
    documents: 'Documents',
    name: 'Policy Name',
    publishedState: 'Published State',
    riskLevel: 'Risk Level',
    search: 'Search',
    sectionTags: 'Section Tags',
    sections: 'Sections',
    status: 'Status',
    version: 'Version',
    viewAll: 'View All',
    visibility: 'Visibility',
};

export default translations;
