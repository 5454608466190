import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { sectionsListAtom } = state.sectionsList.atoms;

const usePoliciesList = () => [
    useRecoilValue(sectionsListAtom),
    useSetRecoilState(sectionsListAtom)
];

export default usePoliciesList;