import ImportantText from 'components/ImportantText';

import styles from './styles.module.scss';

const EntityTagList = ({ className = '', items }) => (
    <div className={`${className} ${styles.entityTagList}`}>
        {items.map(tag => (
            <ImportantText key={tag} text={tag} />
        ))}
    </div>
);

export default EntityTagList;
