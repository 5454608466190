const normalizeData = ({ data, schema }) =>
    {
        const groups = data[`${schema}_group`].reduce(
            (accumulator, group) => {
                accumulator.push({
                    id: group?.id,
                    label: group?.name,
                    selected: false,
                    type: 'group'
                })
                return accumulator
            }, []
        );
        const users = data[`tenant_user`].reduce(
            (accumulator, tenantUser) => {
                const id = tenantUser?.id;
                const user = tenantUser[`user_${schema}`]
                if (user !== null){
                    accumulator.push({
                        id,
                        label: `${user?.first_name} ${user?.last_name}`,
                        selected: false,
                        type: 'user'
                    })
                }
                return accumulator
            }, []
        );
        const categories = data[`${schema}_categories`].reduce(
            (accumulator, category) => {
                const id = category?.id;
                const label = category?.label;
                if (label !== null){
                    accumulator.push({
                        label,
                        value: id
                    })
                }
                return accumulator
            }, []
        );
        const appliedToOptions = groups.concat(users);

        return { appliedToOptions, categories }
    }

export default normalizeData;
