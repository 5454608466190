import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useConfig from 'hooks/useConfig';
import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import sendMarketingEscalatedEmail from 'utilities/sendMarketingEscalatedEmail';

const { requestUpdateModal } = state;

const { requestUpdateModalAtom } = requestUpdateModal.atoms;

const MUTATE_UPDATE_ASSIGNED_TO = tenantSchema => gql`
mutation updateAssignedTo($requestId: Int!, $userId: Int!, $status: String = "inactive") {
    update_${tenantSchema}_marketing_request_assigned_to(_set: {status: $status}, where: {request_id: {_eq:$requestId}, _and: {user_id: {_eq:$userId}}}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_INSERT_ASSIGNED_TO = tenantSchema => gql`
mutation insertAssignedTo($requestId: Int!, $userId: Int!, $status: String = "inactive") {
    insert_${tenantSchema}_marketing_request_assigned_to(objects: {request_id: $requestId, user_id: $userId, status: $status}) {
      returning {
        id
      }
    }
  }  
`;

const MUTATE_INSERT_VERSION_HISTORY = tenantSchema => gql`
mutation insertVersionHistory(
        $color: String = "yellow",
        $message: String = "escalated the request",
        $userId: Int!,
        $versionId: Int!
    ) {
    insert_${tenantSchema}_marketing_request_version_history(
        objects: {
            color: $color,
            message: $message,
            user_id: $userId,
            version_id: $versionId
        }) {
      returning {
        id
      }
    }
  }  
`;

const useData = (providedSchema) => {
    const mutationUpdateAssignedTo = useGraphQL(MUTATE_UPDATE_ASSIGNED_TO, providedSchema);
    const mutationInsertAssignedTo = useGraphQL(MUTATE_INSERT_ASSIGNED_TO, providedSchema);
    const mutationInsertVersionHistory = useGraphQL(MUTATE_INSERT_VERSION_HISTORY, providedSchema);

    const { useSchema, useSchemaDescription } = useGlobalStateHooks();

    const { API_KEY } = useConfig();

    const [schema] = useSchema();
    const [schemaDescription] = useSchemaDescription();
    const usedSchema = providedSchema ? providedSchema : schema;
    const usedSchemaDescrption = providedSchema ? providedSchema : schemaDescription;

    const requestUpdate = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            for (const assigned of variables?.assignedTo){
              const updateRes = await mutationUpdateAssignedTo({
                  requestId: variables?.requestId,
                  status: 'respond',
                  userId: assigned?.value
              })

              const runInsert = (updateRes?.data[`update_${usedSchema}_marketing_request_assigned_to`].returning.length === 0);
              if(runInsert){
                  await mutationInsertAssignedTo({
                      requestId: variables?.requestId,
                      status: 'respond',
                      userId: assigned?.value
                  });
              }
              sendMarketingEscalatedEmail(variables?.requestId, variables?.requestName, null, usedSchema, usedSchemaDescrption, assigned?.value, API_KEY ? API_KEY : '')
            }

            for(const userId of variables?.assignedUsers){
              await mutationUpdateAssignedTo({
                  requestId: variables?.requestId,
                  status: 'inactive',
                  userId: userId
              });
            }

            await mutationInsertVersionHistory(variables);

            set(requestUpdateModalAtom, 'complete');
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(requestUpdateModalAtom, '');
    });

    return [useRecoilValue(requestUpdateModalAtom), requestUpdate, reset];
};

export default useData;
