import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';

const MUTATE = tenantSchema => gql`
mutation mutateSectionMane($effectiveDate: timestamp!, $id: Int!) {
    update_${tenantSchema}_sections(where: {id: {_eq: $id}}, _set: {effective_date: $effectiveDate}) {
      returning {
        effective_date
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const saveSectionEffectiveDate =  variables => {
        const runMutation = async () => {
            if(variables?.effectiveDate !== ''){
              await mutation(variables);
            }
        };

        runMutation();
    };

    return [saveSectionEffectiveDate];
};

export default useData;
