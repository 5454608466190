import ContentBox from 'components/ContentBox';
import Menu from 'components/Menu';

import usePopperState from 'hooks/usePopperState';

import styles from './styles.module.scss';

const WithTooltipHtml = props => {
    const { className = '', children, placement = 'bottom', text = '' } = props;

    const { handleClick, onClose, referenceElement } = usePopperState();

    return (
        <div
            className={className}
            onMouseEnter={handleClick}
            onMouseLeave={onClose}
        >
            {children}

            {referenceElement && (
                <Menu className={styles.contentBox} placement={placement} referenceElement={referenceElement}>
                    <ContentBox
                        className={styles.content}
                        html={text}
                    />
                </Menu>
            )}
        </div>
    );
};

export default WithTooltipHtml;
