import { memo } from 'react';

import ImportantText from 'components/ImportantText';
import WithAvatar from 'components/WithAvatar';

const AvatarWithTitle = ({
    className = '',
    user = {}
}) => {

    return (
        <WithAvatar className={className} user={user}>
            <ImportantText text={user.name} />
        </WithAvatar>
    );
};

export default memo(AvatarWithTitle);
