import ActivityUserListItem from 'components/ActivityUserListItem';
import EditButton from 'components/EditButton';
import AssigneeStatus from 'components/AssigneeStatus';

import styles from './styles.module.scss';

const ActivityUserList = ({ className = '', onEditClick, users, isCompleted, title }) => (
    <div className={`${className} ${styles.activityUserList}`}>
        <AssigneeStatus isCompleted={isCompleted} text={title} />

        {onEditClick ? (
            <EditButton
                className={styles.editButton}
                onClick={onEditClick}
            />
        ) : null}

        {users.map(user => (
            <ActivityUserListItem
                className={styles.user}
                id={user.id}
                isCompleted={user.status === 'complete'}
                key={`${user.id}_${user.type}`}
                name={`${user.firstName} ${user.lastName}`}
                status={
                    user.status !== 'inprogress' && user.status !== 'removed'
                        ? 'Complete'
                        : user.status === 'removed'
                        ? 'Removed'
                        : isCompleted
                        ? 'Not Required'
                        : 'Pending'
                }
            />
        ))}
    </div>
);

export default ActivityUserList;
