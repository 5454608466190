import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateGroupSupervisors } = state;

const { updateGroupSupervisorsAtom } = updateGroupSupervisors.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateDeleteGroupSupervisors(
  $groupId: Int!,
  $groupSupervisoryId: Int,
  $userId: Int
  ) {
    delete_${tenantSchema}_group_supervisor (
      where: {
        group_id: {_eq: $groupId},
        user_id: {_eq: $userId}
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const deleteUserSupervisor = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
              const res = await mutation(variables);

              const deletedGroupSupervisor = res?.data[`delete_${usedSchema}_group_supervisor`].returning[0]?.id;

              set(updateGroupSupervisorsAtom, deletedGroupSupervisor);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateGroupSupervisorsAtom, '');
    });

    return [useRecoilValue(updateGroupSupervisorsAtom), deleteUserSupervisor, reset];
};

export default useData;
