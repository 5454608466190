import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('casePanel');

    return {
        CASE: t('case')
    };
};

export default useTranslation;
