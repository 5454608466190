import BubbleWithText from 'components/BubbleWithText';
import Items from 'components/Items';

import useTranslation from './hooks/useTranslation';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';
import useConfig from 'hooks/useConfig';

const RequestStatus = ({
    assignedCount = 0,
    className = '',
    reviewCount = 0,
    openCount = 0
}) => {
    const { useUserId } = useGlobalStateHooks();
    const [userId] = useUserId();

    const { MARKETING_REQUESTS_ROUTE } = useConfig();

    const { ASSIGNED_TO_ME, OPEN_REQUESTS, UNDER_REVIEW } = useTranslation();

    return (
        <Items className={`${className} ${styles.requestStatus}`}>
            <BubbleWithText
                className={styles.assignedToMe}
                count={assignedCount}
                text={ASSIGNED_TO_ME}
                url={`${MARKETING_REQUESTS_ROUTE}?userId=${userId}`}
            />

            <BubbleWithText
                className={styles.underReview}
                count={reviewCount}
                text={UNDER_REVIEW}
                url={`${MARKETING_REQUESTS_ROUTE}?openRequestUserId=${userId}`}
            />

            <BubbleWithText
                className={styles.openRequests}
                count={openCount}
                text={OPEN_REQUESTS}
                url={`${MARKETING_REQUESTS_ROUTE}?status=draft`}
            />
        </Items>
    );
};

export default RequestStatus;
