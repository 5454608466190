import { forwardRef, useCallback, useState } from 'react';

import CloseCaseMenu from 'components/CloseCaseMenu';
import SubmitButton from 'components/SubmitButton';

import useTranslation from './hooks/useTranslation';

const CloseCaseButton = forwardRef(
    (
        { caseId, caseRecord, className = '', isDisabled = false },
        ref
    ) => {
        

        const { CLOSE_CASE, CLOSED } = useTranslation();

        const [isButtonDisabled, setIsButtonDisabled] = useState(isDisabled);

        const handleClick = useCallback(() => {
            setIsButtonDisabled(true);
        }, [setIsButtonDisabled]);

        return (
            <CloseCaseMenu
                caseId={caseId}
                caseRecord={caseRecord}
                onClick={handleClick}
            >
                {handleClick => (
                    <SubmitButton
                        className={className}
                        isDisabled={isButtonDisabled}
                        onClick={handleClick}
                        ref={ref}
                        text={isDisabled ? CLOSED : CLOSE_CASE}
                    />
                )}
            </CloseCaseMenu>
        );
    }
);

export default CloseCaseButton;
