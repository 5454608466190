import BodyText from 'components/BodyText';
import ImportantLink from 'components/ImportantLink';
import TableRow from 'components/TableRow';
import AvatarWithTitle from 'components/AvatarWithTitle';

import useConfig from 'hooks/useConfig';

import styles from './styles.module.scss';
import titleCase from 'utilities/titleCase';

const DocumentManagementTableRow = props => {
    const { dateIntroduced, name, referenceId, referenceName, referenceType, tenantCount, type, uploadBy, schema } = props;

    const { ACTIVITY_ROUTE, CASE_ROUTE } = useConfig();

    const url = type === 'Case' ? `${CASE_ROUTE.replace(':caseId', referenceId).replace(':caseSchema', schema)}` : `${ACTIVITY_ROUTE.replace(':activityId', referenceId).replace(':activitySchema', schema)}`

    return (
        <TableRow className={tenantCount>1 ? styles.documentManagementTableRowMulti : styles.documentManagementTableRow}>
            <ImportantLink
                className={styles.link}
                newTab
                text={name}
                to={url}
            />

            <BodyText text={type || '-'} />

            {tenantCount>1 &&
                <BodyText text={titleCase(schema) || '-'} />
            }

            <BodyText text={referenceName || '-'} />

            <BodyText text={referenceType || '-'} />

            <BodyText text={dateIntroduced || '-'} />

            {uploadBy.length > 1? (
                <AvatarWithTitle
                    user={{ name: uploadBy }}
                />
            ) : (
                <BodyText text={'-'} />
            )}

        </TableRow>
    );
};

export default DocumentManagementTableRow;
