import OverdueCountPanel from 'components/OverdueCountPanel';
import DoneCountPanel from 'components/DoneCountPanel';
import InProgressCountPanel from 'components/InProgressCountPanel';
import UpcomingCountPanel from 'components/UpcomingCountPanel';

import styles from './styles.module.scss';

import PanelTitle from 'components/PageTitle';

const ActivitiesStatistics = ({ className = '', completeCount, inProgressCount, overdueCount, upcomingCount }) => {
    const totalCount = completeCount + inProgressCount + overdueCount + upcomingCount;
    const completeRatio = completeCount / totalCount;
    const inProgressRatio = inProgressCount / totalCount;
    const overdueRatio = overdueCount / totalCount;
    const upcomingRatio = upcomingCount / totalCount;
    const minimumSize = 0.5;
    const totalSize = 4;
    const variableSize = totalSize - minimumSize * 4;
    const completeSize = variableSize * completeRatio + minimumSize;
    const inProgressSize = variableSize * inProgressRatio + minimumSize;
    const overdueSize = variableSize * overdueRatio + minimumSize;
    const upcomingSize = variableSize * upcomingRatio + minimumSize;

    return (
        <div className={`${styles.activitiesStatisticsContainer}`}>
            <PanelTitle className={`${styles.activitiesStatisticsHeader}`} text={'Activities Statistics'} />

            <div
                className={`${className} ${styles.activitiesStatistics}`}
                style={{
                    gridTemplateColumns: `${completeSize}fr ${inProgressSize}fr ${upcomingSize}fr ${overdueSize}fr `
                }}
            >
                <DoneCountPanel count={completeCount} />

                <InProgressCountPanel count={inProgressCount} />

                <UpcomingCountPanel count={upcomingCount} />

                <OverdueCountPanel count={overdueCount} />
            </div>
        </div>
    );
};

export default ActivitiesStatistics;
