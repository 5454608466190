import BarListItem from 'components/BarListItem';
import TextLabel from 'components/TextLabel';

import styles from './styles.module.scss';

const TitleListItem = ({ className = '', items = [] }) => (
    <BarListItem className={`${className} ${styles.titleListItem}`}>
        {items.map((item, index) => (
            <TextLabel key={`${item}.${index}`} text={item} />
        ))}
    </BarListItem>
);

export default TitleListItem;
