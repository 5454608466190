import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('policySectionForm');

    return {
        ADD_DESCRIPTION: t('addDescription'),
        CATEGORIES: t('categories'),
        DESCRIPTION: t('description'),
        ENTER_SECTION_NAME: t('enterSectionName'),
        RISK_LABEL: t('riskLabel'),
        SECTION_NAME: t('sectionName')
    };
};

export default useTranslation;
