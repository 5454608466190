import { forwardRef } from 'react';

import Button from 'components/Button';

import styles from './styles.module.scss';

const Buttonize = forwardRef(
    ({ children, className = '', isDisabled = false, onClick }, ref) => (
        <Button
            className={`${className} ${styles.buttonize}`}
            isDisabled={isDisabled}
            onClick={onClick}
            ref={ref}
        >
            {children}
        </Button>
    )
);

export default Buttonize;
