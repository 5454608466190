import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateActivityRecurrence } = state;

const { updateActivityRecurrenceAtom } = updateActivityRecurrence.atoms;

const MUTATE_UPDATE_ACTIVITY_RECURRENCE = tenantSchema => gql`
  mutation updateActivityRecurrence(
        $activityRecurrenceId: Int!,
    ){
    update_${tenantSchema}_activity_recurrence(
      where: {
        id: {_eq: $activityRecurrenceId}
      },
      _set: {
        is_active: false
      }
    ){
        returning {
            id
        }
    }
  }
`;

const MUTATE_DELETE_ACTIVITIES = tenantSchema => gql`
mutation updateActivityToDeleted(
      $activityId: Int!
  ){
  update_${tenantSchema}_activity(where: {id: {_eq: $activityId}}, _set: {deleted: true}) {
    returning {
      id
    }
  }
}
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_UPDATE_ACTIVITY_RECURRENCE, providedSchema);
    const mutationDeleteActivity = useGraphQL(MUTATE_DELETE_ACTIVITIES, providedSchema);
    // const mutationUser = useGraphQL(MUTATE_CASE_USER, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const deleteActivityRecurrence = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const id =
                res?.data[`update_${usedSchema}_activity_recurrence`]?.returning[0]
                    ?.id;

            for(const activityId of variables?.recurrencesForUpdate){
              await mutationDeleteActivity({activityId})
            }

            set(updateActivityRecurrenceAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateActivityRecurrenceAtom, '');
    });

    return [useRecoilValue(updateActivityRecurrenceAtom), deleteActivityRecurrence, reset];
};

export default useData;
