import AdministrationLogTypesTableHeader from 'components/AdministrationLogTypesTableHeader';
import AdministrationActivityTypesTableRow from 'components/AdministrationActivityTypesTableRow';
import Table from 'components/Table';

const AdministrationLogTypesTable = ({ items = [], onSort, sortBy }) => (
    <Table>
        <AdministrationLogTypesTableHeader
            onSort={onSort}
            sortBy={sortBy}
        />

        {items.map(({ id, name, riskLevel, visibleTo }) => (
            <AdministrationActivityTypesTableRow
                key={id}
                name={name}
                riskLevel={riskLevel}
                visibleTo={visibleTo}
            />
        ))}
    </Table>
);

export default AdministrationLogTypesTable;
