import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySettings = tenantSchema => gql`
    query getAdminSettings {
        ${tenantSchema}_admin_settings (where: {setting_name: {_eq: "display_marketing"}}) {
            id
            setting
            setting_name
        }
    }
`;

const useData = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(querySettings(schema), { fetchPolicy: 'no-cache' });
};

export default useData;
