import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('asideMenu');

    return {
        ADMIN: t('admin'),
        CASE_MANAGEMENT: t('caseManagement'),
        COMPLIANCE_CENTRAL: t('complianceCentral'),
        COMPLIANCE_LOG: t('complianceLog'),
        DOCUMENT_MANAGEMENT: t('documentManagement'),
        FORMS_MANAGEMENT: t('formsManagement'),
        HR: t('humanResources'),
        PROGRAM_OVERVIEW: t('programOverview'),
        REPORTS: t('reports')
    };
};

export default useTranslation;
