import FormDetailsPanel from 'components/FormDetailsPanel';
import FormMetaPanel from 'components/FormMetaPanel';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';

import styles from './styles.module.scss';

const FormDetailsTab = ({ form, loading }) => (
    <PanelWithAsidePanel>
        <FormDetailsPanel form={form} />

        <FormMetaPanel className={styles.formMetaPanel} form={form} loading={loading} />
    </PanelWithAsidePanel>
);

export default FormDetailsTab;
