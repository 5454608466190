import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmRequestUpdateModal from 'components/ConfirmRequestUpdateModal';
import NegativeHollowButton from 'components/NegativeHollowButton';
import SubmitButton from 'components/SubmitButton';

import isFunction from 'utilities/isFunction';

import useConfig from 'hooks/useConfig';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const RespondUpdateModal = ({ requestorId, requestName, requestSchema, onCancel, requestId, versionId }) => {
    const { useUserId } = useGlobalStateHooks();
    const [userId] = useUserId();

    const { NO_CANCEL, YES_RESPOND_UPDATES } = useTranslation();

    const navigate = useNavigate();

    const { MARKETING_REQUESTS_ROUTE } = useConfig();

    const [_errorMessage, setErrorMessage] = useState();

    const [requestedUpdate, requestUpdate, _reset] = useData(requestSchema);

    const handleModalClose = useCallback(() => {
        if (isFunction(onCancel)) {
            onCancel(false);
        }
    }, [onCancel]);

    const handleSubmit = useCallback(
        form => () => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                handleModalClose();

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {}
            );
            formObject.requestorId = requestorId;
            formObject.requestId = requestId;
            formObject.requestName = requestName;
            formObject.versionId = versionId;
            formObject.userId = userId;
            formObject.type = "response";

            requestUpdate(formObject);

            
        },
        [handleModalClose, requestUpdate, requestorId, requestId, requestName, versionId, userId]
    );

    useEffect(() => {
        if (!requestedUpdate?.length) {
            return;
        }

        navigate(MARKETING_REQUESTS_ROUTE)
    }, [navigate, requestedUpdate, MARKETING_REQUESTS_ROUTE]);

    return (
        <ConfirmRequestUpdateModal isOpen onClose={onCancel}>
            {form => (
                <>
                    <NegativeHollowButton onClick={onCancel} text={NO_CANCEL} />

                    <SubmitButton
                        onClick={handleSubmit(form)}
                        text={YES_RESPOND_UPDATES}
                    />
                </>
            )}
        </ConfirmRequestUpdateModal>
    );
};

export default RespondUpdateModal;
