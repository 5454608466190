const translations = {
    caseTypeName: 'Case Type Name',
    category: 'Category',
    dueDate: 'Due Date',
    formName: ' Form Name',
    nextStepsResolution: 'Next Steps / Resolution',
    recipient: 'Recipient',
    reminderFrequency: 'Reminder Frequency',
};

export default translations;
