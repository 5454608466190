import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DeletePolicySectionModal from 'components/DeletePolicySectionModal';
import PolicyMappingTableHeader from 'components/PolicyMappingTableHeader';
import PolicyMappingTableRow from 'components/PolicyMappingTableRow';
import Table from 'components/Table';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';

const PolicyMappingTable = ({ categories, className = '', policyId, sections = [], updateSection }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedSection, setSelectedSection] = useState();

    const navigate = useNavigate();

    const { useUserId } = useGlobalStateHooks();
    const [userId] = useUserId();

    const [deletedSection, deleteSection, reset] = useData();

    const handleDeleteModalOpen = useCallback(
        section => {
            setSelectedSection(section);
            setIsDeleteModalOpen(true);
        },
        [setIsDeleteModalOpen, setSelectedSection]
    );

    const handleDelete = useCallback(section => {
            section['userId'] = userId;
            section['policyId'] = policyId;
            deleteSection(section);
        },[deleteSection, policyId, userId]
    );

    useEffect(() => {
        if (!deletedSection) {
            return;
        }

        setIsDeleteModalOpen(false);
        reset();
        window.location.reload();

    }, [navigate, reset, deletedSection]);

    return (
        <>
            <Table className={className}>
                <PolicyMappingTableHeader />

                {sections.map(section => {
                    const { id, name, description, riskLevel, selectedSections } = section;

                    return (
                        <PolicyMappingTableRow
                            categories={categories}
                            description={description}
                            id={id}
                            key={id}
                            name={name}
                            onDelete={handleDeleteModalOpen}
                            riskLevel={riskLevel}
                            selectedCategories={selectedSections}
                            updateSection={updateSection}
                        />
                    );
                })}
            </Table>

            {isDeleteModalOpen && (
                <DeletePolicySectionModal
                    onClose={setIsDeleteModalOpen}
                    onSubmit={handleDelete}
                    section={selectedSection}
                />
            )}
        </>
    );
};

export default PolicyMappingTable;
