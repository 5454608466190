import { gql } from '@apollo/client';

const generateQueryFromDict = ({query, schema, tenants, variables, userGQL=false, usersGQL=false, customGqlContainer=false}) => {
    const queryStrings = [];
    if(tenants && Array.isArray(tenants) && tenants.length > 0){
        for(const t of tenants){
            if(userGQL){
                if(usersGQL){
                    queryStrings.push(query(t, userGQL, usersGQL));
                }else{
                    queryStrings.push(query(t, userGQL));
                }
            }else{
                queryStrings.push(query(t));
            }
        }
    }else{
        if(userGQL){
            if(usersGQL){
                queryStrings.push(query(schema, userGQL, usersGQL));
            }else{
                queryStrings.push(query(schema, userGQL));
            }
        }else{
            queryStrings.push(query(schema));
        }
    }
    const queryString = queryStrings.join("");
    if(customGqlContainer){
        return gql`
            query getActivities(${variables}) {
                ${queryString}
            }
        `;
    }else{
        return gql`
            query getActivities {
                ${queryString}
            }
        `;
    }
}

export default generateQueryFromDict;