import Pill from 'components/Pill';

import styles from './styles.module.scss';

import useTranslation from './hooks/useTranslation';

const MediumRiskPill = ({ className = '' }) => {
    const { MEDIUM } = useTranslation();

    return (
        <Pill
            className={`${className} ${styles.mediumRiskPill}`}
            label={MEDIUM}
        />
    );
};

export default MediumRiskPill;
