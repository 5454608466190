import DatePicker from 'components/DatePicker';
import PolicyNameInput from 'components/PolicyNameInput';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const PolicyInformation = props => {
    const { className = '', date, form, name } = props;

    const { EFFECTIVE_DATE } = useTranslation();

    const { handleError, handleSubmit, handleValid } = form;

    return (
        <div className={`${className} ${styles.policyInformation}`}>
            <PolicyNameInput
                className={styles.name}
                key={name}
                onChange={handleValid('policyName')}
                onError={handleError('policyName')}
                onSubmit={handleSubmit('policyName')}
                value={name}
            />

            <DatePicker
                className={styles.effectiveDate}
                key={date}
                label={EFFECTIVE_DATE}
                onChange={handleValid('effectiveDate')}
                value={new Date(date)}
            />
        </div>
    );
};

export default PolicyInformation;
