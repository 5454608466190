import DeleteButton from 'components/DeleteButton';
import ConfirmationModal from 'components/ConfirmationModal';
import DeactivateIcon from 'components/icons/DeactivateIcon';
import SubmitButton from 'components/SubmitButton';
import replaceTextWithJSX from 'utilities/replaceTextWithJSX';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DeactivatePolicyVersionModal = ({ onClose, onSubmit, policyName }) => {
    const { CANCEL, DEACTIVATE, YOU_ARE_ABOUT_TO_DEACTIVATE } =
        useTranslation();

    return (
        <ConfirmationModal
            icon={<DeactivateIcon />}
            onClose={onClose}
            onSubmit={onSubmit}
            text={replaceTextWithJSX(YOU_ARE_ABOUT_TO_DEACTIVATE, {
                POLICY_NAME: (
                    <span className={styles.policyName}>{policyName}</span>
                )
            })}
        >
            <DeleteButton onClick={onSubmit} text={DEACTIVATE} />

            <SubmitButton onClick={onClose} text={CANCEL} />
        </ConfirmationModal>
    );
};

export default DeactivatePolicyVersionModal;
