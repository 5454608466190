import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const queryLogs = (tenantSchema) => gql`
    query getCase {
        ${tenantSchema}_log {
          date_of_occurrence
          created_date
          tenant_user {
            user_${tenantSchema} {
              first_name
              last_name
            }
          }
          description
          risk_label
          log_users_involveds {
            tenant_user {
              user_${tenantSchema} {
                first_name
                last_name
              }
            }
          }
          log_visible_tos {
            tenant_user {
              user_${tenantSchema} {
                first_name
                last_name
              }
            }
          }
        }
      }
`;

const useData = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(queryLogs(schema), {
        fetchPolicy: 'no-cache'
    });
};

export default useData;
