import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { caseCreationReasonModal } = state;

const { caseCreationReasonModalAtom } = caseCreationReasonModal.atoms;

const MUTATE_CREATE_CASE = tenantSchema => gql`
mutation createCase(
    $activityId: Int,
    $caseName: String,
    $caseType: Int!,
    $createdBy: Int!,
    $creationReason: String!,
    $dueDate: timestamp!,
    $originated: String!, 
    $policyId: Int,
    $riskLabel: String
) { insert_${tenantSchema}_case(objects: {
        activity_id: $activityId,
        case_name: $caseName,
        case_type: $caseType,
        created_by: $createdBy,
        creation_reason: $creationReason,
        due_date: $dueDate,
        originated: $originated,
        policy_id: $policyId,
        risk_label: $riskLabel
    }) { 
        returning {
            id
        }
    }
}
`;

const MUTATE_CASE_USER = tenantSchema => gql`
mutation createCaseUser(
    $userAssignedTo: Int!,
    $caseId: Int!
) {
    insert_${tenantSchema}_user_case(objects: {
        case_id: $caseId,
        user_id: $userAssignedTo
    }) {
        returning {
            id
        }
    }
}
`;

const MUTATE_CASE_POLICIES = tenantSchema => gql`
mutation createCasePolicies(
    $caseId: Int!,
    $policyId: Int,
    $sectionId: Int
) {
    insert_${tenantSchema}_case_policies(objects: {
        case_id: $caseId,
        document_mapping_id: $policyId,
        document_section_id: $sectionId
    }) {
        returning {
            id
        }
    }
}
`;

const MUTATE_CLOSE_ACTIVITY = tenantSchema => gql`
    mutation closeActivity($activityId: Int, $closedDate: timestamp!) {
        update_${tenantSchema}_activity(where: {
            id: {
                _eq: $activityId
            }
        },
        _set: {
            closed: true,
            closed_date: $closedDate
        }) {
            returning {
                id
                closed
            }
        }
    }
`;

const MUTATE_LINKAGE = tenantSchema => gql`
    mutation insertLinkage(
        $activityId: Int,
        $caseId: Int,
        $linkCaseId: Int,
        $logId: Int
    ) {
        insert_${tenantSchema}_linkages(objects: {
            activity_id_1: $activityId
            case_id_1: $caseId
            case_id_2: $linkCaseId
            log_id_1: $logId
        }) {
            returning {
                id
            }
        }
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_CREATE_CASE, providedSchema);
    const mutationUser = useGraphQL(MUTATE_CASE_USER, providedSchema);
    const mutationPolicies = useGraphQL(MUTATE_CASE_POLICIES, providedSchema);
    const mutationActivity = useGraphQL(MUTATE_CLOSE_ACTIVITY, providedSchema);
    const mutationLinkage = useGraphQL(MUTATE_LINKAGE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveCaseCreationReason = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            variables['closedDate'] = new Date();
            
            const res = await mutation(variables);

            const assignedUsers = [];
            const id = res
                ?.data[`insert_${usedSchema}_case`]
                ?.returning[0]?.id;

            try{
                const assignedTo = JSON.parse(variables.usersAssignedTo);
                
                if (assignedTo.constructor === Array) {
                    for (var i = 0; i < assignedTo.length; i++) {
                        assignedUsers.push({
                            caseId: id,
                            userAssignedTo: assignedTo[i].value,
                        });
                    }
                }
            } catch {
                if (variables.usersAssignedTo.constructor !== Array) {
                    assignedUsers.push({
                        caseId: id,
                        userAssignedTo: variables.usersAssignedTo,
                    });
                }
            }

            for (var j = 0; j < assignedUsers.length; j++) {
                await mutationUser(assignedUsers[j]);
            }
            
            for (const policyString of variables.policies || []) {
                const policyArray = policyString.split('.');
                const policy = policyArray[0] > 0 ? parseInt(policyArray[0]) : null;
                const section = policyArray[1] > 0 ? parseInt(policyArray[1]) : null;

                await mutationPolicies({
                    caseId: id,
                    policyId: policy,
                    sectionId: section
                });
            }

            variables.activityId = variables?.activityId || 0;
            if (variables.toClose && variables?.activityId && variables?.activityId !== 0) {
                await mutationActivity(variables);                
            }

            await mutationLinkage({
                activityId: variables?.linkActivity,
                caseId: id,
                linkCaseId: variables?.linkCase,
                logId: variables?.linkLog
            })

            set(caseCreationReasonModalAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(caseCreationReasonModalAtom, '');
    });

    return [
        useRecoilValue(caseCreationReasonModalAtom),
        saveCaseCreationReason,
        reset
    ];
};

export default useData;
