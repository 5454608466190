import { useMemo } from 'react';

import TableHeader from 'components/TableHeader';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DocumentManagementTableHeader = ({ onSort, tenantCount }) => {
    const { CATEGORIES, COMPANY, CURRENT_VERSION, EFFECTIVE_DATE, POLICY_NAME, STATUS, VISIBILITY } =
        useTranslation();

    const headerItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: POLICY_NAME
            },
            {
                isSortable: false,
                key: 'version',
                text: CURRENT_VERSION
            },
            {
                isSortable: true,
                key: 'effectiveDate',
                text: EFFECTIVE_DATE
            },
            {
                isSortable: false,
                key: 'visibileTo',
                text: VISIBILITY
            },
            {
                isSortable: false,
                key: 'categories',
                text: CATEGORIES
            },
            {
                isSortable: true,
                key: 'status',
                text: STATUS
            }
        ],
        [CATEGORIES, CURRENT_VERSION, EFFECTIVE_DATE, POLICY_NAME, STATUS, VISIBILITY]
    );

    const multiTenantHeaderItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: POLICY_NAME
            },
            {
                isSortable: false,
                key: 'version',
                text: CURRENT_VERSION
            },
            {
                isSortable: true,
                key: 'effectiveDate',
                text: EFFECTIVE_DATE
            },
            {
                isSortable: true,
                key: 'schema',
                text: COMPANY
            },
            {
                isSortable: false,
                key: 'visibileTo',
                text: VISIBILITY
            },
            {
                isSortable: false,
                key: 'categories',
                text: CATEGORIES
            },
            {
                isSortable: true,
                key: 'status',
                text: STATUS
            }
        ],
        [CATEGORIES, COMPANY, CURRENT_VERSION, EFFECTIVE_DATE, POLICY_NAME, STATUS, VISIBILITY]
    );

    return (
        <TableHeader
            className={tenantCount > 1 ? styles.documentManagementTableHeaderMultiTenant : styles.documentManagementTableHeader}
            items={tenantCount > 1 ? multiTenantHeaderItems : headerItems}
            onSort={onSort}
        />
    );
};

export default DocumentManagementTableHeader;
