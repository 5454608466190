import { atom } from 'recoil';

const repositoriesListAtom = atom({
    default: [],
    key: 'repositoriesList'
});

const state = {
    atoms: {
        repositoriesListAtom,
    }
};

export default state;
