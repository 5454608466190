import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import AddButton from 'components/AddButton';

import useTranslation from './hooks/useTranslation';

const AddPersonButton = ({ className = '' }) => {
    const { ADD_PERSON } = useTranslation();

    const { ADD_PERSON: ADD_PERSON_URL } = useConfig();

    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(ADD_PERSON_URL);
    }, [ADD_PERSON_URL, navigate]);

    return (
        <AddButton
            className={className}
            onClick={handleClick}
            text={ADD_PERSON}
        />
    );
};

export default AddPersonButton;
