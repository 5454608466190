import CompletedSummarizedListItems from 'components/CompletedSummarizedListItems';
import CompletedItems from 'components/CompletedItems';

const CompletedSummarizedItems = ({ className = '', items, onDownloadItem }) =>  (
    <CompletedItems className={className}>
        <CompletedSummarizedListItems
            items={items}
						onDownloadItem={onDownloadItem}
        />
    </CompletedItems>
);

export default CompletedSummarizedItems;
