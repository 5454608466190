import Pill from 'components/Pill';

import styles from './styles.module.scss';

import useTranslation from './hooks/useTranslation';

const LowRiskPill = ({ className = '' }) => {
    const { LOW } = useTranslation();

    return (
        <Pill className={`${styles.lowRiskPill} ${className}`} label={LOW} />
    );
};

export default LowRiskPill;
