import { useCallback } from 'react';

import SubmitButton from 'components/SubmitButton';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const ActionButton = ({
    children,
    className = '',
    isDisabled = false,
    onClick
}) => {
    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        
        [onClick]
    );

    return (
        <SubmitButton
            className={`${className} ${styles.actionButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
        >
            {children}
        </SubmitButton>
    );
};

export default ActionButton;
