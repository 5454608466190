import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import DeleteButton from 'components/DeleteButton';
import ConfirmationModal from 'components/ConfirmationModal';
import DeactivateIcon from 'components/icons/DeactivateIcon';
import SubmitButton from 'components/SubmitButton';

import replaceTextWithJSX from 'utilities/replaceTextWithJSX';

import useConfig from 'hooks/useConfig';


import useTranslation from './hooks/useTranslation';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import useData from './hooks/useData';

const RejectModal = ({ requestId, requestName = '', requestSchema, onCancel, versionId }) => {
    const { useUserId } = useGlobalStateHooks();
    const [userId] = useUserId();

    const { NO_CANCEL, YES_WITHDRAW, YOU_ARE_ABOUT_TO_WITHDRAW } = useTranslation();

    const navigate = useNavigate();

    const { MARKETING_REQUESTS_ROUTE } = useConfig();

    const [requestedUpdate, requestUpdate, _reset] = useData(requestSchema);
    
    const handleSubmit = useCallback(() => {
        const formObject = [];
        formObject.requestId = requestId;
        formObject.versionId = versionId;
        formObject.userId = userId;

        requestUpdate(formObject);

        
    }, [requestId, requestUpdate, userId, versionId]);

    useEffect(() => {
        if (!requestedUpdate?.length) {
            return;
        }

        navigate(MARKETING_REQUESTS_ROUTE)
    }, [navigate, requestedUpdate, MARKETING_REQUESTS_ROUTE]);

    return (
        <ConfirmationModal
            icon={<DeactivateIcon />}
            onClose={onCancel}
            text={replaceTextWithJSX(YOU_ARE_ABOUT_TO_WITHDRAW, {
                MARKETING_REQUEST_NAME: (
                    <span className={styles.span}>{requestName}</span>
                )
            })}
        >
            <DeleteButton onClick={onCancel} text={NO_CANCEL} />

            <SubmitButton onClick={handleSubmit} text={YES_WITHDRAW} />
        </ConfirmationModal>
    );
};

export default RejectModal;
