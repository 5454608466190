import { useCallback } from 'react';

import CloseButton from 'components/CloseButton';
import Drawer from 'components/Drawer';
import NavigationPanel from 'components/NavigationPanel';

import styles from './styles.module.scss';

const NavigationDrawer = props => {
    const { className = '', isOpen = false, onClose } = props;

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <Drawer
            className={`${className} ${styles.navigationDrawer}`}
            isOpen={isOpen}
            onClose={onClose}
        >
            <CloseButton className={styles.closeButton} onClick={handleClose} />

            <NavigationPanel
                className={styles.navigationPanel}
                onNavigationClick={handleClose}
            />
        </Drawer>
    );
};

export default NavigationDrawer;
