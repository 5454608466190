import { useCallback, useEffect } from 'react';

import CancelDeleteButtons from 'components/CancelDeleteButtons';
import ImportantText from 'components/ImportantText';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PageTitle';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DeleteRecurrenceModal = ({ onClose, recurrenceId, recurrencesForUpdate }) => {
    const { ARE_YOU_SURE, DELETE_RECURRENCE } = useTranslation();

    const [deletedActivityRecurrence, deleteActivityRecurrence, resetDeleted] = useData();

    const handleClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                return onClose(event);
            }
        },
        [onClose]
    );

    const handleDelete = useCallback(() => {
        const filledInVariables = {
            activityRecurrenceId: recurrenceId,
            recurrencesForUpdate,
        }

        deleteActivityRecurrence(filledInVariables);
    }, [recurrenceId, recurrencesForUpdate, deleteActivityRecurrence]);

    useEffect(() => {
        if (!deletedActivityRecurrence) {
            return;
        }

        if (isFunction(onClose)) {
            onClose(deletedActivityRecurrence);
            resetDeleted();
            window.location.reload();
        }
    }, [onClose, resetDeleted, deletedActivityRecurrence]);

    return (
        <Modal isOpen={true} onClose={handleClose}>
            <PanelBody>
                <PanelTitle className={styles.panelTitle} text={DELETE_RECURRENCE} />

                <ImportantText text={ARE_YOU_SURE} />

                <CancelDeleteButtons
                    className={styles.cancelSaveButtons}
                    onCancel={handleClose}
                    onDelete={handleDelete}
                />
            </PanelBody>
        </Modal>
    );
};

export default DeleteRecurrenceModal;
