import httpPostStreaming from 'utilities/httpPostStreaming';

async function getFile(directoryName, fileName, apiKey, getFileUrl) {
  const formData = new FormData();
  formData.append('file_name', fileName);
  formData.append('schema', directoryName);
  const accessToken = localStorage.getItem('accessToken')
  formData.append('access_token', accessToken);
  const blob = await httpPostStreaming({data:formData, key: apiKey ? apiKey : '', url:getFileUrl});
  const urlString = blob?.size === 0 ? '' : URL.createObjectURL(blob);
  return urlString;
}

export default getFile;