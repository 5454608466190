import Title from 'components/Title';

import styles from './styles.module.scss';

const IconWithTitle = ({ className = '', icon, text = '' }) => {
    return (
        <div className={`${className} ${styles.iconWithTitle}`}>
            {icon}

            <Title className={styles.title} text={text} />
        </div>
    );
};

export default IconWithTitle;
