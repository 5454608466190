import { useEffect } from 'react';

import PanelBody from 'components/PanelBody';

// import useActivities from './hooks/useActivities';
import useData from './hooks/useData';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import normalizeData from './data';

const Activities = ({ children, hasCompleteItems, hasInProgressItems, hasOverdueItems, hasUpcomingItems, isAll, isFlagged }) => {
    const { useActivities, useQuerySchemas, useSchema, useTenants, useUserId } = useGlobalStateHooks();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();

    const [activities, setActivities] = useActivities();
    const { loading, error, data } = useData();

    useEffect(() => {
        if (data && (!activities || !('completed' in activities))) {
            const normalizedData = normalizeData({
                data,
                isFlagged,
                querySchemas,
                schema,
                tenants,
                user: userId
            });
            setActivities(normalizedData);
        }
    // eslint-disable-next-line
    }, [data, isAll, isFlagged, querySchemas, tenants, schema, setActivities, userId]);

    if (error) {
        return `Error! ${error}`;
    }

    return (
        <PanelBody isLoading={loading} isLoadingWithText={(!hasCompleteItems && !hasOverdueItems && !hasInProgressItems && !hasUpcomingItems) && !isFlagged ? "There are no flagged activities" : (!hasCompleteItems && !hasOverdueItems && !hasInProgressItems && !hasUpcomingItems) ? 'There are no activities for your completion' : null}>
            {children}
        </PanelBody>
    );
};

export default Activities;
