import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('documentManagementPanel');

    return {
        CATEGORIES: t('categories'),
        DATE_RANGE_END: t('dateRangeEnd'),
        DATE_RANGE_START: t('dateRangeStart'),
        DOCUMENT_TAGS: t('documentTags'),
        DOCUMENTS: t('documents'),
        NAME: t('name'),
        PUBLISHED_STATE: t('publishedState'),
        RISK_LEVEL: t('riskLevel'),
        SECTION_TAGS: t('sectionTags'),
        SECTIONS: t('sections'),
        STATUS: t('status'),
        SEARCH: t('search'),
        VERSION: t('version'),
        VIEW_ALL: t('viewAll'),
        VISIBILITY: t('visibility')
    };
};

export default useTranslation;
