const normalizeUserData = ({ data, schema }) =>
    data[`${schema}_user`].reduce(
        (accumulator, user) => {
            accumulator.push({
                avatar: `/UserImages/${user.tenant_user_id}.png`,
                email: '',
                id: user.tenant_user_id,
                name: `${user.first_name} ${user.last_name}`
            });

            return accumulator;
        }, []);

export default normalizeUserData;