import { useEffect, useState } from 'react'; 

import state from 'app/state';

import getFile from 'utilities/getFile';

import image from './image.svg';

import { altText } from './constants';

import styles from './styles.module.scss';
import useConfig from 'hooks/useConfig';

const { logo } = state;

const { logoAtom } = logo.atoms;

const Logo = ({ className = '' }) => {
    const { API_KEY, GET_FILE_URL } = useConfig();
    const schema = localStorage.getItem('schema');

    const [imageObject, setImageObject] = useState(logoAtom);
    const [navigationImage, setImage] = useState(image)

    useEffect(() => {
        async function getLogo(){
            const urlString = await getFile(schema, "logo.png", API_KEY, GET_FILE_URL);
            if (urlString && urlString.length > 0){
                const newImageObject = Object.assign(imageObject, urlString);
                setImageObject(newImageObject);
            }
            return urlString;
        }
        async function setLogo(){
            const logo = await getLogo();
            if(logo){
                setImage(logo);
            }
        }
        setLogo();
    }, [imageObject, schema, setImage, setImageObject]);

    return (
        <img alt={altText} className={`${className} ${styles.image}`} src={navigationImage} />
    );
}

export default Logo;
