import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';

import CloseAndAddLogEntryMenuListItem from 'components/CloseAndAddLogEntryMenuListItem';

const CloseActivityAndAddLogEntryMenuListItem = ({
    activityId,
    activityRecord,
    activitySchema,
    onClick,
    onSave
}) => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const [closedActivity, closeActivity, reset] = useData(activitySchema);

    const { COMPLIANCE_LOG_ENTRY_ROUTE, HOME } = useConfig();

    const navigate = useNavigate();

    const [_errorMessage, setErrorMessage] = useState();

    const handleActivityClose = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    activityId,
                    createdBy: Number(userId),
                    toClose: true,
                }
            );
            formObject.dateOfOccurrence = new Date(formObject.dateOfOccurrence);
                
            formObject['typeId'] = formObject['logCategory'];

            closeActivity(formObject);
        }, [activityId, closeActivity, userId]
    );

    const activityName = activityRecord?.name || '-';
    const activityType = activityRecord?.type || '-';

    useEffect(() => {
        if (!closedActivity) {
            return;
        }

        if (isFunction(onSave)) {
            onSave();
        }

        if (isFunction(onClick)) {
            onClick(closedActivity);
        }

        navigate(COMPLIANCE_LOG_ENTRY_ROUTE.replace(':entryId', closedActivity))

        reset();
    }, [COMPLIANCE_LOG_ENTRY_ROUTE, HOME, closedActivity, navigate, onClick, onSave, reset]);

    return (
        <CloseAndAddLogEntryMenuListItem
            activityName={activityName}
            activityType={activityType}
            onSave={handleActivityClose}
        />
    );
};

export default CloseActivityAndAddLogEntryMenuListItem;
