import ActiveIndicator from 'components/ActiveIndicator';
import Buttonize from 'components/Buttonize';
import PenIcon from 'components/icons/PenIcon';
import PillGroup from 'components/Pills';
import TableRow from 'components/TableRow';

import styles from './styles.module.scss';

const WorkflowSettingsTableRow = props => {
    const { approvingGroup = [], onEdit, status, submittingGroup = [] } = props;

    const isActive = status === 'activate';

    return (
        <TableRow className={styles.workflowSettingsTableRow}>
            <PillGroup items={submittingGroup} />

            <PillGroup items={approvingGroup} />

            <ActiveIndicator
                className={styles.statusIcon}
                isActive={isActive}
            />

            <Buttonize className={styles.buttonize} onClick={onEdit}>
                <PenIcon />
            </Buttonize>
        </TableRow>
    );
};

export default WorkflowSettingsTableRow;
