import Title from 'components/Title';

import styles from './styles.module.scss';

const NotificationHeader = ({ children, className = '', title }) => {    
    return (
        <div className={`${className} ${styles.notificationItems}`}>
            <Title className={styles.title} text={title} />

            {children.map((child) => (
                <span key={`notification header ${title} ${child}`}>{child}</span>
            ))}
        </div>
    );
};

export default NotificationHeader;
