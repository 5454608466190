import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import UserList from 'components/UserList';
import Versions from 'components/Versions';
import VersionHistory from 'components/VersionHistory';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const PolicyMetaPanel = props => {
    const {
        className = '',
        createdBy,
        onAddVersion,
        onVersionChange,
        providedSchema,
        policyId,
        selectedVersion,
        versions
    } = props;

    const { CREATED_BY } = useTranslation();

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <UserList title={CREATED_BY} users={[createdBy]} />

                <Versions
                    items={versions}
                    onAddVersion={onAddVersion}
                    onChange={onVersionChange}
                    providedSchema={providedSchema}
                    requestId={policyId}
                    selectedVersion={selectedVersion}
                    type={'policy'}
                    versionNumber={versions.length}
                />

                <VersionHistory
                    items={versions}
                    selectedVersion={selectedVersion}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default PolicyMetaPanel;
