import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useMutation, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import state from 'app/state';

const { markReviewedButton } = state;

const { markReviewedButtonAtom } = markReviewedButton.atoms;

const MUTATE_BUTTON = (/*tenantSchema*/) => gql`
    mutation mutateTest {
        __typename ## Placeholder value
    }
`;

const useData = (providedSchema) => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const [markAsReviewedGql] = useMutation(MUTATE_BUTTON(usedSchema));

    const markAsReviewed = useRecoilCallback(
        (/* {  set } */) => (/*{ id }*/) => {
            const asyncUpdateDropdown = async () => {
                await markAsReviewedGql({
                    variables: {
                        // YOUR VARIABLES GO HERE
                    }
                });

                
                
                // set(markReviewedButtonAtom, WHATEVER_YOU_WANT_TO_PUT_IN_GLOBAL_STATE);
            };

            asyncUpdateDropdown();
        }
    );

    return [useRecoilValue(markReviewedButtonAtom), markAsReviewed];
};

export default useData;
