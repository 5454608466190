import { forwardRef } from 'react';

import Textarea from 'components/Textarea';
import InputWithLabel from 'components/InputWithLabel';

import styles from './styles.module.scss';

const TextBox = forwardRef((props, ref) => {
    const {
        className = '',
        id = '',
        isRequired = false,
        hasError = false,
        label = '',
        name = '',
        onChange,
        onSubmit,
        placeholder = '',
        text = '',
        type = 'text',
        textBoxOverride = ''
    } = props;

    return (
        <InputWithLabel
            className={`${className} ${styles.textBox}`}
            text={label}
        >
            <Textarea
                className={`${styles.textarea}`}
                hasError={hasError}
                id={id}
                isRequired={isRequired}
                name={name}
                onChange={onChange}
                onSubmit={onSubmit}
                placeholder={placeholder}
                ref={ref}
                text={text}
                textBoxOverride={textBoxOverride}
                type={type}
            />
        </InputWithLabel>
    );
});

export default TextBox;
