import { useMemo } from 'react';

import TableHeader from 'components/TableHeader';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const FormSettingsTableHeader = ({ onSort }) => {
    const { FIELD, STATUS, VISIBILITY } = useTranslation();

    const headerItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'visibility',
                text: VISIBILITY
            },
            {
                isSortable: false,
                key: 'field',
                text: FIELD
            },
            {
                isSortable: false,
                key: 'status',
                text: STATUS
            }
        ],
        [FIELD, STATUS, VISIBILITY]
    );

    return (
        <TableHeader
            className={styles.formSettingsTableHeader}
            items={headerItems}
            onSort={onSort}
        />
    );
};

export default FormSettingsTableHeader;
