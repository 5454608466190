import { useMemo } from 'react';

import TableHeader from 'components/TableHeader';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DocumentManagementRepositoryTableHeader = ({ onSort, tenantCount }) => {
    const { COMPANY, DOCUMENT_NAME, TYPE, REFERENCE_NAME, REFERENCE_TYPE, DATE_INTRODUCED, UPLOAD_BY } =
        useTranslation();

    const headerItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: DOCUMENT_NAME
            },
            {
                isSortable: false,
                key: 'type',
                text: TYPE
            },
            {
                isSortable: true,
                key: 'referenceName',
                text: REFERENCE_NAME
            },
            {
                isSortable: false,
                key: 'referenceType',
                text: REFERENCE_TYPE
            },
            {
                isSortable: true,
                key: 'dateIntroduced',
                text: DATE_INTRODUCED
            },
            {
                isSortable: false,
                key: 'uploadBy',
                text: UPLOAD_BY
            }
            
        ],
        [ DATE_INTRODUCED, DOCUMENT_NAME, REFERENCE_NAME, REFERENCE_TYPE, TYPE, UPLOAD_BY ]
    );

    const multiTenantHeaderItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: DOCUMENT_NAME
            },
            {
                isSortable: false,
                key: 'type',
                text: TYPE
            },
            {
                isSortable: false,
                key: 'schema',
                text: COMPANY
            },
            {
                isSortable: true,
                key: 'referenceName',
                text: REFERENCE_NAME
            },
            {
                isSortable: false,
                key: 'referenceType',
                text: REFERENCE_TYPE
            },
            {
                isSortable: true,
                key: 'dateIntroduced',
                text: DATE_INTRODUCED
            },
            {
                isSortable: false,
                key: 'uploadBy',
                text: UPLOAD_BY
            }
            
        ],
        [ COMPANY, DATE_INTRODUCED, DOCUMENT_NAME, REFERENCE_NAME, REFERENCE_TYPE, TYPE, UPLOAD_BY ]
    );

    return (
        <TableHeader
            className={tenantCount>1 ? styles.documentManagementRepositoryTableHeaderMulti : styles.documentManagementRepositoryTableHeader}
            items={tenantCount>1 ? multiTenantHeaderItems : headerItems}
            onSort={onSort}
        />
    );
};

export default DocumentManagementRepositoryTableHeader;
