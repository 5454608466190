import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('editRecurrenceModal');

    return {
        ACTIVITY_NAME: t('activityName'),
        ACTIVITY_TYPE: t('activityType'),
        ADD_INSTRUCTIONS_HERE: t('addInstructionsHere'),
        DUE_DATE: t('dueDate'),
        EDIT_RECURRING_ACTIVITY: t('editRecurringActivity'),
        ENTER_ACTIVITY_NAME: t('enterActivityName'),
        FIRST_ASSIGNEE: t('firstAssignee'),
        FORM_NAME: t('formName'),
        INSTRUCTIONS: t('instructions'),
        POLICY_REFERENCE: t('policyReference'),
        RECIPIENT: t('recipient'),
        REVIEWER: t('reviewer'),
        START_DATE: t('startDate'),
    };
};

export default useTranslation;
