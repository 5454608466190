import { useQuery } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import generateQueryFromDict from 'utilities/generateQueryFromDict';

const query = (tenantSchema, userGQL) => {
  console.log(tenantSchema)
  return `${userGQL}`
};

const useData = () => {
  const { useQuerySchemas, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
  const [querySchemas] = useQuerySchemas();
  const [schema] = useSchema();
  const [tenants] = useTenants();
  const [userSetSchema] = useUserSetSchema();
  const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
  var userGQL = '';
  if(querySchemas.length > 0){
      for(const s of querySchemas){
          userGQL += `
          ${s.value}_user {
            tenant_user {
              email
              id
              username
              user_roles {
                role {
                  name
                  description
                }
              }
              group_users_${s.value} {
                group {
                  name
                }
              }
            }
            first_name
            last_name
            registration_status
            employee_status
          }`
      }
  }else if(usedSchema){
      userGQL += `
      ${usedSchema}_user {
        tenant_user {
          email
          id
          username
          user_roles {
            role {
              name
              description
            }
          }
          group_users_${usedSchema} {
            group {
              name
            }
          }
        }
        first_name
        last_name
        registration_status
        employee_status
      }`
  }
  return useQuery(generateQueryFromDict({query, schema:usedSchema, tenants, userGQL}), { fetchPolicy: 'no-cache' });
};

export default useData;
