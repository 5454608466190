import normalizeListData from "utilities/normalizeListData";
import formatDate from "utilities/formatDate";

const schemaData = ({ data, schema }) =>
    data[`${schema}_activity`].reduce((accumulator, activity) => {
        const {
            activity_type: activityTypeObject,
            // cases: casesArray,
            closed,
            // closed_date: closedDateString,
            due_date: dueDateString,
            id,
            name,
            risk_label: riskLabel,
            start_date: startDateString,
            user_activities: userActivitiesArray,
            deleted
        } = activity;

        // const cases = casesArray.reduce((accumulator, caseItem) => {
        //     accumulator.push(caseItem?.case_name);
        //     return accumulator;
        // }, [])

        if(deleted) return accumulator;

        const userActivities = userActivitiesArray.reduce((accumulator, userActivity) => {
            const { group, tenant_user: tenantUser } = userActivity;
            if (group !== null){
                accumulator.push({firstName: group?.name, id: group?.id, label: `${group?.name} Group`, lastName: 'Group', type: 'group', value:`${group?.name} Group`});
            } else if (tenantUser !== null){
                const userId = tenantUser?.id;
                const user = tenantUser[`user_${schema}`];
                const userName = `${user?.first_name} ${user?.last_name}`
                accumulator.push({firstName: user?.first_name, id: userId, label: userName, lastName: user?.last_name, type: 'user', value:userName});
            }
            return accumulator;
        },[]);

        // const closedDate = closedDateString ? formatDate(new Date(closedDateString)) : closedDateString;
        const dueDate = dueDateString ? formatDate(new Date(dueDateString)) : dueDateString;
        const startDate = startDateString ? formatDate(new Date(startDateString)) : startDateString;
        const closedStatus = closed ? 'Closed' : 'Open';

        // eslint-disable-next-line
        accumulator.push({name, activityType: activityTypeObject?.name, closed: closedStatus, dueDate, riskLabel, startDate, userActivities, id});

        return accumulator;
    }, []);

const normalizeData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeData;
