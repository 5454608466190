import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { requestCountsAtom } = state.requestCounts.atoms;

const useCaseTypeCountsAtom = () => [
    useRecoilValue(requestCountsAtom),
    useSetRecoilState(requestCountsAtom)
];

export default useCaseTypeCountsAtom;
