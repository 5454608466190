import BarListItems from 'components/BarListItems';
import FormsListItem from 'components/FormsListItem';
import TitleListItem from 'components/TitleListItem';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const FormsListItems = ({ className = '', items = [] }) => {
    const { ASSIGNED, COMPLETED, NAME } = useTranslation();

    return (
        <BarListItems className={className}>
            <TitleListItem
                className={styles.titleListItem}
                items={[NAME, COMPLETED, ASSIGNED]}
            />

            {items.map(
                (
                    {
                        assigned,
                        completed,
                        dueDate,
                        image,
                        isActive,
                        name,
                        total
                    },
                    index
                ) => (
                    <FormsListItem
                        assigned={assigned}
                        completed={completed}
                        dueDate={dueDate}
                        image={image}
                        isActive={isActive}
                        key={`${name}.${index}`}
                        name={name}
                        total={total}
                    />
                )
            )}
        </BarListItems>
    );
};

export default FormsListItems;
