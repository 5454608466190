import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { selectedRegulatoryReferencesAtom } = state.activityDetailsPanel.atoms;

const useSelectedRegulatoryReferences = () => [
    useRecoilValue(selectedRegulatoryReferencesAtom),
    useSetRecoilState(selectedRegulatoryReferencesAtom)
];

export default useSelectedRegulatoryReferences;
