import PanelBody from 'components/PanelBody';
import { ActivityTableItems } from './activities-table-items.component';
import styles from './styles.module.scss';

export const ActivitiesTable = ({
    activities = [],
    hasNextPage = false,
    isLoading = false,
    isMultiTenant,
    onCompleteItem,
    onToggleFlagItem,
    onLoadMore
}) => {
    const overdueActivities = activities.filter(
        activity => activity.status === 'overdue'
    );

    const pendingActivities = activities.filter(
        activity => activity.status === 'pending'
    );

    const completedActivities = activities.filter(
        activity => activity.status === 'completed'
    );

    const upcomingActivities = activities.filter(
        activity => activity.status === 'upcoming'
    );

    const isEmpty = !isLoading && activities.length === 0;

    const TableItems = ({ activities, title }) => (
        <ActivityTableItems
            activities={activities}
            isMultiTenant={isMultiTenant}
            onCompleteItem={onCompleteItem}
            onToggleFlagItem={onToggleFlagItem}
            title={title}
        />
    );

    return (
        <PanelBody isLoadingWithText={isEmpty ? 'No activities found.' : ''}>
            {overdueActivities.length > 0 && (
                <TableItems activities={overdueActivities} title="Overdue" />
            )}

            {pendingActivities.length > 0 && (
                <TableItems activities={pendingActivities} title="Pending" />
            )}

            {upcomingActivities.length > 0 && (
                <TableItems activities={upcomingActivities} title="Upcoming" />
            )}

            {completedActivities.length > 0 && (
                <TableItems
                    activities={completedActivities}
                    title="Completed"
                />
            )}

            {hasNextPage && (
                <p className={styles.loadMore} onClick={onLoadMore}>
                    {isLoading ? 'Loading...' : hasNextPage ? 'Load More' : ''}
                </p>
            )}
        </PanelBody>
    );
};
