import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('visibleToDropdown');

    return {
        VISIBLE_TO: t('visibleTo')
    };
};

export default useTranslation;
