import { useCallback, useState } from 'react';

import DeleteButton from 'components/DeleteButton';
import DeletePolicyVersionModal from 'components/DeletePolicyVersionModal';

const DeletePolicyVersionButton = ({ policyVersion = '' }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <DeleteButton onClick={handleClick} />

            {isOpen && (
                <DeletePolicyVersionModal
                    onClose={handleClose}
                    onSubmit={() => {}}
                    version={policyVersion}
                />
            )}
        </>
    );
};

export default DeletePolicyVersionButton;
