export const insertActivityHistoryOneName = schema =>
    `insert_${schema}_activity_history_one`;

export const insertActivityHistoryOneMutationBodyGql = ({
    activityId,
    color = 'yellow',
    message = 'flagged the activity',
    schema,
    userId
}) => {
    return {
        [insertActivityHistoryOneName(schema)]: {
            __args: {
                object: {
                    activity_id: activityId,
                    color,
                    message,
                    user_id: userId
                }
            },
            id: true
        }
    };
};
