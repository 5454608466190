import ContentPanel from 'components/ContentPanel';
import FormsHeader from 'components/FormsHeader';
import FormsListItems from 'components/FormsListItems';
import Loader from 'components/Loader';
import PanelBody from 'components/PanelBody';

import useData from './data';

import styles from './styles.module.scss';

const FormsPanel = ({ className = '' }) => {
    const items = useData();

    return (
        <ContentPanel className={`${className} ${styles.formsPanel}`}>
            <FormsHeader />

            <PanelBody className={styles.panelBody}>
                {items.length > 0 ? <FormsListItems items={items} /> : <Loader className='loader' text="No forms outstanding with outside users" />}
            </PanelBody>
        </ContentPanel>
    );
};

export default FormsPanel;
