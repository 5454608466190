import CheckPermissions from 'components/CheckPermissions';
import DeleteActivityModal from 'components/DeleteActivityModal';
import CircleCheckIcon from 'components/icons/CircleCheckIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import EmptyIcon from 'components/icons/EmptyIcon';
import FlaggedIcon from 'components/icons/FlaggedIcon';
import FlagIcon from 'components/icons/FlagIcon';
import { useState } from 'react';
import styles from './styles.module.scss';

export const ActivityTableActions = ({
    activity,
    onComplete,
    onDelete,
    onToggleFlag: onToggleFlagProps,
    schema
}) => {
    const { active, id, flagged, name: activityName } = activity;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isFlagged, setIsFlagged] = useState(flagged);

    const onCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const onOpenDeleteModal = () => {
        setIsDeleteModalOpen(true);
    };

    const onToggleFlag = () => {
        const newFlagged = !isFlagged;
        onToggleFlagProps({ activity, flagged: newFlagged });
        setIsFlagged(newFlagged);
    };

    const onDeleteActivity = event => {
        if (onDelete) {
            onDelete(event);
        }

        onOpenDeleteModal();
    };

    return (
        <>
            {isDeleteModalOpen && (
                <DeleteActivityModal
                    activityName={activityName}
                    id={id}
                    onClose={onCloseDeleteModal}
                    schema={schema}
                />
            )}

            <div className={styles.actions}>
                {!isFlagged && <FlagIcon onClick={onToggleFlag} />}

                {isFlagged && <FlaggedIcon onClick={onToggleFlag} />}

                {active && (
                    <CircleCheckIcon onClick={() => onComplete(activity)} />
                )}

                {!active && <EmptyIcon />}

                <CheckPermissions permission={['activity_delete']}>
                    <DeleteIcon onClick={onDeleteActivity} />
                </CheckPermissions>
            </div>
        </>
    );
};
