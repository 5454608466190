import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import isFunction from 'utilities/isFunction';

import useActivityId from './hooks/useActivityId';
import useData from './hooks/useData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import CloseAndCreateNewActivityMenuListItem from 'components/CloseAndCreateNewActivityMenuListItem';

const CloseActivityAndCreateNewActivityMenuListItem = ({
    activityId,
    activityName,
    activitySchema,
    onClick
}) => {
    const [_, setActivityLinkId] = useActivityId();
    const [closedActivity, closeActivity, reset] = useData(activitySchema);
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const { ADD_ACTIVITY_ROUTE } = useConfig();

    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        closeActivity({
            activityId,
            userId
        });
        setActivityLinkId({
            activityId: activityId,
            activityName: activityName
        });
    }, [activityId, activityName, closeActivity, setActivityLinkId, userId]);

    useEffect(() => {
        if (!closedActivity) {
            return;
        }

        if (isFunction(onClick)) {
            onClick(closedActivity);
        }

        navigate(ADD_ACTIVITY_ROUTE);

        reset();
    }, [activityId, ADD_ACTIVITY_ROUTE, closedActivity, navigate, onClick, reset, setActivityLinkId]);

    return <CloseAndCreateNewActivityMenuListItem onClick={handleClose} />;
};

export default CloseActivityAndCreateNewActivityMenuListItem;
