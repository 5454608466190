import BodyText from 'components/BodyText';
import ListItemLink from 'components/ListItemLink';

import styles from './styles.module.scss';

const LegendItem = ({ className = '', color = '', onClick, text = '' }) => {

    return (
        <ListItemLink className={`${className} ${styles.legendItem}`} onClick={onClick}>
            <div className={styles.colorDot} style={{ backgroundColor: color }} />

            <BodyText text={text} />
        </ListItemLink>
    );
};

export default LegendItem;