import { forwardRef } from 'react';

// import Input from 'components/Input';
import InputWithEditableLabel from 'components/InputWithEditableLabel';

import styles from './styles.module.scss';

const TextInput = forwardRef((props, ref) => {
    const {
        className = '',
        id = '',
        label = '',
        name = '',
        onChange,
        text,
        type = 'text',
        value
    } = props;

    return (
        <InputWithEditableLabel
            className={`${className} ${styles.textInput}`}
            defaultValue={value}
            id={id}
            name={name}
            onChange={onChange}
            placeholder={text}
            ref={ref}
            text={label}
            type={type}
        >
            <div className={styles.input} />
        </InputWithEditableLabel>
    );
});

export default TextInput;
