import AttachmentList from 'components/AttachmentList';
import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const Attachments = props => {
    const { className = '', hasEdit = true, hasEditPermission, items, onDelete, onUpdate, providedSchema } = props;

    const { ATTACHMENTS } = useTranslation();

    return (
        <SectionWithBorder className={`${className} ${styles.attachments}`}>
            <Title text={ATTACHMENTS} />

            <AttachmentList
                hasEdit={hasEdit}
                hasEditPermission={hasEditPermission}
                items={items}
                onDelete={onDelete}
                onUpdate={onUpdate}
                providedSchema={providedSchema}
            />
        </SectionWithBorder>
    );
};

export default Attachments;
