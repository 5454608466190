import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecLog = (tenantSchema, logId, userGQL, usersGQL) => gql`
    query getLog{
        ${tenantSchema}_log(where: {id: {_eq: ${logId}}}) {
          id
          created_date
          date_of_occurrence
          description
          dropdown_selections
          log_activities {
            activity {
              name
              risk_label
              closed_date
              due_date
              start_date
              instructions
              id
            }
          }
          risk_label
          log_cases {
            case {
              caseTypeByCaseType {
                name
                id
              }
              risk_label
              closed_date
              due_date
              created_date
              creation_reason
              id
            }
          }
          log_entities {
            entity {
              id
              name
            }
          }
          log_policies {
            document {
              id
              name
            }
            section {
              id
              name
            }
          }
          log_types {
            log_type {
              dropdown
              id
              name
            }
          }
          log_user_involveds {
            tenant_user {
              id
              ${userGQL}
            }
          }
          log_visible_tos {
            tenant_user {
              id
              ${userGQL}
            }
          }
          tenant_user {
            id
            ${usersGQL}
          }
        }
      }
`;

const useData = (logId, logSchema) => {
  const { useQuerySchemas, useSchema } = useGlobalStateHooks();

  const [querySchemas] = useQuerySchemas();
  const [schema] = useSchema();
  var userGQL = '';
  var usersGQL = '';
  if(querySchemas.length > 0){
    for(const s of querySchemas){
        if(s.value){
            userGQL += `user_${s.value} {
              first_name
              last_name
              profile_photo
            }`
            usersGQL += `users_${s.value} {
              first_name
              last_name
              profile_photo
            }`
        }
    }
  }else{
    if(schema){
        userGQL += `user_${schema} {
          first_name
          last_name
          profile_photo
        }`
        usersGQL += `users_${schema} {
          first_name
          last_name
          profile_photo
        }`
    }
  }

    return useQuery(querySpecLog(logSchema, logId, userGQL, usersGQL), { fetchPolicy: 'no-cache' });
};

export default useData;
