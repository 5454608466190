const translations = {
    categories: 'Categories',
    company: 'Company',
    currentVersion: 'Current Version',
    lastReviewedDate: 'Last Reviewed Date',
    sections: 'Sections',
    sideLetterName: 'Side Letter Name',
    status: 'Status',
    tags: 'Tags'
};

export default translations;
