const translations = {
    admin: 'Administration',
    caseManagement: 'Case Management',
    complianceCentral: 'Dashboard',
    complianceLog: 'Compliance Log',
    documentManagement: 'Document Management',
    formsManagement: 'Forms Management',
    humanResources: 'People',
    programOverview: 'Program Overview',
    reports: 'Reports'
};

export default translations;
