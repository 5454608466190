import { atom } from 'recoil';

const attachmentsAtom = atom({
    default: [],
    key: 'attachments'
});

const state = {
    atoms: {
        attachmentsAtom
    }
};

export default state;
