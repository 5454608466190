import { useCallback } from 'react';

import ActiveVersionButton from 'components/ActiveVersionButton';
import ArchivedVersionButton from 'components/ArchivedVersionButton';
import Buttons from 'components/Buttons';
import DraftVersionButton from 'components/DraftVersionButton';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const buttonsMap = {
    active: ActiveVersionButton,
    approved: ActiveVersionButton,
    archived: ArchivedVersionButton,
    draft: DraftVersionButton,
    rejected: ArchivedVersionButton,
    withdrawn: ArchivedVersionButton
};

const VersionButtons = props => {
    const { className = '', items = [], onChange, selectedVersion } = props;

    const handleClick = useCallback(
        index => () => {
            if (isFunction(onChange)) {
                onChange(index);
            }
        },
        [onChange]
    );

    return (
        <Buttons className={`${className} ${styles.versionButtons}`}>
            {items.map(({ status, version }) => {
                const VersionButtonComponent = buttonsMap[status];

                return (
                    <VersionButtonComponent
                        isSelected={selectedVersion === version}
                        key={version}
                        onClick={handleClick(version)}
                        status={status}
                        version={version}
                    />
                );
            })}
        </Buttons>
    );
};

export default VersionButtons;
