import { useCallback, useEffect, useState } from 'react';

import DeleteActivityModal from 'components/DeleteActivityModal';
// import DownloadIcon from 'components/DownloadIcon';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

// import useActivities from './hooks/useActivities';

import isFunction from 'utilities/isFunction';
import CheckPermissions from 'components/CheckPermissions';
import FlagIcon from 'components/icons/FlagIcon';
import CircleCheckIcon from 'components/icons/CircleCheckIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import EmptyIcon from 'components/icons/EmptyIcon';
import FlaggedIcon from 'components/icons/FlaggedIcon';

import useFlagged from './hooks/useFlagged';
import useUnflagged from './hooks/useUnflagged';

// import styles from './styles.module.scss';
// import { ClickableArea } from 'components/ClickableArea';

const ListItemDetailsButton = ({
    active,
    activityName,
    flagged,
    handleComplete,
    id,
    onDelete,
    // onDownload,
    schema
}) => {
    const { useActivities, useUserId } = useGlobalStateHooks();
    const [activities, setActivities] = useActivities();
    const [isOpen, setIsOpen] = useState(false);
    const [flaggedState, setFlaggedState] = useState(flagged);
    const [userId] = useUserId();

    const [flaggedActivity, flagActivity, resetFlagged] = useFlagged(schema);
    const [unflaggedActivity, unflagActivity, resetUnFlagged] =
        useUnflagged(schema);

    const handleDelete = useCallback(
        event => {
            if (isFunction(onDelete)) {
                onDelete(event);
            }

            setIsOpen(!isOpen);
        },
        [isOpen, onDelete]
    );

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleFlag = useCallback(() => {
        const formObject = { activityId: id, userId: parseInt(userId) };
        flagActivity(formObject);
    }, [flagActivity, id, userId]);

    const handleUnflag = useCallback(() => {
        const formObject = { activityId: id, userId: parseInt(userId) };
        unflagActivity(formObject);
    }, [id, unflagActivity, userId]);

    useEffect(() => {
        if (!flaggedActivity && !unflaggedActivity) {
            return;
        }
        if (id === flaggedActivity || id === unflaggedActivity) {
            resetFlagged();
            resetUnFlagged();
            setFlaggedState(!flaggedState);
            const completed = activities?.completed.map(obj => {
                if (obj.id === id) {
                    return { ...obj, flagged: !obj.flagged };
                }
                return obj;
            });
            const inProgress = activities?.inProgress.map(obj => {
                if (obj.id === id) {
                    return { ...obj, flagged: !obj.flagged };
                }
                return obj;
            });
            const overdue = activities?.overdue.map(obj => {
                if (obj.id === id) {
                    return { ...obj, flagged: !obj.flagged };
                }
                return obj;
            });
            const upcoming = activities?.upcoming.map(obj => {
                if (obj.id === id) {
                    return { ...obj, flagged: !obj.flagged };
                }
                return obj;
            });
            const newActivities = { completed, inProgress, overdue, upcoming };
            setActivities(newActivities);
        }
    }, [
        activities,
        flaggedActivity,
        flaggedState,
        id,
        resetFlagged,
        resetUnFlagged,
        setActivities,
        unflaggedActivity
    ]);

    return (
        <>
            {!flaggedState && (<FlagIcon onClick={handleFlag} />)}

            {flaggedState && (<FlaggedIcon onClick={handleUnflag} />)}

            {active && <CircleCheckIcon onClick={handleComplete} />}

            {!active && <EmptyIcon />}

            <CheckPermissions permission={['activity_delete']}>
                <DeleteIcon onClick={handleDelete} />
            </CheckPermissions>
            
            {isOpen && <DeleteActivityModal activityName={activityName} id={id} onClose={handleClose} schema={schema} />}
        </>
    );
};

export default ListItemDetailsButton;
