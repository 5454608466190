import { memo } from 'react';

import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import PolicyForm from 'components/PolicyForm';

const AddPolicyPanel = ({ categories, className = '', fileUploader, form, setPolicyCategories, setSectionCategories, visibleTo }) => (
    <ContentPanel className={className}>
        <PanelBody>
            <PolicyForm categories={categories} fileUploader={fileUploader} form={form} setPolicyCategories={setPolicyCategories} setSectionCategories={setSectionCategories} visibleTo={visibleTo} />
        </PanelBody>
    </ContentPanel>
);

export default memo(AddPolicyPanel);
