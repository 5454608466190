const translations = {
    assignedTo: 'Assigned To',
    caseCreatedDate: 'Case Created Date',
    company: 'Company',
    createdBy: 'Created By',
    dueDate: 'Due Date',
    usersInvolved: 'Users Involved',
    visibleBy: 'Visible By'
};

export default translations;
