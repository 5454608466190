import { useMemo } from 'react';

import ActionMenu from 'components/ActionMenu';
import SubmitButton from 'components/SubmitButton';

import DownloadIcon from 'components/DownloadIcon';
import ExcelIcon from 'components/icons/ExcelIcon';
import PdfIcon from 'components/icons/PdfIcon';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DownloadButton = ({
    className = '',
    hasPDFButton = false,
    isDisabled = false,
    onExcelExport,
    onPdfExport,
    onExportPdfReport,
    onAllDataExport,
    onFilteredDataExport,
}) => {
    const { EXPORT_TO_EXCEL, EXPORT_TO_PDF, EXPORT_ALL_DATA_EXCEL, EXPORT_FILTERED_DATA_EXCEL } = useTranslation();

    const items = useMemo(() => {
        const itemList = [];
        
        if (onExcelExport) {
            itemList.push({
                icon: <ExcelIcon />,
                onClick: onExcelExport,
                text: EXPORT_TO_EXCEL
            });
        }

        if (onPdfExport) {
            itemList.push({
                icon: <ExcelIcon />,
                onClick: onPdfExport,
                text: EXPORT_TO_PDF
            });
        }

        if (onAllDataExport) {
            itemList.push({
                icon: <ExcelIcon />,
                onClick: onAllDataExport,
                text: EXPORT_ALL_DATA_EXCEL
            });
        }

        if (onFilteredDataExport) {
            itemList.push({
                icon: <ExcelIcon />,
                onClick: onFilteredDataExport,
                text: EXPORT_FILTERED_DATA_EXCEL
            });
        }

        if (onExportPdfReport && hasPDFButton) {
            itemList.push({
                icon: <PdfIcon />,
                onClick: onExportPdfReport,
                text: 'PDF Report'
            });
        }

        return itemList;
    }, [EXPORT_ALL_DATA_EXCEL, EXPORT_FILTERED_DATA_EXCEL, EXPORT_TO_EXCEL, EXPORT_TO_PDF, hasPDFButton, onAllDataExport, onExcelExport, onExportPdfReport, onFilteredDataExport, onPdfExport]);

    return (
        <ActionMenu
            items={items}
            renderReferenceElement={({ handleClick }) => (
                <SubmitButton
                    className={`${className} ${styles.downloadButton}`}
                    isDisabled={isDisabled}
                    onClick={handleClick}
                    text={<DownloadIcon />}
                />
            )}
        ></ActionMenu>
    );
};

export default DownloadButton;
