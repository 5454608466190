import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('formDetailsPanel');

    return {
        ACTIVITY_ASSIGNMENTS: t('activityAssignments'),
        ACTIVITY_NAME: t('activityName'),
        ASSIGNMENT: t('assignment'),
        ASSIGNMENT_TYPE: t('assignmentType'),
        CREATED_BY: t('createdBy'),
        FORM_NAME: t('formName'),
        IS_ACTIVE: t('isActive'),
        NOTES: t('notes')
    };
};

export default useTranslation;
