import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addFromActivityButton');

    return {
        ADD_ITEM: t('addItem'),
        ADDED_ITEM: t('addedItem')
    };
};

export default useTranslation;
