import { atom } from 'recoil';

const activityTypeDataAtom = atom({
    default: '',
    key: 'activityTypeData'
})

const addActivityTypeAtom = atom({
    default: '',
    key: 'addActivityType'
});

const state = {
    atoms: {
        activityTypeDataAtom,
        addActivityTypeAtom,

    }
};

export default state;
