import { useCallback, useState } from 'react';

import ActionMenuItem from 'components/ActionMenuItem';
import FileIcon from 'components/icons/FileIcon';
import CaseCreationReasonModal from 'components/CaseCreationReasonModal';

import isFunction from 'utilities/isFunction';

import useCaseId from './hooks/useCaseId';
import useTranslation from './hooks/useTranslation';

const CreateCaseMenuListItemFromCase = ({ caseRecord, onSave, riskLabel }) => {
    if (!caseRecord) {
        throw new Error(
            'No valid caseRecord was passed to CloseAndCreateCaseMenuListItem'
        );
    }

    const { CREATE_A_CASE } = useTranslation();

    const [_, setCaseLinkId] = useCaseId();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = useCallback(() => {
        setCaseLinkId({
            caseId: caseRecord?.id,
            caseName: caseRecord?.case_name
        });
        setIsModalOpen(!isModalOpen);
    }, [caseRecord, isModalOpen, setCaseLinkId, setIsModalOpen]);

    const handleModalClose = useCallback(() => {
        setCaseLinkId('');
        setIsModalOpen(false);
    }, [setCaseLinkId, setIsModalOpen]);

    const handleSave = useCallback(() => {
        if (isFunction(onSave)) {
            onSave();
        }

        setIsModalOpen(false);
    }, [onSave, setIsModalOpen]);

    return (
        <>
            <ActionMenuItem
                icon={<FileIcon />}
                onClick={handleClick}
                text={CREATE_A_CASE}
            />

            {isModalOpen && (
                <CaseCreationReasonModal
                    caseRecord={caseRecord}
                    onClose={handleModalClose}
                    onSave={handleSave}
                    riskLabel={riskLabel}
                    toClose={false}
                />
            )}
        </>
    );
};

export default CreateCaseMenuListItemFromCase;