import BarListItem from 'components/BarListItem';

import styles from './styles.module.scss';

const NegativeBarListItem = ({
    children,
    className = '',
    isActive = false
}) => {
    const activeClass = isActive ? styles.isActive : '';

    return (
        <BarListItem
            className={`${activeClass} ${className} ${styles.negativeBarListItem}`}
            isActive={isActive}
        >
            {children}
        </BarListItem>
    );
};

export default NegativeBarListItem;
