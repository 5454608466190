import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useConfig from 'hooks/useConfig';

import sendActivityAssignedEmail from 'utilities/sendActivityAssignedEmail';
import getRecurrenceLoopCount from 'utilities/getRecurrenceLoopCount';
import getDateDifference from 'utilities/getDateDifference';
import getNextDateInRecurrenceLoop from 'utilities/getNextDateInRecurrenceLoop';
import { useSetCurrentActivityForm } from 'hooks/useSetCurrentActivityForm';

const { addActivity } = state;

const { addActivityAtom } = addActivity.atoms;

const MUTATE_ACTIVITY = tenantSchema => gql`
    mutation insertActivity (
        $activityTypeId: Int!,
        $dropdownSelections: jsonb!,
        $dueDate: timestamp = "",
        $groupCompletionRate: jsonb!,
        $hierarchical: Boolean!,
        $initialEmail: Boolean!,
        $instructions: String!,
        $name: String!,
        $policyId: Int = 0,
        $startDate: timestamp = ""
    ) {
        insert_${tenantSchema}_activity(
            objects: {
                activity_type_id: $activityTypeId,
                dropdown_selections: $dropdownSelections,
                due_date: $dueDate,
                group_completion_rate: $groupCompletionRate,
                instructions: $instructions,
                is_hierarchical: $hierarchical,
                name: $name,
                policy_id: $policyId,
                send_email: $initialEmail,
                start_date: $startDate
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_RECURRENCE = tenantSchema => gql`
    mutation insertActivityRecurrence (
        $activityTypeId: Int!,
        $groupCompletionRate: jsonb!,
        $hierarchical: Boolean!,
        $initialEmail: Boolean!,
        $instructions: String!,
        $name: String!,
        $policyId: Int = 0,
        $recurrence: String!,
        $startDate: Int!,
    ) {
        insert_${tenantSchema}_activity_recurrence(
            objects: {
                activity_type_id: $activityTypeId,
                start_date: $startDate,
                group_completion_rate: $groupCompletionRate,
                instructions: $instructions,
                is_hierarchical: $hierarchical,
                name: $name,
                policy_id: $policyId,
                recurrence_type: $recurrence,
                send_email: $initialEmail,
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_RECURRENCES = tenantSchema => gql`
    mutation insertActivityRecurrences (
        $activityId: Int!,
        $activityRecurrenceId: Int!
    ) {
        insert_${tenantSchema}_activity_recurrences(
            objects: {
                activity_id: $activityId,
                activity_recurrence_id: $activityRecurrenceId
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_USER = tenantSchema => gql`
    mutation insertUserActivity(
        $activityId: Int!,
        $assignmentType: String!,
        $status: String!,
        $userId: Int,
        $groupId: Int
    ) {
        insert_${tenantSchema}_user_activity(objects: {
            activity_id: $activityId,
            assignment_type: $assignmentType,
            status: $status,
            user_id: $userId,
            group_id: $groupId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_USER_RECURRENCE = tenantSchema => gql`
    mutation insertUserActivity(
        $activityRecurrenceId: Int!,
        $assignmentType: String!,
        $userId: Int,
        $groupId: Int
    ) {
        insert_${tenantSchema}_user_activity_recurrence(objects: {
            activity_recurrence_id: $activityRecurrenceId,
            assignment_type: $assignmentType,
            user_id: $userId,
            group_id: $groupId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_POLICY = tenantSchema => gql`
    mutation insertActivityPolicy(
        $activityId: Int!,
        $policyId: Int!,
        $sectionId: Int
    ) {
        insert_${tenantSchema}_activity_policies(objects: {
            activity_id: $activityId,
            document_mapping_id: $policyId,
            document_section_id: $sectionId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_ATTACHMENT = tenantSchema => gql`
    mutation insertActivityAttachment(
        $activityId: Int!,
        $filename: String!,
        $name: String!
    ) {
        insert_${tenantSchema}_activity_attachment(objects: {
            file: $filename,
            activity_id: $activityId,
            name: $name
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_FORMS = tenantSchema => gql`
    mutation insertActivityForm(
        $activityId: Int!,
        $formId: Int!,
        $assignment: String!,
        $assignmentType: String = "1",
        $dueDate: timestamp = ""
    ) {
        insert_${tenantSchema}_activity_forms(objects: {
            activity_id: $activityId,
            form_id: $formId,
            assignment: $assignment,
            assignment_type: $assignmentType,
            due_date: $dueDate,
						locked: false,
						current: true
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_ENTITY = tenantSchema => gql`
    mutation insertActivityEntity(
        $activityId: Int!,
        $entityId: Int!
    ) {
        insert_${tenantSchema}_activity_entity(objects: {
            activity_id: $activityId,
            entity_id: $entityId,
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_LINKAGE = tenantSchema => gql`
    mutation insertActivityLinkage(
        $activityId: Int,
        $linkActivityId: Int,
        $caseId: Int,
        $logId: Int
    ) {
        insert_${tenantSchema}_linkages(objects: {
            activity_id_1: $activityId
            activity_id_2: $linkActivityId
            case_id_1: $caseId
            log_id_1: $logId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_RECURRENCE_POLICY = tenantSchema => gql`
    mutation insertActivityRecurrencePolicy(
        $activityId: Int!,
        $policyId: Int!,
        $sectionId: Int
    ) {
        insert_${tenantSchema}_activity_recurrence_policies(objects: {
            activity_recurrence_id: $activityId,
            document_mapping_id: $policyId,
            document_section_id: $sectionId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_RECURRENCE_ATTACHMENT = tenantSchema => gql`
    mutation insertActivityRecurrenceAttachment(
        $activityId: Int!,
        $filename: String!,
        $name: String!
    ) {
        insert_${tenantSchema}_activity_recurrence_attachment(objects: {
            file: $filename,
            activity_recurrence_id: $activityId,
            name: $name
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_RECURRENCE_FORMS = tenantSchema => gql`
    mutation insertActivityRecurrenceForm(
        $activityId: Int!,
        $formId: Int!,
        $assignment: String!,
        $assignmentType: String = "1",
        $dueDate: Int!
    ) {
        insert_${tenantSchema}_activity_recurrence_forms(objects: {
            activity_recurrence_id: $activityId,
            form_id: $formId,
            assignment: $assignment,
            assignment_type: $assignmentType,
            due_date: $dueDate
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_RECURRENCE_ENTITY = tenantSchema => gql`
    mutation insertActivityRecurrenceEntity(
        $activityId: Int!,
        $entityId: Int!
    ) {
        insert_${tenantSchema}_activity_recurrence_entity(objects: {
            activity_recurrence_id: $activityId,
            entity_id: $entityId,
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_RECURRENCE_LINKAGE = tenantSchema => gql`
    mutation insertActivityRecurrenceLinkage(
        $activityId: Int,
        $linkActivityId: Int,
        $caseId: Int
    ) {
        insert_${tenantSchema}_activity_recurrence_linkage(objects: {
            activity_recurrence_id: $activityId
            link_activity_id: $linkActivityId
            case_id: $caseId
        }) {
            returning {
                id
            }
        }
    }
`;

const useData = providedSchema => {
    const mutation = useGraphQL(MUTATE_ACTIVITY, providedSchema);
    const mutationUserActivity = useGraphQL(
        MUTATE_ACTIVITY_USER,
        providedSchema
    );
    const mutationActivityPolicy = useGraphQL(
        MUTATE_ACTIVITY_POLICY,
        providedSchema
    );
    const mutationActivityAttachment = useGraphQL(
        MUTATE_ACTIVITY_ATTACHMENT,
        providedSchema
    );
    const mutationActivityForms = useGraphQL(
        MUTATE_ACTIVITY_FORMS,
        providedSchema
    );
    const mutationActivityEntity = useGraphQL(
        MUTATE_ACTIVITY_ENTITY,
        providedSchema
    );
    const mutationActivityLinkage = useGraphQL(
        MUTATE_ACTIVITY_LINKAGE,
        providedSchema
    );
    const mutationActivityRecurrence = useGraphQL(
        MUTATE_ACTIVITY_RECURRENCE,
        providedSchema
    );
    const mutationActivityRecurrences = useGraphQL(
        MUTATE_ACTIVITY_RECURRENCES,
        providedSchema
    );
    const mutationActivityUserRecurrence = useGraphQL(
        MUTATE_ACTIVITY_USER_RECURRENCE,
        providedSchema
    );
    const mutationActivityRecurrencePolicy = useGraphQL(
        MUTATE_ACTIVITY_RECURRENCE_POLICY,
        providedSchema
    );
    const mutationActivityRecurrenceAttachment = useGraphQL(
        MUTATE_ACTIVITY_RECURRENCE_ATTACHMENT,
        providedSchema
    );
    const mutationActivityRecurrenceForms = useGraphQL(
        MUTATE_ACTIVITY_RECURRENCE_FORMS,
        providedSchema
    );
    const mutationActivityRecurrenceEntity = useGraphQL(
        MUTATE_ACTIVITY_RECURRENCE_ENTITY,
        providedSchema
    );
    const mutationActivityRecurrenceLinkage = useGraphQL(
        MUTATE_ACTIVITY_RECURRENCE_LINKAGE,
        providedSchema
    );
    const setCurrentActiveForm = useSetCurrentActivityForm();

    const { API_KEY } = useConfig();
    const { useSchema, useSchemaDescription, useTenants, useUserSetSchema } =
        useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();

    const usedSchema = providedSchema
        ? providedSchema
        : userSetSchema
        ? userSetSchema
        : tenants && tenants.length === 1
        ? tenants[0]
        : schema;
    const [schemaDescription] = useSchemaDescription();
    const usedSchemaDescription = providedSchema
        ? providedSchema
        : userSetSchema
        ? userSetSchema
        : tenants && tenants.length === 1
        ? tenants[0]
        : schemaDescription;

    // TODO we need to normalize this signature to work like all other useData
    const createActivity = useRecoilCallback(({ set }) => activityUsers => {
        // const createActivity = useRecoilCallback(({ set }) => variables => {

        activityUsers.instructions =
            activityUsers?.instructions === ''
                ? ' '
                : activityUsers?.instructions;

        const assignments = [];
        const groupCompletionRate = [];
        const dropdownSelections = {};
        const recurrenceIds = [];
        var primaryId;

        let assignmentCategoryCount = 0;

        for (var key in activityUsers) {
            if (key.includes('assignee') || key.includes('reviewer')) {
                assignmentCategoryCount++;

                try {
                    const assignmentType = JSON.parse(activityUsers[key]);

                    if (assignmentType.constructor === Array) {
                        for (const user of assignmentType) {
                            if (user.type === 'user') {
                                assignments.push({
                                    assignmentType: key,
                                    userId: user.value
                                });
                            } else if (user.type === 'group') {
                                assignments.push({
                                    assignmentType: key,
                                    groupId: user.value
                                });
                            }
                        }
                    }
                } catch {
                    if (activityUsers[key] !== '') {
                        assignments.push({
                            assignmentType: key,
                            userId: activityUsers[key]
                        });
                    }
                }
            }
        }
        for (var i = 0; i < assignmentCategoryCount; i++) {
            groupCompletionRate.push(1);
        }

        const fullRun = async () => {
            const runMutation = async setId => {
                const dueDate = new Date(
                    activityUsers['due-date']
                ).toISOString();

                const startDate = new Date(
                    activityUsers['start-date']
                ).toISOString();

                const res = await mutation({
                    activityTypeId: Number(activityUsers['activity-type']),
                    dropdownSelections,
                    dueDate,
                    groupCompletionRate,
                    hierarchical: activityUsers.hierarchical,
                    initialEmail: activityUsers.initialEmail,
                    instructions: activityUsers.instructions,
                    name: activityUsers.activity,
                    policyId: Number(activityUsers.policy),
                    startDate
                });

                const id =
                    res?.data[`insert_${usedSchema}_activity`].returning[0]?.id;

                await Promise.all(
                    assignments.map(
                        async ({ assignmentType, groupId, userId }) => {
                            await mutationUserActivity({
                                activityId: id,
                                assignmentType,
                                groupId,
                                status: 'inprogress',
                                userId
                            });
                            if (
                                activityUsers &&
                                new Date(activityUsers['start-date']) <
                                    new Date()
                            ) {
                                if (activityUsers.initialEmail) {
                                    sendActivityAssignedEmail(
                                        id,
                                        activityUsers.activity,
                                        assignmentType,
                                        new Date(activityUsers['due-date']),
                                        groupId,
                                        usedSchema,
                                        usedSchemaDescription,
                                        userId,
                                        API_KEY ? API_KEY : ''
                                    );
                                }
                            }
                        }
                    )
                );

                for (const formPolicy of activityUsers.policy) {
                    await mutationActivityPolicy({
                        activityId: id,
                        policyId: Number(formPolicy?.policy),
                        sectionId: formPolicy.section
                            ? Number(formPolicy?.section)
                            : null
                    });
                }

                const files = activityUsers?.files;
                if (
                    typeof files?.files === 'object' &&
                    !Array.isArray(files?.files) &&
                    files?.files !== null
                ) {
                    for (let [key, value] of Object.entries(files.files)) {
                        if (!value?.isUploadError) {
                            const namePrefix = key
                                .slice(0, key.lastIndexOf('_'))
                                .replaceAll('_', ' ')
                                .trim();
                            const fileExtension = key.slice(
                                key.lastIndexOf('.') + 1
                            );
                            const name = `${namePrefix}.${fileExtension}`;
                            await mutationActivityAttachment({
                                activityId: id,
                                filename: key,
                                name
                            });
                        }
                    }
                }

                for (let form of activityUsers.forms) {
                    if ('formId' in form) {
                        const { data } = await mutationActivityForms({
                            activityId: id,
                            assignment: form?.assignee,
                            dueDate: form?.dueDate,
                            formId: form?.formId
                        });

                        for (const activityForm of data[
                            `insert_${usedSchema}_activity_forms`
                        ]?.returning ?? []) {
                            await setCurrentActiveForm({
                                activityFormId: activityForm.id,
                                activityId: id,
                                tenantSchema: usedSchema
                            });
                        }
                    }
                }

                if (
                    'entity' in activityUsers &&
                    activityUsers?.entity &&
                    Array.isArray(activityUsers?.entity) &&
                    activityUsers?.entity.filter(e => e).length > 0
                ) {
                    const runEntities = activityUsers?.entity.filter(e => e);
                    for (const entity of runEntities) {
                        await mutationActivityEntity({
                            activityId: id,
                            entityId: entity
                        });
                    }
                }

                await mutationActivityLinkage({
                    activityId: id,
                    caseId: activityUsers?.linkCase,
                    linkActivityId: activityUsers?.linkActivity,
                    logId: activityUsers?.linkLog
                });
                if (setId) {
                    primaryId = id;
                    return primaryId;
                } else {
                    recurrenceIds.push(id);
                }
            };

            const setId = await runMutation(true);

            const recurrences = getRecurrenceLoopCount(
                activityUsers?.recurrence
            );

            const dateDifference = getDateDifference(
                activityUsers['due-date'],
                activityUsers['start-date']
            );
            var endDateForLoop = new Date(
                new Date(activityUsers['due-date']).setDate(
                    new Date(activityUsers['due-date']).getDate()
                )
            );
            for (let recur = 0; recur < recurrences; recur++) {
                [activityUsers['start-date'], endDateForLoop] =
                    getNextDateInRecurrenceLoop(
                        endDateForLoop,
                        recurrences,
                        dateDifference
                    );
                activityUsers['due-date'] = endDateForLoop;
                await runMutation(false);
            }

            const runRecurrence = async () => {
                const activityRecurrenceMutation =
                    await mutationActivityRecurrence({
                        activityTypeId: Number(activityUsers['activity-type']),
                        groupCompletionRate,
                        hierarchical: activityUsers['hierarchical'],
                        initialEmail: activityUsers['initialEmail'],
                        instructions: activityUsers['instructions'],
                        name: activityUsers['activity'],
                        recurrence: activityUsers['recurrence'],
                        startDate: dateDifference
                    });
                const activityRecurrenceId =
                    activityRecurrenceMutation?.data[
                        `insert_${usedSchema}_activity_recurrence`
                    ].returning[0]?.id;

                await mutationActivityRecurrences({
                    activityId: primaryId,
                    activityRecurrenceId: activityRecurrenceId
                });

                for (const recurrenceId of recurrenceIds) {
                    await mutationActivityRecurrences({
                        activityId: recurrenceId,
                        activityRecurrenceId: activityRecurrenceId
                    });
                }

                await Promise.all(
                    assignments.map(
                        async ({ assignmentType, groupId, userId }) => {
                            await mutationActivityUserRecurrence({
                                activityRecurrenceId: activityRecurrenceId,
                                assignmentType,
                                groupId,
                                userId
                            });
                        }
                    )
                );

                for (const formPolicy of activityUsers.policy) {
                    await mutationActivityRecurrencePolicy({
                        activityId: activityRecurrenceId,
                        policyId: Number(formPolicy?.policy),
                        sectionId: formPolicy.section
                            ? Number(formPolicy?.section)
                            : null
                    });
                }
                const files = activityUsers?.files;
                if (
                    typeof files?.files === 'object' &&
                    !Array.isArray(files?.files) &&
                    files?.files !== null
                ) {
                    for (let [key, value] of Object.entries(files.files)) {
                        if (!value?.isUploadError) {
                            const namePrefix = key
                                .slice(0, key.lastIndexOf('_'))
                                .replaceAll('_', ' ')
                                .trim();
                            const fileExtension = key.slice(
                                key.lastIndexOf('.') + 1
                            );
                            const name = `${namePrefix}.${fileExtension}`;
                            await mutationActivityRecurrenceAttachment({
                                activityId: activityRecurrenceId,
                                filename: key,
                                name
                            });
                        }
                    }
                }
                for (let form of activityUsers.forms) {
                    if ('formId' in form) {
                        await mutationActivityRecurrenceForms({
                            activityId: activityRecurrenceId,
                            assignment: form?.assignee,
                            dueDate: dateDifference,
                            formId: form?.formId
                        });
                    }
                }
                if (
                    'entity' in activityUsers &&
                    activityUsers?.entity &&
                    Array.isArray(activityUsers?.entity) &&
                    activityUsers?.entity.filter(e => e).length > 0
                ) {
                    const runEntities = activityUsers?.entity.filter(e => e);
                    for (const entity of runEntities) {
                        await mutationActivityRecurrenceEntity({
                            activityId: activityRecurrenceId,
                            entityId: entity
                        });
                    }
                }
                await mutationActivityRecurrenceLinkage({
                    activityId: activityRecurrenceId,
                    caseId: activityUsers?.linkCase,
                    linkActivityId: activityUsers?.linkActivity
                });
            };

            if (recurrenceIds.length > 0) {
                await runRecurrence();
            }

            set(addActivityAtom, setId);
        };

        fullRun();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addActivityAtom, '');
    });

    return [useRecoilValue(addActivityAtom), createActivity, reset];
};

export default useData;
