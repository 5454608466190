import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('complianceLog');

    return {
        COMPLIANCE_LOG: t('complianceLog')
    };
};

export default useConstants;
