export const updateUserActivityName = schema =>
    `update_${schema}_user_activity`;

export const updateUserActivityMutationBodyGql = ({
    activityId,
    groupId,
    status,
    schema,
    userId
}) => {
    return {
        [updateUserActivityName(schema)]: {
            __args: {
                _set: {
                    status
                },
                where: {
                    activity_id: { _eq: activityId },
                    ...(groupId && {
                        group_id: { _eq: groupId }
                    }),
                    ...(userId && {
                        user_id: { _eq: userId }
                    })
                }
            },
            returning: {
                activity_id: true,
                group_id: true,
                status: true,
                user_id: true
            }
        }
    };
};
