import TextLabel from 'components/TextLabel';

import styles from './styles.module.scss';

const WithLabel = props => {
    const { className = '', children, text = '' } = props;

    return (
        <div className={`${className} ${styles.withLabel}`}>
            <TextLabel className={styles.textLabel} text={text} />

            {children}
        </div>
    );
};

export default WithLabel;
