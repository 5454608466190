import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionMenuItem from 'components/ActionMenuItem';
import CalendarIcon from 'components/icons/CalendarIcon';

import useConfig from 'hooks/useConfig';

import useCaseId from './hooks/useCaseId';
import useTranslation from './hooks/useTranslation';

const CreateActivityMenuListItemFromCase = ({ caseId, caseName }) => {
    const [_, setCaseLinkId] = useCaseId();

    const { ADD_ACTIVITY_ROUTE } = useConfig();

    const navigate = useNavigate();

    const { CREATE_AN_ACTIVITY } = useTranslation();

    const handleClick = useCallback(() => {
        setCaseLinkId({
            caseId: caseId,
            caseName: caseName
        });

        navigate(ADD_ACTIVITY_ROUTE);
    }, [ADD_ACTIVITY_ROUTE, caseId, caseName, navigate, setCaseLinkId]);

    return (
        <ActionMenuItem
            icon={<CalendarIcon />}
            onClick={handleClick}
            text={CREATE_AN_ACTIVITY}
        />
    );
};

export default CreateActivityMenuListItemFromCase;