import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createLogEntryForm');

    return {
        DATE_OF_OCCURRENCE: t('dateOfOccurrence'),
        LOG_CATEGORY: t('logCategory'),
        LOG_DESCRIPTION: t('logDescription'),
        RISK_LABEL_LOG_ENTRY: t('riskLabelLogEntry')
    };
};

export default useTranslation;
