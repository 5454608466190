import { useCallback, useState } from 'react';

import AddButton from 'components/AddButton';
import AddLogEntryModal from 'components/AddLogEntryModal';

import useTranslation from './hooks/useTranslation';

const AddCaseButton = ({ className = '' }) => {
    const { ADD_LOG_ENTRY } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <AddButton
                className={className}
                onClick={handleClick}
                text={ADD_LOG_ENTRY}
            />

            {isOpen && <AddLogEntryModal onClose={handleClose} />}
        </>
    );
};

export default AddCaseButton;
