import AvatarWithText from 'components/AvatarWithText';
import BarListItem from 'components/BarListItem';
import BodyText from 'components/BodyText';
import Due from 'components/Due';
import ImportantLink from 'components/ImportantLink';
import Overdue from 'components/Overdue';

import differenceInDays from 'utilities/differenceInDays';

import styles from './styles.module.scss';

const todaysDate = new Date();

const FormsListItem = ({
    assigned,
    className = '',
    completed,
    dueDate,
    image,
    isActive = false,
    name,
    total
}) => {
    const dueInDays = differenceInDays(todaysDate, dueDate);

    const isOverDue = dueInDays < 0;

    const activeClass = isActive ? styles.isActive : '';

    return (
        <BarListItem
            className={`${activeClass} ${className} ${styles.formsListItem}`}
            isActive={isActive}
        >
            <div>
                <ImportantLink className={styles.name} text={name} />

                {isOverDue ? (
                    <Overdue count={dueInDays} />
                ) : (
                    <Due count={dueInDays} />
                )}
            </div>

            <BodyText
                className={styles.completed}
                text={`${completed} / ${total}`}
            />

            <AvatarWithText
                className={styles.avatar}
                image={image}
                text={assigned}
            />
        </BarListItem>
    );
};

export default FormsListItem;
