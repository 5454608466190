import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('complianceEntryPolicyPanel');

    return {
        DESCRIPTION: t('description'),
        EFFECTIVE_DATE: t('effectiveDate'),
        POLICY_DETAILS: t('policyDetails'),
        POLICY_NAME: t('policyName'),
        POLICY_REFERENCE: t('policyReference'),
        RISK_LABEL: t('riskLabel')
    };
};

export default useTranslation;
