import formatDate from 'utilities/formatDate';
import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) =>
    data[`${schema}_log`].reduce((accumulator, log = {}) => {
        const {
            created_date: logCreatedDate,
            date_of_occurrence: dateOfOccurrence,
            log_types: logTypes = [],
            id: logId,
            risk_color: riskColor,
            risk_label: riskLabel,
            description: logDescription
        } = log;

        const { log_type: logType } = logTypes[0] || {};
        const { name } = logType || {};

        accumulator.push({
            createdDate: formatDate(new Date(logCreatedDate)),
            dateOfOccurrence: formatDate(new Date(dateOfOccurrence)),
            description: logDescription,
            id: logId,
            logSchema: schema,
            riskColor,
            riskLabel,
            sortDate: dateOfOccurrence,
            type: name
        });
        
        accumulator = accumulator.sort(
            (objA, objB) => (objA.sortDate > objB.sortDate ? 1 : -1)
        );

        return accumulator;
    }, []);

const normalizeData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeData;
