import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';

import AddActivityDetailsPanel from 'components/AddActivityDetailsPanel';
import AddActivityMetaPanel from 'components/AddActivityMetaPanel';
import CancelSaveButtons from 'components/CancelSaveButtons';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';
import WithPageTitle from 'components/WithPageTitle';

import translations from './constants';

import tryCatch from 'utilities/tryCatch';

import styles from './styles.module.scss';

import useActivityId from './hooks/useActivityId';
import useCaseId from './hooks/useCaseId';
import useData from './hooks/useData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const AddActivity = () => {
    const { ADD_NEW_ACTIVITY } = translations();

    const { ACTIVITY_ROUTE, HOME } = useConfig();
    const { useActivities, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();

    const [isUploadingFiles, setIsUploadingFiles] = useState(false);
    const [_errorMessage, setErrorMessage] = useState();
    const [activityLink, setActivityLinkId] = useActivityId();
    const [caseLink, setCaseLinkId] = useCaseId();
    const [addReviewer, setAddReviewer] = useState();
    const [assignees, setAssignees] = useState([]);
    const [entities, setEntities] = useState([]);
    const [forms, setForms] = useState([{}]);
    const [createdActivity, createActivity, reset] = useData();
    const [files, setFiles] = useState({});
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    const [_activities, setActivities] = useActivities();

    const form = useForm({
        activity: '',
        'activity-type': '',
        'assignee-': '',
        'due-date': '',
        entity: '',
        hierarchical: '',
        'initial-email': '',
        instructions: '',
        'overdue-frequency': '',
        policy: '',
        recurrence: '',
        'reminder-frequency': '',
        reviewer: '',
        'start-date': '',
        tenant: ''
    });

    const { handleForm } = form;

    const navigate = useNavigate();

    const handleCancel = useCallback(() => {
        setActivityLinkId('');
        setCaseLinkId('');
        navigate(HOME);
    }, [HOME, navigate, setActivityLinkId, setCaseLinkId]);

    const handleSave = useCallback(() => {
        if(isUploadingFiles){
            return;
        }

        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }

        const formObject = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                

                accumulator[key] = value;

                return accumulator;
            },
            {}
        );
        const formPolicies = tryCatch(() => JSON.parse(formObject['policy']), []);
        const formPoliciesAndSections = [];
        if(Array.isArray(formPolicies) && formPolicies.length > 0)
        for (const p of formPolicies){
            if(p){
                formPoliciesAndSections.push({policy: p.split(".")[0], section: (p.split(".")[1] !== '0') ? p.split(".")[1] : null})
            }
        }
        formObject['policy'] = formPoliciesAndSections;
        formObject['entity'] = entities;
        formObject['files'] = files;
        formObject['forms'] = forms;
        formObject['linkActivity'] = activityLink?.activityId || null;
        formObject['linkCase'] = caseLink?.caseId || null;
        formObject['initialEmail'] = (formObject['initial-email'] === "true");
        formObject['hierarchical'] = (formObject['hierarchical'] === "true");
        createActivity(formObject);
        setActivityLinkId('');
        // eslint-disable-next-line
    }, [activityLink, caseLink, handleForm, setErrorMessage, createActivity, entities, files, forms, setActivityLinkId]);

    const handleFileUpload = newFiles => {
        if (Object.keys(newFiles).length > 0) {
            const variables = {};

            variables['files'] = newFiles;

            setFiles(variables);
        }
    }

    useEffect(() => {
        if (files && Object.keys(files).length > 0) {
            const isAnyFileUploading = Object.values(files.files).some(file => file.isUploading);
            setIsUploadingFiles(isAnyFileUploading);
        } else {
            setIsUploadingFiles(false);
        }
    }, [files, setIsUploadingFiles]);

    useEffect(() => {
        if (!createdActivity) {
            return;
        }

        setActivities({});
        const schemaReplacement = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;

        navigate(ACTIVITY_ROUTE.replace(':activityId', createdActivity).replace(':activitySchema', schemaReplacement));

        reset();
    }, [ACTIVITY_ROUTE, navigate, reset, createdActivity, schema, setActivities, tenants, userSetSchema]);

    return (
        <div className={styles.addActivity}>
            <WithPageTitle title={ADD_NEW_ACTIVITY}>
                <CancelSaveButtons
                    isSaveDisabled={isUploadingFiles}
                    onCancel={handleCancel}
                    onSave={handleSave}
                />
            </WithPageTitle>

            <PanelWithAsidePanel>
                <AddActivityDetailsPanel
                    addReviewer={addReviewer}
                    assignees={assignees}
                    fileUploader={handleFileUpload}
                    form={form}
                    forms={forms}
                    providedSchema={userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema}
                    setForms={setForms}
                />

                <AddActivityMetaPanel addReviewer={addReviewer} assignees={assignees} form={form} setAddReviewer={setAddReviewer} setAssignees={setAssignees} setSelectedEntities={setEntities} />
            </PanelWithAsidePanel>
        </div>
    );
};

export default memo(AddActivity);
