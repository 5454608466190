import ContentPanel from 'components/ContentPanel';
import Menu from 'components/Menu';
import Overlay from 'components/Overlay';

import styles from './styles.module.scss';

const DropdownMenu = props => {
    const {
        children,
        className = '',
        isOpen,
        onClose,
        placement = 'bottom',
        referenceElement
    } = props;
    return (
        <Overlay className={styles.overlay} isOpen={isOpen} onClose={onClose}>
            <Menu
                className={className}
                placement={placement}
                referenceElement={referenceElement}
            >
                <ContentPanel className={styles.contentPanel}>
                    {children}
                </ContentPanel>
            </Menu>
        </Overlay>
    );
};

export default DropdownMenu;
