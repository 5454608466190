import { useCallback, useState } from 'react';

import MetaPanel from 'components/MetaPanel';
import PanelBody from 'components/PanelBody';
import UserList from 'components/UserList';
import Versions from 'components/Versions';
import VersionHistory from 'components/VersionHistory';

import useTranslation from './hooks/useTranslation';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useComment from './hooks/useComment';

import styles from './styles.module.scss';
import VersionedComments from 'components/VersionedComments';
import TextWithLabel from 'components/TextWithLabel';
import titleCase from 'utilities/titleCase';

const MarketingRequestMetaPanel = props => {
    const {
        activeParty,
        className = '',
        comments,
        createdBy,
        onVersionChange,
        requestId,
        requestName,
        requestSchema,
        selectedVersion,
        versions=[]
    } = props;

    const { useTenants, useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();
    const [tenants] = useTenants();

    const [_, createComment, deleteComment, updateComment] = useComment(requestId, requestSchema);
    const [versionComments, setVersionComments] = useState(comments);

    const handleCommentSubmit = useCallback(
        async formData => {
            const addedComment = formData.get('comment');
            const commentId = await createComment({addedComment, requestId, versionId:versions.filter(version => version.label === selectedVersion)[0]?.id});

            const commentDate = new Date();

            const newComment = {
                content: addedComment,
                createdAt: commentDate.toISOString().slice(0, -1),
                id: commentId,
                user: {
                    id: userId,
                    name: `${localStorage.firstName} ${localStorage.lastName}`
                }
            };

            const newComments = {...versionComments};
            if (selectedVersion in newComments){
                newComments[selectedVersion].push(newComment);
            }else{
                newComments[selectedVersion] = [newComment];
            }
            
            setVersionComments(newComments);
        },
        [requestId, selectedVersion, createComment, userId, versions, versionComments]
    );

    const handleDeleteComment = async commentId => {
        await deleteComment(commentId);

        const newComments = { ...versionComments };
        newComments[selectedVersion] = newComments[selectedVersion].map(comment => {
            if (comment.id === commentId) {
                return { ...comment, deleted: true };
            }
            return comment;
        });

        setVersionComments(newComments);
    };

    const handleUpdateComment = async params => {
        const { commentId, newText } = params;
        await updateComment(commentId, newText);

        const newComments = { ...versionComments };
        newComments[selectedVersion] = newComments[selectedVersion].map(comment => {
            if (comment.id === commentId) {
                return { ...comment, content: newText };
            }
            return comment;
        });

        setVersionComments(newComments);
    };

    const { ACTIVE_PARTY, CREATED_BY } = useTranslation();

    return (
        <MetaPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <UserList title={CREATED_BY} users={[createdBy]} />

                <UserList title={ACTIVE_PARTY} users={activeParty} />


                {tenants.length > 1 &&
                    <TextWithLabel className={styles.frequencyText} label={'Company'} text={requestSchema ? titleCase(requestSchema) : '-'} />
                }

                <Versions
                    items={versions}
                    onChange={onVersionChange}
                    providedSchema={requestSchema}
                    requestId={requestId}
                    selectedVersion={selectedVersion}
                />

                <VersionedComments
                    className={styles.comments}
                    commentType={'marketing-request'}
                    commentTypeId={requestId}
                    commentTypeName={requestName}
                    comments={versionComments}
                    onDelete={handleDeleteComment}
                    onSubmit={handleCommentSubmit}
                    onUpdate={handleUpdateComment}
                    usedSchema={requestSchema}
                    user={{
                        id: userId,
                        name: `${localStorage.firstName} ${localStorage.lastName}`
                    }}
                />

                <VersionHistory
                    items={versions}
                    selectedVersion={selectedVersion}
                />
            </PanelBody>
        </MetaPanel>
    );
};

export default MarketingRequestMetaPanel;
