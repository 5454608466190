import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('selectUsersDrawer');

    return {
        CHANGE_USER: t('changeUser'),
        SEARCH_AND_SELECT_USERS: t('searchAndSelectUsers')
    };
};

export default useTranslation;
