import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('confirmRequestUpdateModal');

    return {
        REQUEST_UPDATES_FROM_SUBMITTER: t('requestUpdatesFromSubmitter')
    };
};

export default useTranslation;
