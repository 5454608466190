import { useCallback } from 'react';

import DeleteButton from 'components/DeleteButton';
import ConfirmationModal from 'components/ConfirmationModal';
import SubmitButton from 'components/SubmitButton';

import isFunction from 'utilities/isFunction';
import replaceTextWithJSX from 'utilities/replaceTextWithJSX';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DeleteAttachmentModal = ({ attachmentName, onClose, onSubmit }) => {
    const { CANCEL, YOU_ARE_ABOUT_TO_DELETE_ATTACHMENT } = useTranslation();

    const handleClose = useCallback(() => {
        if (isFunction(onClose)) {
            onClose(false);
        }
    }, [onClose]);

    const handleSubmit = useCallback(() => {
        if (isFunction(onSubmit)) {
            onSubmit();
        }
    }, [onSubmit]);

    return (
        <ConfirmationModal
            onClose={handleClose}
            onSubmit={onSubmit}
            text={replaceTextWithJSX(YOU_ARE_ABOUT_TO_DELETE_ATTACHMENT, {
                ATTACHMENT_NAME: (
                    <span className={styles.sectionName}>{attachmentName}</span>
                )
            })}
        >
            <DeleteButton onClick={handleSubmit} />

            <SubmitButton onClick={handleClose} text={CANCEL} />
        </ConfirmationModal>
    );
};

export default DeleteAttachmentModal;
