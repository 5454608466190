import ContentPanel from 'components/ContentPanel';
import { ActivitiesPanelHeader } from './activities-panel-header.component';
import { ActivitiesTable } from './activities-table.component';
import styles from './styles.module.scss';

export const ActivitiesPanel = ({
    activities = [],
    className = '',
    filtersState = [],
    isLoading = false,
    hasNextPage = false,
    isMultiTenant = false,
    onCompleteItem,
    onLoadMore,
    onToggleFlagItem,
    schemas = []
}) => {
    const [filters, setFilterValue] = filtersState;

    return (
        <ContentPanel className={`${className} ${styles.addActivitiesPanel}`}>
            <ActivitiesPanelHeader
                endDate={filters.activityDueDateTo}
                isAssignedToMe={filters.isAssignedToMe}
                isFlagged={filters.isFlagged}
                onChangeActivityType={setFilterValue('activityTypes')}
                onChangeAssignees={setFilterValue('activityAssignees')}
                onChangeEndDate={setFilterValue('activityDueDateTo')}
                onChangeEntities={setFilterValue('entityName')}
                onChangeRecurrence={setFilterValue('activityRecurrence')}
                onChangeReviewers={setFilterValue('activityReviewers')}
                onChangeSearch={setFilterValue('activityName')}
                onChangeStartDate={setFilterValue('activityDueDateFrom')}
                onChangeStatus={setFilterValue('activityStatus')}
                onToggleIsFlagged={() =>
                    setFilterValue('isFlagged')(!filters.isFlagged)
                }
                onToggleIsMe={() =>
                    setFilterValue('isAssignedToMe')(!filters.isAssignedToMe)
                }
                schemas={schemas}
                startDate={filters.activityDueDateFrom}
            />

            <ActivitiesTable
                activities={activities}
                hasNextPage={hasNextPage}
                isLoading={isLoading}
                isMultiTenant={isMultiTenant}
                onCompleteItem={onCompleteItem}
                onLoadMore={onLoadMore}
                onToggleFlagItem={onToggleFlagItem}
            />
        </ContentPanel>
    );
};
