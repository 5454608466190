import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('formTable');

    return {
        ACTIVITIES_APPLIED_TO: t('activitiesAppliedTo'),
        ANSWERS_RECEIVED: t('answersReceived'),
        COMPANY: t('company'),
        FORM_NAME: t('formName'),
        IS_ACTIVE: t('isActive'),
        QUESTIONS_IN_FORM: t('questionsInForm')
    };
};

export default useTranslation;
