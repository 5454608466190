import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('doneCountPanel');

    return {
        DONE: t('done')
    };
};

export default useTranslation;
