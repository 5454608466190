import { useCallback, useEffect, useState } from 'react';

import ContentPanel from 'components/ContentPanel';
import Dropdown from 'components/Dropdown';
import PanelBody from 'components/PanelBody';
import PeopleColumnsMenu from 'components/PeopleColumnsMenu';
import PeopleTable from 'components/PeopleTable';
import SearchInput from 'components/SearchInput';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useConstants from './hooks/useConstants';
import useTranslation from './hooks/useTranslation';
import usePeople from './hooks/usePeople';
import useData from './hooks/useData';

import normalizeData from './data';

import styles from './styles.module.scss';

const EmployeesPanel = ({ className = '' }) => {
    const { GROUP, ROLE, SEARCH } = useTranslation();

    const [columns, setColumns] = useState();

    const { useQuerySchemas, useSchema, useTenants } = useGlobalStateHooks();

    const [querySchemas] = useQuerySchemas()
    const [schema] = useSchema();
    const [tenants] = useTenants();

    const [people, setPeople] = usePeople();
    const { loading, error, data } = useData();

    const { initialColumns } = useConstants();

    useEffect(() => {
        setColumns(initialColumns);
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema,
                tenants
            });

            setPeople(normalizedData?.people);
        }
    }, [initialColumns, setColumns, data, querySchemas, schema, setPeople, tenants]);

    const [selectedRows, setSelectedRows] = useState([]);

    const toggleColumn = useCallback(
        toggleIndex => {
            const modifiedColumns = [...columns];

            modifiedColumns[toggleIndex].isSelected =
                !columns[toggleIndex].isSelected;

            setColumns(modifiedColumns);
        },
        [columns]
    );

    const toggleRow = useCallback(id => {
        setSelectedRows(prevSelectedRows => {
            if (prevSelectedRows.includes(id)) {
                return prevSelectedRows.filter(selectedId => selectedId !== id);
            }

            return [...prevSelectedRows, id];
        });
    }, []);

    const toggleAllRows = useCallback(() => {
        setSelectedRows(prevSelectedRows => {
            if (prevSelectedRows.length === people.length) {
                return [];
            }

            return people.map(people => people.id);
        });
    }, [people]);

    if (error) {
        
    }

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody} isLoading={loading}>
                <Dropdown label={ROLE} />

                <Dropdown label={GROUP} />

                <SearchInput placeholder={SEARCH} />

                <PeopleColumnsMenu
                    columns={columns}
                    onMenuClick={toggleColumn}
                />

                <PeopleTable
                    className={styles.table}
                    columns={columns}
                    isAllRowsSelected={selectedRows.length === people.length}
                    selectedRows={selectedRows}
                    toggleAllRows={toggleAllRows}
                    toggleRowSelection={toggleRow}
                    users={people}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default EmployeesPanel;
