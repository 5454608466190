export function safeGetLocalStorage(key, defaultValue = null) {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) {
            return defaultValue;
        }
        return JSON.parse(serializedValue);
    } catch (e) {
        console.error('Error reading from local storage:', e);
        return defaultValue;
    }
}

export function safeSetLocalStorage(key, value) {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (e) {
        console.error('Error saving to local storage:', e);
    }
}
