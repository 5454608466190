import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('humanResources');

    return {
        PEOPLE: t('people')
    };
};

export default useConstants;
