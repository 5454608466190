import BodyText from 'components/BodyText';
import MenuItem from 'components/MenuItem';
import WithCheckbox from 'components/WithCheckbox';

const CheckboxMenuItem = props => {
    const {
        className = '',
        isChecked = false,
        onChange,
        onClick,
        text = ''
    } = props;

    return (
        <MenuItem className={className} onClick={onClick}>
            <WithCheckbox isChecked={isChecked} onChange={onChange}>
                <BodyText text={text} />
            </WithCheckbox>
        </MenuItem>
    );
};

export default CheckboxMenuItem;
