import { Link as OriginalLink } from 'react-router-dom';
import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const Link = ({ className = '', children, onClick, target, to }) => {
    const linkTo = /^\//.test(to) ? to : { pathname: to };

    const handleClick = event => {
        if (isFunction(onClick)) {
            onClick(event.target.textContent);
        }
    };

    return (
        <OriginalLink
            className={`${className} ${styles.link}`}
            onClick={handleClick}
            target={target}
            to={linkTo}
        >
            {children}
        </OriginalLink>
    );
};

export default Link;
