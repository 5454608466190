import { atom } from 'recoil';

const casesAtom = atom({
    default: [],
    key: 'cases'
});

const caseFilterAtom = atom({
    default: [],
    key: 'caseFilter'
});

const riskLabelFilterAtom = atom({
    default: [],
    key: 'riskLabelFilter'
});

const state = {
    atoms: {
        caseFilterAtom,
        casesAtom,
        riskLabelFilterAtom
    }
};

export default state;
