import ActionMenu from 'components/ActionMenu2';
import CloseAndCompleteActivityAndAddLogEntryMenuListItem from 'components/CloseAndCompleteActivityAndAddLogEntryMenuListItem';
import CloseAndCompleteActivityAndCreateCaseMenuListItem from 'components/CloseAndCompleteActivityAndCreateCaseMenuListItem';
import CloseAndCompleteActivityAndCreateNewActivityMenuListItem from 'components/CloseAndCompleteActivityAndCreateNewActivityMenuListItem';
import CloseAndCompleteActivityMenuListItem from 'components/CloseAndCompleteActivityMenuListItem';

const CloseActivityMenu = ({
    activityId,
    activityRecord,
    activitySchema,
    children,
    groups,
    onClick,
    referencedUsers,
    riskLabel,
    userId
}) => (
    <ActionMenu
        renderReferenceElement={({ handleClick }) => children(handleClick)}
    >
        {onPopperClose => (
            <>
                <CloseAndCompleteActivityMenuListItem
                    activityId={activityId}
                    activitySchema={activitySchema}
                    groups={groups}
                    onClick={onPopperClose}
                    referencedUsers={referencedUsers}
                    userId={userId}
                />

                <CloseAndCompleteActivityAndCreateNewActivityMenuListItem
                    activityId={activityId}
                    activityName={activityRecord?.name}
                    activitySchema={activitySchema}
                    groups={groups}
                    onClick={onPopperClose}
                    referencedUsers={referencedUsers}
                    userId={userId}
                />

                <CloseAndCompleteActivityAndCreateCaseMenuListItem
                    activityId={activityId}
                    activityRecord={activityRecord}
                    activitySchema={activitySchema}
                    groups={groups}
                    onClick={onPopperClose}
                    onSave={onClick}
                    referencedUsers={referencedUsers}
                    riskLabel={riskLabel}
                    userId={userId}
                />

                <CloseAndCompleteActivityAndAddLogEntryMenuListItem
                    activityId={activityId}
                    activityRecord={activityRecord}
                    activitySchema={activitySchema}
                    groups={groups}
                    onClick={onPopperClose}
                    onSave={onClick}
                    referencedUsers={referencedUsers}
                    userId={userId}
                />
            </>
        )}
    </ActionMenu>
);

export default CloseActivityMenu;
