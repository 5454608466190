import { useMemo } from 'react';
import { formatDistanceStrict } from 'date-fns';

import HistoryEntries from 'components/HistoryEntries';
import WithTitle from 'components/WithTitle';

import useTranslation from './hooks/useTranslation';

const VersionHistory = ({ items = [], selectedVersion }) => {
    const { VERSION_HISTORY } = useTranslation();

    const activeVersion = useMemo(
        () => items.find(({ label }) => label === selectedVersion),
        [items, selectedVersion]
    );

    const versionHistory = useMemo(
        () =>
            activeVersion?.history.map(history => ({
                ...history,

                date: formatDistanceStrict(new Date(history.date), new Date(), {
                    addSuffix: true
                })
            })),
        [activeVersion]
    );

    return (
        <WithTitle text={VERSION_HISTORY}>
            <HistoryEntries items={versionHistory} />
        </WithTitle>
    );
};

export default VersionHistory;
