import { useCallback, useState } from 'react';

import Buttonize from 'components/Buttonize';
import CheckIcon from 'components/icons/CheckIcon';
import CloseIcon from 'components/icons/CloseIcon';
import ContentBox from 'components/ContentBox';
import PenIcon from 'components/icons/PenIcon';
import FormField from 'components/FormField';
import Editor from 'components/Editor';

import useForm from 'hooks/useForm';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const EditableEditor = ({ className = '', hasEdit = true, inTableRow=false, onSave, text, title }) => {
    const [isEditing, setIsEditing] = useState(false);

    const form = useForm({
        text: ''
    });

    const { handleSubmit, handleValid } = form;

    const handleCancel = useCallback(() => {
        setIsEditing(false);
    }, []);

    const handleEdit = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleSave = useCallback(() => {
        if (isFunction(onSave)) {
            onSave({ form });
        }

        setIsEditing(false);
    }, [form, onSave, setIsEditing]);

    const contentBoxStyle = inTableRow ? styles.inTableRow : '';

    return (
        <div className={`${className} ${styles.editableContentBox}`}>
            {!isEditing && (
                <>
                    <ContentBox
                        className={`${styles.contentBox} ${contentBoxStyle}`}
                        html={text ? text.replaceAll('<p></p>', '<br />') : ''}
                        title={title}
                    />

                    {hasEdit && (
                        <Buttonize
                            className={styles.buttonize}
                            onClick={handleEdit}
                        >
                            <PenIcon />
                        </Buttonize>
                    )}
                </>
            )}

            {isEditing && (
                <>
                    <FormField className={styles.textBox} label={title}>
                        <Editor
                            html={text}
                            onSubmit={handleSubmit('text')}
                            onValid={handleValid('text')}
                            placeholder={text}
                        />
                    </FormField>

                    <Buttonize
                        className={styles.closeIcon}
                        onClick={handleCancel}
                    >
                        <CloseIcon />
                    </Buttonize>

                    <Buttonize
                        className={styles.checkIcon}
                        onClick={handleSave}
                    >
                        <CheckIcon />
                    </Buttonize>
                </>
            )}
        </div>
    );
};

export default EditableEditor;
