import { useCallback } from 'react';

import InfoButton from 'components/InfoButton';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const InviteButton = ({ className = '', isDisabled = false, onClick }) => {
    const { INVITE } = useTranslation();

    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <InfoButton
            className={`${className} ${styles.inviteButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
            text={INVITE}
        />
    );
};

export default InviteButton;
