// import { useEffect } from 'react';

import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useMutation, gql } from '@apollo/client';

import state from 'app/state';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { complianceEntryDetailsPanel } = state;

const { complianceEntryDetailsPanelDropdownsAtom } =
    complianceEntryDetailsPanel.atoms;
const { complianceEntryDetailsPanelDropdownsAtomFamily } =
    complianceEntryDetailsPanel.atomFamilies;

const MUTATE_DROPDOWN_DELETE = tenantSchema => gql`
    mutation updateDropdownSelectionDel($logId:Int!, $dropdownIndex:String!) {
        update_${tenantSchema}_log(
            where: {
                id: {_eq: $logId}
            },
            _delete_key:{ dropdown_selections: $dropdownIndex }
        ) {
            returning {
                dropdown_selections
            }
        }
    }
`;

const MUTATE_DROPDOWN_INSERT = tenantSchema => gql`
    mutation updateDropdownSelectionIns($logId:Int!, $dropdownObj:jsonb!) {
        update_${tenantSchema}_log(
            where: {
                id: {_eq: $logId}
            },
            _append:{ dropdown_selections: $dropdownObj}
        ) {
            returning {
                dropdown_selections
            }
        }
    }
`;

// const MUTATE_LOG_HISTORY = tenantSchema => gql`
//     mutation insert_log_history_dropdown($logId: Int!, $color: String = "yellow", $message: String = "changed a dropdown", $userId: Int!) {
//         insert_${tenantSchema}_log_history(
//             objects: {
//                 log_id: $logId,
//                 color: $color,
//                 message: $message,
//                 user_id: $userId
//             }
//         ) {
//             returning {
//                 id
//             }
//         }
//     }
// `;

const useDropdowns = (dropdowns = []) => {
    const dropdownIds = useRecoilValue(
        complianceEntryDetailsPanelDropdownsAtom
    );

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const [removeDropdown] = useMutation(MUTATE_DROPDOWN_DELETE(schema));
    const [createDropdown] = useMutation(MUTATE_DROPDOWN_INSERT(schema));
    // const [createHistory] = useMutation(MUTATE_LOG_HISTORY(schema));


    const insertDropdown = useRecoilCallback(
        ({ reset, set }) =>
            ({
                logId,
                changedDropdownIndex,
                id,
                options,
                selectedIndex,
                title
            }) => {

                const deleteDropdown = async () => {
                    const indexOfId = dropdownIds.indexOf(id);
                    const mutatedDropdownIds = [...dropdownIds];

                    mutatedDropdownIds.splice(indexOfId, 1);

                    set(
                        complianceEntryDetailsPanelDropdownsAtom,
                        mutatedDropdownIds
                    );

                    reset(complianceEntryDetailsPanelDropdownsAtomFamily(id));

                    const asyncDeleteDropdown = async () => {
                        await removeDropdown({
                            variables: {
                                dropdownIndex: id,
                                logId
                            }
                        });
                    };

                    await asyncDeleteDropdown();
                }

                const setDropdown = async () => {
                    set(complianceEntryDetailsPanelDropdownsAtomFamily(id), {
                        changedDropdownIndex,
                        id,
                        options,
                        selectedIndex,
                        title
                    });

                    const dropdownObj = {
                        [id]: selectedIndex
                    };

                    const asyncUpdateDropdown = async () => {
                        await createDropdown({
                            variables: {
                                dropdownObj,
                                logId
                            }
                        });
                    };

                    await asyncUpdateDropdown();
                }

                const upsertDropdown = async () => {
                    await deleteDropdown();
                    await setDropdown();
                }

                upsertDropdown()
            }
    );

    // const insertHistory = useRecoilCallback(() => ({ logId }) => {
    //     const asyncUpdateLogHistory = async () => {
    //         await createHistory({
    //             variables: {
    //                 logId,
    //                 userId: userId
    //             }
    //         });
    //     };

    //     asyncUpdateLogHistory();
    // });

    return [
        dropdownIds,

        ({
            logId,
            _changedDropdownId,
            changedDropdownIndex,
            _dropdownSelection,
            _formData,
            selectedIndex
        }) => {
            const { id, options, title } = dropdowns[changedDropdownIndex];

            insertDropdown({
                changedDropdownIndex,
                id,
                logId,
                options,
                selectedIndex,
                title
            });

            // insertHistory({ logId });
        }
    ];
};

export default useDropdowns;
