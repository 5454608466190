import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) =>
    data[`${schema}_form`].reduce(
        (accumulator, form) => {
            accumulator.push({
                label: form.name,
                value: form.id
            });

            return accumulator;
        }, []);

const normalizeFormData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeFormData;