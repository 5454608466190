import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { activityIdAtom } = state.activityId.atoms;

const useActivityId = () => [
    useRecoilValue(activityIdAtom),
    useSetRecoilState(activityIdAtom)
];

export default useActivityId;

