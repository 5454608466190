import { atom } from 'recoil';

const requestsAtom = atom({
    default: [],
    key: 'requests'
});

const state = {
    atoms: {
        requestsAtom
    }
};

export default state;
