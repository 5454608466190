import { useMutation, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const useGraphQL = (gqlString, providedSchema) => {
    const GQL = tenantSchema => gql`
        ${gqlString(tenantSchema)}
    `;

    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    
    const querySchema = providedSchema ? providedSchema : userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;

    const gqlQlString = GQL(querySchema);

    const [mutation, { error }] = useMutation(gqlQlString);

    if (error) {
        console.error('[useGraphQL] error: ', error);
    }

    return async variables =>
        await mutation({
            variables
        });
};

export default useGraphQL;
