const translations = {
    areYouSure: 'Are you sure?',
    cancel: 'No, cancel',
    deactivate: 'Yes, deactivate',
    youAreAboutToDeactivate: `
        You are about to deactivate the "POLICY_NAME". 
        Do you still want to proceed?
    `
};

export default translations;
