import RequestTableRow from 'components/RequestTableRow';
import Table from 'components/Table';
import TableFooter from 'components/TableFooter';
import TextLabel from 'components/TextLabel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { DetailedListItems2 } from 'components/DetailedListItems2';
import Title from 'components/Title';

const RequestsTable = ({ className = '', items = [] }) => {
    const {
        APPROVED_VERSION,
        ASSIGNED_TO,
        COMPANY,
        DATE_APPROVED,
        DATE_RQUESTED,
        DUE_DATE,
        EXPECTED_USE_DATE,
        REQUESTED_BY,
        // STATUS
    } = useTranslation();

    const { useTenants } = useGlobalStateHooks();
    const [tenants] = useTenants();

    const requestsByState = (items ?? []).reduce((acc, item) => {
        if (!acc[item.status]) {
            acc[item.status] = [];
        }
        acc[item.status].push(item);
        return acc;
    }, []);

    if (requestsByState.approved) {
        requestsByState.approved = requestsByState.approved.sort((a, b) => {
            const dateA = new Date(a.dateApproved);
            const dateB = new Date(b.dateApproved);

            if (!a.dateApproved || isNaN(dateA)) return 1;
            if (!b.dateApproved || isNaN(dateB)) return -1;

            return dateB - dateA;
        });
    }

    const isMultiTenant = tenants && tenants.length > 1;
    const rowStyle = isMultiTenant
        ? styles.titleListItemMultiTenant
        : styles.titleListItem;

    return (
        <Table className={`${className} ${styles.requestsTable}`}>
            {Object.entries(requestsByState).map(([state, items]) => {
                return (
                    <DetailedListItems2
                        header={[
                            <Title
                                className={`${styles.titleCell} ${styles[state]}`}
                                text={state}
                            />,
                            ...(isMultiTenant
                                ? [<TextLabel text={COMPANY} />]
                                : []),
                            <TextLabel text={DUE_DATE} />,
                            <TextLabel text={EXPECTED_USE_DATE} />,
                            <TextLabel text={DATE_APPROVED} />,
                            <TextLabel text={DATE_RQUESTED} />,
                            <TextLabel text={ASSIGNED_TO} />,
                            <TextLabel text={REQUESTED_BY} />,
                            // <TextLabel text={STATUS} />,
                            <TextLabel text={APPROVED_VERSION} />,
                        ]}
                        headerClassName={rowStyle}
                        onRenderItem={(item, _index) => item}
                    >
                        {items.map(
                            (
                                {
                                    approvedVersions,
                                    assignedTo,
                                    dateApproved,
                                    dateRequested,
                                    dueDate,
                                    expectedUseDate,
                                    requestName,
                                    requestSchema,
                                    requestedBy,
                                    id,
                                    status
                                },
                                index
                            ) => (
                                <RequestTableRow
                                    approvedVersions={approvedVersions}
                                    assignedTo={assignedTo}
                                    className={rowStyle}
                                    dateApproved={dateApproved}
                                    dateRequested={dateRequested}
                                    dueDate={dueDate}
                                    expectedUseDate={expectedUseDate}
                                    id={id}
                                    isMultiTenant={isMultiTenant}
                                    key={`${requestName} ${index}`}
                                    requestName={requestName}
                                    requestSchema={requestSchema}
                                    requestedBy={requestedBy}
                                    status={status}
                                />
                            )
                        )}
                    </DetailedListItems2>
                );
            })}

            <TableFooter className={styles.tableFooter}></TableFooter>
        </Table>
    );
};

export default RequestsTable;
