import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('policyActions');

    return {
        DEACTIVATE: t('deactivate'),
        SAVE: t('save')
    };
};

export default useTranslation;
