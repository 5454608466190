const httpPostStreaming = async (submissionObject) => {
    const {data, key=null, options = {}, url = ''} = submissionObject;
    const requestOptions = {
        body: data,
        // cache: 'no-cache',
        method: 'POST',
        // mode: 'no-cors',
        redirect: 'follow',
        // headers: {
        //     'Content-Type': 'application/json'
        // },
        ...options
    };
    
    if(key){
        if (
            'headers' in requestOptions &&
            typeof requestOptions.headers === 'object' &&
            !Array.isArray(requestOptions.headers) &&
            requestOptions.headers !== null
        ) {
            requestOptions.headers['api_auth'] = `Bearer ${key}`
        }else{
            requestOptions.headers = {'api_auth': `Bearer ${key}`}
        }
    }

    try {
        const response = await fetch(url, requestOptions);

        const blob = await response.blob();
        return blob;
    } catch (error) {
        return error;
    }
};

export default httpPostStreaming;
