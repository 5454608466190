import AsideLink from 'components/AsideLink';
import Buttonize from 'components/Buttonize';

import styles from './styles.module.scss';

const AddDropDownOption = ({ className = '', onClick = '' }) => (
    <Buttonize onClick={onClick}>
        <AsideLink
            className={`${className} ${styles.AddDropDownOption}`}
            text="+ Add Option"
        />
    </Buttonize>
);

export default AddDropDownOption;
