const normalizeDefaultCaseTypeData = ({ data, schema }) =>
    data[`${schema}_admin_settings`].reduce(
        (accumulator, caseType) => {
            accumulator.push({
                id: parseInt(caseType.setting.id),
                name: caseType.setting.name,
                settingId: caseType.id,
                settingName: caseType.setting_name,
            });

            return accumulator;
        }, []);

export default normalizeDefaultCaseTypeData;