import ContentPanel from 'components/ContentPanel';
import DateWithLabel from 'components/DateWithLabel';
import ContentBox from 'components/ContentBox';
import LinkInNewTabWithLabel from 'components/LinkInNewTabWithLabel';
import PanelBody from 'components/PanelBody';
import RiskPillWithLabel from 'components/RiskPillWithLabel';
import Title from 'components/Title';

import useConfig from 'hooks/useConfig';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const ComplianceEntryActivityPanel = props => {
    const { ACTIVITY_ROUTE } = useConfig();
    const {
        className = '',
        closedDate,
        dueDate,
        id,
        instructions,
        logSchema,
        name,
        riskLabel,
        startDate
    } = props;

    const {
        ACTIVITY_DETAILS,
        ACTIVITY_NAME,
        DATE_CLOSED,
        DUE_DATE,
        INSTRUCTIONS,
        RISK_LABEL,
        START_DATE
    } = useTranslation();

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <Title className={styles.title} text={ACTIVITY_DETAILS} />

                <LinkInNewTabWithLabel
                    label={ACTIVITY_NAME}
                    text={name}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', logSchema)}`}
                />

                <RiskPillWithLabel level={riskLabel} text={RISK_LABEL} />

                <DateWithLabel
                    className={styles.date}
                    date={closedDate}
                    text={DATE_CLOSED}
                />

                <DateWithLabel
                    className={styles.startDate}
                    date={startDate}
                    text={START_DATE}
                />

                <DateWithLabel
                    className={styles.dueDate}
                    date={dueDate}
                    text={DUE_DATE}
                />

                <ContentBox
                    className={styles.contentBox}
                    html={instructions}
                    title={INSTRUCTIONS}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default ComplianceEntryActivityPanel;
