import { accessTokenPermissionsKey } from 'constants';
import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import { useGetAccessToken } from './useGetAccessToken';
import { parseHasuraArrayString } from 'utilities/hasura';

export function usePermissions() {
    const accessToken = useGetAccessToken();
    const [permissions] = useState(getPermissions);

    function getPermissions() {
        const decodedToken = jwtDecode(accessToken);
        const permissionsValue = decodedToken[accessTokenPermissionsKey] ?? [];
				const permissions = parseHasuraArrayString(permissionsValue);
        return permissions;
    }

    function hasPermissions(requestedPermissions = []) {
        return requestedPermissions.some(requestedPermission =>
            permissions.includes(requestedPermission)
        );
    }

    return {
        hasPermissions,
        permissions
    };
}
