import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('administration');

    return {
        ACTIVITY_TYPES: t('activityTypes'),
        ADDITIONAL_SETTINGS: t('additionalSettings'),
        ADMINISTRATION: t('administration'),
        CASE_TYPES: t('caseTypes'),        
        EMAIL_MANAGEMENT: t('emailManagement'),
        GROUPS: t('groups'),
        LOG_TYPES: t('logTypes'),
        MARKETING: t('marketing'),
        ROLES: t('roles')
    };
};

export default useTranslation;
