import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addLogEntryModal');

    return {
        CREATE_LOG_ENTRY: t('createLogEntry')
    };
};

export default useTranslation;
