import CheckboxWithLabel from 'components/CheckboxWithLabel';
import Dropdown from 'components/Dropdown';
import DynamicRadioButtons from 'components/DynamicRadioButtons';
import Label from 'components/Label';
import TextInput from 'components/TextInput';
import TextareaInput from 'components/TextareaInput';

import styles from './styles.module.scss';

const ActivityFormComponent = props => {
    const {
        answer,
        choices,
        handleError,
        handleForm,
        handleSubmit,
        handleValid,
        id,
        inactive,
        name,
        questionId,
        questions,
        type
    } = props;

    if(type === 'title'){
        return(<h2 className={styles.formField} >{name}</h2>);
    }else if(type === 'text'){
        return(<p className={styles.formField} >{name}</p>);
    }else if(type === 'textInput'){
        return(
            <TextInput
                className={styles.formField}
                isDisabled={inactive}
                label={name}
                onChange={handleValid(`${id}`)}
                onError={handleError(`${id}`)}
                onSubmit={handleSubmit(`${id}`)}
                value={answer}
            />
        );
    }else if(type === 'textAreaInput'){
        return(
            <TextareaInput
                className={styles.formField}
                isDisabled={inactive}
                label={name}
                onChange={handleValid(`${id}`)}
                onError={handleError(`${id}`)}
                onSubmit={handleSubmit(`${id}`)}
                text={answer}
            />
        );
    }else if(type === 'radioInput'){
        const choicesWithAnswer = choices.reduce((accumulator, choice) => {
                const { label, name, value } = choice;
                const checked = value === answer ? true : false;
                const updatedChoice = {
                    isChecked: checked,
                    label,
                    name,
                    value
                }
                accumulator.push(updatedChoice);
                return accumulator
            }, []);
        return(
            <>
            <Label className={styles.formField} text={name} />

            <DynamicRadioButtons
                className={`${styles.radioButtons} ${styles.formField}`}
                globalIsDisabled={inactive}
                items={choicesWithAnswer}
                onChange={handleValid(`${id}`)}
                onError={handleError(`${id}`)}
                onSubmit={handleSubmit(`${id}`)}
                selected={answer}
            />
            </>
        )
    }else if(type === 'dropDownInput'){
        const selectedAnswer = choices ? choices.filter(function (choice) {return choice.value === answer })[0] : [];
        const selectedAnswerIndex = choices?.indexOf(selectedAnswer);
        return (
            <Dropdown
                className={styles.formField}
                isDisabled={inactive}
                items={choices}
                label={name}
                onChange={handleValid(`${id}`)}
                onError={handleError(`${id}`)}
                onSubmit={handleSubmit(`${id}`)}
                selected={selectedAnswerIndex}
            />
        );
    }else if(type === 'checkBoxInput'){
        return (
            <div className={styles?.checkbox}>
                <Label text={name} />

                {choices.map((option, index) => {
                    return(
                        <div>
                            <CheckboxWithLabel
                                isChecked={answer && Array.isArray(answer.split(",")) && answer.split(",").map(x => parseInt(x)).includes(index)}
                                isDisabled={inactive}
                                onChange={handleValid(`${id}_${index}`)}
                                onError={handleError(`${id}_${index}`)}
                                onSubmit={handleSubmit(`${id}_${index}`)}
                                text={option.label}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }else if(type === 'conditional'){
        // eslint-disable-next-line
        const subQuestions = questions.map((subQuestion) => {
            const formComponent = subQuestion?.formComponent.split("_");
            if(formComponent[1]===questionId){
                const output = handleForm();
        
                if (typeof output === 'string') {
                    // eslint-disable-next-line
                    return;
                }

                const selections = Array.from(output.entries()).reduce(
                    (accumulator, [key, value]) => {
                        const splitKey = key && key.includes('_') ? key.split('_') : key;
                        const keyForFilter = Array.isArray(splitKey) ? splitKey[0] : splitKey;
                        const filteredQuestions = questions.filter(e => e.id === parseInt(keyForFilter));
                        if(Array.isArray(filteredQuestions) && filteredQuestions.length > 0){
                            const filteredQuestion = filteredQuestions[0];
                            const filteredChoices = !Array.isArray(splitKey) ? filteredQuestion?.choices.filter(f => f?.value === value) : value === 'true' ? [filteredQuestion?.choices[splitKey[1]]] : [];
                            if(Array.isArray(filteredChoices) && filteredChoices.length > 0){
                                const filteredChoice = filteredChoices[0]
                                const newKey = Array.isArray(splitKey) ? `${filteredQuestion?.name}_${splitKey[1]}` : filteredQuestion?.name;
                                const newValue = Array.isArray(splitKey) ? `${filteredChoice?.label}_${value}` : filteredChoice?.label
                                accumulator[newKey] = newValue;
                            }
                        }
                        return accumulator;
                    },{}
                );
                const selectionKeys = Object.keys(selections).filter(x => x.includes(name))
                const selected = Object.values(Object.fromEntries(Object.entries(selections).filter(([key]) => selectionKeys.includes(key))));
                const dynamics = [];
                for(const selectedAnswer of selected){
                    console.log(selectedAnswer, formComponent)
                    const selectedAnswerArray = selectedAnswer && selectedAnswer.includes('_') ? selectedAnswer.split('_') : selectedAnswer;
                    const statementOfTruth = Array.isArray(selectedAnswerArray) ? selectedAnswerArray[1] === 'true' && formComponent[2] === selectedAnswerArray[0] : formComponent[2] === selectedAnswerArray;

                    if(statementOfTruth){
                        dynamics.push(<ActivityFormComponent
                                answer={subQuestion?.answer}
                                choices={subQuestion?.choices}
                                formComponent={subQuestion?.formComponent}
                                handleError={handleError}
                                handleForm={handleForm}
                                handleSubmit={handleSubmit}
                                handleValid={handleValid}
                                id={subQuestion?.id}
                                inactive={inactive}
                                name={subQuestion?.name}
                                questionId={subQuestion?.questionId}
                                questions={questions}
                                type={subQuestion?.type}
                            />
                        );
                    }
                }
                if(dynamics.length > 0){
                    return dynamics;
                }else{
                    return <></>;
                }
            }
        });
        return subQuestions;
    }else{
        return <></>
    }
};

export default ActivityFormComponent;
