import { useMemo } from 'react';

import AddVersionLink from 'components/AddVersionLink';
import Title from 'components/Title';
import VersionButtons from 'components/VersionButtons';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const Versions = props => {
    const { className = '', items = [], onChange, providedSchema, requestId, selectedVersion, type="marketing" } = props;
    
    const { VERSIONS } = useTranslation();

    const versionOptions = useMemo(() => {
        const sortedItems = items.sort((a, b) => {
            if (a.label > b.label) return -1;
            if (a.label < b.label) return 1;
            return 0;
        });

        return sortedItems.map(({ label, status }) => ({
            status,
            version: label
        }));
    }, [items]);

    const getMaxLabel = (items) => {
        if (items.length === 0) return 0;
        return items.reduce((max, item) => item.label > max ? item.label : max, items[0].label);
    };

    const maxValueVersion = getMaxLabel(items);

    return (
        <div className={`${className} ${styles.versions}`}>
            <Title text={VERSIONS} />

            <AddVersionLink className={styles.addVersionLink} maxValueVersion={maxValueVersion} providedSchema={providedSchema} requestId={requestId} type={type}/>

            <VersionButtons
                className={styles.versionButtons}
                items={versionOptions}
                onChange={onChange}
                selectedVersion={selectedVersion}
            />
        </div>
    );
};

export default Versions;
