import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecPolicy = (tenantSchema, policyId, userGQL) => gql`
query getPolicy {
    ${tenantSchema}_document(where: {id: {_eq:${policyId}}}) {
      id
      category
      description
      name
      is_active
      risk_label
      document_categories {
        category {
          id
          label
        }
      }
      applied_tos {
        group {
          id
          name
        }
        tenant_user {
          id
          ${userGQL}
        }
      }
      document_versions {
        deleted
        effective_date
        file
        file_name
        id
        version_number
        notes
        uploaded_timestamp
        tenant_user {
          id
          ${userGQL}
        }
        document_version_histories {
          color
          created_at
          message
          tenant_user{
            id
            ${userGQL}
          }
        }
        is_active_version
        is_draft
        is_published
      }
      policy_sections(where: {deleted: {_is_null: true}}) {
        section {
          id
          name
          description
          riskLevel
          file
          section_categories {
            category {
              id
              label
            }
          }
        }
      }
      tenant_user {
        id
        ${userGQL}
      }
    }
  	${tenantSchema}_group {
      id
      name
    }
  	tenant_user {
      id
      ${userGQL}
    }
    ${tenantSchema}_categories {
      id
      label
    }
  }
`;

const useData = (policyId, policySchema) => {
  const { useQuerySchemas, useSchema } = useGlobalStateHooks();

  const [querySchemas] = useQuerySchemas();
  const [schema] = useSchema();
  var userGQL = '';
  if(querySchemas.length > 0){
    for(const s of querySchemas){
        if(s.value){
            userGQL += `user_${s.value} {
                first_name 
                last_name 
            }`
        }
    }
  }else{
    if(schema){
        userGQL += `user_${schema} {
            first_name 
            last_name 
        }`
    }
  }

  return useQuery(querySpecPolicy(policySchema, policyId, userGQL), {
      fetchPolicy: 'no-cache'
  });
};

export default useData;
