import FormTableHeader from 'components/FormTableHeader';
import FormTableRow from 'components/FormTableRow';
import Table from 'components/Table';
import TableFooter from 'components/TableFooter';

import useTranslation from './hooks/useTranslation';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

const CaseTable = ({ className = '', items = [] }) => {
    const {
        ACTIVITIES_APPLIED_TO,
        ANSWERS_RECEIVED,
        COMPANY,
        FORM_NAME,
        IS_ACTIVE,
        QUESTIONS_IN_FORM
    } = useTranslation();

    const { useTenants } = useGlobalStateHooks();
    const [tenants] = useTenants();

    const isMultiTenant = tenants && tenants.length > 1;
    const rowStyle = isMultiTenant
        ? styles.titleListItemMultiTenant
        : styles.titleListItem;

    return (
        <Table className={`${className} ${styles.formTable}`}>
            <FormTableHeader
                className={styles.tableHeader}
                items={[
                    FORM_NAME,
                    ...(isMultiTenant
                        ? [COMPANY]
                        : []),
                    IS_ACTIVE,
                    QUESTIONS_IN_FORM,
                    ACTIVITIES_APPLIED_TO,
                    ANSWERS_RECEIVED,
                    ''
                ]}
                headerClassName={rowStyle}
            />

            {items.map(
                (
                    {
                        activities,
                        answers,
                        formId,
                        formSchema,
                        isActive,
                        name,
                        questions
                    }
                ) => (
                    <FormTableRow
                        activities={activities}
                        answers={answers}
                        formId={formId}
                        formSchema={formSchema}
                        isActive={isActive}
                        isMultiTenant={isMultiTenant}
                        name={name}
                        questions={questions}
                        rowStyle={rowStyle}
                    />
                )
            )}

            <TableFooter className={styles.tableFooter}></TableFooter>
        </Table>
    );
};

export default CaseTable;

