import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
const allRepositoriesDataAtom = atom({
    default: {data: []},
    key: 'allRepositoriesDataAtom'
});
export function useGetAllRepositoriesData() {
    return useRecoilValue(allRepositoriesDataAtom);
}
export function useSetAllRepositoriesData() {
    return useSetRecoilState(allRepositoriesDataAtom);
}