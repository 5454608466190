import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';

import CloseAndAddLogEntryMenuListItem from 'components/CloseAndAddLogEntryMenuListItem';

const CreateLogMenuListItemFromCase = ({
    caseId,
    caseRecord,
    onClick,
    onSave
}) => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const [addedLog, addLog, reset] = useData();

    const { COMPLIANCE_LOG_ENTRY_ROUTE, HOME } = useConfig();

    const navigate = useNavigate();

    const [_errorMessage, setErrorMessage] = useState();

    const handleAddLog = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId,
                    createdBy: Number(userId),
                    toClose: false,
                }
            );

            formObject.dateOfOccurrence = new Date(formObject.dateOfOccurrence);

            formObject['typeId'] = formObject['logCategory'];

            addLog(formObject);
        },
        [caseId, addLog, userId]
    );

    useEffect(() => {
        if (!addedLog) {
            return;
        }

        if (isFunction(onSave)) {
            onSave();
        }

        if (isFunction(onClick)) {
            onClick(addedLog);
        }

        navigate(COMPLIANCE_LOG_ENTRY_ROUTE.replace(':entryId', addedLog))

        reset();
    }, [COMPLIANCE_LOG_ENTRY_ROUTE, HOME, addedLog, navigate, onClick, onSave, reset]);

    return (
        <CloseAndAddLogEntryMenuListItem
            caseName={caseRecord?.caseName || '-'}
            caseType={caseRecord?.type?.name || '-'}
            onSave={handleAddLog}
            toClose={false}
        />
    );
};

export default CreateLogMenuListItemFromCase;
