import { Droppable, Draggable } from "react-beautiful-dnd";
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';

import PanelTitle from 'components/PanelTitle';
import MoveIcon from 'components/icons/MoveIcon';
import CloseIcon from 'components/icons/CloseSubtleIcon';
import Buttonize from 'components/Buttonize';

const BuildPolicyDetailsPanel = ({ className = '', formElements, setFormElements }) => {
    const handleRemoveElement = (_, index) => {
        const deletedElement = formElements[index];
        const formComponent = deletedElement?.formComponent;
        const formIndex = deletedElement?.index;
        const formElementsToModify = [...formElements];
        formElementsToModify.splice(index, 1);
        const changedElements = [...formElementsToModify];

        for (let i = 0; i < changedElements.length; i++) {
            if(changedElements[i]['formComponent'] === formComponent){
                if(changedElements[i]['index'] > formIndex){
                    changedElements[i]['index'] = changedElements[i]['index'] - 1;
                }
            }
        }
        setFormElements(changedElements);
    };

    return (
        <ContentPanel
            className={`${className} ${styles.addFormDetailsPanel}`}
        >
            <PanelBody className={styles.panelBody}>
                <PanelTitle text={'Build Your Policy Here'} />

                <Droppable className={styles.panelBody} droppableId={'newForm'} key={'newForm'}>
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  background: snapshot.isDraggingOver
                                    ? "#f9f9f9"
                                    : "inherit",
                                  minHeight: 800,
                                  padding: 4,
                                  width: '100%'
                                }}
                            >
                                {formElements && formElements.map((element, index) => {
                                    return(
                                    <Draggable
                                        draggableId={element.id}
                                        index={index}
                                        key={element.id}
                                    >
                                        {(provided, _snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        padding: 16,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>

                                                        <h2>{element.name}</h2>

                                                        <Buttonize
                                                            className={styles.icon}
                                                            onClick={res => handleRemoveElement(res, index)}
                                                        >
                                                            <CloseIcon className={styles.closeIconComponent}/>
                                                        </Buttonize>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>
                                )})}
                                
                            {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable>
            </PanelBody>
        </ContentPanel>
    );
};

export default BuildPolicyDetailsPanel;
