import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('caseCreationReasonModal');

    return {
        ACTIVITY_NAME: t('activityName'),
        ACTIVITY_TYPE: t('activityType'),
        ASSIGNED_TO: t('assignedTo'),
        CASE_CREATION_REASON: t('caseCreationReason'),
        CASE_NAME: t('caseName'),
        CASE_TYPE: t('caseType'),
        CLOSE_AND_CREATE_A_CASE: t('closeAndCreateCase'),
        CREATE_A_CASE: t('createCase'),
        DUE_DATE: t('dueDate'),
        ENTER_CASE_NAME: t('enterCaseName'),
        LINKED_CASE_NAME: t('linkedCaseName'),
        LINKED_CASE_TYPE: t('linkedCaseType'),
        POLICY_REFERENCE: t('policyReference'),
        REASON: t('reason'),
        RISK_LABEL_CASE: t('riskLabelCase')
    };
};

export default useTranslation;
