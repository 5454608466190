const translations = {
    activityName: 'Activity Name',
    activityType: 'Activity Type',
    addInstructionsHere: 'Add Instructions Here',
    dueDate: 'Due Date',
    editRecurringActivity: 'Edit Recurring Activity',
    enterActivityName: 'Enter Activity Name',
    firstAssignee: 'Assignee 1',
    formName: 'Form Name',
    instructions: 'Instructions',
    policyReference: 'Policy Reference',
    recipient: 'Recipient',
    reviewer: 'Reviewer',
    startDate: 'Start Date'
};

export default translations;