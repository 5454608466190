import BodyText from 'components/BodyText';
import Buttons from 'components/Buttons';
import DeleteButton from 'components/DeleteButton';
import ImportantText from 'components/ImportantText';
import PublishIcon from 'components/icons/PublishIcon';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import SubmitButton from 'components/SubmitButton';

import replaceTextWithJSX from 'utilities/replaceTextWithJSX';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const PublishPolicyVersionModal = ({
    currentVersion,
    newVersion,
    onClose,
    onSubmit
}) => {
    const { ARE_YOU_SURE, CANCEL, PUBLISH, YOU_ARE_ABOUT_TO_PUBLISH } =
        useTranslation();

    return (
        <Modal isOpen onClose={onClose}>
            <PanelBody className={styles.panelBody}>
                <PublishIcon />

                <PanelTitle text={ARE_YOU_SURE} />

                <BodyText
                    className={styles.bodyText}
                    text={replaceTextWithJSX(YOU_ARE_ABOUT_TO_PUBLISH, {
                        CURRENT_VERSION: (
                            <ImportantText
                                className={styles.version}
                                text={currentVersion}
                            />
                        ),
                        NEW_VERSION: (
                            <ImportantText
                                className={styles.version}
                                text={newVersion}
                            />
                        )
                    })}
                />

                <Buttons>
                    <DeleteButton onClick={onClose} text={CANCEL} />

                    <SubmitButton onClick={onSubmit} text={PUBLISH} />
                </Buttons>
            </PanelBody>
        </Modal>
    );
};

export default PublishPolicyVersionModal;
