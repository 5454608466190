import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateInstructions } = state;

const { updateInstructionsAtom } = updateInstructions.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateCaseCreationReason($text: String!, $caseId: Int!) {
    update_${tenantSchema}_case(where: {id: {_eq: $caseId}}, _set: {creation_reason: $text}) {
      returning {
        creation_reason
      }
    }
  }
`;

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insertCaseHistoryDropdown($caseId: Int!, $color: String = "yellow", $message: String = "changed the case creation reason", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);
    const mutationCaseHistory = useGraphQL(MUTATE_CASE_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveCreationReason = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
          if(variables?.text !== ''){
            const res = await mutation(variables);

            const newReason = res?.data[`update_${usedSchema}_case`].returning[0]?.creation_reason;

            await mutationCaseHistory(variables);

            set(updateInstructionsAtom, newReason);
          }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateInstructionsAtom, '');
    });

    return [useRecoilValue(updateInstructionsAtom), saveCreationReason, reset];
};

export default useData;
