import RiskPill from 'components/RiskPill';
import WithLabel from 'components/WithLabel';

import styles from './styles.module.scss';

const RiskPillWithLabel = props => {
    const { className = '', level, text } = props;

    return (
        <WithLabel className={`${className} ${styles.withLabel}`} text={text}>
            <RiskPill className={styles.riskPill} riskLevel={level} />
        </WithLabel>
    );
};

export default RiskPillWithLabel;
