import { atom } from 'recoil';

const policiesListAtom = atom({
    default: [],
    key: 'policiesList'
});

const state = {
    atoms: {
        policiesListAtom
    }
};

export default state;
