import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('caseDetailsPanel');

    return {
        ACTION_PLAN: t('actionPlan'),        
        ACTIVITY_REFERENCE: t('activityReference'),
        ADD_ACTION_PLAN: t('addActionPlan'),
        ADD_PROPOSED_SOLUTION: t('addProposedSolution'),
        ADD_RESOLUTION: t('addResolution'),
        CASE_CREATION_REASON: t('caseCreationReason'),
        CASE_NAME: t('caseName'),
        // CASE_TYPE: t('caseType'),
        POLICY_REFERENCE: t('policyReference'),
        PROPOSED_SOLUTION: t('proposedSolution'),
        RESOLUTION: t('resolution'),
        RISK_LEVEL_CASE: t('riskLevelCase')
    };
};

export default useTranslation;
