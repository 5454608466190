import { useQuery } from '@apollo/client';

import generateQueryFromDict from 'utilities/generateQueryFromDict';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = (tenantSchema, userGQL) => `
      consultancy(where: {schema: {_eq: "${tenantSchema}"}}) {
        description
        id
      }
      consultancy_users{
        user_id
        consultancy_id
        tenant_id
        consultancy_users_consultancy {
          name
        }
        consultancy_users_tenant {
          name
        }
      }
      tenant{
        consultancy_id
        tenant_id
      }
      tenant_user{
        id
        consultancy_id
      }
      internal_permission {
        permission
      }
      ${userGQL}
      ${tenantSchema}_group {
        name
        id
      }
      ${tenantSchema}_group_user {
        tenant_user_id
        group_id
      }
      ${tenantSchema}_admin_settings(where: {setting_name: {_eq: "site_permissions"}}) {
        setting
      }
`;

const useData = () => {
    const { useSchema, useTenants, useQuerySchemas, useUserSetSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [querySchemas] = useQuerySchemas();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
    var userGQL = '';
    if(querySchemas.length > 0){
        for(const s of querySchemas){
            userGQL += `${s['value']}_user(where: {_or: {registration_status: {_neq: "disabled"}, employee_status: {_neq: "disabled"}}}) {
              first_name
              last_name
              new_tab
              tenant_user_id
              tenant_user {
                email
                user_roles {
                  role {
                    role_permissions {
                      internal_permission {
                        permission
                      }
                    }
                  }
                }
              }
            }`
        }
    }else if(usedSchema){
        userGQL += `${usedSchema}_user(where: {_or: {registration_status: {_neq: "disabled"}, employee_status: {_neq: "disabled"}}}) {
          first_name
          last_name
          tenant_user_id
          tenant_user {
            email
            user_roles {
              role {
                role_permissions {
                  internal_permission {
                    permission
                  }
                }
              }
            }
          }
        }`
    }
    const gqlQuery = generateQueryFromDict({query, schema:usedSchema, userGQL})
    return useQuery(gqlQuery, { fetchPolicy: 'no-cache' });
};

export default useData;
