import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('editSectionModal');

    return {
        DESCRIPTION: t('description'),
        DOCUMENTS: t('documents'),
        EDIT_SECTION: t('editSection'),
        EFFECTIVE_DATE: t('effectiveDate'),
        PUBLISHED: t('published'),
        RISK_LABEL: t('riskLabel'),
        SECTION_NAME: t('sectionName'),
        SELECTED_CATEGORIES: t('selectedCategories'),
        STATUS: t('status'),
        VERSION: t('version')
    };
};

export default useTranslation;
