const translations = {
    approvedVersion: 'Approved Version',
    assignedTo: 'Assigned To',
    company: 'Company',
    dateApproved: 'Date Approved',
    dateRequested: 'Date Requested',
    dueDate: 'Due Date',
    expectedUseDate: 'Expected Use Date',
    requestName: 'Request Name',
    requestedBy: 'Requested By',
    status: 'Status'
};

export default translations;
