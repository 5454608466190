import { gql, useQuery } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const queryMarketingSettings = tenantSchema => gql`
    query getMarketingInstructions {
        ${tenantSchema}_admin_settings(where: {setting_name: {_like: "marketing%"}}) {
            setting_name
            setting
        }
    }
`;

const normalizeMarketingSettings = ({ data, schema }) => {
    return (data[`${schema}_admin_settings`] ?? []).reduce(
        (settings, setting) => {
            settings[setting['setting_name']] = setting['setting'];
            return settings;
        },
        {}
    );
};

export const useGetAppMarketingSettings = () => {
    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const { data={}, error, loading } = useQuery(queryMarketingSettings(schema), {
        fetchPolicy: 'no-cache'
    });

    const normalizedData = normalizeMarketingSettings({
        data,
        schema
    });
    return { data: normalizedData, error, loading };
};