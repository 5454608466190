const lexigraphicalSort = (arr, props) => {
    return [...arr].sort((a, b) => {
        for (const prop of props) {
            let aValue = a[prop];
            let bValue = b[prop];

            if (typeof aValue === 'string') aValue = aValue.toLowerCase();
            if (typeof bValue === 'string') bValue = bValue.toLowerCase();

            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
        }
        return 0;
    });
};

export default lexigraphicalSort;
