import ListItems from 'components/ListItems';

import styles from './styles.module.scss';

const BarListItems = ({ children, className = '' }) => (
    <ListItems className={`${className} ${styles.barListItems}`}>
        {children}
    </ListItems>
);

export default BarListItems;
