import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import DropdownItem from 'components/DropdownItem';
import Input from 'components/Input';
import InputWithLabel from 'components/InputWithLabel';
import DropdownMenu from 'components/DropdownMenu';
import Select from 'components/Select';

import useConstants from './hooks/useTranslation';
import usePopperState from 'hooks/usePopperState';

import styles from './styles.module.scss';

import arrayRemove from 'utilities/arrayRemove';
import isFunction from 'utilities/isFunction';

const DropdownMultiSelect = forwardRef((props, ref) => {
    const { SELECT } = useConstants();

    const {
        className = '',
        isRequired = false,
        items = [],
        label = '',
        onChange,
        onSubmit,
        onValid,
        placeholder = SELECT,
        returnObject = false,
        selected = []
    } = props;

    const errorMessage = useRef('');

    const [selectedLabel, setSelectedLabel] = useState([]);
    const [selectedIndexList, setSelectedIndexList] = useState([]);
    useEffect(() => {
        if (placeholder !== SELECT) {
            setSelectedLabel([placeholder]);
        }
    },[setSelectedLabel, SELECT, placeholder]);

    // Note - this component does not have a UE that handles updates
    // Keys of components must handle updates unless dependencies are added to UE

    // useEffect(() => {
    //     if (selected.length !== selectedIndexList.length) {
    //         const newSelectedIndexArray = selected.reduce(
    //             (accumulator, selection) => {
    //                 const foundValue = items.findIndex(item => item.value === selection)
    //                 if (foundValue >= 0) {
    //                         accumulator.push(foundValue)
    //                 }
    //                 return accumulator;
    //             }, []
    //         );

    //         const newIndexArray = [...new Set([...newSelectedIndexArray, ...selectedIndexList])]; //[23, 0]

    //         const newLabelArray = [];
    //         for (const i of newIndexArray){
    //             newLabelArray.push(items[i]?.label);
    //         }

    //         setSelectedIndexList(newIndexArray);
    //         setSelectedLabel(newLabelArray);
    //     }
    // }, [items, selected, selectedIndexList])

    // initializes dropdown field
    useEffect(() => {
        if(Array.isArray(selected) && Array.isArray(selectedIndexList) && selectedIndexList.length === 0){
            const newSelectedArray = [];
            const itemsArray = [];
            for (const i of items){
                itemsArray.push(i?.value);
            }
            for (const i of selected){
                const selectedIndex = itemsArray.indexOf(i);
                if (selectedIndex >= 0){
                    newSelectedArray.push(selectedIndex);
                }
            }
            const newIndexArray = [...new Set([...newSelectedArray, ...selectedIndexList])];
            const newLabelArray = [];
            for (const i of newIndexArray){
                newLabelArray.push(items[i]?.label);
            }
            setSelectedIndexList(newIndexArray);
            setSelectedLabel(newLabelArray);
        }else if (Array.isArray(selected) && selectedIndexList.length === 0){
            setSelectedIndexList(selected);
        }else if (Array.isArray(selectedIndexList)){
            if(selected !== ''){
                const selectedIndex = items.indexOf(selected);
                if (selectedIndex){
                    // setSelectedIndexList(selectedIndex.push(selectedIndex));
                    setSelectedIndexList([...selectedIndexList, selectedIndex])
                }
            }
        }else{
            setSelectedIndexList([]);
        }
        // eslint-disable-next-line
    }, []);

    const { handleClick, isOpen, onClose, referenceElement } = usePopperState();

    const handleChange = useCallback(
        (index) => {
            const valuesArray = [];
            for (const i of index){
                valuesArray.push(items[i]?.value)
            }
            if(isFunction(onChange)){
                onChange(valuesArray);
            }
        },
        [onChange, items]
    );

    const handleBlur = useCallback(
        event => {
            if (
                !event?.relatedTarget?.classList.contains(styles.dropdownMenu)
            ) {
                onClose();
            }
        },
        [onClose]
    );

    const handleDropdownItemClick = useCallback(
        index => () => {
            if(selectedIndexList.includes(index)){
                const newSelectedIndex = arrayRemove(selectedIndexList, index);
                const newSelectedLabel = arrayRemove(selectedLabel, items[index]?.label);
                setSelectedIndexList(newSelectedIndex);
                setSelectedLabel(newSelectedLabel);

                const newValuesArray = [];
                for (const i of newSelectedIndex){
                    if (returnObject){
                        newValuesArray.push(items[i]);
                    }else{
                        newValuesArray.push(items[i]?.value);
                    }
                }
                onValid?.(newValuesArray);
                handleChange(newSelectedIndex)
            }else{
                const newSelectedIndex = [...selectedIndexList, index];
                const newSelectedLabel = [...selectedLabel, items[index]?.label]
                setSelectedIndexList(newSelectedIndex);
                setSelectedLabel(newSelectedLabel);

                const newValuesArray = [];
                for (const i of newSelectedIndex){
                    if (returnObject){
                        newValuesArray.push(items[i]);
                    }else{
                        newValuesArray.push(items[i]?.value);
                    }
                }
                onValid?.(newValuesArray);
                handleChange(newSelectedIndex)
            }
        },

        [handleChange, items, onValid, returnObject, selectedIndexList, selectedLabel]
    );

    useEffect(() => {
        const currentReference = ref?.current;

        const handleSubmit = () => {
            const inputValue = currentReference?.value;

            handleChange({ target: { value: inputValue } });
            onSubmit?.(inputValue, errorMessage.current);
        };

        currentReference?.addEventListener('submit', handleSubmit);

        return () => {
            currentReference?.removeEventListener('submit', handleSubmit);
        };
    }, [errorMessage, handleChange, onSubmit, ref]);

    return (
        <InputWithLabel
            className={`${className} ${styles.dropdown}`}
            isRequired={isRequired}
            text={label}
        >
            <Select
                items={items}
                multiple={true}
                onBlur={handleBlur}
                onClick={handleClick}
                ref={ref}
                value={selectedLabel}
            />

            <Input
                className={`${className} ${styles.input}`}
                isReadOnly={true}
                onBlur={handleBlur}
                onClick={handleClick}
                placeholder={placeholder}
                value={selectedLabel}
            />

            <DropdownMenu
                className={styles.dropdownMenu}
                isOpen={isOpen}
                onClose={onClose}
                referenceElement={referenceElement}
            >
                {items.map(({ label, type, value }, index) => {
                    const isSelected = selectedIndexList.includes(index);
                    return (
                    <DropdownItem
                        isSelected={isSelected}
                        key={`${label}${index}`}
                        onClick={handleDropdownItemClick(index)}
                        text={label}
                        type={type}
                        value={value}
                    />
                )})}
            </DropdownMenu>
        </InputWithLabel>
    );
});

export default DropdownMultiSelect;
