import openInNewTab from 'utilities/openInNewTab';

import styles from './styles.module.scss';

const LinkInNewTab = ({
    className = '',
    text, 
    to 
}) => {
    const linkTo = /^\//.test(to) ? to : { pathname: to };

    const handleOpenInNewTab = () => {
        return openInNewTab(linkTo)
    }

    return (
        <div
            className={`${styles.importantLink} ${className}`}
            onClick={handleOpenInNewTab}
        >
            {text}
        </div>
    )
};

export default LinkInNewTab;