import { useCallback } from 'react';

import AsideLink from 'components/AsideLink';
import BodyText from 'components/BodyText';
import ImportantText from 'components/ImportantText';
import WithAvatar from 'components/WithAvatar';

import styles from './styles.module.scss';
import useTranslation from './hooks/useTranslation';

const SelectUserListItem = props => {
    const {
        assignmentType,
        className = '',
        // email,
        id,
        image,
        isSelected,
        name,
        onChange,
        previousRecord,
        type
    } = props;

    const { ADD, REMOVE } = useTranslation();

    const toggleSelection = useCallback(() => onChange(
        {assignmentType, isSelected, previousRecord, type, userId:id}
    ), [assignmentType, id, type, isSelected, onChange, previousRecord]);

    return (
        <div className={`${className} ${styles.selectUserListItem}`}>
            <WithAvatar
                className={styles.withAvatar}
                image={image}
                user={{id: id, name: name}}
            >
                <div>
                    <ImportantText text={name} />

                    {/* <BodyText className={styles.email} text={email} /> */}

                    <BodyText className={styles.email} text={`${name}@skematic.com`} />

                    <AsideLink
                        className={isSelected ? styles.selectedLink : ''}
                        onClick={toggleSelection}
                        text={isSelected ? REMOVE : ADD}
                    />
                </div>
            </WithAvatar>
        </div>
    );
};

export default SelectUserListItem;
