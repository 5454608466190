import { atom } from 'recoil';

const logDetailsAtom = atom({
    default: [],
    key: 'logDetails'
});

const state = {
    atoms: {
        logDetailsAtom,
    }
};

export default state;
