import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ContentPanel from 'components/ContentPanel';
import ErrorMessage from 'components/ErrorMessage';
import StandoutText from 'components/StandoutText';
import PanelBody from 'components/PanelBody';

import httpPost from 'utilities/httpPost';

import useConfig from 'hooks/useConfig';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AuthenticatingPanel = ({ className = '' }) => {
    const { AUTHENTICATING } = useTranslation();

    const { API_KEY, AUTH_URL, HOME } = useConfig();

    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');

    const [searchParams] = useSearchParams();

    const randomString = searchParams.get('str');

    const url = `${AUTH_URL}?rand_str=${randomString}`;

    useEffect(() => {
        const fetchToken = async () => {
            const {
                access_token: accessToken,
                refresh_token: refreshToken,
                detail
            } = await httpPost({key: API_KEY ? API_KEY : '', url});

            if (accessToken && refreshToken) {
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                

                navigate(HOME);
            } else {
                setErrorMessage(detail);
            }
        };
        fetchToken();
    }, [API_KEY, HOME, navigate, randomString, url]);

    return (
        <ContentPanel className={`${className} ${styles.passwordLoginPanel}`}>
            <PanelBody className={styles.panelBody}>
                <StandoutText text={AUTHENTICATING} />

                {errorMessage && <ErrorMessage text={errorMessage} />}
            </PanelBody>
        </ContentPanel>
    );
};

export default AuthenticatingPanel;
