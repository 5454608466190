import { Droppable, Draggable } from "react-beautiful-dnd";

import styles from './styles.module.scss';

import Dropdown from 'components/Dropdown';
import DynamicRadioButtons from 'components/DynamicRadioButtons';
import Label from 'components/Label';
import TextInput from 'components/TextInput';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import SectionWithBorder from 'components/SectionWithBorder';
import ImportantText from 'components/ImportantText';
import CloseIcon from 'components/icons/CloseSubtleIcon';
import Buttonize from 'components/Buttonize';

const AddFormDetailsConditionalElement = ({ element, embedded=false, formElements, index, setFormElements }) => {

    const handleRemoveElement = (_, index) => {
        const deletedElement = formElements[index];
        const formComponent = deletedElement?.formComponent;
        const formIndex = deletedElement?.index;
        const formElementsToModify = [...formElements];
        formElementsToModify.splice(index, 1);
        const changedElements = [...formElementsToModify];

        for (let i = 0; i < changedElements.length; i++) {
            if(changedElements[i]['formComponent'] === formComponent){
                if(changedElements[i]['index'] > formIndex){
                    changedElements[i]['index'] = changedElements[i]['index'] - 1;
                }
            }
        }
        setFormElements(changedElements);
    };

    const style = embedded ? styles.embededConditional : styles.conditionalsContainer

    return (
            <div className={style}>
                {element.options && element.options.map((option, _index) => {
                    const optionValue = option?.value ? option?.value : option?.name;
                    return(
                        <SectionWithBorder className={styles.sectionWithBorder}>
                            <ImportantText text={option?.label} />

                            <Droppable
                                droppableId={`conditionalQuestionsFor_${element?.id}_${optionValue}`}
                                key={`conditionalQuestionsFor_${element?.id}_${optionValue}`}
                            >
                                {(embeddedProvided, snapshot) => {
                                    return (
                                        <div
                                            {...embeddedProvided.droppableProps}
                                            ref={embeddedProvided.innerRef}
                                            style={{
                                                background: snapshot.isDraggingOver
                                                    ? "lightgrey"
                                                    : "inherit",
                                                left: 'auto !important',
                                                minHeight: 200,
                                                top: 'auto !important'
                                            }}
                                        >
                                            {formElements.map((subElement, subIndex) => {
                                                return(
                                                <Draggable
                                                    draggableId={element.id}
                                                    index={index}
                                                    key={element.id}
                                                >
                                                    {(embeddedProvided) => {
                                                        if(subElement?.formComponent === `conditionalQuestionsFor_${element?.id}_${optionValue}`){
                                                            // const embeddedProvided = {...provided}
                                                            // embeddedProvided.dragHandleProps["data-rbd-drag-handle-draggable-id"] = subElement?.id;
                                                            // embeddedProvided.draggableProps["data-rbd-draggable-id"] = subElement?.id;
                                                            return (
                                                                <div
                                                                    ref={embeddedProvided.innerRef}
                                                                    {...embeddedProvided.draggableProps}
                                                                    data-rbd-draggable-id={subElement.id}
                                                                    style={{
                                                                        padding: 16,
                                                                        userSelect: "none",
                                                                        ...embeddedProvided.draggableProps.style
                                                                    }}
                                                                >
                                                                    {subElement.type === 'title' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>            
                                                                            <h2>{subElement.text}</h2>
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subIndex)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'text' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>            
                                                                            <p>{subElement.text}</p>
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subIndex)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'textInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>            
                                                                            <TextInput
                                                                                label={subElement.text}
                                                                            />
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subIndex)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'radioInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>            
                                                                            <div>
                                                                                <Label text={subElement.text} />
            
                                                                                <DynamicRadioButtons
                                                                                    className={styles.radioButtons} 
                                                                                    items={subElement.options}
                                                                                />
                                                                            </div>
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subIndex)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'dropDownInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>            
                                                                            <Dropdown items={subElement.options} label={subElement.text} />
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subIndex)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'checkBoxInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>            
                                                                            <>
                                                                                <Label text={subElement.text} />
            
                                                                                {subElement.options.map((option, _index) => {
                                                                                    return(
                                                                                        <CheckboxWithLabel text={option.label} />
                                                                                    );
                                                                                })}
                                                                            </>
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subIndex)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }

                                                                    {subElement.type === 'conditional' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <h4>Conditional on field '{subElement.text}'</h4>

                                                                            <div></div>

                                                                            <AddFormDetailsConditionalElement element={subElement} embedded={true} formElements={formElements} index={index} setFormElements={setFormElements} />

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, index)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }else{
                                                            return(
                                                                <div
                                                                    ref={embeddedProvided.innerRef}
                                                                >
                                                                </div>
                                                            )
                                                        }
                                                    }}
                                                </Draggable>
                    )})}

                                            {embeddedProvided.placeholder}
                                        </div>
                                    )
                                }}   
                            </Droppable>
                        </SectionWithBorder>
                    );
                })}
            </div>
    );
};

export default AddFormDetailsConditionalElement;
