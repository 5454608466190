import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CancelDeleteSaveButtons from 'components/CancelDeleteSaveButtons';
import Dropdown from 'components/Dropdown';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
import Editor from 'components/Editor';
import FormField from 'components/FormField';
import HeaderText from 'components/HeaderText';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import Table from 'components/Table';
import TableRow from 'components/TableRow';
import TextInput from 'components/TextInput';

import useConfig from 'hooks/useConfig';
import useVariableForm from 'hooks/useVariableForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';
import checkArrayOfObjectEquality from 'utilities/checkArrayOfObjectEquality';

import useData from './hooks/useData';
import useDataQuery from './hooks/useDataQuery';
import useEntities from './hooks/useEntities';
import useForms from './hooks/useForms';
import useTranslation from './hooks/useTranslation';
import usePolicies from './hooks/usePolicies';
import useTypes from './hooks/useTypes';
import useUsers from './hooks/useUsers';

import styles from './styles.module.scss';

import normalizeEntityData from './dataEntities';
import normalizeFormData from './dataForms';
import normalizePolicyData from './dataPolicies';
import normalizeTypeData from './dataTypes';
// import normalizeUserData from './dataUsers';
import titleCase from 'utilities/titleCase';
import PositiveHollowButton from 'components/PositiveHollowButton';
import CloseButton from 'components/CloseButton';
import DeleteRecurrenceModal from 'components/DeleteRecurrenceModal';
import WithLabel from 'components/WithLabel';
import ToggleWithLabel from 'components/ToggleWithLabel';

const EditRecurrenceModal = ({
    activityEntities,
    activityForms,
    activityId,
    activityInstructions,
    activityName,
    activityPolicy,
    activityRecurrence,
    activitySchema,
    activityType,
    activityUsers,
    className,
    currentActivityEndDate,
    currentActivityStartDate,
    onClose,
    onDelete,
    recurrences
}) => {
    const { INTERNAL_SERVER_ERROR } = useConfig();
    const {
        ACTIVITY_NAME,
        ACTIVITY_TYPE,
        ADD_INSTRUCTIONS_HERE,
        DUE_DATE,
        EDIT_RECURRING_ACTIVITY,
        ENTER_ACTIVITY_NAME,
        FORM_NAME,
        INSTRUCTIONS,
        POLICY_REFERENCE,
        RECIPIENT,
        REVIEWER,
        START_DATE,
    } = useTranslation();

    const navigate = useNavigate();

    const { useConsultancyUsers, useTenants, useUserId } = useGlobalStateHooks();

    const [consultancyUsers] = useConsultancyUsers();
    const [tenants] = useTenants();
    const [userId] = useUserId();

    const [policies, setPolicies] = usePolicies();
    const [types, setTypes] = useTypes();
    const [users, setUsers] = useUsers();

    const [isOpen, setIsOpen] = useState(false);
    const [recurrencesForUpdateState, setRecurrencesForUpdateState] = useState();

    const [_errorMessage, setErrorMessage] = useState();
    const [expandForm, setExpandForm] = useState((activityRecurrence?.forms && activityRecurrence?.forms.length>0) || activityForms.length > 0 ? true : false);

    const [userDict, setUserDict] = useState({});

    const [addReviewer, setAddReviewer] = useState(false);
    const [assignees, setAssignees] = useState([]);

    const [updatedActivityRecurrence, updateActivityRecurrence, reset] = useData(activitySchema);
    const [entities, setEntities] = useEntities();
    const [databaseForms, setDatabaseForms] = useForms();
    const [presetActivityType, setPresetActivityType] = useState();
    const [presetEntities, setPresetEntities] = useState();
    const [presetPolicies, setPresetPolicies] = useState();
    const [presetHierarchy, setPresetHierarchy] = useState(activityRecurrence?.hierarchical);
    const [presetSendEmail, setPresetSendEmail] = useState(activityRecurrence?.sendEmail);

    const [forms, setForms] = useState(activityRecurrence?.forms ? activityRecurrence?.forms : activityForms.reduce(function(accumulator, value){
        accumulator.push({
            assignment: value?.assignment,
            assignmentType: value?.assignment_type,
            dueDate: Math.ceil(Math.abs(new Date(currentActivityEndDate) - new Date(currentActivityStartDate))/ (1000 * 60 * 60 * 24)),
            id: value?.form?.id,
            name: value?.form?.name
        })
        return accumulator;
    }, []));

    const handleExpandForm = () => {
        setExpandForm(!expandForm);
    }

    const handleSetFormName = (formId, index) => {
        const modifiedForm = [...forms];
        modifiedForm[index] = {...modifiedForm[index]};
        modifiedForm[index]['formId'] = formId;
        setForms(modifiedForm);
    }

    const handleSetFormDate = (formDate, index) => {
        const modifiedForm = [...forms];
        modifiedForm[index] = {...modifiedForm[index]};
        modifiedForm[index]['dueDate'] = formDate;
        setForms(modifiedForm);
    }

    const handleSetAssignee = (assignee, index) => {
        const modifiedForm = [...forms];
        modifiedForm[index] = {...modifiedForm[index]};
        modifiedForm[index]['assignee'] = assignee;
        setForms(modifiedForm);
    }

    const handleNewForm = () => {
        const modifiedForm = [...forms];
        modifiedForm.push({})
        setForms(modifiedForm);
    }

    const removeFormRow = (_click, index) => {
        const modifiedForm = [...forms];
        modifiedForm.splice(index, 1);
        setForms(modifiedForm);
    }

    const { loading, error, data } = useDataQuery(activitySchema);

    useEffect(() => {
        if (data) {
            const normalizedPolicyData = normalizePolicyData({ data, schema:activitySchema, tenants });
            const normalizedTypeData = normalizeTypeData({ data, schema:activitySchema, tenants });
            const normalizedFormData = normalizeFormData({ data, schema:activitySchema, tenants });
            const normalizedEntityData = normalizeEntityData({ data, schema:activitySchema, tenants });

            setDatabaseForms(normalizedFormData);
            setEntities(normalizedEntityData);
            setPolicies(normalizedPolicyData);
            setTypes(normalizedTypeData);
        }
    }, [data, activitySchema, setEntities, setDatabaseForms, setPolicies, setTypes, tenants]);

    useEffect(() => {
        const presetActivityTypesToLoad = activityRecurrence?.activityTypeId && types
            ? types.findIndex(x => activityRecurrence?.activityTypeId === x?.value)
            : types
                ? types.findIndex(x => activityType === x?.label)
                : '';
        setPresetActivityType(presetActivityTypesToLoad);
    }, [data, activityType, activityRecurrence, types, setPresetActivityType])

    useEffect(() => {
        const presetEntitiesToLoad = activityRecurrence?.entity
            ? entities.filter(x => activityRecurrence?.entity && activityRecurrence?.entity.includes(x?.value)).map(entity => entity?.value)
            : entities.filter(x => activityEntities.includes(x?.value)).map(entity => entity?.value);
        setPresetEntities(presetEntitiesToLoad);
    }, [data, activityRecurrence, activityEntities, entities, setPresetEntities])

    useEffect(() => {
        const presetPoliciesToLoad = Array.isArray(policies) && policies.length > 0
            ? activityRecurrence?.policies
                ? activityRecurrence?.policies
                : policies.reduce(function(accumulator, value){
                    if(activityPolicy.map(a => a.id).includes(value?.value)){
                        accumulator.push(value?.value);
                    }
                    return accumulator;
                }, [])
            : [];
        setPresetPolicies(presetPoliciesToLoad);
    }, [data, activityRecurrence, activityPolicy, policies, setPresetPolicies])

    const handleAssigneeRecurrence = useCallback(() => {
        const mutatedAssignees = [...assignees];
        const mutatedUserDict = {...userDict};

        const addedAssigneeKey = `assignee-${mutatedAssignees.length + 1}`;
        mutatedUserDict[addedAssigneeKey] = [];

        mutatedAssignees.push({label: titleCase(addedAssigneeKey), value: addedAssigneeKey});

        setAssignees(mutatedAssignees);
        setUserDict(mutatedUserDict);
    }, [assignees, setAssignees, setUserDict, userDict]);

    const handleClick = useCallback(() => {
        const mutatedAssignees = [...assignees];

        mutatedAssignees.push({label: 'Reviewer', value: 'reviewer'});

        setAssignees(mutatedAssignees);
        setAddReviewer(true);
    }, [assignees, setAddReviewer]);

    const [editorState, setEditorState] = useState(activityRecurrence?.instructions);

    const handleClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                return onClose(event);
            }
        },
        [onClose]
    );

    const form = useVariableForm();

    const { handleError, handleForm, handleSubmit, handleValid } = form;

    const handleSave = useCallback(() => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        const formObject = {};
        
        const modifiedGroups = [];
        const users = []

        output.forEach((value, key) => {
            formObject[key] = value;
            if(key.includes('assignee') || key.includes('reviewer')){
                modifiedGroups.push(key);
                const userArray = value.split(',');
                for(const u of userArray){
                    users.push({
                        assignmentType: key,
                        type: 'user',
                        value: Number(u)
                    })
                }
            }
        });

        formObject['activityRecurrenceId'] = activityRecurrence?.id;

        const comparableCurrentActivityDate = new Date(currentActivityStartDate);
        comparableCurrentActivityDate.setDate(comparableCurrentActivityDate.getDate() + 1)
        const recurrencesForUpdate = recurrences.filter(recurrence => new Date(recurrence.startDate) > comparableCurrentActivityDate).map(r => r.id);

        const filledInVariables = {
            activity: formObject?.activity ? formObject?.activity : activityRecurrence?.name ? activityRecurrence?.name : activityName,
            activity_id: activityId,
            activity_type: formObject?.activity_type ? formObject?.activity_type : activityRecurrence?.activityTypeId ? activityRecurrence?.activityTypeId : types[types.findIndex(x => activityType === x?.label)]?.value,
            activityRecurrenceId: formObject?.activityRecurrenceId,
            currentActivityEndDate,
            currentActivityStartDate,
            entity: formObject?.entity ? formObject?.entity : activityRecurrence?.entity ? entities.filter(x => activityRecurrence?.entity && activityRecurrence?.entity.includes(x?.value)).map(entity => entity?.value) : entities.filter(x => activityEntities.includes(x?.value)).map(entity => entity?.value),
            entityRun: formObject?.entity ? true : !formObject?.activityRecurrenceId && activityEntities.length > 0 ? true : false,
            forms: forms,
            formsRun: !formObject?.activityRecurrenceId ? true : (Array.isArray(forms) && Array.isArray(activityRecurrence?.forms) && !checkArrayOfObjectEquality(forms, activityRecurrence?.forms)),
            groupCompletionRate: formObject?.groupCompletionRate ? formObject?.groupCompletionRate : activityRecurrence?.groupCompletionRate,
            hierarchy: presetHierarchy ? presetHierarchy : false,
            instructions: formObject?.instructions ? formObject?.instructions : activityRecurrence?.instructions ? activityRecurrence?.instructions : activityInstructions,
            modifiedGroups,
            policies: formObject?.policy || formObject?.policy === "" ? formObject?.policy.split(',') : activityRecurrence?.policies ? activityRecurrence?.policies : policies.reduce(function(accumulator, value){if(activityPolicy.map(a => a.id).includes(value?.value)){accumulator.push(value?.value);}return accumulator;}, []),
            policiesRun: formObject?.policy || (Array.isArray(activityRecurrence?.policies) && formObject.policy === "") ? true : !formObject?.activityRecurrenceId && policies.reduce(function(accumulator, value){if(activityPolicy.map(a => a.id).includes(value?.value)){accumulator.push(value?.value);}return accumulator;}, []).length > 0 ? true : false,
            recurrence: formObject?.recurrence ? formObject?.recurrence : activityRecurrence?.recurrenceType,
            recurrenceRun: formObject?.recurrence ? true : false,
            recurrences,
            recurrencesForUpdate,
            sendEmail: presetSendEmail ? presetSendEmail : false,
            start_date: formObject?.start_date ? formObject?.start_date : activityRecurrence?.startDate ? activityRecurrence?.startDate : Math.ceil(Math.abs(new Date(currentActivityEndDate) - new Date(currentActivityStartDate))/ (1000 * 60 * 60 * 24)),
            startDateRun: formObject?.start_date ? true : false,
            userId,
            users: users.length > 0 ? users : activityRecurrence?.users ? activityRecurrence?.users : activityUsers,
            usersRun: users.length > 0 ? true : !formObject?.activityRecurrenceId && activityUsers ? true : false,
        }
        updateActivityRecurrence(filledInVariables);
    }, [activityEntities, activityId, activityInstructions, activityName, activityType, activityPolicy, activityRecurrence, activityUsers, currentActivityEndDate, currentActivityStartDate, entities, forms, policies, presetHierarchy, presetSendEmail, recurrences, types, updateActivityRecurrence, handleForm, setErrorMessage, userId]);


    const handleDelete = useCallback(
        event => {
            if (isFunction(onDelete)) {
                onDelete(event);
            }

            const comparableCurrentActivityDate = new Date(currentActivityStartDate);
            comparableCurrentActivityDate.setDate(comparableCurrentActivityDate.getDate() + 1)
            setRecurrencesForUpdateState(recurrences.filter(recurrence => new Date(recurrence.startDate) > comparableCurrentActivityDate).map(r => r.id));

            setIsOpen(!isOpen);
        },
        [currentActivityStartDate, isOpen, onDelete, recurrences, setRecurrencesForUpdateState]
    );

    useEffect(() => {
        if (data) {
            const normalizedTypeData = normalizeTypeData({ data, schema:activitySchema });
            // const normalizedUserData = normalizeUserData({ data, schema:activitySchema, tenants });
            const usersToReduce = activityRecurrence?.users ? activityRecurrence?.users : activityUsers;

            const presetUsers = usersToReduce.reduce((accumulator, user) => {
                if(Object.keys(accumulator).includes(user?.assignmentType)){
                    accumulator[user?.assignmentType].push(user);
                }else{
                    accumulator[user?.assignmentType] = [user];
                }
                return accumulator;
            }, {});
            const presetAssignees = Object.keys(presetUsers).reduce((accumulator, key) => {
                accumulator.push({label: titleCase(key), value:key})
                return accumulator;
            }, [])

            setTypes(normalizedTypeData);
            setUsers(consultancyUsers ? consultancyUsers.filter(item => item.tenants.includes(activitySchema)) : []);
            setUserDict(presetUsers);
            setAssignees(presetAssignees);
            if(Object.keys(presetUsers).includes('reviewer')){
                setAddReviewer(Object.keys(presetUsers).includes('reviewer'));
            }
        }
    }, [activityRecurrence, activityUsers, data, activitySchema, setPolicies, setTypes, setUsers, tenants]);

    useEffect(() => {
        if (!updatedActivityRecurrence) {
            return;
        }

        if (isFunction(onClose)) {
            onClose(updatedActivityRecurrence);
            reset();
            window.location.reload();
        }
    }, [onClose, reset, updatedActivityRecurrence]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    const headers = [
        FORM_NAME,
        `${DUE_DATE} (Days after activity start date)`,
        RECIPIENT
    ];

    const recurrenceItems = [
        {label: "None", value: "none"},
        {label: "Annually", value: "annually"},
        {label: "Semi-Annual", value: "semi_annual"},
        {label: "Quarterly", value: "quarterly"},
        {label: "Monthly", value: "monthly"},
        {label: "Bi-Weekly", value: "biweekly"},
        {label: "Weekly", value: "weekly"},
        {label: "Daily", value: "daily"},
    ];

    return (
        <Modal isOpen={true} onClose={onClose}>
            <PanelBody className={styles.wide} loading={loading}>
                <PanelTitle className={styles.panelTitle} text={EDIT_RECURRING_ACTIVITY} />

                <TextInput
                    className={styles.textInput}
                    label={ACTIVITY_NAME}
                    onChange={handleValid('activity')}
                    onError={handleError('activity')}
                    onSubmit={handleSubmit('activity')}
                    placeholder={ENTER_ACTIVITY_NAME}
                    value={activityRecurrence?.name ? activityRecurrence?.name : activityName}
                />

                <DropdownMultiSelect
                    items={policies}
                    label={POLICY_REFERENCE}
                    onError={handleError('policy')}
                    onSubmit={handleSubmit('policy')}
                    onValid={handleValid('policy')}
                    selected={presetPolicies}
                />

                <Dropdown
                    items={types ? types : []}
                    label={ACTIVITY_TYPE}
                    onError={handleError('activity_type')}
                    onSubmit={handleSubmit('activity_type')}
                    onValid={handleValid('activity_type')}
                    selected={presetActivityType}
                />

                <DropdownMultiSelect
                    className={styles.dropdown}
                    items={entities}
                    label="Entity"
                    onError={handleError('entity')}
                    onSubmit={handleSubmit('entity')}
                    onValid={handleValid('entity')}
                    selected={presetEntities}
                />

                <Dropdown
                    className={styles.dropdown}
                    items={recurrenceItems}
                    label="Recurrence"
                    onError={handleError('recurrence')}
                    onSubmit={handleSubmit('recurrence')}
                    onValid={handleValid('recurrence')}
                    selected={recurrenceItems.findIndex(x => activityRecurrence?.recurrenceType === x?.value)}
                />

                <TextInput
                    className={styles.textInput}
                    label={`${START_DATE} (days before due date)`}
                    onChange={handleValid('start_date')}
                    onError={handleError('start_date')}
                    onSubmit={handleSubmit('start_date')}
                    placeholder={`${START_DATE} (days before due date)`}
                    value={activityRecurrence?.startDate ? activityRecurrence?.startDate : Math.ceil(Math.abs(new Date(currentActivityEndDate) - new Date(currentActivityStartDate))/ (1000 * 60 * 60 * 24))}
                />

                <FormField className={styles.editor} label={INSTRUCTIONS}>
                    <Editor
                        html={editorState ? editorState : activityInstructions}
                        onChange={setEditorState}
                        onValid={handleValid('instructions')}
                        placeholder={ADD_INSTRUCTIONS_HERE}
                    />
                </FormField>

                <WithLabel text='Hierarchy' >
                    <ToggleWithLabel isActive={!presetHierarchy} onToggle={setPresetHierarchy} />
                </WithLabel>

                <WithLabel text='Send Email Updates' >
                    <ToggleWithLabel isActive={!presetSendEmail} onToggle={setPresetSendEmail} />
                </WithLabel>

                {Object.keys(userDict).map((assigneeKey, index) => {
                    if(assigneeKey.includes('assignee')){
                        return (
                            <DropdownMultiSelect
                                className={styles.dropdownWithTags}
                                items={users}
                                key={assigneeKey}
                                label={`Assignee ${index + 1}`}
                                onError={handleError(assigneeKey)}
                                onSubmit={handleSubmit(assigneeKey)}
                                onValid={handleValid(assigneeKey)}
                                selected={userDict[assigneeKey].map(a => a.value)}
                                tags={[]}
                            />
                        );
                    }
                    return <></>
                })}

                <div className={styles.wideField}>
                    <PositiveHollowButton onClick={handleAssigneeRecurrence} text={'+ Add Assignee'} />
                </div>

                {!addReviewer &&
                    <div className={styles.wideField}>
                        <PositiveHollowButton onClick={handleClick} text={'+ Add Reviewer'} />
                    </div>
                }

                {addReviewer && (
                    <DropdownMultiSelect
                        className={styles.dropdownWithTags}
                        items={users}
                        key={'reviewerSelect'}
                        label={REVIEWER}
                        onError={handleError('reviewer')}
                        onSubmit={handleSubmit('reviewer')}
                        onValid={handleValid('reviewer')}
                        selected={'reviewer' in userDict ? userDict['reviewer'].map(a => a.value) : []}
                    />
                )}

                {expandForm &&
                <div className={`${styles.tableContainer} ${styles.wideField}`}>
                    <Table className={`${className} ${styles.activityTable}`}>
                        <TableRow className={`${className} ${styles.activityTableHeader}`}>
                            {headers.map((item, index) => (
                                <HeaderText
                                    className={`${className} ${styles.headerText}`}
                                    key={`${item}.${index}`}
                                    text={item}
                                />
                            ))}
                        </TableRow>

                        <div>
                        {forms && forms.map((element, index) => {
                            const assigmentIndex = assignees.map(function(e) { return e.value; }).indexOf(element?.assignment);
                            const nameIndex = databaseForms.map(function(e) { return e.label; }).indexOf(element?.name);
                            const presetAssignment = assigmentIndex !== -1 ? assigmentIndex : '';
                            const presetDueDate = element?.dueDate || '';
                            const presetName = nameIndex !== -1 ? nameIndex : '';
                            return(
                                <TableRow className={styles.formTableRow} key={`row_${index}`} >

                                    <Dropdown
                                        items={databaseForms}
                                        key={`name_${index}`}
                                        onChange={id => {handleSetFormName(id, index)}}
                                        selected={presetName}
                                    />

                                    <TextInput
                                        key={`date_${index}`}
                                        onChange={date => {handleSetFormDate(date, index)}}
                                        value={presetDueDate}
                                    />

                                    <Dropdown
                                        items={assignees}
                                        key={`assignee_${index}`}
                                        onChange={assignee => {handleSetAssignee(assignee, index)}}
                                        selected={presetAssignment}
                                    />

                                    <CloseButton className={styles.formDeleteButton} onClick={click => {removeFormRow(click, index)}} />
                                </TableRow>
                            )
                        })}
                        </div>

                        <div className={styles.wideField}>
                            <PositiveHollowButton onClick={handleNewForm} text={'+ Add Form'} />
                        </div>
                    </Table>
                </div>
                }

                {!expandForm &&
                    <div className={styles.wideField}>
                        <PositiveHollowButton onClick={handleExpandForm} text={'+ Add Form'} />
                    </div>
                }

                <CancelDeleteSaveButtons
                    className={styles.cancelSaveButtons}
                    onCancel={handleClose}
                    onDelete={handleDelete}
                    onSave={handleSave}
                />
            </PanelBody>

            {isOpen && <DeleteRecurrenceModal onClose={handleClose} recurrenceId={activityRecurrence?.id} recurrencesForUpdate={recurrencesForUpdateState} />}
        </Modal>
    );
};

export default EditRecurrenceModal;
