import ComplianceEntryCasePanel from 'components/ComplianceEntryCasePanel';

const ComplianceEntryCases = ({ items, logSchema }) =>
    items.map(
        (
            {
                closedDate,
                createDate,
                creationReason,
                dueDate,
                id,
                name,
                riskLabel
            },
            index
        ) => (
            <ComplianceEntryCasePanel
                closedDate={closedDate}
                createDate={createDate}
                creationReason={creationReason}
                dueDate={dueDate}
                id={id}
                key={`${id}${index}`}
                logSchema={logSchema}
                name={name}
                riskLabel={riskLabel}
            />
        )
    );

export default ComplianceEntryCases;
