import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import WithPageTitle from 'components/WithPageTitle';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import {usePermissions} from 'hooks/usePermissions';
import useData from './hooks/useData';
import useTranslation from './hooks/useTranslations';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import normalizeData from './data';


import CancelSaveButtons from 'components/CancelSaveButtons';
import useCreateActivityType from 'pages/AddActivityType/hooks/useCreateActivityType';
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

import Input from 'components/Input';
import InputWithLabel from 'components/InputWithLabel';


import RiskLevelRadioButtons from 'components/RiskLevelRadioButtons';

import styles from './styles.module.scss';

const ACTIVITY_TYPE_PERMISSION_UPDATE = 'activityType:update';

const ActivityTypeEditForm = () => {
    const params = useParams();
    const { activityTypeId } = params;

    const {hasPermissions} = usePermissions();

    const hasPermissionToEdit = hasPermissions([ACTIVITY_TYPE_PERMISSION_UPDATE]);

    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();

    const { loading, data } = useData(activityTypeId);

    const [selectedRiskLevel, setSelectedRiskLevel] = useState("");
    const [activytyTypeData, setActivityTypeData] = useState();
    const [activityTypeName, setActivityTypeName] = useState();

    const [
        createdActivityType, 
        _createActivityType,
        updateActivityType,
        resetCreateActivityType
    ] = useCreateActivityType();

    const {
        ACTIVITY_TYPE,
        ACTIVITY_TYPE_NAME,
        RISK_LABEL
    } = useTranslation()


    const { ADMIN } = useConfig();

    const form = useForm({
        name: "",
        riskLevel: '',

    });

    const navigate = useNavigate();

    const {
        handleForm,
        handleValid,
     } = form;
    
    const handleCancel = useCallback(() => {
        navigate(ADMIN);
    }, [ADMIN, navigate]);

    const handleSave = useCallback(() =>{
        const output = handleForm();

        if (typeof output === 'string') {
            return;
        }

        const formObject = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                accumulator[key] = value;
                return accumulator;
            }, {}
        );

        formObject.riskLevel = selectedRiskLevel;
        formObject.name = activityTypeName
        formObject.id = activityTypeId

        updateActivityType(formObject);
        }, [handleForm, selectedRiskLevel, activityTypeName, activityTypeId, updateActivityType]
    );

    useEffect(() => {
        if(data){
            const normalizedData = normalizeData({
                data,
                schema
            })
            setActivityTypeData(normalizedData)
            setActivityTypeName(normalizedData?.activityTypes?.name)
        }

    }, [data, handleValid, schema]);

    useEffect(() => {
        if (!createdActivityType) {
            return;
        }

        navigate(ADMIN);

        resetCreateActivityType();
    }, [createdActivityType, navigate, resetCreateActivityType, ADMIN]);

    if (loading) {
        return `Loading...`;
    }
    
    return (
        <div className={styles.activityType}>
            <WithPageTitle title={ACTIVITY_TYPE}>
                <CancelSaveButtons onCancel={handleCancel} isSaveDisabled={!hasPermissionToEdit} onSave={handleSave} />
            </WithPageTitle>

            <ContentPanel>
                <PanelBody className={styles.panelBody}>

                    <InputWithLabel text={ACTIVITY_TYPE_NAME}>
                        <Input
                            isReadOnly={!hasPermissionToEdit}
                            onChange={setActivityTypeName}
                            value={activityTypeName}
                        />
                    </InputWithLabel>

                    <RiskLevelRadioButtons
                        className={styles.riskLevelRadioButtons}
                        disabledAllItems={!hasPermissionToEdit}
                        label={RISK_LABEL}
                        onChange={setSelectedRiskLevel}
                        riskLabel={activytyTypeData?.activityTypes?.risk_label|| ''}
                    />
                </PanelBody>
            </ContentPanel>
        </div>
    );
};

export default ActivityTypeEditForm;
