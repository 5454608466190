import { atom } from 'recoil';

const requestCountsAtom = atom({
    default: [],
    key: 'requestCounts'
});

const state = {
    atoms: {
        requestCountsAtom,
    }
};

export default state;
