import { useCallback } from 'react';

import ActionButton from 'components/ActionButton';
import PlusIcon from 'components/icons/PlusIcon';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const AddButton = ({ className = '', isDisabled = false, onClick, text }) => {
    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <ActionButton
            className={`${className} ${styles.addButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
        >
            <PlusIcon />

            <span className={styles.span}>{text}</span>
        </ActionButton>
    );
};

export default AddButton;
