import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('completedItems');

    return {
        COMPLETED: t('completed')
    };
};

export default useConstants;
