const normalizeData = ({ data, schema }) =>
    data[`${schema}_log_types`].reduce((accumulator, type) => {
        accumulator.push({
            label: type.name,
            value: type.id
        });

        return accumulator;
    }, []);

export default normalizeData;
