import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';

// import useForm from 'hooks/useForm';

import CloseAndAddLogEntryMenuListItem from 'components/CloseAndAddLogEntryMenuListItem';

const CloseCaseAndAddLogEntryMenuListItem = ({
    caseId,
    caseRecord,
    onClick,
    onSave
}) => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const [closedCase, closeCase, reset] = useData();

    const { CASE_MANAGEMENT_ROUTE } = useConfig();

    const navigate = useNavigate();

    const [_errorMessage, setErrorMessage] = useState();

    const handleCaseClose = useCallback(
        ({ form }) => {

            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const usersInvolved = caseRecord?.usersInvolved.length > 0 ? caseRecord?.usersInvolved.reduce((accumulator, user) => {
                accumulator.push(user?.id);
                return accumulator;
            }, []) : []

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    activityId: caseRecord?.activityCase?.activity?.id,
                    caseId,
                    createdBy: Number(userId),
                    usersInvolved
                }
            );

            formObject.dateOfOccurrence = new Date(formObject.dateOfOccurrence);
            formObject.typeId = Number(formObject.logCategory);

            closeCase(formObject);
        },
        [caseId, caseRecord, closeCase, userId /* onSave */]
    );

    useEffect(() => {
        

        if (!closedCase) {
            return;
        }

        if (isFunction(onSave)) {
            onSave();
        }

        if (isFunction(onClick)) {
            onClick(closedCase);
        }

        navigate(CASE_MANAGEMENT_ROUTE);

        reset();
    }, [CASE_MANAGEMENT_ROUTE, closedCase, navigate, onClick, onSave, reset]);

    return (
        <CloseAndAddLogEntryMenuListItem
            caseName={caseRecord?.caseName || '-'}
            caseType={caseRecord?.type?.name || '-'}
            onSave={handleCaseClose}
        />
    );
};

export default CloseCaseAndAddLogEntryMenuListItem;
