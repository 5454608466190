import DeleteButton from 'components/DeleteButton';
import ConfirmationModal from 'components/ConfirmationModal';
import SubmitButton from 'components/SubmitButton';

import replaceTextWithJSX from 'utilities/replaceTextWithJSX';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DeletePolicyVersionModal = ({ onClose, onSubmit, version }) => {
    const { CANCEL, DELETE, YOU_ARE_ABOUT_TO_DELETE_DRAFT } = useTranslation();

    return (
        <ConfirmationModal
            onClose={onClose}
            onSubmit={onSubmit}
            text={replaceTextWithJSX(YOU_ARE_ABOUT_TO_DELETE_DRAFT, {
                VERSION: <span className={styles.version}>{version}</span>
            })}
        >
            <DeleteButton onClick={onSubmit} text={DELETE} />

            <SubmitButton onClick={onClose} text={CANCEL} />
        </ConfirmationModal>
    );
};

export default DeletePolicyVersionModal;
