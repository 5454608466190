import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateSectionName } = state;

const { updateSectionNameAtom } = updateSectionName.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateSectionName($text: String!, $sectionId: Int!) {
    update_${tenantSchema}_sections(where: {id: {_eq: $sectionId}}, _set: {name: $text}) {
      returning {
        name
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveSectionName = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if(variables?.text !== ''){
                const res = await mutation(variables);
  
                const newName = res?.data[`update_${usedSchema}_sections`].returning[0]?.name;
                
                set(updateSectionNameAtom, {id: variables?.sectionId, newName});
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateSectionNameAtom, '');
    });

    return [useRecoilValue(updateSectionNameAtom), saveSectionName, reset];
};

export default useData;
