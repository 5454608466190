import ContentPanel from 'components/ContentPanel';
import Dropdown from 'components/Dropdown';
import DropdownMultiSelectWithLabel from 'components/DropdownMultiSelectWithLabel';
import PanelBody from 'components/PanelBody';
import TextInput from 'components/TextInput';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const PersonalInformationPanel = ({ className = '', departments, form, groups, positions, setDepartment, setGroups, setPosition }) => {
    const { handleError, handleSubmit, handleValid } = form;

    const {
        DEPARTMENTS,
        EMAIL_ADDRESS,
        FIRST_NAME,
        GROUPS,
        LAST_NAME,
        POSITION
    } = useTranslation();

    return (
        <ContentPanel
            className={`${className} ${styles.personalInformationPanel}`}
        >
            <PanelBody className={styles.panelBody}>
                <TextInput
                    label={FIRST_NAME}
                    onChange={handleValid('firstName')}
                    onError={handleError('firstName')}
                    onSubmit={handleSubmit('firstName')}
                />

                <TextInput
                    label={LAST_NAME}
                    onChange={handleValid('lastName')}
                    onError={handleError('lastName')}
                    onSubmit={handleSubmit('lastName')}
                />

                <TextInput
                    label={EMAIL_ADDRESS}
                    onChange={handleValid('emailAddress')}
                    onError={handleError('emailAddress')}
                    onSubmit={handleSubmit('emailAddress')}
                />

                <DropdownMultiSelectWithLabel
                    change={setGroups}
                    label={GROUPS}
                    options={groups}
                />

                <Dropdown
                    className={styles.dropdown}
                    items={positions}
                    label={POSITION}
                    onChange={setPosition}
                    onError={handleError('position')}
                    onSubmit={handleSubmit('position')}
                    onValid={handleValid('position')}
                    selected={0}
                />

                <Dropdown
                    className={styles.dropdown}
                    items={departments}
                    label={DEPARTMENTS}
                    onChange={setDepartment}
                    onError={handleError('department')}
                    onSubmit={handleSubmit('department')}
                    onValid={handleValid('department')}
                    selected={0}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default PersonalInformationPanel;
