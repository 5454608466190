import httpPost from 'utilities/httpPost';

async function pushFile(directoryName, file, fileName, apiKey, pushFileUrl) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('file_name', fileName);
  formData.append('schema', directoryName);
  const accessToken = localStorage.getItem('accessToken')
  formData.append('access_token', accessToken);
  const blob = await httpPost({data:formData, key: apiKey ? apiKey : '', url:pushFileUrl});
  try{
    const newURL = blob?.size === 0 ? '' : URL.createObjectURL(blob);
    return newURL;
  }catch{
    return {}
  }
}

export default pushFile;