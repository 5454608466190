import PanelTitle from 'components/PanelTitle';
import DocumentsModal from 'components/DocumentsModal';

import styles from './styles.module.scss';

const DocumentsModalWithTitle = ({ children, onClose, title }) => (
    <DocumentsModal onClose={onClose}>
        <PanelTitle className={styles.title} text={title} />

        {children}
    </DocumentsModal>
);

export default DocumentsModalWithTitle;
