import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('activitiesViewSelector');

    return {
        ACTIVITY_TYPES: t('activityTypes'),
        END_DATE: t('endDate'),
        ENTITIES: t('entities'),
        SEARCH: t('search'),
        START_DATE: t('startDate'),
    };
};

export default useConstants;
