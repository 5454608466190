import HtmlContent from 'components/HtmlContent';
import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';

import styles from './styles.module.scss';

const ContentBox = props => {
    const { className = '', html, sectionWithBorderOverride = '', title } = props;

    return (
        <SectionWithBorder className={`${className} ${styles.contentBox} ${sectionWithBorderOverride}`}>
            <Title text={title} />

            <HtmlContent className={`${styles.htmlContent}`} html={html} sanitizeHtml={true} />
        </SectionWithBorder>
    );
};

export default ContentBox;
