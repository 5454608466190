import PoliciesReportPanel from 'components/PoliciesReportPanel';
import WithPageTitle from 'components/WithPageTitle';

import styles from './styles.module.scss';

const Reports = () => (
    <div className={styles.reports}>
        <WithPageTitle className={styles.head} title="Policy Report" />

        <PoliciesReportPanel className={styles.reportsPanel} />
    </div>
);

export default Reports;
