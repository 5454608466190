import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ContentPanel from 'components/ContentPanel';
import DateWithLabel from 'components/DateWithLabel';
import PanelBody from 'components/PanelBody';
import SelectUsersDrawer from 'components/SelectUsersDrawer';
import UserList from 'components/UserList';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';
import useUpdateEntities from './hooks/useUpdateEntities';
import useUsers from './hooks/useUsers';
import useEntities from './hooks/useEntities';
import useUpdateUpdateUsersInvolved from './hooks/useUpdateUpdateUsersInvolved';

import styles from './styles.module.scss';

import normalizeUserData from './dataUsers';
import normalizeEntityData from './dataEntities';
import EditableMultiSelectWithLabel from 'components/EditableMultiSelectWithLabel';
import EntityTags from 'components/EntityTags';

const ComplianceLogEntryMetaPanel = props => {
    const { className = '', entry, logSchema } = props;
    const { INTERNAL_SERVER_ERROR } = useConfig();

    const { createdAt, createdBy } = entry.entry;

    const navigate = useNavigate();

    const { useSchema, useUserId } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [userId] = useUserId();

    const [updatedEntities, updateEntities, resetUpdateEntities] = useUpdateEntities(logSchema);
    const [updatedUsersInvolved, updateUsersInvolved, resetUpdateUsersInvolved] = useUpdateUpdateUsersInvolved(logSchema);
    
    const [users, setUsers] = useUsers();
    const [entities, setEntities] = useEntities();
    const [_errorMessage, setErrorMessage] = useState();
    const [isEditingEntities, setIsEditingEntities] = useState(false);
    const [selectedEntityLabels, setSelectedEntityLabels] = useState();
    const [selectedEntityValues, setSelectedEntityValues] = useState();

    const { CREATED_BY, DATE_OF_LOG_ENTRY, USERS_INVOLVED, /*VISIBLE_BY*/ } =
        useTranslation();

    const { error, data } = useData();

    useEffect(() => {
        if (!entities) {
            return;
        }

        const selectedLabels = [];
        entry?.entities.forEach(entity => selectedLabels.push(entity));
        const selectedValues = [];
        for (const entity of entities) {
            if (selectedLabels.includes(entity.label)) {
                selectedValues.push(entity.value);
            }
        }

        setSelectedEntityValues(selectedValues)
        setSelectedEntityLabels(selectedLabels)

    }, [entry, entities]);

    const handleEntities = useCallback(() => {
        setIsEditingEntities(true);
    }, []);

    const handleEntitySave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    logId: entry?.id,
                    userId: userId
                }
            );
            formObject['entities'] = JSON.parse(formObject.selected)

            updateEntities(formObject);
        }, [entry, updateEntities, userId]
    );

    const saveUsersInvolved = useCallback(({ userId:usersInvolvedId }) => {
        const usersInvolvedIds = entry.usersInvolved.reduce((accumulator, user) => {
            accumulator.push(user?.id)
            return accumulator
        },[]);
        const newUsers = usersInvolvedIds.includes(usersInvolvedId) ? [...usersInvolvedIds].filter(item => item !== usersInvolvedId) : [...usersInvolvedIds, usersInvolvedId]
        const formObject = {
            changeUserId: userId,
            logId: entry.id,
            users: newUsers
        }

        updateUsersInvolved(formObject);
    }, [updateUsersInvolved, userId, entry]);

    useEffect(() => {
        if (!updatedUsersInvolved) {
            return;
        }
        window.location.reload();
    }, [resetUpdateUsersInvolved, updatedUsersInvolved]);

    useEffect(() => {
        if (!updatedEntities) {
            return;
        }

        setIsEditingEntities(false);
        setSelectedEntityValues(updatedEntities);

        const selectedEntityValuesArray = [];
        for (const updatedEntityValue of updatedEntities) {
            selectedEntityValuesArray.push(entities
                .find(entity => entity.value === updatedEntityValue)
            );
        }

        const selectedLabels = selectedEntityValuesArray
            .map(({ label }) => label);

        setSelectedEntityLabels(selectedLabels);
        resetUpdateEntities();
    }, [entities, isEditingEntities, resetUpdateEntities, updatedEntities, setSelectedEntityValues]);

    useEffect(() => {
        if (data) {
            const normalizedUserData = normalizeUserData({ data, schema });
            const normalizedEntityData = normalizeEntityData({ data, schema });

            setUsers(normalizedUserData);
            setEntities(normalizedEntityData);
        }
    }, [data, schema, setEntities, setUsers]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);

        return;
    }

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <DateWithLabel date={createdAt} text={DATE_OF_LOG_ENTRY} />

                <UserList
                    className={styles.list}
                    title={CREATED_BY}
                    users={[createdBy]}
                />

                <SelectUsersDrawer
                    onChange={saveUsersInvolved} 
                    selectedUsers={entry.usersInvolved}
                    users={users}
                >
                    {({ toggle }) => (
                        <UserList
                            className={styles.list}
                            onEditClick={toggle}
                            title={USERS_INVOLVED}
                            users={entry.usersInvolved}
                        />
                    )}
                </SelectUsersDrawer>

                {/* <SelectUsersDrawer
                    selectedUsers={entry.visibleTo}
                    users={users}
                >
                    {({ toggle }) => (
                        <UserList
                            className={styles.list}
                            onEditClick={toggle}
                            title={VISIBLE_BY}
                            users={entry.visibleTo}
                        />
                    )}
                </SelectUsersDrawer> */}

                <>
                    {!isEditingEntities &&
                        <EntityTags
                            className={styles.entityTags}
                            items={(selectedEntityLabels ?? [])}
                            onEditClick={handleEntities}
                        />                    
                    }

                    {isEditingEntities &&
                        <EditableMultiSelectWithLabel
                            className={styles.editingEntities}
                            editOn={true}
                            items={entities}
                            label={'Entity Tag'}
                            onClose={() => setIsEditingEntities(false)}
                            onSave={handleEntitySave}
                            selected={selectedEntityValues}
                        />
                    }
                </>
            </PanelBody>
        </ContentPanel>
    );
};

export default ComplianceLogEntryMetaPanel;
