import { useState, useEffect } from 'react';
import ContentPanel from 'components/ContentPanel';
import DonutChart from 'components/DonutChart';
import Legend from 'components/Legend';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import PanelBody from 'components/PanelBody';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

import normalizeData from './data';
import ImportantText from 'components/ImportantText';

const OverviewPanel = ({
    className = '',
    dueDateFilter,
    entityFilter,
    entries,
    items,
    selectedEndDate,
    selectedStartDate,
    startDateFilter
}) => {
    

    // const { entities , groups } = useData();
    const { DUE_DATE, /*ENTITIES , GROUPS,*/ START_DATE } = useTranslation();
    const [types, setTypes] = useState();
    const [risks, setRisks] = useState();

    

    useEffect(() => {
        if (entries) {
            const normalizedData = normalizeData(entries);

            setTypes(normalizedData.types);
            setRisks(normalizedData.risks);
        }
    }, [entries, setTypes, setRisks]);

    return (
        <ContentPanel className={`${className} ${styles.overviewPanel}`}>
            <PanelBody className={styles.panelBody}>
                <Dropdown
                    className={styles.categoryDropdown}
                    items={items}
                    label={"Log Category"}
                    onChange={entityFilter}
                />

                {/* <DropdownWithTags
                    className={styles.dropdownWithTags}
                    items={groups}
                    label={GROUPS}
                    onChange={onChange}
                /> */}

                <DatePicker
                    className={styles.startDate}
                    label={START_DATE}
                    onChange={startDateFilter}
                    placeholder={selectedStartDate.toISOString().split('T')[0]}
                />

                <DatePicker
                    className={styles.endDate}
                    label={DUE_DATE}
                    onChange={dueDateFilter}
                    placeholder={selectedEndDate.toISOString().split('T')[0]}
                />

                {entries?.length && (
                    <>
                        <ImportantText className={styles.entriesHeader} text={'Number of Entries'} />

                        <ImportantText className={styles.entriesCount} text={entries?.length} />
                    </>
                )}

                {types?.length && (
                    <>
                        <ImportantText className={styles.categoriesHeader} text={'Categories'} />

                        <DonutChart
                            className={styles.typeDonutChart}
                            items={types}
                        />

                        <Legend className={styles.typeLegend} items={types} />
                    </>
                )}

                {risks?.length && (
                    <>
                        <ImportantText className={styles.riskHeader} text={'Risk Breakdown'} />

                        <DonutChart
                            className={styles.riskDonutChart}
                            donut={false}
                            items={risks}
                        />

                        <Legend className={styles.riskLegend} items={risks} />
                    </>
                )}
            </PanelBody>
        </ContentPanel>
    );
};

export default OverviewPanel;
