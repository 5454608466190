import { useCallback } from 'react'; 

import BodyText from 'components/BodyText';
import CheckPermissions from 'components/CheckPermissions';
import SlideToggle from 'components/SlideToggle';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const FlaggedToggle = ({ className = '', onToggle }) => {
    const {useIsFlaggedFilter} = useGlobalStateHooks();
    const [isFlagged, setIsFlagged] = useIsFlaggedFilter();

    const { FLAGGED } = useTranslation();

    const handleToggle = useCallback(
        isOn => {
            if (isFunction(onToggle)) {
                setIsFlagged(isOn);
                onToggle(!isOn);
            }
        },
        [onToggle, setIsFlagged]
    );

    const onStyles = isFlagged ? styles.isActive : '';
    const offStyles = onStyles ? '' : styles.isActive;

    return (
        <CheckPermissions permission={'all'}>
            <div className={`${className} ${styles.allMeToggle}`}>
                <BodyText className={`${onStyles} ${styles.bodyText}`} text={''} />

                <SlideToggle color={'orange'} isActive={isFlagged} onToggle={handleToggle} />

                <BodyText className={`${offStyles} ${styles.bodyText}`} text={FLAGGED} />
            </div>
        </CheckPermissions>
    );
};

export default FlaggedToggle;
