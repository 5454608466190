import { useCallback } from 'react';

import Buttonize from 'components/Buttonize';
import ArrowIcon from 'components/icons/ArrowIcon';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const SortArrow = ({ className = '', isAscending = false, onClick }) => {
    const handleClick = useCallback(() => {
        if (isFunction(onClick)) {
            onClick();
        }
    }, [onClick]);

    const ascendingClass = isAscending ? styles.isAscending : '';

    return (
        <Buttonize className={className} onClick={handleClick}>
            <ArrowIcon className={`${ascendingClass} ${styles.sortArrow}`} />
        </Buttonize>
    );
};

export default SortArrow;
