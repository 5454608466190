import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('formsListItems');

    return {
        ASSIGNED: t('assigned'),
        COMPLETED: t('completed'),
        NAME: t('name')
    };
};

export default useTranslation;
