// import titleCase from "utilities/titleCase";
import { kebabCase } from "lodash";
import formatDate from 'utilities/formatDate';

const cleanSheetName = name => {
    return name.replace(/[\\*?[\]/:]/g, '-');
};

const buildNameFromUser = user => {
    if (!user) return '';

    return `${user.firstName ?? user.first_name} ${
        user.lastName ?? user.last_name
    }`.trim();
};

const processRowValue = (text, clean) => {
    const string = (text ?? '').trim();
    return clean ? string.replace(/(<([^>]+)>)/gi, '') : string;
};

const buildRowForCase = data => {
    const row = {};

    // Default columns that all case types will have
    //
    row['Case Type'] = processRowValue(data.caseType);
    row['Case Name'] = processRowValue(data.caseName);
    row['Creation Reason'] = processRowValue(data.creationReason, true);
    row['Action Plan'] = processRowValue(data.actionPlan, true);
    row['Proposed Solution'] = processRowValue(data.proposedSolution, true);
    row['Resolution'] = processRowValue(data.resolution, true);
    row['Users Involved'] = processRowValue(
        data.usersInvolved?.map(user => buildNameFromUser(user)).join(', ')
    );
    row['Status'] = data.closed
        ? 'Complete'
        : new Date(data.dueDate) < new Date()
        ? 'Overdue'
        : 'In Progress';
    row['Due Date'] = processRowValue(data.dueDate);
    row['Assigned To'] = processRowValue(
        data.assignedTo?.map(user => buildNameFromUser(user)).join(', ')
    );
    row['Date Closed'] = data.closedDate
        ? formatDate(new Date(data.closedDate))
        : '';
    row['Risk Label'] = processRowValue(data.riskLabel);
    row['Entities'] =
        data.entityTags?.length > 0 ? data.entityTags.join(', ') : '';

    // Specific dropdown columns for each case type
    //
    for (const dropdown of data.dropdowns ?? []) {
        let value = '';
        if (dropdown.id in (data.dropdownSelections ?? {})) {
            value = processRowValue(
                dropdown.options[data.dropdownSelections[dropdown.id]]
            );
        }
        row[dropdown.title] = value;
    }

    // Specific form question columns for each case type
//
for (const question of data.caseForms?.form_questions ?? []) {
    let answers = question.case_form_answers ?? [];
    answers = answers.filter(answer => answer.case_id === data.id);
    let lastAnswer = undefined;
    if (answers.length > 0) {
        lastAnswer = answers[answers.length - 1];
        let value = '';
    
        if (question.question.type === 'textInput' && lastAnswer?.answer === "Test Answer") {
            continue;
        }

        if (question.question.type === 'checkBoxInput') {
            const indexes = lastAnswer?.answer
                .split(',')
                .map(index => parseInt(index.trim())) ?? [];
            const choices = question.question.choices
                .map(choice => choice.text)
                .sort();
            const values = choices.map(
                (choice, index) =>
                    `${choice} - ${
                        indexes.includes(index)
                            ? 'Checked'
                            : 'Not Checked'
                    }`
            );
            value = values.join('\n');
        } else if (question.question.type === 'radioInput') {
            const answer = lastAnswer?.answer;
            const choices = question.question.choices
                .map(choice => choice.text)
                .sort();
            const values = choices.map(
                (choice) =>
                    `${choice} - ${
                        answer && kebabCase(choice) === answer
                            ? 'Selected'
                            : 'Not Selected'
                    }`
            );
            value = values.join('\n');
        } else {
            value = lastAnswer?.answer ?? '';
        }
        
        let columnName = question.question.name;
        if (row[columnName]) {
            let i = 0;
            while (row[columnName]) {
                i++;
                columnName = `${question.question.name} #${i}`;
            }
        }
        row[`${question.question.name}`] = processRowValue(value);
    } else {
        if(question.question.type === 'radioInput'){
            const choices = question.question.choices
                .map(choice => choice.text)
                .sort();
            const values = choices.map(
                (choice) =>
                    `${choice} - Not selected`
            );
            row[`${question.question.name}`] = values.join('\n');
        }else if(question.question.type === 'checkBoxInput'){
            row[`${question.question.name}`] = (
                question.question.choices
                    .map(choice => choice.text)
                    .sort()
                    .map(choice => `${choice} - Not selected`)
                    .join('\n')
            )
        } else if(question.question.type === 'textInput'){
            row[`${question.question.name}`] = "Not answered";
        } 
        else {
            row[`${question.question.name}`] = "Not selected";
        }
    }
}

    // More default columns that all case types will have
    //
    row['Comments'] = data.comments
        .map(comment => {
            const date = formatDate(new Date(comment.created_at));
            const user =
                comment.user_case_comments?.length > 0 &&
                comment.user_case_comments[0].tenant_user
                    ? comment.user_case_comments[0].tenant_user[
                          Object.keys(
                              comment.user_case_comments[0].tenant_user
                          )[0]
                      ]
                    : undefined;
            const name = buildNameFromUser(user);

            return `${date}\n${name}\n${comment.text}`;
        })
        .join('\n');
    row['Attachments'] = data.caseAttachments
        .map(attachment => `${attachment.name}\n${attachment.file}`)
        .join('\n');

    return row;
};

const splitRowsIntoSheets = rows => {
    const sheets = []; // {headerKey: string; headers: string[]; name: string, rows: string[]}[]
    const sheetMap = {};

    for (const row of rows) {
        const caseType = cleanSheetName(row['Case Type']);
        const headerKey = JSON.stringify(Object.keys(row));
        const sheetKey = `${caseType}-${headerKey}`;

        if (!sheetMap[sheetKey]) {
            const sheet = {
                headerKey,
                headers: Object.keys(row),
                name: caseType,
                rows: []
            };
            sheets.push(sheet);
            sheetMap[sheetKey] = sheet;
        }

        sheetMap[sheetKey].rows.push(Object.values(row));
    }

    return sheets;
};

const parseDetailedDataForReport = async (data, isAll, tenants, querySchemas, schema, userId) => {
    const schemaData = ({ data, querySchemas, schema }) =>
    data.reduce((accumulator, cases) => {
        const {
            action_plan: actionPlan,
            activity,
            case_attachments: caseAttachments=[],
            case_comments: comments=[],
            // case_forms: caseForms,
            case_name: caseName,
            case_policies: casePolicies,
            caseTypeByCaseType: caseType,
            closed,
            closed_date: closedDate,
            created_date: createdDate,
            creation_reason: creationReason,
            dropdown_selections: dropdownSelections,
            due_date: caseDueDate,
            id: caseId,
            proposed_solution: proposedSolution,
            resolution,
            risk_color: riskColor,
            risk_label: riskLabel,
            case_entities: caseEntities=[],
            originated,
            user_cases: caseUsers=[],
            case_user_involveds: caseUsersInvolved
        } = cases;

        const entities =
            caseEntities.length > 0
                ? caseEntities.reduce((accumulator, entity) => {
                      const { name } = entity.entity;

                      accumulator.push(name);

                      return accumulator;
                  }, [])
                : [];

        const users = caseUsers.reduce(
            (accumulator, { tenant_user: tenantUser }) => {
                if(querySchemas){
                    const schemas = Array.isArray(querySchemas) ? querySchemas : [{value: querySchemas}];
                    for(const s of schemas){
                        const users = tenantUser[`users_${s.value}`];
                        const id = tenantUser.id;

                        if(users && users.length > 0){
                            const firstName = users[0]?.first_name ? users[0]?.first_name : '';
                            const lastName = users[0]?.last_name ? users[0]?.last_name : '';

                            accumulator.push({
                                firstName,
                                id,
                                lastName
                            });
                        }
                    }
                }else{
                    const users = tenantUser[`users_${schema}`];
                        const id = tenantUser.id;

                        if(users && users.length > 0){
                            const firstName = users[0]?.first_name ? users[0]?.first_name : '';
                            const lastName = users[0]?.last_name ? users[0]?.last_name : '';

                            accumulator.push({
                                firstName,
                                id,
                                lastName
                            });
                        }
                }

                return accumulator;
            },
            []
        );

        const usersInvolved = caseUsersInvolved ? caseUsersInvolved.reduce(
            (accumulator, { tenant_user: tenantUser }) => {
                if(querySchemas){
                    const schemas = Array.isArray(querySchemas) ? querySchemas : [{value: querySchemas}];
                    for(const s of schemas){
                        const users = tenantUser[`users_${s.value}`];
                        if(users && users.length > 0){
                            for(const u of users){
                                const id = tenantUser[`id`];
                                const firstName = u?.first_name;
                                const lastName = u?.last_name;
                                const profilePhoto = u?.profile_photo;

                                if(firstName){
                                    accumulator.push({
                                        firstName,
                                        id,
                                        lastName,
                                        profilePhoto
                                    });
                                }
                            }
                        }
                    }
                }else{
                    const users = tenantUser[`users_${schema}`];
                    if(users && users.length > 0){
                        for(const u of users){
                            const id = tenantUser[`id`];
                            const firstName = u?.first_name;
                            const lastName = u?.last_name;
                            const profilePhoto = u?.profile_photo;

                            if(firstName){
                                accumulator.push({
                                    firstName,
                                    id,
                                    lastName,
                                    profilePhoto
                                });
                            }
                        }
                    }
                }

                return accumulator;
            },
            []
        ) : [];

        accumulator.push({
            actionPlan,
            activityId: activity?.id || undefined,
            activityName: activity?.name || undefined,
            assignedTo: users,
            caseAttachments,
            caseForms: caseType?.form,
            caseName,
            casePolicies,
            caseSchema: schema,
            caseType: caseType?.name,
            closed,
            closedDate,
            comments,
            createdDate,
            creationReason,
            dropdownSelections,
            dropdowns: caseType?.dropdown,
            dueDate: formatDate(new Date(caseDueDate)),
            entityTags: entities,
            id: caseId,
            originated,
            proposedSolution,
            resolution,
            riskColor,
            riskLabel,
            sortDate: caseDueDate,
            usersInvolved
        });

        accumulator = accumulator.sort((objA, objB) => {
            if(objA.sortDate > objB.sortDate) return 1;
            if(objA.sortDate < objB.sortDate) return -1;
            if(objA.caseType > objB.caseType) return 1;
            if(objA.caseType < objB.caseType) return -1;
            if(objA.caseName > objB.caseName) return 1;
            if(objA.caseName < objB.caseName) return -1;
            return 0;
        });

        return accumulator;
    }, []);

    const casedata = await schemaData({ data, isAll, querySchemas, schema, user:userId });

    const sortedCases = casedata.sort(function(a,b){
        return new Date(a.dueDate) - new Date(b.dueDate);
    });

    const rows = [];
    for (const caseData of sortedCases) {
        const row = buildRowForCase(caseData);
        rows.push(row);
    }

    return splitRowsIntoSheets(rows);
}

export default parseDetailedDataForReport