import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addMarketingRequest');

    return {
        INSTRUCTIONS: t('instructions'),
        MARKETING_SUBMISSION: t('marketingSubmission'),
        SUBMIT_REQUEST: t('submitRequest')
    };
};

export default useTranslation;
