import FormDetailsPanel from 'components/FormDetailsPanel';
import FormMetaPanel from 'components/FormMetaPanel';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';

import styles from './styles.module.scss';

const FormMetaTab = ({ items }) => (
    <PanelWithAsidePanel>
        <FormDetailsPanel className={styles.formDetailsPanel} form={items} />

        <FormMetaPanel form={items} />
    </PanelWithAsidePanel>
);

export default FormMetaTab;
