const translations = {
    activityName: 'Activity Name',
    activityType: 'Activity Type',
    addInstructionsHere: 'Add instructions here...',
    dueDate: 'Due Date',
    enterActivityName: 'Enter Activity Name',
    formName: ' Form Name',
    instructions: 'Instructions',
    policyReference: 'Reference',
    recipient: 'Recipient'
};

export default translations;
