import { Link as OriginalLink } from 'react-router-dom';

import AvatarsWithText from 'components/AvatarsWithText';

import styles from './styles.module.scss';

const Link = ({ assigned, className = '', newTab, section, to }) => {
    const linkTo = /^\//.test(to) ? to : { pathname: to };

    return (
        <OriginalLink
            className={`${styles.bodyText}`}
            target={newTab ? '_blank' : undefined}
            to={linkTo}
        >
            <AvatarsWithText
                assigned={assigned}
                className={className}
                section={section}
            />
        </OriginalLink>
    );
};

export default Link;