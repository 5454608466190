import LinkInNewTab from 'components/LinkInNewTab';
import WithLabel from 'components/WithLabel';

import styles from './styles.module.scss';

const LinkInNewTabWithLabel = props => {
    const { 
        className = '', 
        label = '', 
        text = '', 
        to = '' 
    } = props;

    return (
        <WithLabel className={className} text={label}>
            <LinkInNewTab
                className={styles.importantLink}
                text={text}
                to={to}
            />
        </WithLabel>
    );
};

export default LinkInNewTabWithLabel;
