import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { useGetSchema } from 'hooks/useGetSchema';
import { useLockActivityFormsFromActivity } from 'hooks/useLockActivityForm';

const { closeActivityAndAddLogEntryMenuListItem } = state;

const { closeActivityAndAddLogEntryMenuListItemAtom } =
    closeActivityAndAddLogEntryMenuListItem.atoms;

const MUTATE_LOG_ENTRY = tenantSchema => gql`
mutation createLog(
    $createdBy: Int!,
    $dateOfOccurrence: timestamp,
    $description: String!,
    $riskLevel: String!) {
        insert_${tenantSchema}_log(objects: {
            created_by: $createdBy,
            date_of_occurrence: $dateOfOccurrence,
            description: $description,
            log_type: "",
            risk_label: $riskLevel
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_LOG_TYPE_ENTRY = tenantSchema => gql`
mutation createLogType(
    $logId: Int!,
    $typeId: Int!) {
        insert_${tenantSchema}_log_type(objects: {
            log_id: $logId,
            type_id: $typeId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_LOG_ACTIVITY_ENTRY = tenantSchema => gql`
mutation createLogActivity(
    $activityId: Int!,
    $logId: Int!) {
        insert_${tenantSchema}_log_activity(objects: {
            log_id: $logId,
            activity_id: $activityId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_LINKAGE = tenantSchema => gql`
    mutation insertLinkage(
        $activityId: Int,
        $caseId: Int,
        $linkLogId: Int,
        $logId: Int
    ) {
        insert_${tenantSchema}_linkages(objects: {
            activity_id_1: $activityId
            case_id_1: $caseId
            log_id_1: $logId
            log_id_2: $linkLogId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_CLOSE_ACTIVITY = tenantSchema => gql`
    mutation close_activity($activityId: Int!, $closedDate: timestamp!, $color: String!, $message: String!, $userId: Int!) {
        update_${tenantSchema}_activity(where: {
            id: {
                _eq: $activityId
            }
        },
        _set: {
            closed: true,
            closed_date: $closedDate
        }) {
            returning {
                id
                closed
            }
        }

        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_FORM = tenantSchema => gql`
mutation update_activity_forms($activityId: Int!) {
    update_${tenantSchema}_activity_forms(where: {
        activity_id: {
            _eq: $activityId
        },
        
    },
    _set: {
        locked: true
    }) {
        returning {
            id
        }
    }
}
`;

const useData = (providedSchema) => {
		const tenantSchema = useGetSchema(providedSchema);
    const mutation = useGraphQL(MUTATE_CLOSE_ACTIVITY, providedSchema);
    const mutationLogEntry = useGraphQL(MUTATE_LOG_ENTRY, providedSchema);
    const mutationLogTypeEntry = useGraphQL(
        MUTATE_LOG_TYPE_ENTRY,
        providedSchema
    );
    const mutationLogActivityEntry = useGraphQL(
        MUTATE_LOG_ACTIVITY_ENTRY,
        providedSchema
    );
    const mutationLinkage = useGraphQL(MUTATE_LINKAGE, providedSchema);
    const mutationActivityForm = useGraphQL(MUTATE_ACTIVITY_FORM, providedSchema);

		const { setLockedActivityFormsFromActivity } =
        useLockActivityFormsFromActivity();

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const closeActivity = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            variables['closedDate'] = new Date();
            const activityId = Number(variables?.activityId);

             await mutation({
                activityId,
                closedDate: variables.closedDate,
                color: 'green',
                message: `closed the activity and created a log`,
                userId: variables?.createdBy
            });

            const res = await mutationLogEntry(variables);

            const id = res?.data[`insert_${usedSchema}_log`]?.returning[0]?.id;

            variables['logId'] = id;

            await setLockedActivityFormsFromActivity({
                activityId,
                tenantSchema
            });

            await mutationLogTypeEntry(variables);
            await mutationLogActivityEntry(variables);

            await mutationLinkage({
                activityId,
                caseId: variables?.linkCase,
                linkLogId: variables?.linkLog,
                logId: id
            });

            await mutationActivityForm({ activityId: activityId});

            set(closeActivityAndAddLogEntryMenuListItemAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(closeActivityAndAddLogEntryMenuListItemAtom, '');
    });

    return [
        useRecoilValue(closeActivityAndAddLogEntryMenuListItemAtom),
        closeActivity,
        reset
    ];
};

export default useData;
