import { atom } from 'recoil';

const addRequestAtom = atom({
    default: '',
    key: 'addRequest'
});

const state = {
    atoms: {
        addRequestAtom
    }
};

export default state;
