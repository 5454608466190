import { kebabCase } from 'lodash';

const resolveForms = groupForms =>
    groupForms.reduce((accumulator, form) => {
        const formForProcessing = form
            ? form
            : { form_questions: [], id: null, name: null };
        const {
            form_questions: formQuestions,
            id: formId,
            name: formName
        } = formForProcessing;

        const questions = formQuestions.reduce((accumulator, questions) => {
            const { id, required, question } = questions;

            const { formComponent, index, name, questionId, type, choices } =
                question;

            const questionChoices = choices.reduce((accumulator, choice) => {
                accumulator.push({
                    isChecked: false,
                    label: choice?.text,
                    name: name,
                    value: kebabCase(choice?.text)
                });
                return accumulator;
            }, []);

            accumulator.push({
                choices: questionChoices,
                formComponent,
                id,
                index,
                name,
                questionId,
                required,
                type
            });
            return accumulator;
        }, []);

        if (formId) {
            accumulator = {
                formId,
                formName,
                questions: questions.sort((a, b) => {
                    if (a?.index === b?.index) {
                        return 0;
                    }
                    if (a?.index === null) {
                        return 1;
                    }
                    if (b?.index === null) {
                        return -1;
                    }
                    return a?.index < b?.index ? -1 : 1;
                })
            };
        }

        return accumulator;
    }, []);

const resolveAnswers = ({ groupForms, schema }) =>
    groupForms.reduce((accumulator, form) => {
        const formForProcessing = form
            ? form
            : { form_questions: [], id: null, name: null };
        const {
            form_questions: formQuestions,
            id: formId,
            name: formName
        } = formForProcessing;

        const questions = formQuestions.reduce((accumulator, questions) => {
            const {
                id,
                required,
                question,
                marketing_form_answers: formAnswers
            } = questions;

            const { formComponent, index, name, questionId, type } = question;

            const answers = formAnswers
                ? formAnswers.reduce((accumulator, answer) => {
                      const {
                          id: answerId,
                          user_id: userId,
                          question_id: questionId,
                          answer: questionAnswer,
                          tenant_user: tenantUser
                      } = answer;

                      const user = tenantUser[`user_${schema}`];
                      const { first_name: firstName, last_name: lastName } =
                          user;
                      const answerUser = {
                          id: userId,
                          name: `${firstName} ${lastName}`
                      };
                      accumulator.push({
                          answerUser,
                          id: answerId,
                          questionAnswer,
                          questionId,
                          userId
                      });
                      return accumulator;
                  }, [])
                : [];

            answers.sort((a, b) => a.id - b.id);

            accumulator.push({
                answers,
                formComponent,
                id,
                index,
                name,
                questionId,
                required,
                type
            });
            return accumulator;
        }, []);

        if (formId) {
            accumulator = {
                formId,
                formName,
                questions
            };
        }

        return accumulator;
    }, []);

const schemaData = ({ data, schema }) =>
    data?.reduce((accumulator, form) => {
        accumulator.forms = Object.keys(form).includes('id')
            ? resolveForms([form])
            : {};
        accumulator.answers = Object.keys(form).includes('id')
            ? resolveAnswers({ groupForms: [form], schema })
            : {};
        accumulator.allAnswers = Object.keys(form).includes('id')
            ? [form].reduce(
                  (accumulator, forms) => {
                      const additionalQuestions = resolveAnswers({
                          groupForms: [forms],
                          schema
                      });
                      accumulator.questions.push(
                          ...additionalQuestions?.questions
                      );
                      return accumulator;
                  },
                  { questions: [] }
              )
            : {};

        return accumulator;
    }, {});

export const normalizeMarketingFormData = ({ data, schema }) =>
    schemaData({ data, schema });
