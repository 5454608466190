import { useCallback, useState } from 'react';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
    convertEditorStateToHtml,
    convertHtmlToEditorState
} from 'utilities/editor';

import isFunction from 'utilities/isFunction';

import bold from './images/bold.svg';
import image from './images/image.svg';
import italic from './images/italic.svg';
import link from './images/link.svg';
import ordered from './images/o_list.svg';
import remove from './images/remove.svg';
import strikeThrough from './images/line_through.svg';
import underline from './images/underline.svg';
import unordered from './images/u_list.svg';
import video from './images/video.svg';

import styles from './styles.module.scss';

const Editor = ({
    className = '',
    html = '',
    onChange,
    onValid,
    placeholder = ''
}) => {
    const [editorState, setEditorState] = useState(() =>
        html ? convertHtmlToEditorState(html) : EditorState.createEmpty()
    );

    const handleChange = useCallback(
        state => {
            setEditorState(state);

            const newHtml = convertEditorStateToHtml(state);

            if (isFunction(onValid)) {
                onValid(newHtml);
            }

            if (isFunction(onChange)) {
                onChange(newHtml);
            }
        },
        [onChange, onValid]
    );

    return (
        <DraftEditor
            editorClassName={styles.editor}
            editorState={editorState}
            onEditorStateChange={handleChange}
            placeholder={placeholder}
            toolbar={{
                colorPicker: { className: styles.colorPicker },
                embedded: { className: styles.embedded, icon: video },
                image: { icon: image },
                inline: {
                    bold: { icon: bold },
                    className: styles.inlineGroup,
                    italic: { icon: italic },
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                    strikethrough: { icon: strikeThrough },
                    underline: { icon: underline }
                },
                link: {
                    defaultTargetOption: '_blank',
                    isDropdown: true,
                    link: { icon: link },
                    options: ['link']
                },
                list: {
                    className: styles.listGroup,
                    options: ['unordered', 'ordered'],
                    ordered: { icon: ordered },
                    unordered: { icon: unordered }
                },
                options: [
                    'fontFamily',
                    'inline',
                    'colorPicker',
                    'list',
                    'link',
                    'image',
                    'embedded',
                    'remove'
                ],
                remove: { icon: remove }
            }}
            toolbarClassName={styles.toolbar}
            wrapperClassName={`${className} ${styles.container}`}
        />
    );
};

export default Editor;
