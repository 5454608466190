import { useCallback, useState } from 'react';
import AvatarWithTextLink from 'components/AvatarWithTextLink';
import BodyLink from 'components/BodyLink';
import ImportantLink from 'components/ImportantLink';
import PositiveBarListItem from 'components/PositiveBarListItem';

import useConfig from 'hooks/useConfig';
import { usePermissions } from 'hooks/usePermissions';

import styles from './styles.module.scss';
import ListItemDetailsButton from 'components/ListItemDetailsButton';
import WithTooltipAvatar from 'components/WithTooltipAvatar';

import { safeGetLocalStorage } from 'utilities/localStorage';

const ACTIVITIES_ACTIONS_PERMISSIONS = [
    'activities:actions',
];

const CompletedSummarizedListItem = ({
    assigned,
    className = '',
    dueDate,
    flagged,
    id,
		item,
		onDownloadItem,
    schema,
    text,
    type
}) => {
    const {ACTIVITY_ROUTE} = useConfig();

    const [complete, setComplete] = useState(false)

    const { hasPermissions } = usePermissions();

    const activitiesActionsPermission = hasPermissions(
        ACTIVITIES_ACTIONS_PERMISSIONS
    );

    const handleComplete = useCallback(() => {
        setComplete(true);
        }, [setComplete]
    );

    const newTab = safeGetLocalStorage('openActivitiesAndCasesInNewTab', false);

    return (
        <>{!complete &&
        <PositiveBarListItem className={`${className} ${styles.completedSummarizedListItem}`} >
            <ImportantLink
                className={styles.text}
                newTab={newTab}
                text={text}
                to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
            />

            <BodyLink
                className={styles.date}
                newTab={newTab}
                text={dueDate}
                to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
            />

            <BodyLink
                className={styles.avatar}
                newTab={newTab}
                text={type}
                to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
            />

            <WithTooltipAvatar assigned={assigned}>
                <AvatarWithTextLink
                    assigned={assigned}
                    className={styles.link}
                    newTab={newTab}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />
            </WithTooltipAvatar>

            {activitiesActionsPermission && (
                <ListItemDetailsButton activityName={text} flagged={flagged} handleComplete={handleComplete} id={id} onDownload={()=>onDownloadItem(item)} schema={schema} />
            )}
        </PositiveBarListItem>
        }</>
    );
};

export default CompletedSummarizedListItem;
