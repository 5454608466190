import LegendItem from 'components/LegendItem';
import ListItems from 'components/ListItems';

import styles from './styles.module.scss';

const LegendItems = ({ className = '', items = [], onClick }) => (
    <ListItems className={`${className} ${styles.legendItems}`}>
        {items.map(({ color, text }) => (
            <LegendItem color={color} key={text} onClick={onClick} text={text} />
        ))}
    </ListItems>
);

export default LegendItems;
