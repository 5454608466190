import { gql } from '@apollo/client';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import { useLockActivityFormsFromActivity } from 'hooks/useLockActivityForm';
import { useGetSchema } from 'hooks/useGetSchema';

const { closeActivityMenuListItem } = state;

const { closeActivityMenuListItemAtom } = closeActivityMenuListItem.atoms;

const MUTATE_CLOSE_ACTIVITY = tenantSchema => gql`
    mutation close_activity($activityId: Int!, $closedDate: timestamp!, $color: String!, $message: String!, $userId: Int!) {
        update_${tenantSchema}_activity(where: {
            id: {
                _eq: $activityId
            }
        },
        _set: {
            closed: true,
            closed_date: $closedDate
        }) {
            returning {
                id
                closed
            }
        }

        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_FORM = tenantSchema => gql`
mutation update_activity_forms($activityId: Int!) {
    update_${tenantSchema}_activity_forms(where: {
        activity_id: {
            _eq: $activityId
        },
        
    },
    _set: {
        locked: true
    }) {
        returning {
            id
        }
    }
}
`;

const useData = providedSchema => {
    const tenantSchema = useGetSchema(providedSchema);
    const mutation = useGraphQL(MUTATE_CLOSE_ACTIVITY, providedSchema);

    const mutationActivityForm = useGraphQL(MUTATE_ACTIVITY_FORM, providedSchema);

    const { setLockedActivityFormsFromActivity } =
        useLockActivityFormsFromActivity();

    const closeActivity = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const activityId = Number(variables?.activityId);
            const res = await mutation({
                activityId,
                closedDate: new Date(),
                color: 'green',
                message: `closed the activity`,
                userId: variables?.userId
            });

            await setLockedActivityFormsFromActivity({
                activityId,
                tenantSchema
            });

            await mutationActivityForm({ activityId: activityId });

            set(closeActivityMenuListItemAtom, res?.data[`update_${tenantSchema}_activity`]);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(closeActivityMenuListItemAtom, '');
    });

    return [
        useRecoilValue(closeActivityMenuListItemAtom),
        closeActivity,
        reset
    ];
};

export default useData;
