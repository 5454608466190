import CaseDetailsPanel from 'components/CaseDetailsPanel';
import CaseMetaPanel from 'components/CaseMetaPanel';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';

import styles from './styles.module.scss';

const CaseMetaTab = ({
    attachments,
    attachmentsOnDelete,
    attachmentsOnUpdate,
    caseSchema,
    items
}) => (
    <PanelWithAsidePanel>
        <CaseDetailsPanel
            attachments={attachments}
            attachmentsOnDelete={attachmentsOnDelete}
            attachmentsOnUpdate={attachmentsOnUpdate}
            caseSchema={caseSchema}
            cases={items}
            className={styles.caseDetailsPanel}
        />

        <CaseMetaPanel caseSchema={caseSchema} cases={items} />
    </PanelWithAsidePanel>
);

export default CaseMetaTab;
