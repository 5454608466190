import { useEffect, useRef } from 'react';
import { /*useNavigate,*/ useParams } from 'react-router-dom';

import FormDetailsTab from 'components/FormDetailsTab';
// import FormHistoryTab from 'components/FormHistoryTab';
import FormMetaTab from 'components/FormMetaTab';
import TabToggle from 'components/TabToggle';
import WithPageTitle from 'components/WithPageTitle';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useFormDetails from './hooks/useCaseDetails';
import useTranslation from './hooks/useTranslation';
import useData from './hooks/useData';

import styles from './styles.module.scss';

import normalizeData from './data';

const Form = () => {
    const params = useParams();

    const { formId, formSchema } = params;

    const { useQuerySchemas, useSchema, useUserId } = useGlobalStateHooks();

    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [userId] = useUserId();

    const [form, setForm] = useFormDetails();

    const { DETAILS, /*HISTORY,*/ META } = useTranslation();

    const { loading, _error, data } = useData(formId, formSchema);

    const tabMap = {
        details: () => <FormDetailsTab form={form} />,
        // history: () => <FormHistoryTab history={form.history} />,
        meta: () => <FormMetaTab form={form} loading={loading} />
    };

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema: formSchema ? formSchema : schema,
                querySchemas,
                user: userId
            });

            setForm(normalizedData);
        }
    }, [data, formSchema, schema, setForm, userId]);

    const tabs = useRef([
        { label: DETAILS, value: 'details' },
        // { label: HISTORY, value: 'history' },
        { className: styles.meta, label: META, value: 'meta' }
    ]).current;

    // if (error) {
    //     navigate(INTERNAL_SERVER_ERROR);

    //     return;
    // }
    
    

    if (form){
        return (
            <div className={styles.case}>
                <WithPageTitle title={form?.name}>
                    {/* <CaseActions caseId={cases.id} caseRecord={cases} /> */}
                </WithPageTitle>

                <TabToggle
                    className={styles.tabToggle}
                    items={tabs}
                    selectedTab="details"
                >
                    {selectedTab => {
                        const TabComponent = tabMap[selectedTab];

                        return <TabComponent />;
                    }}
                </TabToggle>
            </div>
        );
    }else{
        return
    }
};

export default Form;
