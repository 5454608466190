import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const CheckPermissions = ({ children, permission }) => {
    const { usePermissions } = useGlobalStateHooks();

    const [permissions] = usePermissions();

    const testPermission = Array.isArray(permission) ? permission : [permission]
    const permissionIntersection = testPermission.filter(element => permissions.includes(element));
    const permitted = permissionIntersection.length >= 1;
    
    if(permitted){
        return (
            <>
                {children}
            </>
        );
    }else{
        return (
            <></>
        );
    }
};

export default CheckPermissions;
