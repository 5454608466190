import TextInput from 'components/TextInput';

import useTranslation from './hooks/useTranslation';

const PolicyNameInput = ({
    className = '',
    onChange,
    onError,
    onSubmit,
    value
}) => {
    const { ENTER_POLICY_NAME, POLICY_NAME } = useTranslation();

    

    return (
        <TextInput
            className={className}
            label={POLICY_NAME}
            onChange={onChange}
            onError={onError}
            onSubmit={onSubmit}
            placeholder={ENTER_POLICY_NAME}
            value={value}
        />
    );
};

export default PolicyNameInput;
