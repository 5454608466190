import { useNavigate } from 'react-router-dom';

import ActiveIndicator from 'components/ActiveIndicator';
import BodyText from 'components/BodyText';
import DetailsButton from 'components/DetailsButton';
import ImportantLink from 'components/ImportantLink';
import PenIcon from 'components/icons/PenIcon';
import Pills from 'components/Pills';
import TableRow from 'components/TableRow';

import useTranslation from './hooks/useTranslation';

import useConfig from 'hooks/useConfig';

import styles from './styles.module.scss';
import titleCase from 'utilities/titleCase';

const DocumentManagementSideLetterTableRow = props => {
    const { effectiveDate, id, name, schema, _sections, selectedCategories, status, _tags, tenantCount, version, _visibleTo } = props;

    const { POLICY_ROUTE } = useConfig();

    const { EDIT } = useTranslation();

    const navigate = useNavigate();

    const isActive = status.toLowerCase() === 'activate' || status.toLowerCase() === 'active';

    return (
        <TableRow className={styles.documentManagementTableRow}>
            <ImportantLink
                className={styles.link}
                text={name}
                to={`${POLICY_ROUTE.replace(':policyId', id).replace(':policySchema', schema)}`}
            />

            <BodyText className={styles.version} text={version || '-'} />

            {tenantCount > 1 && <BodyText text={titleCase(schema)} />}

            <BodyText text={effectiveDate} />

            <Pills items={selectedCategories.map(item => item?.label)} />

            <ActiveIndicator
                className={styles.statusIcon}
                isActive={isActive}
            />

            <DetailsButton
                className={styles.detailsButton}
                items={[
                    {
                        icon: <PenIcon />,

                        onClick: () => {
                            navigate('#');
                        },

                        text: EDIT
                    }
                ]}
            />
        </TableRow>
    );
};

export default DocumentManagementSideLetterTableRow;
