const translations = {
    activityName: 'Activity Name',
    activityType: 'Activity Type',
    assignedTo: 'Assigned To',
    caseCreationReason: 'Case Creation Reason',
    caseName: 'Case Name',
    caseType: 'Case Type',
    closeAndCreateCase: 'Close and Create a Case',
    createCase: 'Create a Case',
    dueDate: 'Due Date',
    enterCaseName: 'Enter Case Name',
    linkedCaseName: 'Linked Case Name',
    linkedCaseType: 'Linked Case Type',
    policyReference: 'Reference',
    reason: 'Reason',
    riskLabelCase: 'Risk Label - Case'
};

export default translations;
