import { useCallback, useState } from 'react';

import Buttonize from 'components/Buttonize';
import CheckIcon from 'components/icons/CheckIcon';
import CloseIcon from 'components/icons/CloseIcon';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
import PenIcon from 'components/icons/PenIcon';
import WithLabel from 'components/WithLabel';

import useForm from 'hooks/useForm';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const EditableMultiSelectWithLabel = ({ 
    children, 
    className = '', 
    editOn=false, 
    hasEdit = true,
    items, label, 
    onClose, 
    onSave, 
    selected, 
    wrapperButtonize, 
    wrapperClassName 
}) => {
    /**
     * Notes:
     * items - array of objects with 'label' and 'value' Int properties
     * selected - array of values Int corresponding to 'value' property in items
     */

    const [isEditing, setIsEditing] = useState(editOn);

    const form = useForm({
        selected: ''
    });

    const { handleError, handleSubmit, handleValid } = form;

    const handleCancel = useCallback(() => {
        setIsEditing(false);

        if (isFunction(onClose)) {
            onClose();
        }
    }, [onClose]);

    const handleEdit = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleSave = useCallback(() => {
        if (isFunction(onSave)) {
            onSave({ form });
        }

        setIsEditing(false);
    }, [form, onSave, setIsEditing]);

    return (
        <div className={`${wrapperClassName} ${styles.editableContentBox} `}>
            {!isEditing && (
                <>
                    <WithLabel className={styles.withLabel} text={label}>
                        <div className={`${className}`}>
                            {children}
                        </div>
                    </WithLabel>

                    {hasEdit && (
                        <Buttonize
                            className={`${wrapperButtonize} ${styles.buttonize}`}
                            onClick={handleEdit}
                        >
                            <PenIcon />
                        </Buttonize>
                    )}
                </>
            )}

            {isEditing && (
                <>
                    <div>
                        <DropdownMultiSelect
                            items={items}
                            label={label}
                            onChange={handleValid('selected')}
                            onError={handleError('selected')}
                            onSubmit={handleSubmit('selected')}
                            returnObject={true}
                            selected={selected}
                        />
                    </div>

                    <Buttonize
                        className={styles.closeIcon}
                        onClick={handleCancel}
                    >
                        <CloseIcon />
                    </Buttonize>

                    <Buttonize
                        className={styles.checkIcon}
                        onClick={handleSave}
                    >
                        <CheckIcon />
                    </Buttonize>
                </>
            )}
        </div>
    );
};

export default EditableMultiSelectWithLabel;
