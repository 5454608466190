import { useExport } from './useExport';
import { getFileName } from 'utilities/files';
import generateXLSX from 'utilities/generateXLSX';
import parseDataForReport from 'utilities/parseCaseDataForReport';
import generateDetailedXLSX from 'utilities/generateDetailedXLSX';
import parseDetailedDataForReport from 'utilities/parseCaseDetailedDataForReport';

export function useExportCases() {
    const { exportToExcel } = useExport();

    async function exportCases({ cases, name }) {
        const parsedData = await parseDataForReport(cases);

        const buffer = await generateXLSX(parsedData, name);
        exportToExcel({
            buffer,
            filename: getFileName(name)
        });
    }

    async function exportDetailedCases({
        data,
        isAll,
        name,
        querySchemas,
        schema,
        tenants,
        userId
    }) {
        const parsedData = await parseDetailedDataForReport(
            data,
            isAll,
            querySchemas,
            schema,
            tenants,
            userId
        );
        const buffer = await generateDetailedXLSX(parsedData);
        exportToExcel({
            buffer,
            filename: getFileName(name)
        });
    }

    return {
        exportCases,
        exportDetailedCases
    };
}
