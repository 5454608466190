import ComplianceEntryActivityPanel from 'components/ComplianceEntryActivityPanel';

const ComplianceEntryActivities = ({ items, logSchema }) =>
    items.map(
        (
            {
                closedDate,
                dueDate,
                id,
                instructions,
                name,
                riskLabel,
                startDate
            },
            index
        ) => (
            <ComplianceEntryActivityPanel
                closedDate={closedDate}
                dueDate={dueDate}
                id={id}
                instructions={instructions}
                key={`${id}${index}`}
                logSchema={logSchema}
                name={name}
                riskLabel={riskLabel}
                startDate={startDate}
            />
        )
    );

export default ComplianceEntryActivities;
