import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('marketingRequestsPanel');

    return {
        ASSIGNED_TO: t('assignedTo'),
        REQUEST_STATUS: t('requestStatus'),
        REQUESTED_BY: t('requestedBy'),
        SEARCH: t('search'),
        VIEW_ALL: t('viewAll')
    };
};

export default useTranslation;
