import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import DropdownItem from 'components/DropdownItem';
import Input from 'components/Input';
import InputWithLabel from 'components/InputWithLabel';
import DropdownMenu from 'components/DropdownMenu';
import Select from 'components/Select';

import useConstants from './hooks/useTranslation';
import usePopperState from 'hooks/usePopperState';

import styles from './styles.module.scss';

/**
 * Notes:
 * items - Array of objects with 'label' and 'value' str properties
 * selected - index of selected item in 'items' array
 */

const Dropdown = forwardRef((props, ref) => {
    const { SELECT } = useConstants();

    const {
        className = '',
        hasError = false,
        isDisabled = false,
        isRequired = false,
        items = [],
        label = '',
        logId,
        onChange,
        onSubmit: onSubmitProps,
        onValid,
        placeholder = SELECT,
        selected = ''
    } = props;

    const errorMessage = useRef('');

    const [selectedLabel, setSelectedLabel] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        onLog('Initial state');
    }, []);

    useEffect(() => {
        if (placeholder !== SELECT) {
            setSelectedLabel(placeholder);
        }
    }, [setSelectedLabel, SELECT, placeholder]);

    useEffect(() => {
        if (!selectedIndex && selectedIndex !== 0) {
            onLog('Selected change', {
                selected
            });
            setSelectedIndex(selected);
            setSelectedLabel(
                items.filter(item => item.value === selected)[0]?.label
            );
        }
    }, [items, selected]);

    const {
        handleClick: onClickPopper,
        isOpen,
        onClose: onClosePopper,
        referenceElement
    } = usePopperState();

    function onLog(...logArgs) {
        if (logId) {
            console.log('Dropdown log:', logId, ...logArgs, {
                props,
                states: { selectedLabel, selectedIndex, ref, error }
            });
        }
    }

    function handleClick(event) {
        onClickPopper(event);
        onLog('onOpen', event);
    }

    function onClose() {
        onClosePopper();
        onLog('onClose');
    }

    function onSubmit(...args) {
        onLog('onSubmit', ...args);
        onSubmitProps?.(...args);
    }

    const onError = useCallback(message => {
        setError(message);

        errorMessage.current = message;
    }, []);

    const handleChange = useCallback(
        (dropdownValue, index) => {
            onLog('onChange', {
                selection: {
                    dropdownValue,
                    index
                },
                callingFunction: props?.onChange,
                callingFunctionArgs: { dropdownValue, onError, index }
            });
            onChange?.(dropdownValue, onError, index);

            if (isRequired && !dropdownValue) {
                onError?.('This field is required');
            } else {
                setError(undefined);

                errorMessage.current = '';
            }
        },
        [isRequired, onChange, onError]
    );

    const handleBlur = useCallback(
        event => {
            if (
                !event?.relatedTarget?.classList.contains(styles.dropdownMenu)
            ) {
                onClose();
            }
        },
        [onClose]
    );

    const handleDropdownItemClick = useCallback(
        index => () => {
            setSelectedIndex(index);
            setSelectedLabel(items[index]?.label);
            onClose();

            if (index !== selectedIndex) {
                onValid?.(items[index].value);
                handleChange(items[index].value, index);
            }
        },

        [handleChange, items, onClose, onValid, selectedIndex]
    );

    useEffect(() => {
        const currentReference = ref?.current;

        const handleSubmit = () => {
            const inputValue = currentReference?.value;

            handleChange({ target: { value: inputValue } });
            onSubmit(inputValue, errorMessage.current);
        };

        currentReference?.addEventListener('submit', handleSubmit);

        return () => {
            currentReference?.removeEventListener('submit', handleSubmit);
        };
    }, [errorMessage, handleChange, onSubmit, ref]);

    const errorClassName = hasError || error ? styles.hasError : '';

    const disabledStyle = isDisabled ? styles.disabled : '';

    return (
        <InputWithLabel
            className={`${className} ${styles.dropdown} ${disabledStyle}`}
            isDisabled={isDisabled}
            isRequired={isRequired}
            text={label}
        >
            <Select
                isDisabled={isDisabled}
                items={items}
                onBlur={handleBlur}
                onClick={handleClick}
                ref={ref}
                value={selectedLabel}
            />

            <Input
                className={`${className} ${errorClassName} ${styles.input}`}
                isDisabled={isDisabled}
                isReadOnly={true}
                onBlur={handleBlur}
                onClick={handleClick}
                placeholder={placeholder}
                value={selectedLabel || items[selectedIndex]?.label}
            />

            <DropdownMenu
                className={styles.dropdownMenu}
                isOpen={isOpen}
                onClose={onClose}
                referenceElement={referenceElement}
            >
                {items.map(({ label, type, value }, index) => (
                    <DropdownItem
                        isSelected={false}
                        key={`${label}${index}`}
                        onClick={handleDropdownItemClick(index)}
                        text={label}
                        type={type}
                        value={value}
                    />
                ))}
            </DropdownMenu>
        </InputWithLabel>
    );
});

export default Dropdown;
