import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('addPerson');

    return {
        ADD_NEW_PERSON: t('addNewPerson')
    };
};

export default useConstants;
