import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('updateRecurrenceEndDateModal');

    return {
        CURRENT_DATE: t('currentDate'),
        UPDATE_FUTURE_ACTIVITIES: t('updateFutureActivities'),
        UPDATED_DATE: t('updatedDate')
    };
};

export default useTranslation;
