import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecActivity = (tenantSchema, activityID, userID, userGQL) => gql`
    query getActivity {
        ${tenantSchema}_activity(where: {id: {_eq: ${activityID}}}) {
            id
            start_date
            closed
            deleted
            dropdown_selections
            due_date
            group_completion_rate
            is_hierarchical
            name
            risk_label
            send_email
            linkages {
                activityByActivityId2 {
                  id
                  name
                  deleted
                }
                case {
                  id
                  case_name
                }
                log{
                    id
                    log_types {
                      log_type {
                        name
                      }
                    }
                }
              }
              linkagesByActivityId2{
                activity {
                  id
                  name
                  deleted
                }
                case {
                  id
                  case_name
                }
                log{
                    id
                    log_types {
                      log_type {
                        name
                      }
                    }
                }
              }
            activity_type {
                name
                dropdown
                risk_label
            }
            user_activities {
                status
                tenant_user {
                    id
                    ${userGQL}
                }
                group {
                  name
                  id
                }
                assignment_type
            }
            activity_attachments {
                deleted
                name
                file
                id
                uploaded_timestamp
            }
            instructions
            comments {
                created_at
                deleted
                text
                id
                user_comments {
                  tenant_user {
                      id
                      ${userGQL}
                  }
                }
            }
            activity_policies {
                document {
                    description
                    id
                    name
                    risk_label
                }
                section {
                    description
                    id
                    name
                    riskLevel
                }
            }
            activity_entities {
                entity {
                    name
                }
            }
            activity_histories {
              created_at
              message
              color
              tenant_user {
                ${userGQL}
              }
            }
            activity_forms(where: {current: {_eq:true}}) {
                form {
                    id
                    name
                    form_questions {
                        id
                        required
                        question {
                            formComponent
                            id
                            index
                            name
                            questionId
                            type
                            choices(order_by: {text: asc}) {
                              text
                            }
                        }
                        activity_form_answers(where: {activity_id: {_eq: ${activityID}}}, order_by: {id: desc}) {
                            id
                            user_id
                            question_id
                            answer
                            tenant_user{
                              ${userGQL}
                            }
                        }
                    }
                }
                assignment
                assignment_type
								id
								locked
								current
            }
            activity_recurrences(where: {activity_recurrence: {is_active: {_eq: true}}}) {
                activity_recurrence {
                    activity_type_id
                    start_date
                    group_completion_rate
                    id
                    instructions
                    is_hierarchical
                    name
                    policy_id
                    recurrence_type
                    reminder_frequency_after_due
                    reminder_frequency_before_due
                    risk_label
                    send_email
                    activity_recurrences(where: {activity: {deleted: {_eq: false}}}){
                      activity {
                        due_date
                        id
                        name
                        start_date
                        closed
                      }
                    }
                    activity_recurrence_attachments {
                      name
                      file
                      id
                    }
                    activity_recurrence_entities {
                      entity {
                        id
                        name
                      }
                    }
                    activity_recurrence_forms {
                      assignment
                      assignment_type
                      due_date
                      form {
                        name
                        id
                      }
                    }
                    activity_recurrence_policies {
                      document {
                        id
                        name
                      }
                      section {
                        id
                        name
                      }
                    }
                    user_activity_recurrences {
                      assignment_type
                      tenant_user {
                        id
                        ${userGQL}
                      }
                      group {
                        name
                        id
                      }
                    }
                  }
            }
        }
        ${tenantSchema}_activity_entity (where: {activity_id: {_eq: ${activityID}}}) {
            entity_id
        }
        ${tenantSchema}_activity_regulatory_reference (where: {activity_id: {_eq: ${activityID}}}) {
            id
            activity_id
            regulatory_reference_id
        }
        ${tenantSchema}_entity {
            id
            name
        }
        ${tenantSchema}_group_user(where: {tenant_user_id: {_eq: ${userID}}}) {
            tenant_user_id
            group_id
        }
    }
`;

const useData = (activityId, activitySchema) => {
    const { useQuerySchemas, useSchema, useUserId } = useGlobalStateHooks();

    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [userId] = useUserId();
    var userGQL = '';
    if(querySchemas.length > 0){
      for(const s of querySchemas){
          if(s.value){
              userGQL += `user_${s.value} {
                  first_name
                  last_name
              }`
          }
      }
    }else{
      if(schema){
          userGQL += `user_${schema} {
              first_name
              last_name
          }`
      }
    }

    return useQuery(querySpecActivity(activitySchema, activityId, userId, userGQL), {
        fetchPolicy: 'no-cache'
    });
};

export default useData;
