import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';
import useVisibleTo from './hooks/useVisibleTo';
import useTranslation from './hooks/useTranslations';

import AddPolicyPanel from 'components/AddPolicyPanel';
import CancelSaveButtons from 'components/CancelSaveButtons';
import WithPageTitle from 'components/WithPageTitle';

import styles from './styles.module.scss';

import normalizeData from './data';
import loadFile from 'utilities/loadFile';

const AddPolicy = () => {
    const { useSchema, useTenants, useUserId, useUserSetSchema } = useGlobalStateHooks();

    const [tenants] = useTenants();
    const [userId] = useUserId();
    const [userSetSchema] = useUserSetSchema();
    const [schema] = useSchema();

    const { ADD_NEW_POLICY } = useTranslation();

    const { DOCUMENT_MANAGEMENT_ROUTE, INTERNAL_SERVER_ERROR, POLICY_ROUTE } = useConfig();

    const [addedPolicy, addPolicy, reset] = useData();
    const [categories, setCategories] = useState([]);
    const [policyCategories, setPolicyCategories] = useState([]);
    const [sectionCategories, setSectionCategories] = useState([]);
    const [files, setFiles] = useState({})

    const [visibleTo, setVisibleTo] = useState();

    const [_errorMessage, setErrorMessage] = useState();

    const navigate = useNavigate();

    const form = useForm({
        description: '',
        documentType: '',
        effectiveDate: '',
        policyName: '',
        sectionCategories: '',
        sectionPrefix: '',
        visibleTo: ''
    });

    const { handleForm } = form;

    const handleCancel = useCallback(() => {
        navigate(DOCUMENT_MANAGEMENT_ROUTE);
    }, [DOCUMENT_MANAGEMENT_ROUTE, navigate]);

    const handleSave = useCallback(() => {
        

        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }

        const formObject = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                accumulator[key] = value;

                return accumulator;
            },
            {
                createdBy: userId,
                isActive: true,
                isDraft: true,
                isPublished: false,
                modifiedByUserId: userId,
                versionNumber: 1
            }
        );

        var newCategories = [];
        var existingCategories = [];
        var newPolicyCategories = [];
        var existingPolicyCategories = [];

        formObject['name'] = formObject['policyName'];
        formObject['effectiveDate'] = new Date(formObject['effectiveDate']);
        formObject['file'] = Object.keys(files).length > 0 ? Object.keys(files)[0] : document.getElementById('hyperlinkBox').value;
        formObject['policyCategories'] = policyCategories;
        formObject['sectionCategories'] = sectionCategories;
        for(const category of sectionCategories){
            if(category?.__isNew__){
                newCategories.push(category?.value);
            }else{
                existingCategories.push(category)
            }
        }
        for(const category of policyCategories){
            if(category?.__isNew__){
                newPolicyCategories.push(category?.value);
            }else{
                existingPolicyCategories.push(category)
            }
        }
        formObject['newCategories'] = newCategories;
        formObject['existingCategories'] = existingCategories;
        formObject['newPolicyCategories'] = newPolicyCategories;
        formObject['existingPolicyCategories'] = existingPolicyCategories;
        formObject['userSetSchema'] = userSetSchema ? userSetSchema : null;

        // formObject['sections'] = [{sectionName, sectionDescription}]
        for(const key of Object.keys(files)){
            loadFile(files[key].url);
        }
        addPolicy(formObject);
    }, [addPolicy, files, handleForm, policyCategories, sectionCategories, userId]);

    const handleFileUpload = newFiles => {
        if(Object.keys(newFiles).length > 0){
            setFiles(newFiles);
        }
    }

    const { _loading, error, data } = useVisibleTo();

    useEffect(() => {
        if (!addedPolicy) {
            return;
        }

        const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;

        navigate(POLICY_ROUTE.replace(':policyId', addedPolicy).replace(':policySchema', usedSchema));
    }, [addedPolicy, reset, POLICY_ROUTE, navigate]);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });

            setVisibleTo(normalizedData?.appliedToOptions);
            setCategories(normalizedData?.categories);
        }
    }, [data, schema, setCategories, setVisibleTo]);


    if (error) {
        console.log(error)
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    return (
        <div className={styles.addPolicy}>
            <WithPageTitle title={ADD_NEW_POLICY}>
                <CancelSaveButtons
                    onCancel={handleCancel}
                    onSave={handleSave}
                />
            </WithPageTitle>

            <AddPolicyPanel categories={categories} fileUploader={handleFileUpload} form={form} setPolicyCategories={setPolicyCategories} setSectionCategories={setSectionCategories} visibleTo={visibleTo} />
        </div>
    );
};

export default memo(AddPolicy);
