import ActionMenuItem from 'components/ActionMenuItem';
import useTranslation from './hooks/useTranslation';
import PublishIcon from 'components/icons/PublishIcon';

const UploadPolicyFromFileListItem = ({ onClick }) => {
    const { UPLOAD } = useTranslation();

    return (
        <ActionMenuItem icon={<PublishIcon />} onClick={onClick} text={UPLOAD} />
    );
};

export default UploadPolicyFromFileListItem;
