import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('lowRiskPill');

    return {
        LOW: t('low')
    };
};

export default useTranslation;
