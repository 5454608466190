import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('documentManagementTableHeader');

    return {
        CATEGORIES: t('categories'),
        COMPANY: t('company'),
        CURRENT_VERSION: t('currentVersion'),
        EFFECTIVE_DATE: t('effectiveDate'),
        POLICY_NAME: t('policyName'),
        STATUS: t('status'),
        VISIBILITY: t('visibility')
    };
};

export default useTranslation;
