import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { deleteSectionModal } = state;

const { deleteSectionModalAtom } = deleteSectionModal.atoms;

const MUTATE_DELETE_SECTION = tenantSchema => gql`
mutation deleteSection($policyId: Int!, $id: Int!) {
    update_${tenantSchema}_policy_sections(where: {policy_id: {_eq: $policyId}, section_id: {_eq: $id}}, _set: {deleted: true}) {
      returning {
        id
      }
    }
  }
`;

// const MUTATE_POLICY_HISTORY = tenantSchema => gql`
//     mutation insert_activity_history_deleted($activityId: Int!, $color: String = "red", $message: String = "deleted the activity", $userId: Int!) {
//         insert_${tenantSchema}_activity_history(
//             objects: {
//                 activity_id: $activityId,
//                 color: $color,
//                 message: $message,
//                 user_id: $userId
//             }
//         ) {
//             returning {
//                 id
//             }
//         }
//     }  
// `;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_DELETE_SECTION, providedSchema);
    // const mutationPolicyHistory = useGraphQL(MUTATE_POLICY_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const removeSection = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);
            // await mutationPolicyHistory(variables);

            const id = res?.data[`update_${usedSchema}_policy_sections`].returning[0]?.id;

            set(deleteSectionModalAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(deleteSectionModalAtom, '');
    });

    return [useRecoilValue(deleteSectionModalAtom), removeSection, reset];
};

export default useData;
