import { useCallback } from 'react'; 

import ActivitiesHeader from 'components/ActivitiesHeader';
import ContentPanel from 'components/ContentPanel';
import SummarizedActivities from 'components/SummarizedActivities';

import styles from './styles.module.scss';
import CheckPermissions from 'components/CheckPermissions';
import CheckMissingPermissions from 'components/CheckMissingPermissions';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const ActivitiesPanel = ({ className = '' }) => {
    const {useIsAllFilter, useIsFlaggedFilter} = useGlobalStateHooks();

    const [isAll, setIsAll] = useIsAllFilter();
    const [isFlagged, setIsFlagged] = useIsFlaggedFilter();

    const handleToggle = useCallback(isOn => {
        setIsAll(!isOn);
    }, [setIsAll]);

    const handleFlaggedToggle = useCallback(isOn => {
        setIsFlagged(!isOn);
    }, [setIsFlagged]);

    return (
        <ContentPanel className={`${className} ${styles.activitiesPanel}`}>
            <ActivitiesHeader isAll={isAll} isFlagged={isFlagged} onFlaggedToggle={handleFlaggedToggle} onToggle={handleToggle} />

            <CheckPermissions permission={'activity_view'}>
                <SummarizedActivities isAll={isAll} isFlagged={isFlagged} />
            </CheckPermissions>

            <CheckMissingPermissions
                permission={
                    [
                        'activity_add',
                        'activity_delete',
                        'activity_edit',
                        'activity_view'
                    ]
                }
                text={'activity'}
            />
        </ContentPanel>
    );
};

export default ActivitiesPanel;
