import Pill from 'components/Pill';

import styles from './styles.module.scss';

import useTranslation from './hooks/useTranslation';

const UserPill = ({ className = '', label='' }) => {
    const { USER } = useTranslation();

    if (label===''){
        return <Pill className={`${styles.userPill} ${className}`} label={USER} />;
    } else {
        return <Pill className={`${styles.userPill} ${className}`} label={label} />;
    }
};

export default UserPill;
