// import { useSetRecoilState } from 'recoil';
// import { useEffect, useState } from 'react';

// import { memo } from 'react';

// import state from 'app/state';

// import getFile from 'utilities/getFile';

import Initials from 'components/Initials';

// import { altText } from './constants';

import styles from './styles.module.scss';

// const { avatars } = state;

// const { avatarsAtom } = avatars.atoms;

const Avatar = ({ className = '', user }) => {
    // const [imageObject, setImageObject] = useState(avatarsAtom);
    // const [image, setImage] = useState()

    // useEffect(() => {
    //     async function getAvatar(){
    //         if (user && user?.id && !user?.name.includes('Group')){
    //             if (imageObject && user?.id in imageObject){
    //                 return imageObject[user?.id];
    //             }
    //             const directoryName = "userImages";
    //             const fileName = `${user?.id}.png`;
    //             const urlString = await getFile(directoryName, fileName);
    //             if (urlString){
    //                 const newObject = {}
    //                 newObject[user?.id] = urlString;
    //                 const newImageObject = Object.assign(imageObject, newObject);
    //                 setImageObject(newImageObject);
    //             }
    //             return urlString;
    //         }
    //     }
    //     async function setAvatar(){
    //         const avatar = await getAvatar();
    //         setImage(avatar)
    //     }
    //     setAvatar();
    // }, [imageObject, setImage, setImageObject, user]);

    const indicatorClass = user?.positive ? styles.positiveIndicator
                        : user?.negative ? styles.negativeIndicator
                        : user?.warning ? styles.warningIndicator
                        : styles.neutralIndicator;
    
    return (
    <span className={`${className} ${styles.avatarContainer}`}>
        <div className={styles.avatar}>
            {/* {image ? (
                <img alt={altText} className={styles.image} src={image} />
            ) : ( */}

                <Initials text={user?.name || 'N A'} />

            {/* )} */}
        </div>

        <div className={`${styles.indicator} ${indicatorClass}`}>
            <span>
                {user?.positive ? "✔"
                : user?.negative ? "X"
                : user?.warning ? "!"
                : ""}
            </span>
        </div>
    </span>
)};

export default Avatar;
