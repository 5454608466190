import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('withdrawButton');

    return {
        WITHDRAW: t('withdraw')
    };
};

export default useTranslation;
