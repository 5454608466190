import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { activityMetaPanel } = state;

const { updateActivityEntityAtom } = activityMetaPanel.atoms;

const MUTATE_DELETE_OLD_ENTITIES = tenantSchema => gql`
    mutation deleteActivityEntities($activityId: Int!) {
        delete_${tenantSchema}_activity_entity(where: {activity_id: {_eq: $activityId}}) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_INSERT_NEW_ENTITIES = tenantSchema => gql`
    mutation insertActivityEntity(
        $activityId: Int!,
        $entityId: Int!,
    ) {
        insert_${tenantSchema}_activity_entity(objects: {
            activity_id: $activityId, 
            entity_id: $entityId
        }) {
            returning {
                entity_id
            }
        }
    }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insertActivityHistoryDropdown($activityId: Int!, $color: String = "yellow", $message: String = "changed the entities", $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutationDeleteOldEntities = useGraphQL(MUTATE_DELETE_OLD_ENTITIES, providedSchema);
    const mutationInsertNewEntities = useGraphQL(MUTATE_INSERT_NEW_ENTITIES, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveEntities = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if(variables?.text !== ''){
                await mutationDeleteOldEntities(variables);
                
                const newEntities = []
                for (const selection of variables?.entities){
                    variables['entityId'] = selection;
                    
                    const res = await mutationInsertNewEntities(variables);
                    newEntities.push(res?.data[`insert_${usedSchema}_activity_entity`]?.returning[0].entity_id);
                }

                await mutationActivityHistory(variables);

                set(updateActivityEntityAtom, newEntities);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateActivityEntityAtom, '');
    });

    return [useRecoilValue(updateActivityEntityAtom), saveEntities, reset];
};

export default useData;
