const translations = {
    activityAssignments: 'Activity Assignments',
    activityName: 'Activity Name',
    assignment: 'Assignement',
    assignmentType: 'Assignment Type',
    createdBy: 'Created By',
    formName: 'Form Name',
    isActive: 'Is Active',
    notes: 'Notes'
};

export default translations;
