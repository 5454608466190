import BodyText from 'components/BodyText';
import Buttonize from 'components/Buttonize';

import styles from './styles.module.scss';

const ActionMenuItem = props => {
    const { className = '', icon, onClick, text } = props;

    return (
        <Buttonize
            className={`${className} ${styles.actionMenuItem}`}
            onClick={onClick}
        >
            {icon}

            <BodyText text={text} />
        </Buttonize>
    );
};

export default ActionMenuItem;
