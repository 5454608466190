export function computeQuestionWithAnswers({ forms, answers }) {
    return Array.isArray(forms?.questions) && forms?.questions.length > 0
        ? forms?.questions.reduce((accumulator, question) => {
              const {
                  choices,
                  formComponent,
                  id,
                  index,
                  name,
                  questionId,
                  required,
                  type
              } = question;
              const answerQuestions = answers?.questions.filter(function (e) {
                  return e.id === id;
              })[0]?.answers;
              const answer =
                  answerQuestions[answerQuestions.length - 1]?.questionAnswer;
              const answerUser =
                  answerQuestions[answerQuestions.length - 1]?.answerUser;
              accumulator.push({
                  answer,
                  answerUser,
                  choices,
                  formComponent,
                  id,
                  index,
                  name,
                  questionId,
                  required,
                  type
              });
              return accumulator;
          }, [])
        : [];
}
