import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = (tenantSchema) => gql`
query getGroups {
  	${tenantSchema}_group {
        id
        name
    }
    ${tenantSchema}_department {
        id
        description
    }
    ${tenantSchema}_position {
        id
        title
    }
  }
`;

const useGroupsAndRoles = policyId => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(query(schema, policyId), {
        fetchPolicy: 'no-cache'
    });
};

export default useGroupsAndRoles;