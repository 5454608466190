import { useState, useEffect } from 'react';

import AddRequestButton from 'components/AddRequestButton';
import MarketingRequestsPanel from 'components/MarketingRequestsPanel';
import WithPageTitle from 'components/WithPageTitle';
import WithDownloadButtonMarketing from 'components/WithDownloadButtonMarketing';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useTranslation from './hooks/useTranslation';

import useData from './hooks/useData';
import useDataQuestionsAnswers from './hooks/useDataQuestionsAnswers';

import styles from './styles.module.scss';

const MarketingRequests = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const { loading, error, data } = useData();
    const { data: dataQuestionsAnswers } = useDataQuestionsAnswers();
    const [marketingData, setMarketingData] = useState();

    const { MARKETING_REVIEW } = useTranslation();
    
    if(loading){}
    if(error){}

    useEffect(() => {
        if(data){
            const dataArray = data[`${schema}_marketing_request`]
            if (Array.isArray(dataArray)) {
                setMarketingData(dataArray);
            }
        }
    }, [data, schema, setMarketingData]);

    useEffect(() => {
    }, [marketingData])

    return (
        <div className={styles.case}>
            <WithPageTitle title={MARKETING_REVIEW}>
                {marketingData &&
                    <WithDownloadButtonMarketing data={marketingData} dataQuestionsAnswers={dataQuestionsAnswers} name={'Marketing'} schema={schema}>
                        <AddRequestButton className={styles.addRequestButton} />
                    </WithDownloadButtonMarketing>
                }
            </WithPageTitle>

            <MarketingRequestsPanel />
        </div>
    );
};

export default MarketingRequests;
