import { useEffect, useState } from 'react';

import DocumentManagementSideLetterPanel from 'components/DocumentManagementSideLetterPanel';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useSideLetters from './hooks/useSideLetters';
import useData from './hooks/useData';
import usePermissions from './hooks/usePermissions';

import normalizeData from './data';
import normalizePermissions from './permissions';
import Loader from 'components/Loader';

const DocumentManagementSideLetterTab = () => {
    const { useSchema, useTenants, useQuerySchemas } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [querySchemas] = useQuerySchemas();

    const [sideLetters, setSideLetters] = useSideLetters();
    const { loading, data } = useData();
    const { loading: permissionsLoading, data: permissions } = usePermissions();

    // undefined, 'default', 'salus'
    const [upsell, setUpsell] = useState('default');

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema,
                tenants
            });

            setSideLetters(normalizedData);
        }
    }, [data, querySchemas, schema, setSideLetters, tenants]);

    useEffect(() => {
        if (permissions) {
            const normalizedPermissions = normalizePermissions({
                data: permissions,
                querySchemas,
                schema,
                tenants
            });

            if (normalizedPermissions.includes('salus')) {
                setUpsell('salus');
            } else if (normalizedPermissions.includes('default')) {
                setUpsell('default');
            } else {
                setUpsell(undefined);
            }
        }
    }, [permissions, querySchemas, schema, setUpsell, tenants]);

    if (permissionsLoading || upsell === 'default') {
        return (
            <Loader
                text={
                    <span>
                        <a href="mailto:brian@skematic.com">Contact us</a> to
                        learn more about our Side Letter offering
                    </span>
                }
            />
        );
    }

    if (upsell === 'salus') {
        return (
            <Loader
                text={
                    'Side letters are not enabled on Beacon. Please contact an admin if needed.'
                }
            />
        );
    }

    if(tenants && tenants.length===1){
        return <DocumentManagementSideLetterPanel items={sideLetters} loading={loading} />;
    }else{
        return <Loader text={'Please select only one tenant to view a list of documents.'} />
    }
};

export default DocumentManagementSideLetterTab;
