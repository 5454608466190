import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) =>
    data[`${schema}_case_type`].reduce(
        (accumulator, type) => {
            accumulator.push({
                formId: type.form_id || null,
                label: type.name,
                schema,
                value: type.id,
            });

            return accumulator;
        }, []);

const normalizeTypeData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeTypeData;