import normalizeListData from "utilities/normalizeListData";

const countCaseTypes = caseTypes =>
    caseTypes.reduce((accumulator, { caseTypeByCaseType }) => {
        const { name } = caseTypeByCaseType;

        if (!(name in accumulator)) {
            accumulator[name] = 0;
        }

        accumulator[name]++;

        return accumulator;
    }, {});

const schemaData = ({ data, schema }) => {
    const caseTypes = data[`${schema}_case`];

    if (caseTypes.length === 0) {
        return [];
    }

    function getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.round(Math.random() * 15)];
        }
        return color;
    }

    const backgroundColors = ['#00AB55', '#2D99FF', '#FFE700', '#826AF9'];
    const textColors = ['#fff', '#fff', '#000', '#fff'];

    const typeCount = countCaseTypes(caseTypes);

    const newData = [];

    for (const [i, [key, value]] of Object.entries(Object.entries(typeCount))) {
        newData.push({
            color: (i < backgroundColors.length) ? backgroundColors[i] : getRandomColor(),
            text: key,
            textColor: (i < textColors.length) ? textColors[i] : textColors[0],
            value: Number(value)
        });
    }

    return newData;
};

const normalizeData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeData;
