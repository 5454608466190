import { useCallback, useEffect, useState } from 'react';

// import BodyText from 'components/BodyText';
import Buttonize from 'components/Buttonize';
import DeleteIcon from 'components/icons/DeleteIcon';
// import ImportantText from 'components/ImportantText';
import TableRow from 'components/TableRow';

import isFunction from 'utilities/isFunction';
import styles from './styles.module.scss';
// import HtmlContent from 'components/HtmlContent';
import EditableImportantTextInRow from 'components/EditableImportantTextInRow';
import EditableEditor from 'components/EditableEditor';

import useAddCategory from './hooks/useAddCategory';
import useRemoveCategory from './hooks/useRemoveCategory';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useSectionName from './hooks/useSectionName';
import useSectionDescription from './hooks/useSectionDescription';
import EditableMultiSelectWithAddNew from 'components/EditableMultiSelectWithAddNew';

const PolicyMappingTableRow = props => {
    const { id, name, categories, description, onDelete, riskLevel, selectedCategories, updateSection } = props;

    const { useSchema, useTenants, useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();
    const [_schema] = useSchema();
    const [_tenants] = useTenants();

    const [_errorMessage, setErrorMessage] = useState();
    const [displayedSelectedCategories, setDisplayedSelectedCategories] = useState(selectedCategories);
    const [updatedCategories, updateCategories] = useState(selectedCategories);

    const [savedSectionName, saveSectionName, resetSectionName] = useSectionName();
    const [savedSectionDescription, saveSectionDescription, resetSectionDescription] = useSectionDescription();
    const [addCategory] = useAddCategory();
    const [removeCategory] = useRemoveCategory();

    const handleDelete = useCallback(() => {
        if (isFunction(onDelete)) {
            onDelete({description, id, name, riskLevel});
        }
    }, [description, id, name, onDelete, riskLevel]);

    const handleSectionNameSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    sectionId: id,
                    userId: userId
                }
            );

            saveSectionName(formObject);
        },
        [id, saveSectionName, userId]
    );

    useEffect(() => {
        if (!savedSectionName) {
            return;
        }

        if(id===savedSectionName?.id){
            updateSection({
                id: savedSectionName?.id,
                text: savedSectionName?.newName,
                type: 'name'
            });
        }
        resetSectionName();
    }, [id, resetSectionName, savedSectionName, updateSection]);

    const handleSectionCategoriesSave = () => {
        const isSameCategory = (a, b) => a.value === b.value && a.label === b.label;
        const onlyInLeft = (left, right, compareFunction) => 
            left.filter(leftValue =>
                !right.some(rightValue => 
                    compareFunction(leftValue, rightValue)));
        const removedCategory = onlyInLeft(selectedCategories, updatedCategories, isSameCategory);
        removeCategory({removedCategory, sectionId:id})
        const addedCategory = onlyInLeft(updatedCategories, selectedCategories, isSameCategory);
        var newCategories = [];
        var existingCategories = [];
        for(const category of addedCategory){
            if(category?.__isNew__){
                newCategories.push(category?.value);
            }else{
                existingCategories.push(category)
            }
        }
        addCategory({existingCategories, newCategories, sectionId:id})

        setDisplayedSelectedCategories(updatedCategories);
    };

    const handleSectionDescriptionSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    sectionId: id,
                    userId: userId
                }
            );

            saveSectionDescription(formObject);
        },
        [id, saveSectionDescription, userId]
    );

    useEffect(() => {
        if (!savedSectionDescription) {
            return;
        }

        if(id===savedSectionDescription?.id){
            updateSection({
                id: savedSectionDescription?.id,
                text: savedSectionDescription?.newDescription,
                type: 'description'
            });
        }
        resetSectionDescription();
    }, [id, resetSectionDescription, savedSectionDescription, updateSection]);

    return (
        <TableRow className={styles.policyMappingTableRow}>
            <EditableImportantTextInRow
                label={''}
                onSave={handleSectionNameSave}
                text={name}
            />

            <EditableEditor
                className={styles.description}
                inTableRow={true}
                onSave={handleSectionDescriptionSave}
                outline={false}
                sanitizeHtml={false}
                text={description}
            />

            <EditableMultiSelectWithAddNew
                className={styles.categories}
                inTableRow={true}
                items={displayedSelectedCategories}
                onSave={handleSectionCategoriesSave}
                options={categories}
                setModified={updateCategories}
                title={'Categories'}
            />

            <Buttonize
                className={`${styles.buttonize}`}
                onClick={handleDelete}
            >
                <DeleteIcon />,
            </Buttonize>
        </TableRow>
    );
};

export default PolicyMappingTableRow;
