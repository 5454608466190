import StandoutText from 'components/StandoutText';
import StatisticsText from 'components/StatisticsText';

import styles from './styles.module.scss';

const CountPanel = ({ className = '', count = 0, text = '' }) => (
    <div className={`${className} ${styles.countPanel}`}>
        <StatisticsText text={count} />

        <StandoutText text={text} />
    </div>
);

export default CountPanel;
