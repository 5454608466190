import { useCallback, useEffect, useMemo, useState } from 'react';

import Buttons from 'components/Buttons';
import HollowButton from 'components/HollowButton';
import SaveButton from 'components/SaveButton';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';

import styles from './styles.module.scss';
import useTranslation from './hooks/useTranslation';
import Table from 'components/Table';
import TableHeader from 'components/TableHeader';
import TableRow from 'components/TableRow';
import formatDate from 'utilities/formatDate';

const UpdateRecurrenceEndDateModal = ({ dateDiff, onClose, recurrences }) => {
    const { CURRENT_DATE, UPDATED_DATE, UPDATE_FUTURE_ACTIVITIES } = useTranslation();
    const [updatedActivities, updateActivities, reset] = useData();

    const [sortBy, setSortBy] = useState(null);

    const headerItems = useMemo(
        () => [
            {
                isSortable: false,
                key: 'current_date',
                text: CURRENT_DATE
            },
            {
                isSortable: false,
                key: 'arrow',
                text: ''
            },
            {
                isSortable: false,
                key: 'updated_date',
                text: UPDATED_DATE
            }
        ],
        [CURRENT_DATE, UPDATED_DATE]
    );

    const handleClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                return onClose(event);
            }
        },
        [onClose]
    );

    const handleSaveFuture = useCallback(() => {
        const formObject = {all:false, dateDiff, recurrences};
        updateActivities(formObject);
    }, [dateDiff, recurrences, updateActivities]);

    useEffect(() => {
        if (!updatedActivities) {
            return;
        }

        if (isFunction(onClose)) {
            onClose();
            reset();
        }
    }, [updatedActivities, onClose, reset]);

    return (
        <Modal isOpen={true} onClose={handleClose} overlayClose={false}>
            <PanelBody>
                <PanelTitle className={styles.panelTitle} text={UPDATE_FUTURE_ACTIVITIES} />

                <Table>
                    <TableHeader
                        className={styles.updateDatesHeader}
                        items={headerItems}
                        onSort={setSortBy}
                        sortBy={sortBy}
                    />

                    {recurrences && recurrences.map((recurrence, index) => {
                        const currentDueDate = new Date(recurrence?.dueDate)
                        const newDueDate = new Date(currentDueDate.getTime() + parseInt(dateDiff)*24*60*60*1000)
                        return(
                            <TableRow className={styles.updateDatesRow} key={`row_${index}`} >
                                <div>{formatDate(currentDueDate)}</div>

                                <div>&#x2192;</div>

                                <div>{formatDate(newDueDate)}</div>
                            </TableRow>
                        )
                    })}
                </Table>

                <Buttons>
                    <SaveButton
                        onClick={handleSaveFuture}
                        saveText={"Update Future"}
                    />

                    <HollowButton
                        onClick={handleClose}
                        text={"Do Not Update"}
                    />
                </Buttons>
            </PanelBody>
        </Modal>
    );
};

export default UpdateRecurrenceEndDateModal;
