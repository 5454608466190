import AdminGroupForm from 'components/AdminGroupForm';
import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';
import DeleteButton from 'components/DeleteButton';
import SaveButton from 'components/SaveButton';
import WithPageTitle from 'components/WithPageTitle';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslations';

import styles from './styles.module.scss';

const EditGroup = () => {
    const { group, supervisorOptions } = useData();

    const { EDIT_GROUP } = useTranslation();

    return (
        <div className={styles.editGroup}>
            <WithPageTitle title={EDIT_GROUP}>
                <Buttons>
                    <CancelButton />

                    <DeleteButton />

                    <SaveButton />
                </Buttons>
            </WithPageTitle>

            <AdminGroupForm
                initialData={group}
                supervisorOptions={supervisorOptions}
            />
        </div>
    );
};

export default EditGroup;
