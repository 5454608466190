import { useCallback, useRef, useState } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { PublicClientApplication } from "@azure/msal-browser";

import { useNavigate } from 'react-router-dom';

import ContentPanel from 'components/ContentPanel';
import ErrorMessage from 'components/ErrorMessage';
import PanelBody from 'components/PanelBody';
import SubmitButton from 'components/SubmitButton';
import UsernameInput from 'components/UsernameInput';
import LoginHeader from 'components/LoginHeader';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';

import handleSubmitOnEnter from 'utilities/handleSubmitOnEnter';
import httpPost from 'utilities/httpPost';

import useAuth from './hooks/useAuth';
import translations from './constants';

import styles from './styles.module.scss';

import './state';

const LoginPanel = ({ className = '' }) => {
    const { API_KEY, AUTH_SSO_URL, LOGIN_TYPE_URL, LOGIN_SSOS_URL, LOGIN_USERNAME_BY_EMAIL_URL, HOME } = useConfig();

    const [_, setAuth] = useAuth();

    const navigate = useNavigate();

    const { LOGIN, LOGIN_DESCRIPTION, SUBMIT } = translations();

    const usernameRef = useRef();

    const [errorMessage, setErrorMessage] = useState();

    const { handleChange, handleError, handleForm, handleSubmit, handleValid } =
        useForm({
            site_prefix: window.location.origin,
            username: ''
        });
    async function check_ssos(){
        const sso_options = await httpPost({key: API_KEY ? API_KEY : '', url:LOGIN_SSOS_URL});
        const ssos = sso_options?.ssos ? JSON.parse(sso_options.ssos) : [];
        for(const sso of ssos){
            const { aad_tenant_id: aadTenantId, client_id: clientId, issuer, sso_provider:provider } = sso;
            async function auth(email) {
                const formData = new FormData();
                formData.append("email", email);
                const user = await httpPost({data: formData, key: API_KEY ? API_KEY : '', url:LOGIN_USERNAME_BY_EMAIL_URL});
                const username = user?.username;
                const url = `${AUTH_SSO_URL}?username=${username}&authenticated=true`;
                const {
                    access_token: accessToken,
                    refresh_token: refreshToken
                } = await httpPost({key:API_KEY ? API_KEY : '', url});
        
                if (accessToken && refreshToken) {
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    navigate(HOME);
                }else{
                    // navigate(LOGIN);
                }
            }
            if(provider === 'okta'){
                const oktaAuth = new OktaAuth({
                    clientId,
                    issuer,
                    pkce: true,
                    redirectUri: window.location.origin + "/login/callback",
                });
        
                const authenticate = async() => {
                    const loggedIn = await oktaAuth.getUser();
                    auth(loggedIn?.email);
                }
                authenticate();
            }else if(provider === 'aad'){
                const msalConfig = {
                    auth: {
                        authority: `https://login.microsoftonline.com/${aadTenantId}`,
                        clientId,
                        postLogoutRedirectUri: '/login',
                        redirectUri: window.location.origin + "/login/callback",
                    },
                    scopes: [
                        "api.access", "User.Read", "User.ReadBasic.All", "email"
                    ]
                };
                
                const msalInstance = new PublicClientApplication(msalConfig);
        
                async function login(){
                    const loginResponse = await msalInstance.ssoSilent(msalConfig.scopes);
                    const loggedInEmail = loginResponse?.account?.username
                    auth(loggedInEmail);
                }
                login()
            }
        }
    }
    check_ssos()

    const handleLogin = useCallback(async () => {
        const event = new Event('submit', { bubbles: true });

        usernameRef.current.dispatchEvent(event);

        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        
        const { aadTenantId, client_id: clientId, issuer, provider, login_type: loginType, user_email: userEmail, username } = await httpPost({
            data: output,
            key: API_KEY ? API_KEY : '',
            url: LOGIN_TYPE_URL
        });

        localStorage.setItem('loginType', '');

        setAuth(loginType);
        if (loginType==='sso'){
            localStorage.setItem('userEmail', userEmail);
            localStorage.setItem('username', username);
            localStorage.setItem('loginType', 'sso');
            localStorage.setItem('ssoProvider', provider);
            localStorage.setItem('ssoIssuer', issuer);
            localStorage.setItem('ssoClientId', clientId);
            localStorage.setItem('aadTenantId', aadTenantId);
            navigate('/login/sso');
        }else{
            localStorage.setItem('loginType', 'magicLink');
            navigate('/login/authenticate');
        }
    }, [API_KEY, LOGIN_TYPE_URL, handleForm, navigate, setAuth]);

    return (
        <ContentPanel className={`${className} ${styles.loginPanel}`}>
            <PanelBody className={styles.panelBody}>
                <LoginHeader subtext={LOGIN_DESCRIPTION} text={LOGIN} />

                <UsernameInput
                    className={styles.textInput}
                    isRequired={false}
                    onChange={handleChange}
                    onError={handleError('username')}
                    onKeyUp={handleSubmitOnEnter(handleLogin)}
                    onSubmit={handleSubmit('username')}
                    onValid={handleValid('username')}
                    ref={usernameRef}
                />

                <ErrorMessage
                    className={styles.errorMessage}
                    text={errorMessage}
                />

                <SubmitButton
                    className={styles.submitButton}
                    onClick={handleLogin}
                    text={SUBMIT}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default LoginPanel;
