import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('closeAndAddLogEntryMenuListItem');

    return {
        ADD_LOG_ENTRY: t('addLogEntry'),
        CLOSE_AND_ADD_LOG_ENTRY: t('closeAndAddLogEntry'),
    };
};

export default useTranslation;
