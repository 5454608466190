import { useCallback } from 'react';

import HeaderText from 'components/HeaderText';
import SortArrow from 'components/SortArrow';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const SortableHeader = ({
    className = '',
    isSorted,
    isAscending,
    onSort,
    sortKey,
    text
}) => {
    const sortHandler = useCallback(
        sortByKey => {
            if (isFunction(onSort)) {
                onSort({
                    isAscending: isSorted ? !isAscending : true,
                    key: sortByKey
                });
            }
        },
        [isAscending, isSorted, onSort]
    );

    return (
        <div className={`${className} ${styles.sortableHeader}`}>
            <HeaderText text={text} />

            <SortArrow
                className={`${styles.sortArrow} ${
                    isSorted ? styles.activeSortArrow : ''
                }`}
                isAscending={isSorted ? isAscending : true}
                onClick={() => sortHandler(sortKey)}
            />
        </div>
    );
};

export default SortableHeader;
