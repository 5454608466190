import DownloadButton from 'components/DownloadButton';

import downloadBlob from 'utilities/downloadBlob';
import parseDetailedDataForReport from './parseDetailedDataForReport';
import generateDetailedXLSX from 'utilities/generateDetailedXLSX';

import styles from './styles.module.scss';

const WithDownloadButtonMarketing = props => {
    const { className = '', children, data, dataQuestionsAnswers, name, schema } = props;

    const filename = `${name}_${new Date().toISOString().split('T')[0]}`

    const onPdfExport = () => {
        const downloadExcel = async () => {
            const sheets = parseDetailedDataForReport(data, dataQuestionsAnswers, schema);
            if (sheets.length === 0) return;
            const buffer = await generateDetailedXLSX(sheets);
            const blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            downloadBlob(blob, `${filename}.xlsx`);
        }
        downloadExcel();
    }

    return (
        <div className={`${className} ${styles.withDownloadButton}`}>
            <DownloadButton
                className={styles.downloadButton}
                onPdfExport={onPdfExport}
            />

            {children}
        </div>
    );
};

export default WithDownloadButtonMarketing;
