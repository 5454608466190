import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CancelSaveButtons from 'components/CancelSaveButtons';
import ContentPanel from 'components/ContentPanel';
import MarketingRequestForm from 'components/MarketingRequestForm';
import PanelBody from 'components/PanelBody';
import WithPageTitle from 'components/WithPageTitle';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import useVariableForm from 'hooks/useVariableForm';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

import useData from './hooks/useData';

import {
    useGetAppMarketingSettings
} from 'hooks/useAppMarketingSettings';

import { computeQuestionWithAnswers } from './data';
import { useGetNewMarketingFormData } from './hooks/useMarketingForm';

const AddMarketingRequest = () => {
    const { useSchema, useUserSetSchema, useUserId } =
        useGlobalStateHooks();
    const [schema] = useSchema();
    const [userId] = useUserId();
    const [userSetSchema] = useUserSetSchema();
    const { MARKETING_SUBMISSION, SUBMIT_REQUEST } = useTranslation();

    const { HOME, MARKETING_REQUEST_ROUTE } = useConfig();

    const navigate = useNavigate();

    const [_errorMessage, setErrorMessage] = useState();

    const [request, saveRequest, reset] = useData();
    const { data: settings } = useGetAppMarketingSettings();

    const [files, setFiles] = useState({});

    const formData = useGetNewMarketingFormData({
        formId: settings.marketing_form,
        schema
    });

    const { forms, answers } = formData ?? {};
    const questionsWithAnswers = computeQuestionWithAnswers({ answers, forms });

    const form = useForm({
        description: '',
        dropdown0: '',
        dropdown1: '',
        dropdown2: '',
        dropdown3: '',
        dropdown4: '',
        dropdown5: '',
        dropdown6: '',
        dueDate: '',
        expectedUseDate: '',
        highImportance: '',
        recipient: '',
        requestName: ''
    });
    const { handleForm } = form;
    const variableForm = useVariableForm();
    const { handleForm: handleVariableForm } = variableForm;
    const {
        handleError: handleVariableError,
        handleSubmit: handleVariableSubmit,
        handleValid: handleVariableValid,
        handleValidPre: handleVariableValidPre
    } = variableForm;

    const handleCancel = useCallback(() => {
        navigate(HOME);
    }, [HOME, navigate]);

    const handleSave = useCallback(() => {
        const output = handleForm();
        const variableOutput = handleVariableForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }

        const formObject = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                accumulator[key] = value;

                return accumulator;
            },
            {
                description: '',
                dropdown0: '',
                dropdown1: '',
                dropdown2: '',
                dropdown3: '',
                dropdown4: '',
                dropdown5: '',
                dropdown6: '',
                highImportance: '',
                recipient: '',
                requestName: ''
            }
        );

        if (typeof variableOutput === 'string') {
            setErrorMessage(variableOutput);

            return;
        }

        const variableFormObject = {};
        const answers = Array.from(variableOutput.entries()).reduce(
            (accumulator, [key, value]) => {
                if (key.includes('_')) {
                    const checkboxKey = parseInt(key.split('_')[0]);
                    const checkboxIndex = parseInt(key.split('_')[1]);
                    if (value) {
                        accumulator.push({
                            answer: checkboxIndex,
                            questionId: checkboxKey,
                            value
                        });
                    }
                } else {
                    accumulator.push({
                        answer: value,
                        questionId: parseInt(key)
                    });
                }

                return accumulator;
            },
            []
        );
        const finalizedAnswerQuestions = questionsWithAnswers.filter(
            question => question.type !== 'checkBoxInput'
        );
        const finalizedAnswerQuestionsIds = finalizedAnswerQuestions.reduce(
            (accumulator, question) => {
                accumulator.push(question?.id);
                return accumulator;
            },
            []
        );
        const finalizedAnswers = answers.filter(answer =>
            finalizedAnswerQuestionsIds.includes(answer?.questionId)
        );
        const checkboxAnswers = answers.filter(
            answer => !finalizedAnswerQuestionsIds.includes(answer?.questionId)
        );
        const checkboxArrayAnswers = checkboxAnswers.reduce(
            (accumulator, answer) => {
                const answerArray = Array.isArray(
                    accumulator[answer?.questionId]
                )
                    ? [...accumulator[answer?.questionId]]
                    : [];
                if (answer?.value === 'true') {
                    answerArray.push(answer?.answer);
                }
                accumulator[answer?.questionId] = answerArray;
                return accumulator;
            },
            {}
        );

        const accumulatedAnswers = Object.entries(checkboxArrayAnswers).reduce(
            (accumulator, answer) => {
                accumulator.push({
                    answer: answer[1].toString(),
                    questionId: answer[0]
                });
                return accumulator;
            },
            []
        );

        variableFormObject['answers'] = [
            ...finalizedAnswers,
            ...accumulatedAnswers
        ];
        variableFormObject['formId'] = forms?.formId;
        variableFormObject['userId'] = parseInt(userId);

        formObject.userId = userId;
        formObject.status = 'draft';
        formObject.dueDate = new Date(formObject.dueDate);
        formObject.expectedUseDate = new Date(formObject.expectedUseDate);
        formObject.file = Object.keys(files).join(', ');
        formObject.highImportance =
            formObject.highImportance === '' ? false : true;
        formObject.assignedTo = settings?.marketing_default_assigned_to;
        formObject.dropdownSelections = [
            formObject.dropdown0,
            formObject.dropdown1,
            formObject.dropdown2,
            formObject.dropdown3,
            formObject.dropdown4,
            formObject.dropdown5,
            formObject.dropdown6
        ];
        formObject.variableForm = variableFormObject;
        const selectionSliceIndex = settings?.marketing_dropdowns
            ? settings?.marketing_dropdowns.length
            : 0;
        const selectionDeleteCount =
            formObject.dropdownSelections.length - selectionSliceIndex;
        formObject.dropdownSelections.splice(
            selectionSliceIndex,
            selectionDeleteCount
        );
        saveRequest(formObject);
    }, [
        handleForm,
        handleVariableForm,
        files,
        forms,
        questionsWithAnswers,
        settings,
        saveRequest,
        userId
    ]);

    const handleFileUpload = newFiles => {
        if (Object.keys(newFiles).length > 0) {
            setFiles(newFiles);
        }
    };

    useEffect(() => {
        const filteredQuestions = questionsWithAnswers.filter(
            question => question?.type === 'checkBoxInput'
        );
        for (const question of filteredQuestions) {
            const answerArray = question?.answer
                ? question?.answer.split(',').map(x => parseInt(x))
                : [];
            for (const answer of answerArray) {
                handleVariableValidPre(`${question?.id}_${answer}`, 'true');
            }
        }
        //eslint-disable-next-line
    }, [questionsWithAnswers]);

    useEffect(() => {
        if (!request) {
            return;
        }

        const redirectSchema = userSetSchema ? userSetSchema : schema
        navigate(MARKETING_REQUEST_ROUTE.replace(':requestId', request).replace(':requestSchema', redirectSchema));

        reset();
    }, [MARKETING_REQUEST_ROUTE, navigate, reset, request, schema]);

    return (
        <div className={styles.addMarketingRequest}>
            <WithPageTitle title={MARKETING_SUBMISSION}>
                <CancelSaveButtons
                    onCancel={handleCancel}
                    onSave={handleSave}
                    saveText={SUBMIT_REQUEST}
                />
            </WithPageTitle>

            <ContentPanel>
                <PanelBody>
                    <MarketingRequestForm
                        dropdowns={settings?.marketing_dropdowns}
                        fileUploader={handleFileUpload}
                        form={form}
                        forms={forms}
                        handleForm={handleVariableForm}
                        handleVariableError={handleVariableError}
                        handleVariableSubmit={handleVariableSubmit}
                        handleVariableValid={handleVariableValid}
                        handleVariableValidPre={handleVariableValidPre}
                        instructions={settings?.marketing_instructions?.text}
                        questionsWithAnswers={questionsWithAnswers}
                        variableForm={variableForm}
                    />
                </PanelBody>
            </ContentPanel>
        </div>
    );
};

export default AddMarketingRequest;
