import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('requestsTable');

    return {
        APPROVED_VERSION: t('approvedVersion'),
        ASSIGNED_TO: t('assignedTo'),
        COMPANY: t('company'),
        DATE_APPROVED: t('dateApproved'),
        DATE_RQUESTED: t('dateRequested'),
        DUE_DATE: t('dueDate'),
        EXPECTED_USE_DATE: t('expectedUseDate'),
        REQUEST_NAME: t('requestName'),
        REQUESTED_BY: t('requestedBy'),
        STATUS: t('status')
    };
};

export default useTranslation;
