import { useCallback, useEffect, useState } from 'react';

import HollowButton from 'components/HollowButton';
import PublishPolicyVersionModal from 'components/PublishPolicyVersionModal';

import useTranslation from './hooks/useTranslation';
import usePublishVersion from './hooks/usePublishVersion';

import styles from './styles.module.scss';

const PublishPolicyVersionButton = ({policyId, policyVersion, publishedVersion}) => {
    const { PUBLISH_VERSION } = useTranslation();
    const [savedPublishedVersion, savePublishedVersion, resetPublishedVersion] = usePublishVersion();

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handlePublish = useCallback(() => {
            savePublishedVersion({policyId, policyVersion, publishedVersion});
        },
        [policyId, policyVersion, publishedVersion, savePublishedVersion]
    );

    useEffect(() => {
        if (!savedPublishedVersion) {
            return;
        }
        resetPublishedVersion();
        window.location.reload();
    }, [resetPublishedVersion, savedPublishedVersion]);

    return (
        <>
            <HollowButton
                className={styles.publishPolicyVersionButton}
                onClick={handleClick}
                text={PUBLISH_VERSION}
            />

            {isOpen && (
                <PublishPolicyVersionModal
                    currentVersion={publishedVersion}
                    newVersion={policyVersion}
                    onClose={handleClose}
                    onSubmit={handlePublish}
                />
            )}
        </>
    );
};

export default PublishPolicyVersionButton;
