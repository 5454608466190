import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('policyMappingTableHeader');

    return {
        CATEGORIES: t('categories'),
        DESCRIPTION: t('description'),
        LABEL: t('label'),
        SECTION_NAME: t('sectionName')
    };
};

export default useTranslation;
