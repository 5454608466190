import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
    ${tenantSchema}_user(where: {_or: {registration_status: {_neq: "disabled"}, employee_status: {_neq: "disabled"}}}) {
        tenant_user_id
        first_name
        last_name
    }
    ${tenantSchema}_group {
        name
        id
    }
`;

const useData = () => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    return useQuery(generateQuery(query, schema, tenants), { fetchPolicy: 'no-cache' });
};

export default useData;
