import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { caseDetailsPanel } = state;

const { updateCaseEntityAtom } = caseDetailsPanel.atoms;

const MUTATE_DELETE_OLD_ENTITIES = tenantSchema => gql`
    mutation deleteCaseEntities($caseId: Int!) {
        delete_${tenantSchema}_case_entity(where: {case_id: {_eq: $caseId}}) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_INSERT_NEW_ENTITIES = tenantSchema => gql`
    mutation insertCaseEntity(
        $caseId: Int!,
        $entityId: Int!,
    ) {
        insert_${tenantSchema}_case_entity(objects: {
            case_id: $caseId, 
            entity_id: $entityId
        }) {
            returning {
                entity_id
            }
        }
    }
`;

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insertCaseHistoryDropdown($caseId: Int!, $color: String = "yellow", $message: String = "changed the entities", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutationDeleteOldEntities = useGraphQL(MUTATE_DELETE_OLD_ENTITIES, providedSchema);
    const mutationInsertNewEntities = useGraphQL(MUTATE_INSERT_NEW_ENTITIES, providedSchema);
    const mutationCaseHistory = useGraphQL(MUTATE_CASE_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveEntities = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if(variables?.text !== ''){
                await mutationDeleteOldEntities(variables);
                
                const newEntities = []
                for (const selection of variables?.entities){
                    variables['entityId'] = selection;
                    
                    const res = await mutationInsertNewEntities(variables);
                    newEntities.push(res?.data[`insert_${usedSchema}_case_entity`]?.returning[0].entity_id);
                }

                await mutationCaseHistory(variables);

                set(updateCaseEntityAtom, newEntities);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateCaseEntityAtom, '');
    });

    return [useRecoilValue(updateCaseEntityAtom), saveEntities, reset];
};

export default useData;
