const updateActivities = (activities, setActivities, id, key, newValue, invert=false) => {  
    var update = false;  
    const completed = activities?.completed ? activities?.completed.map(obj => {
        if (obj.id === id) {
            update = true;
            const newObj = {...obj};
            newObj[key] = newValue ? newValue : invert ? !newObj[key] : newObj[key];
            return newObj;
        }
        return obj;
    }) : [];
    const inProgress = activities?.inProgress ? activities?.inProgress.map(obj => {
        if (obj.id === id) {
            update = true;
            const newObj = {...obj};
            newObj[key] = newValue ? newValue : invert ? !newObj[key] : newObj[key];
            return newObj;
        }
        return obj;
    }) : [];
    const overdue = activities?.overdue ? activities?.overdue.map(obj => {
        if (obj.id === id) {
            update = true;
            const newObj = {...obj};
            newObj[key] = newValue ? newValue : invert ? !newObj[key] : newObj[key];
            return newObj;
        }
        return obj;
    }) : [];
    const upcoming = activities?.upcoming ? activities?.upcoming.map(obj => {
        if (obj.id === id) {
            update = true;
            const newObj = {...obj};
            newObj[key] = newValue ? newValue : invert ? !newObj[key] : newObj[key];
            return newObj;
        }
        return obj;
    }) : [];
    if(update){
        const newActivities = {completed, inProgress, overdue, upcoming};
        setActivities(newActivities);
    }
}

export default updateActivities;