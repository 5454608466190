import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useData from './useData';
import normalizeData from '../data';

export function useCaseManagementPanelData() {
    const { useQuerySchemas, useSchema, useTenants } = useGlobalStateHooks();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const { loading, error, data } = useData();
    const normalizedData = data
        ? normalizeData({
              data,
              querySchemas,
              schema,
              tenants
          })
        : [];

    return {
        data: normalizedData,
        error,
        loading
    };
}
