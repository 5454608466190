import { atom } from 'recoil';

const closeActivityAndCreateCaseMenuListItemAtom = atom({
    default: '',
    key: 'closeActivityAndCreateCaseMenuListItem'
});

const state = {
    atoms: {
        closeActivityAndCreateCaseMenuListItemAtom
    }
};

export default state;
