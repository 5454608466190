import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { formsAtom } = state.forms.atoms;

const useForms = () => [
    useRecoilValue(formsAtom),
    useSetRecoilState(formsAtom)
];

export default useForms;
