import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('closeAndCreateNewActivityMenuListItem');

    return {
        CLOSE_AND_CREATE_NEW_ACTIVITY: t('closeAndCreateNewActivity')
    };
};

export default useTranslation;
