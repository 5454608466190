import { atom } from 'recoil';

const markCompleteButtonAtom = atom({
    default: '',
    key: 'markCompleteButton'
});

const state = {
    atoms: {
        markCompleteButtonAtom
    }
};

export default state;
