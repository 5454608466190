import AvatarWithTitle from 'components/AvatarWithTitle';
import EditButton from 'components/EditButton';
import Title from 'components/Title';

import styles from './styles.module.scss';

const GroupUserList = props => {
    const { className = '', onEditClick, title, users = [] } = props;

    return (
        <div className={`${className} ${styles.groupPanel}`} >
            <div className={`${styles.header}`}>
                <Title className={styles.title} text={title} />

                {onEditClick ? (
                    <EditButton
                        className={styles.editButton}
                        onClick={onEditClick}
                    />
                ) : null}
            </div>

            {users.length === 0 &&
            <div className={styles.noGroupMembers}>No group members</div>
            }

            <div className={`${styles.userList}`}>
                {users.map(({ id, firstName, lastName, name }) => {
                    if (name){
                        return (
                            <AvatarWithTitle
                                className={styles.user}
                                key={`${id}_${name}`}
                                user={{id:id, name:name}}
                            />);
                    }else{
                        return (
                        <AvatarWithTitle
                            className={styles.user}
                            key={`${id}_${firstName}_${lastName}`}
                            user={{id:id, name:`${firstName} ${lastName}`}}
                        />);
                    }
                })}
            </div>            
        </div>
    );
};

export default GroupUserList;
