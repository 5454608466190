import { atom } from 'recoil';

const completedActivityOverdueSummarizedAtom = atom({
    default: '',
    key: 'completedActivityOverdueSummarized'
});

const state = {
    atoms: {
        completedActivityOverdueSummarizedAtom
    }
};

export default state;
