import styles from './styles.module.scss';

const InputLabel = ({ className = '', hasFocus = false, isDisabled=false, isRequired=false, text = '' }) => {
    const focusClassName = hasFocus ? styles.hasFocus : '';
    const disabledClassName = isDisabled ? styles.disabled : '';

    return (
        <fieldset
            aria-hidden="true"
            className={`${className} ${focusClassName} ${disabledClassName} ${styles.inputLabel}`}
        >
            <legend className={`${focusClassName} ${disabledClassName} ${styles.legend}`}>
                {text}

                {isRequired && <span className={styles.isRequired}> *</span>}
            </legend>
        </fieldset>
    );
};

export default InputLabel;
