import normalizeListData from 'utilities/normalizeListData';

const schemaData = ({ data, schema }) =>
    data[`${schema}_admin_settings`].reduce((accumulator, permission) => {
        const { setting } = permission;

        for (const value of setting) {
            accumulator.push(value);
        }

        return accumulator;
    }, []);

const normalizePermissions = ({ data, querySchemas, schema, tenants }) =>
    normalizeListData({ data, querySchemas, schema, schemaData, tenants });

export default normalizePermissions;
