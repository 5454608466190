import { useCallback } from 'react';

import SubmitButton from 'components/SubmitButton';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const SaveButton = ({ className = '', isDisabled = false, onClick, saveText="Save" }) => {

    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <SubmitButton
            className={`${className} ${styles.saveButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
            text={saveText}
        />
    );
};

export default SaveButton;
