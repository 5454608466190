import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { ContentState, convertToRaw, EditorState } from 'draft-js';

export const convertEditorStateToHtml = state => {
    const rawContentState = convertToRaw(state.getCurrentContent());

    const htmlText = draftToHtml(rawContentState);

    return htmlText;
};

export const convertHtmlToEditorState = html => {
    if (!html) {
        return EditorState.createEmpty();
    }

    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;

    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );

    return EditorState.createWithContent(contentState);
};
