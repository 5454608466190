import { useEffect, useState, useMemo } from 'react';

import ComplianceLogTable from 'components/ComplianceLogTable';
import ContentPanel from 'components/ContentPanel';
import OverviewPanel from 'components/OverviewPanel';
import PanelBody from 'components/PanelBody';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useEntries from './hooks/useEntries';
import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import normalizeData from './data';

import styles from './styles.module.scss';

const LogEntriesPanel = ({ className = '' }) => {

    const { useSchema, useTenants } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();

    const [dueDateFilter, setDueDateFilter] = useState(new Date());
    const [entityFilter, setEntityFilter] = useState();
    const [startDateFilter, setStartDateFilter] = useState(new Date(new Date().setDate(new Date().getDate() - 365)));

    const [entries, setEntries] = useEntries();
    const [filteredEntries, setFilteredEntries] = useState();
    const { loading, error, data } = useData();

    const { VIEW_ALL } = useTranslation();

    const dropdownItems = useMemo(() => {
        if (!entries) {
            return [];
        }

        const dataItems = entries.reduce(
            (accumulator, { type }) => {
                
                if (!(type in accumulator.items)) {
                    accumulator.items[type] = 0;
                }

                accumulator.items[type]++;
                accumulator.totalCount++;

                return accumulator;
            },
            { items: {}, totalCount: 0 }
        );

        const { items, totalCount } = dataItems;

        const options = Object.keys(items).map(key => ({
            label: `${key} (${items[key]})`,
            value: key
        }));

        const defaultItem = {
            label: `${VIEW_ALL} (${totalCount})`,
            value: null
        };

        return [defaultItem, ...options];
    }, [VIEW_ALL, entries]);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema,
                tenants
            });

            const entityFilteredLogs = entityFilter
                ? normalizedData.filter(
                    ({ type }) => type === entityFilter
                )
                : normalizedData;

            const startDateFilteredLogs = startDateFilter
                ? entityFilteredLogs.filter(
                    ({ dateOfOccurrence }) => new Date(dateOfOccurrence) >= startDateFilter
                )
                : entityFilteredLogs;

            const dueDateFilteredLogs = dueDateFilter
                ? startDateFilteredLogs.filter(
                    ({ dateOfOccurrence }) => new Date(dateOfOccurrence) <= dueDateFilter
                )
                : startDateFilteredLogs;

            setEntries(normalizedData);
            setFilteredEntries(dueDateFilteredLogs)
        }
    }, [data, schema, setEntries, setFilteredEntries, entityFilter, startDateFilter, dueDateFilter, tenants]);

    if (error) {
        return `Error! ${error}`;
    }

    return (
        <>
            <OverviewPanel
                className={styles.overviewPanel}
                dueDateFilter={setDueDateFilter}
                entityFilter={setEntityFilter}
                entries={filteredEntries}
                items={dropdownItems}
                selectedEndDate={dueDateFilter}
                selectedStartDate={startDateFilter}
                startDateFilter={setStartDateFilter}
            />

            <ContentPanel className={className}>
                <PanelBody isLoading={loading}>
                    <ComplianceLogTable entries={filteredEntries} />
                </PanelBody>
            </ContentPanel>
        </>
    );
};

export default LogEntriesPanel;
