// import state from 'app/state';
import { Route, Routes } from 'react-router-dom';

// import { useRecoilValue } from 'recoil';
import Login from 'pages/Login';
import Authenticating from 'pages/Authenticating';
import NotFoundError from 'pages/NotFoundError';
import UnauthenticatedHeader from 'components/UnauthenticatedHeader'

import styles from './styles.module.scss';
import Authenticate from 'pages/Authenticate';
import SSOAuthenticate from 'pages/SSOAuthenticate';
import SSOCallback from 'pages/SSOCallback';

// const { configAtom } = state.app.atoms;

const UnauthenticatedContent = ({ className = '' }) => {

    return (
        <main className={`${className} ${styles.unauthenticatedContent}`}>
            <UnauthenticatedHeader className={styles.unauthenticatedHeader} />

            <Routes>
                <Route element={<Login className={styles.content} />} path="/" />

                <Route element={<Authenticate className={styles.content} />} path="/authenticate" />

                <Route element={<Authenticating className={styles.content} />} path="/authenticating" />

                <Route element={<SSOAuthenticate className={styles.content} />} path="/sso" />

                <Route element={<SSOCallback className={styles.content} />} path="/callback" />

                <Route element={<NotFoundError className={styles.content} />} path="*" />
            </Routes>
        </main>
    );
};

export default UnauthenticatedContent;
