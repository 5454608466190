import LinkInNewTab from 'components/LinkInNewTab';
import styles from './styles.module.scss';

import useConfig from 'hooks/useConfig';

const NotificationItem = ({
    className,
    dueDate,
    highImportance,
    id,
    name,
    schema,
    type
}) => {
    const { ACTIVITY_ROUTE, CASE_ROUTE, MARKETING_REQUEST_ROUTE } = useConfig();
    const route = (type==="activity") ? ACTIVITY_ROUTE.replace(":activityId", id).replace(":activitySchema", schema) :
                    (type==="case") ? CASE_ROUTE.replace(":caseId", id).replace(":caseSchema", schema) :
                    MARKETING_REQUEST_ROUTE.replace(":requestId", id).replace(":requestSchema", schema)

    return(
        <div className={`${className} ${styles.notificationItem}`} >
            <LinkInNewTab
                className={styles.item}
                text={name}
                to={route}
            />

            <LinkInNewTab
                className={styles.item}
                text={new Date(dueDate).toISOString().split('T')[0]}
                to={route}
            />

            {highImportance ? <LinkInNewTab className={styles.centeredLink} text={<>&#x2713;</>} to={id} /> : <div></div>}
        </div>
    );
};

export default NotificationItem;
