import TableHeader from 'components/TableHeader';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const ComplianceLogTableHeader = ({ onSort, tenantCount=1 }) => {
    const { COMPANY, DATE_OF_OCCURENCE, DESCRIPTION, LABEL, LOG_CATEGORY } =
        useTranslation();

    const headerItems = tenantCount > 1 ? [
            {
                isSortable: true,
                key: 'logCategory',
                text: LOG_CATEGORY
            },
            {
                isSortable: true,
                key: 'company',
                text: COMPANY
            },
            {
                isSortable: true,
                key: 'occurredOn',
                text: DATE_OF_OCCURENCE
            },
            {
                isSortable: false,
                key: 'description',
                text: DESCRIPTION
            },
            {
                isSortable: false,
                key: 'riskLevel',
                text: LABEL
            }
        ] : [
            {
                isSortable: true,
                key: 'logCategory',
                text: LOG_CATEGORY
            },
            {
                isSortable: true,
                key: 'occurredOn',
                text: DATE_OF_OCCURENCE
            },
            {
                isSortable: false,
                key: 'description',
                text: DESCRIPTION
            },
            {
                isSortable: false,
                key: 'riskLevel',
                text: LABEL
            }
        ];

    return (
        <TableHeader
            className={tenantCount > 1 ? styles.complianceLogTableHeaderMultiTenant : styles.complianceLogTableHeader}
            items={headerItems}
            onSort={onSort}
        />
    );
};

export default ComplianceLogTableHeader;
