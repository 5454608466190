import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const queryRequests = (tenantSchema, userGql) => gql`
  query getRequests {
      ${tenantSchema}_marketing_request {
        id
        request_name
        due_date
        expected_use_date
        marketing_request_assigned_tos(where: {status: {_neq: "inactive"}}) {
          status
          group {
            id
            name
          }
          tenant_user {
            id
            ${userGql}
          }
        }
        tenant_user {
          id
          ${userGql}
        }
        status
        marketing_request_versions {
          file_name
          status
          version_number
          created_by
          created_date
          marketing_request_version_histories {
            created_at
            message
            tenant_user {
              ${userGql}
            }
          }
        }
        marketing_request_version_comments(where: {deleted: {_is_null: true}}, order_by: {created_at: asc}) {
          created_at
          created_by
          deleted
          id
          text
          tenant_user {
            ${userGql}
          }
        }
      }
  }
`;

const useData = () => {
    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema
        ? userSetSchema
        : tenants && tenants.length === 1
        ? tenants[0]
        : schema;

    const userGql = `user_${usedSchema} {
      first_name
      last_name
    }`;

    return useQuery(queryRequests(usedSchema, userGql), { fetchPolicy: 'no-cache' });
};

export default useData;
