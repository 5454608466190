import { useRef, useState } from 'react';

import ContentPanel from 'components/ContentPanel';
import ErrorMessage from 'components/ErrorMessage';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import useInterval from 'hooks/useInterval';

// import httpGet from 'utilities/httpGet';

import useConfig from 'hooks/useConfig';

import useTranslation, {
    MAXIMUM_TOKEN_POLL_ATTEMPTS,
    POLL_INTERVAL
} from './hooks/useTranslation';

import './state';

import { CLOSE_TIMEOUT } from './constants';

import styles from './styles.module.scss';

const AuthenticatePanel = ({ className = '' }) => {
    const { LOGIN } = useConfig();

    const { AUTHENTICATION, EMAIL_SENT, LOGIN_TIMEOUT, SUCCESSFUL_LOGIN } =
        useTranslation();

    const countdown = useRef(CLOSE_TIMEOUT);

    const [successfulLogin, setSuccessfulLogin] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [statusMessage, setStatusMessage] = useState(EMAIL_SENT);

    const attemptCount = useRef(0);

    useInterval(() => {
        if (successfulLogin) {
            countdown.current = countdown.current - 1000;

            setStatusMessage(
                SUCCESSFUL_LOGIN.replace('SECONDS', countdown.current / 1000)
            );

            if (countdown.current <= 0) {
                window.open(LOGIN, '_self').close();
            }
        }
    }, 1000);

    useInterval(async () => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (accessToken instanceof SyntaxError) {
            setErrorMessage(accessToken.message);

            return false;
        }

        if (refreshToken instanceof SyntaxError) {
            setErrorMessage(refreshToken.message);

            return false;
        }

        if (accessToken && refreshToken) {
            setSuccessfulLogin(true);

            return false;
        }

        attemptCount.current++;

        if (attemptCount.current > MAXIMUM_TOKEN_POLL_ATTEMPTS) {
            setErrorMessage(LOGIN_TIMEOUT);

            return false;
        }
    }, POLL_INTERVAL);

    return (
        <ContentPanel className={`${className} ${styles.loginPanel}`}>
            <PanelBody className={styles.panelBody}>
                <PanelTitle
                    className={styles.panelTitle}
                    text={AUTHENTICATION}
                />

                <p>{statusMessage}</p>

                {errorMessage && (
                    <ErrorMessage
                        className={styles.errorMessage}
                        text={errorMessage}
                    />
                )}
            </PanelBody>
        </ContentPanel>
    );
};

export default AuthenticatePanel;
