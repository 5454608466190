import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('documentManagementSideLetterTableHeader');

    return {
        CATEGORIES: t('categories'),
        COMPANY: t('company'),
        CURRENT_VERSION: t('currentVersion'),
        LAST_REVIEWED_DATE: t('lastReviewedDate'),
        SECTIONS: t('sections'),
        SIDE_LETTER_NAME: t('sideLetterName'),
        STATUS: t('status'),
        TAGS: t('tags')
    };
};

export default useTranslation;
