import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addActivityMetaPanel');

    return {
        DUE_DATE: t('dueDate'),
        FIRST_ASSIGNEE: t('firstAssignee'),
        REVIEWER: t('reviewer'),
        START_DATE: t('startDate')
    };
};

export default useTranslation;
