function getNextDateInRecurrenceLoop(date, recurrences, dateDifference) {
    const prevDate = new Date(date);
    if(recurrences === 14){
        prevDate.setDate(prevDate.getDate() + 1);
        if(prevDate.getDay() !== 6 && prevDate.getDay() !== 0){
        }
    }else if(recurrences === 104){
        if(prevDate.getDay() === 2){
            prevDate.setDate(prevDate.getDate() + 2);
        }else if(prevDate.getDay() === 4){
            prevDate.setDate(prevDate.getDate() + 5);
        }
    }else if(recurrences === 52){
        prevDate.setDate(prevDate.getDate() + 7);
    }else if(recurrences === 26){
        prevDate.setDate(prevDate.getDate() + 14);
    }else if(recurrences === 12){
        prevDate.setMonth(prevDate.getMonth() + 1);
        if(prevDate.getDay() === 6){
            prevDate.setDate(prevDate.getDate() - 1);
        }
        if(prevDate.getDay() === 0){
            prevDate.setDate(prevDate.getDate() - 2);
        }
    }else if(recurrences === 4){
        prevDate.setMonth(prevDate.getMonth() + 3);
        if(prevDate.getDay() === 6){
            prevDate.setDate(prevDate.getDate() - 1);
        }
        if(prevDate.getDay() === 0){
            prevDate.setDate(prevDate.getDate() - 2);
        }
    }else if(recurrences === 2){
        prevDate.setMonth(prevDate.getMonth() + 6);
        if(prevDate.getDay() === 6){
            prevDate.setDate(prevDate.getDate() - 1);
        }
        if(prevDate.getDay() === 0){
            prevDate.setDate(prevDate.getDate() - 2);
        }
    }else if(recurrences === 1){
        prevDate.setFullYear(prevDate.getFullYear() + 1);
        if(prevDate.getDay() === 6){
            prevDate.setDate(prevDate.getDate() - 1);
        }
        if(prevDate.getDay() === 0){
            prevDate.setDate(prevDate.getDate() - 2);
        }
    }
    const newStartDate = new Date(prevDate);
    newStartDate.setDate(newStartDate.getDate() - dateDifference);
    if(newStartDate.getDay() === 6){
        newStartDate.setDate(newStartDate.getDate() - 1);
    }
    if(newStartDate.getDay() === 0){
        newStartDate.setDate(newStartDate.getDate() - 2);
    }
    return [newStartDate, prevDate];
}

export default getNextDateInRecurrenceLoop