import DatePicker from 'components/DatePicker';
import Editor from 'components/Editor';
import FileUpload from 'components/FileUpload';
import PolicyNameInput from 'components/PolicyNameInput';
import Dropdown from 'components/Dropdown';
import TextInput from 'components/TextInput';
import VisibleToDropdown from 'components/VisibleToDropdown';
import WithLabel from 'components/WithLabel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import { useState } from 'react';
import DropdownMultiSelectWithLabel from 'components/DropdownMultiSelectWithLabel';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import titleCase from 'utilities/titleCase';

const PolicyForm = ({ categories=[], fileUploader, form, setPolicyCategories, setSectionCategories, visibleTo }) => {
    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [parseFile, setParseFile] = useState(false);
    const [parseContents, setParseContents] = useState(false);

    const {
        ADD_DESCRIPTION,
        CATEGORIES,
        DESCRIPTION,
        DOCUMENT_TYPE,
        EFFECTIVE_DATE,
        LAST_REVIEW_DATE,
        PARSE_CONTENTS,
        PARSE_FILE,
        SECTION_PREFIX,
        SELECT_DOCUMENT,
        UPLOAD
    } = useTranslation();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema, setUserSetSchema] = useUserSetSchema();

    const { handleError, handleSubmit, handleValid } = form;

    const handleSetParseFile = () => {
        setParseFile(!parseFile);
    }

    const handleSetParseContents = () => {
        setParseContents(!parseContents);
    }

    const setTenant = selectedTenant => {
        setUserSetSchema(selectedTenant);
    }

    return (
        <div className={styles.policyForm}>
            <Dropdown
                className={styles.fullWidth}
                isRequired={true}
                items={[
                    {label: 'Policy', value: 'policy'},
                    {label: 'Side Letter', value: 'side_letter'},
                    ]}
                label={DOCUMENT_TYPE}
                onError={handleError('documentType')}
                onSubmit={handleSubmit('documentType')}
                onValid={handleValid('documentType')}
            />

            {tenants.length > 1 &&
                <Dropdown
                    className={styles.fullWidth}
                    isRequired={true}
                    items={tenants.map(tenant => ({label: titleCase(tenant), value: tenant}))}
                    label="Tenant"
                    onChange={setTenant}
                    onError={handleError('tenant')}
                    onSubmit={handleSubmit('tenant')}
                    onValid={handleValid('tenant')}
                />
            }

            <PolicyNameInput
                className={styles.policyNameInput}
                onChange={handleValid('policyName')}
                onError={handleError('policyName')}
                onSubmit={handleSubmit('policyName')}
            />

            <DatePicker
                className={styles.effectiveDate}
                label={EFFECTIVE_DATE}
                onChange={handleValid('effectiveDate')}
            />
            
            <DropdownMultiSelectWithLabel
                change={setPolicyCategories}
                className={styles.fullWidth}
                label={CATEGORIES}
                options={categories}
            />

            <VisibleToDropdown
                className={styles.visibleTo}
                items={visibleTo}
                onChange={handleValid('visibleTo')}
            />

            <WithLabel className={styles.description} text={DESCRIPTION}>
                <Editor
                    onValid={handleValid('description')}
                    placeholder={ADD_DESCRIPTION}
                />
            </WithLabel>

            <CheckboxWithLabel
                className={styles.fullWidth}
                isChecked={parseFile}
                onChange={handleSetParseFile}
                text={PARSE_FILE}
            />
            
            {parseFile && 
                <CheckboxWithLabel
                    className={styles.fullWidth}
                    isChecked={parseContents}
                    onChange={handleSetParseContents}
                    text={PARSE_CONTENTS}
                />
            }

            {parseContents && 
                <>
                    <DropdownMultiSelectWithLabel
                        change={setSectionCategories}
                        label={CATEGORIES}
                        options={categories}
                    />
                    
                    <DatePicker
                        label={LAST_REVIEW_DATE}
                        onChange={handleValid('sectionCategories')}
                        onError={handleError('sectionCategories')}
                        onSubmit={handleSubmit('sectionCategories')}
                        onValid={handleValid('sectionCategories')}
                    />

                    <TextInput
                        label={SECTION_PREFIX}
                        onChange={handleValid('sectionPrefix')}
                        onError={handleError('sectionPrefix')}
                        onSubmit={handleSubmit('sectionPrefix')}
                    />
                </>
            }

            <WithLabel className={styles.fileInput} text={UPLOAD}>
                <FileUpload filesList={fileUploader} providedSchema={userSetSchema ? userSetSchema : schema} showUrl={!parseFile} title={SELECT_DOCUMENT} />
            </WithLabel>
        </div>
    );
};

export default PolicyForm;
