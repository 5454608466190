import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import ActivityFormComponent from 'components/ActivityFormComponent';

const AddMarketingQuestions = props => {
    const { className = '', handleError, handleForm, handleSubmit, handleValid, inactive=false, questions={} } = props;
    
    const {
        // NEXT,
        QUESTIONS,
        // VIEW_FULL_FORM
    } = useTranslation();

    return (
        <SectionWithBorder
            className={`${className} ${styles.addActivityQuestions}`}
        >
            <Title className={styles.title} text={QUESTIONS} />
        
            {questions.map(({answer, choices, formComponent, id, index, questionId, name, type}) => {
                if(formComponent === "newForm"){
                    return(
                        <ActivityFormComponent
                            answer={answer}
                            choices={choices}
                            formComponent={formComponent}
                            handleError={handleError}
                            handleForm={handleForm}
                            handleSubmit={handleSubmit}
                            handleValid={handleValid}
                            id={id}
                            inactive={inactive}
                            index={index}
                            name={name}
                            questionId={questionId}
                            questions={questions}
                            type={type}
                        />
                    );
                }else{
                    return <></>;
                }
            })}
        </SectionWithBorder>
    );
};

export default AddMarketingQuestions;
