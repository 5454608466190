import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('selectUserListItem');

    return {
        ADD: t('add'),
        REMOVE: t('remove')
    };
};

export default useTranslation;
