import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';
import RespondUpdateButton from 'components/RespondUpdateButton';
import WithdrawButton from 'components/WithdrawButton';

const RequestorActions = props => {
    const {
        className = '',
        createdById,
        onCancel,
        pendingRequest,
        requestId,
        requestName,
        requestSchema,
        requestorId,
        status,
        versionId
    } = props;

    return (
        <Buttons className={className}>
            <CancelButton onClick={onCancel} />

            {status === 'draft' && 
            <>
                <WithdrawButton requestId={requestId} requestName={requestName} requestSchema={requestSchema} versionId={versionId} />

                {pendingRequest && (
                    <>
                        <RespondUpdateButton createdById={createdById} requestId={requestId} requestorId={requestorId} requestSchema={requestSchema} versionId={versionId} />
                    </>
                )}
            </>
            }
        </Buttons>
    );
};

export default RequestorActions;
