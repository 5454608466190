import ActionMenuItem from 'components/ActionMenuItem';
import CalendarIcon from 'components/icons/CalendarIcon';

import useTranslation from './hooks/useTranslation';

const CloseAndCreateNewActivityMenuListItem = ({ onClick }) => {
    const { CLOSE_AND_CREATE_NEW_ACTIVITY } = useTranslation();

    return (
        <ActionMenuItem
            icon={<CalendarIcon />}
            onClick={onClick}
            text={CLOSE_AND_CREATE_NEW_ACTIVITY}
        />
    );
};

export default CloseAndCreateNewActivityMenuListItem;
