import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { caseTypeCountsAtom } = state.caseTypeCounts.atoms;

const useCaseTypeCountsAtom = () => [
    useRecoilValue(caseTypeCountsAtom),
    useSetRecoilState(caseTypeCountsAtom)
];

export default useCaseTypeCountsAtom;
