import { useCallback, useState } from 'react';

import AddButton from 'components/AddButton';
import AddPolicySectionModal from 'components/AddPolicySectionModal';

import useTranslation from './hooks/useTranslation';

const AddPolicySectionButton = ({ categories, className = '', id, providedSchema }) => {
    const { ADD_POLICY_SECTION } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    return (
        <>
            <AddButton
                className={className}
                onClick={handleClick}
                text={ADD_POLICY_SECTION}
            />

            {isOpen && <AddPolicySectionModal categories={categories} id={id} onClose={setIsOpen} providedSchema={providedSchema} />}
        </>
    );
};

export default AddPolicySectionButton;
