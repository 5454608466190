import { useCallback, useState } from 'react';

import DeleteButton from 'components/DeleteButton';
import DeactivatePolicyVersionModal from 'components/DeactivatePolicyVersionModal';

import useTranslation from './hooks/useTranslation';

const DeactivatePolicyVersionButton = ({ policyName }) => {
    const { DEACTIVATE } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <DeleteButton onClick={handleClick} text={DEACTIVATE} />

            {isOpen && (
                <DeactivatePolicyVersionModal
                    onClose={handleClose}
                    onSubmit={() => {}}
                    policyName={policyName}
                />
            )}
        </>
    );
};

export default DeactivatePolicyVersionButton;
