import { useCallback } from 'react';

import SubmitButton from 'components/SubmitButton';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const ApproveButton = ({ className = '', isDisabled = false, onClick }) => {
    const { APPROVE } = useTranslation();

    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <SubmitButton
            className={`${className} ${styles.approveButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
            text={APPROVE}
        />
    );
};

export default ApproveButton;
