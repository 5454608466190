import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('deleteButton');

    return {
        DELETE: t('delete')
    };
};

export default useConstants;
