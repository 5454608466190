const normalizeData = ({ data, schema }) => {
    const groups = data[`${schema}_group`].reduce(
        (accumulator, group) => {
            const { 
                description,
                group_supervisors = [],
                group_users,
                id,
                name,
            } = group;

            const supervisors = group_supervisors.reduce(
                (accumulator, supervisor) => {
                    const {
                        groupBySupervisoryGroupId,
                        tenant_user
                    } = supervisor;

                    if (groupBySupervisoryGroupId?.id) {
                        accumulator.push({
                            label: groupBySupervisoryGroupId.name,
                            type: 'group',
                            value: groupBySupervisoryGroupId.id,
                        })
                    };

                    if (tenant_user?.id) {
                        accumulator.push({
                            firstName: tenant_user[`user_${schema}`].first_name,
                            lastName: tenant_user[`user_${schema}`].last_name,
                            type: 'user',
                            value: tenant_user.id
                        })
                    };

                    return accumulator;
                }, []
            );

            const groupUsers = group_users.reduce(
                (accumulator, user) => {
                    const {
                        group_id,
                        tenant_user_id
                    } = user;

                    accumulator.push({
                        groupId: group_id,
                        userId: tenant_user_id
                    });

                    return accumulator;
                }, []
            )

            accumulator.push({
                description,
                groupUsers,
                id,
                name,
                supervisors
            });

            return accumulator;
        }, []
    );

    const users = data[`${schema}_user`].reduce(
        (accumulator, user) => {
            accumulator.push({
                id: user.tenant_user_id,
                name: `${user.first_name} ${user.last_name}`,
                type: 'user'
            });

            return accumulator;
        }, []
    );

    return {
        groups,
        users
    };
};

export default normalizeData;
