import ActionMenu from 'components/ActionMenu2';
import AddPolicyFromFileMenuListItem from 'components/AddPolicyFromFileMenuListItem';
// import AddPolicyFromRepoMenuListItem from 'components/AddPolicyFromRepoMenuListItem';

const AddPolicyMenu = ({
    children
}) => (
    <ActionMenu
        renderReferenceElement={({ handleClick }) => children(handleClick)}
    >
        {onPopperClose => (
            <>
                {/* <AddPolicyFromRepoMenuListItem
                    onClick={onPopperClose}
                /> */}

                <AddPolicyFromFileMenuListItem
                    onClick={onPopperClose}
                />
            </>
        )}
    </ActionMenu>
);

export default AddPolicyMenu;
