import { atom } from 'recoil';

const activityTypeDetailsAtom = atom({
    default: '',
    key: 'activityTypeDetails'
});

const activityTypeFormSectionAtom = atom({
    default: '',
    key: 'activityTypeFormSection'
});

const state = {
    atoms: {
        activityTypeDetailsAtom,
        activityTypeFormSectionAtom
    }
};

export default state;
