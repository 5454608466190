import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import { OktaAuth } from '@okta/okta-auth-js';

import useConfig from 'hooks/useConfig';

import styles from './styles.module.scss';
import httpGetCors from 'utilities/httpGetCors';

const AuthenticateSSOPanel = ({ className = '' }) => {
    const { API_KEY, AUTH_SAML_URL } = useConfig();

    if(localStorage.getItem('ssoProvider') === 'okta'){
        const oktaAuth = new OktaAuth({
            clientId: localStorage.getItem('ssoClientId'),
            issuer: localStorage.getItem('ssoIssuer'),
            pkce: true,
            redirectUri: window.location.origin + "/login/callback",
            transformAuthState: async (oktaAuth, authState) => {
                if (!authState.isAuthenticated) {
                return authState;
                }
                const user = await oktaAuth.token.getUserInfo();
                authState.isAuthenticated = !!user;
                authState.users = user;
                return authState;
            }
        });

        oktaAuth.signInWithRedirect();
    }else if(localStorage.getItem('ssoProvider') === 'aad'){
        window.location = window.location.origin + "/login/callback";
    }else if(localStorage.getItem('ssoProvider') === 'aad_saml'){
        const authenticate = async() => {
            const urlDict = await httpGetCors({key: API_KEY ? API_KEY : '', url:`${AUTH_SAML_URL}?aadid=${localStorage.getItem('ssoClientId')}`});
            
            window.location = urlDict?.response?._headers?.location;
        }
        authenticate();
    }

    return (
        <ContentPanel className={`${className} ${styles.loginPanel}`}>
            <PanelBody>You are now being redirected to your SSO login.</PanelBody>
        </ContentPanel>
    );
};

export default AuthenticateSSOPanel;
