import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('policyMappingPanel');

    return {
        POLICY_MAPPING: t('policyMapping')
    };
};

export default useTranslation;
