const translations = {
    email: 'Email',
    group: 'Group',
    groups: 'Groups',
    name: 'Name',
    registration: 'Registration',
    role: 'Role',
    roles: 'Roles',
    search: 'Search',
    status: 'Status',
    tenant: 'Tenant',
    username: 'User Name'
};

export default translations;
