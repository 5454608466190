function replaceUserReferences(html) {
    if(html.includes('@')){
        const userReferenceIndices = [];
        for (let index = 0; index < html.length; index++) {
            if (html[index] === '@') {
                userReferenceIndices.push(index);
            }
        }
        const userReferences = [];
        for (const userReference of userReferenceIndices){
            const referenceStart = html.slice(userReference);
            const spaceCount = referenceStart.split(' ').length-1
            const atReference = spaceCount >= 2 ? referenceStart.substring(0, referenceStart.indexOf(' ', referenceStart.indexOf(' ')+1)) : referenceStart;
            const reference = atReference.replace('@', '');
            const user = JSON.parse(localStorage.users || "[]").filter(({searchString}) => searchString === reference);
            const group = JSON.parse(localStorage.groups || "[]").filter(({searchString}) => searchString === reference);
            if (user.length > 0){
                userReferences.push(user[0]);
            }
            if (group.length > 0){
                userReferences.push(group[0]);
            }
        }
        let modifiedHTML = html;
        for (const user of userReferences){
            modifiedHTML = modifiedHTML.replace(`@${user.searchString}`, `<a href="/people/${user.id}" style="color:#00ab55;text-decoration:none;">@${user.searchString}</a>`);
        }
        return modifiedHTML
    }
    return html;
}

export default replaceUserReferences;