import BodyText from 'components/BodyText';
import WithAvatar from 'components/WithAvatar';

const AvatarWithText = ({ className = '', text = '', user }) => (
    <WithAvatar className={className} user={user}>
        <BodyText text={text} />
    </WithAvatar>
);

export default AvatarWithText;
