import BarListItems from 'components/BarListItems';
import Title from 'components/Title';
import TitleListItem from 'components/TitleListItem';
import { conditionalSpreadArray } from 'utilities/common.util';
import { ActivityTableItem } from './activities-table-item.component';
import styles from './styles.module.scss';

const translations = {
    actions: 'Actions',
    activityType: 'Activity Type',
    assigned: 'Assigned',
    company: 'Company',
    dueDate: 'Due Date',
    entities: 'Entities',
    recurrence: 'Recurrence',
    reviewer: 'Reviewer',
    startDate: 'Start Date',
    type: 'Type'
};

export const ActivityTableItems = ({
    className = '',
    activities,
    isMultiTenant,
    onCompleteItem,
    onDownloadItem,
    onToggleFlagItem,
    title
}) => {
    const rowClass = isMultiTenant
        ? styles.titleListItemMultiTenant
        : styles.titleListItem;

    return (
        <div className={className}>
            <Title className={styles.title} text={title} />

            {activities.length > 0 ? (
                <BarListItems className={className}>
                    <TitleListItem
                        className={rowClass}
                        items={[
                            '',
                            translations.startDate,
                            translations.dueDate,
                            translations.activityType,
                            translations.assigned,
                            translations.reviewer,
                            ...conditionalSpreadArray({
                                condition: isMultiTenant,
                                value: translations.company
                            }),
                            translations.recurrence,
                            translations.entities,
                            translations.actions
                        ]}
                    />

                    {activities.map(activity => {
                        return (
                            <ActivityTableItem
                                activity={activity}
                                className={rowClass}
                                isMultiTenant={isMultiTenant}
                                key={[activity.id, activity.schema].join('.')}
                                onComplete={onCompleteItem}
                                onDownload={onDownloadItem}
                                onToggleFlag={onToggleFlagItem}
                            />
                        );
                    })}
                </BarListItems>
            ) : (
                <p className={styles.noItems}>No items to show</p>
            )}
        </div>
    );
};
