import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const CheckPermissionsAlternateElement = ({ children, permission }) => {
    const { usePermissions } = useGlobalStateHooks();

    const [permissions] = usePermissions();

    const testPermission = Array.isArray(permission) ? permission : [permission]
    const permissionIntersection = testPermission.filter(element => permissions.includes(element));
    const permitted = permissionIntersection.length >= 1;

    if(Array.isArray(children)){
        if(permitted){
            return (
                <>
                    {children[0]}
                </>
            );
        }else{
            return (
                <>
                    {children[1]}
                </>
            );
        }
    }else{
        return (
            <>
                {children}
            </>
        );
    }
};

export default CheckPermissionsAlternateElement;
