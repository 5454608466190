import PageTitle from 'components/PageTitle';

import styles from './styles.module.scss';

const WithPageTitle = props => {
    const { className = '', children, title = '' } = props;

    return (
        <div className={`${className} ${styles.withPageTitle}`}>
            <PageTitle className={styles.pageTitle} text={title} />

            {children}
        </div>
    );
};

export default WithPageTitle;
