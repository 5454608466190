import { useEffect, useMemo, useState } from 'react';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './useData';
import normalizeData from '../data';
import useTranslation from './useTranslation';

const useConstants = () => {
    const { EMAIL, GROUPS, NAME, REGISTRATION, ROLES, STATUS, TENANT, USERNAME } =
        useTranslation();

    const { loading, error, data } = useData();

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    if (loading) {
        
    }

    if (error) {
        
    }

    const [selectedColumns, setColumns] = useState();

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });

            setColumns(normalizedData?.selectedColumns);
        }
    }, [setColumns, data, schema]);

    const initialColumns = useMemo(
        () => [
            {
                isSelected: selectedColumns
                    ? selectedColumns.includes('name')
                    : false,
                isSortable: true,
                key: 'name',
                text: NAME
            },
            {
                isSelected: selectedColumns
                    ? selectedColumns.includes('username')
                    : false,
                isSortable: false,
                key: 'username',
                text: USERNAME
            },
            {
                isSelected: selectedColumns
                    ? selectedColumns.includes('email')
                    : false,
                isSortable: false,
                key: 'email',
                text: EMAIL
            },
            {
                isSelected: selectedColumns
                    ? selectedColumns.includes('roles')
                    : false,
                isSortable: false,
                key: 'roles',
                text: ROLES
            },
            {
                isSelected: selectedColumns
                    ? selectedColumns.includes('groups')
                    : false,
                isSortable: false,
                key: 'groups',
                text: GROUPS
            },
            {
                isSelected: selectedColumns
                    ? selectedColumns.includes('registration')
                    : false,
                isSortable: false,
                key: 'registration',
                text: REGISTRATION
            },
            {
                isSelected: selectedColumns
                    ? selectedColumns.includes('status')
                    : false,
                isSortable: false,
                key: 'status',
                text: STATUS
            },
            {
                isSelected: selectedColumns
                    ? selectedColumns.includes('tenant')
                    : false,
                isSortable: false,
                key: 'tenant',
                text: TENANT
            }
        ],
        [
            EMAIL,
            GROUPS,
            NAME,
            REGISTRATION,
            ROLES,
            STATUS,
            TENANT,
            USERNAME,
            selectedColumns
        ]
    );

    return {
        initialColumns
    };
};

export default useConstants;
