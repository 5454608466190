import { useRecoilCallback } from 'recoil';
import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';

const MUTATE_DELETE_SECTION_CATEGORY = tenantSchema => gql`
mutation deleteSectionCategory($categoryId: Int!, $sectionId: Int!) {
  delete_${tenantSchema}_section_category(where: {_and: {category_id: {_eq: $categoryId}, section_id: {_eq: $sectionId}}}) {
    returning {
      id
    }
  }
}
`;

const useRemoveCategory = (providedSchema) => {
    const mutationNewDocumentCategory = useGraphQL(MUTATE_DELETE_SECTION_CATEGORY, providedSchema);

    const removeCategory = useRecoilCallback(() => variables => {
        const runMutation = async () => {
            const { removedCategory, sectionId } = variables;

            for(const category of removedCategory){
              await mutationNewDocumentCategory({categoryId:category?.value, sectionId});
            }
        };

        runMutation();
    });

    return [removeCategory];
};

export default useRemoveCategory;
