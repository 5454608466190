import { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import AddFromCaseButton from 'components/AddFromCaseButton';
import CaseActions from 'components/CaseActions';
import CaseDetailsTab from 'components/CaseDetailsTab';
import CaseHistoryTab from 'components/CaseHistoryTab';
import CaseMetaTab from 'components/CaseMetaTab';
import TabToggle from 'components/TabToggle';
import WithPageTitle from 'components/WithPageTitle';
import SubmitButton from 'components/SubmitButton';
import DownloadIcon from 'components/DownloadIcon';
import { ExportModal } from 'components/ExportModal';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useAttachments from './hooks/useAttachments';
import useCaseDetails from './hooks/useCaseDetails';
import useTranslation from './hooks/useTranslation';
import useData from './hooks/useData';
import { useCaseManagementData } from '../CaseManagement/hooks/useCaseData';
import useReopenCase from './hooks/useReopenCase';
import { useExportCases } from 'hooks/useExportCases';

import styles from './styles.module.scss';

import normalizeData from './data';
import { getFileName } from 'utilities/files';

const Case = () => {
    const { INTERNAL_SERVER_ERROR } = useConfig();

    const navigate = useNavigate();

    const params = useParams();
    const { caseId, caseSchema } = params;

    const { useQuerySchemas, useSchema, useTenants, useUserId } = useGlobalStateHooks();

    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();
    const [reopenCase] = useReopenCase(caseSchema);

    const [cases, setCase] = useCaseDetails();

    const [displayedAttachments, setDisplayedAttachments] = useState([]);
    const [showExportModal, setShowExportModal] = useState(false);
    const [isCreatingPDF, setIsCreatingPDF] = useState(false);
    
    const [
        createdAttachment,
        updateAttachments,
        reset,
        deleteAttachment,
        updateAttachment
    ] = useAttachments(caseSchema);
    const { exportDetailedCases } = useExportCases();

    const { DETAILS, HISTORY, META } = useTranslation();

    const tabMap = {
        details: () => (
            <CaseDetailsTab
                attachments={displayedAttachments}
                attachmentsOnDelete={attachmentsOnDelete}
                attachmentsOnUpdate={attachmentsOnUpdate}
                caseSchema={caseSchema}
                handleFileUpload={handleFileUpload}
                isCreatingPDF={isCreatingPDF}
                items={cases}
            />
        ),
        history: () => (
            <CaseHistoryTab caseSchema={caseSchema} history={cases.history} />
        ),
        meta: () => <CaseMetaTab caseSchema={caseSchema} items={cases} />
    };

    const { loading, error, data } = useData(caseId, caseSchema);
    const { data: dataCases } = useCaseManagementData();

    const handleFileUpload = newFiles => {
        if(Object.keys(newFiles).length > 0){
            const variables = {};
            variables['id'] = caseId;
            variables['files'] = newFiles;
            updateAttachments(variables);
        }
    }

    const attachmentsOnDelete = async params => {
        const { attachmentId, filename } = params;
        await deleteAttachment({ attachmentId, caseId, filename });
        setDisplayedAttachments(
            displayedAttachments.filter(
                attachment => attachment.id !== attachmentId
            )
        );
    };

    const attachmentsOnUpdate = async params => {
        const { attachmentId, filename, newName } = params;
        await updateAttachment({
            attachmentId,
            caseId,
            filename,
            newName
        });
        setDisplayedAttachments(
            displayedAttachments.map(attachment =>
                attachment.id === attachmentId
                    ? { ...attachment, name: newName }
                    : attachment
            )
        );
    };

    const onShowExportModal = () => {
        setShowExportModal(true);
    };

    const onHideExportModal = () => {
        setShowExportModal(false);
    };

    const exportPDF = useCallback(async () => {
        const input = document.getElementById('case-view');
        console.log("input", input);
        input.classList.add('pdf-export');
    
        const canvas = await html2canvas(input, {
            scale: 2,
            scrollX: 0,
            scrollY: 0,
        });
    
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
            format: 'a4',
            orientation: 'portrait',
            unit: 'mm',
        });
    
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
    
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
        const pageHeightInCanvasPixels = pdfHeight * (imgProps.width / pdfWidth);
        const pageCount = Math.ceil(imgHeight / pdfHeight);
    
        const nameFile = getFileName('Case');
    
        for (let i = 0; i < pageCount; i++) {
            if (i > 0) {
                pdf.addPage();
            }
    
            const sourceY = i * pageHeightInCanvasPixels;
            const targetHeight = Math.min(pageHeightInCanvasPixels, imgProps.height - sourceY);
    
            const pageCanvas = document.createElement('canvas');
            pageCanvas.width = imgProps.width;
            pageCanvas.height = targetHeight;
            const ctx = pageCanvas.getContext('2d');
            ctx.drawImage(canvas, 0, sourceY, imgProps.width, targetHeight, 0, 0, imgProps.width, targetHeight);
    
            const pageImgData = pageCanvas.toDataURL('image/png');
            pdf.addImage(pageImgData, 'PNG', 0, 0, pdfWidth, (targetHeight / imgProps.width) * pdfWidth);
        }
    
        pdf.save(nameFile + '.pdf');
        setIsCreatingPDF(false);
    }, []);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema: caseSchema,
                user: userId
            });

            setCase(normalizedData);
            setDisplayedAttachments(normalizedData.attachments);
        }
    }, [caseSchema, data, querySchemas, schema, setCase, userId]);

    useEffect(() => {
        if (createdAttachment && createdAttachment.constructor.name !== "Array") {
            reset();
            window.location.reload();
        }
    }, [createdAttachment, reset])

    useEffect(() => {
        if(isCreatingPDF){
            exportPDF();
        }
    }, [isCreatingPDF, exportPDF]);

    const tabs = useRef([
        { label: DETAILS, value: 'details' },
        { label: HISTORY, value: 'history' },
        { className: styles.meta, label: META, value: 'meta' }
    ]).current;

    if (loading) {
        return `Loading...`
    }

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);

        return;
    }

    if (!cases?.activityCase?.type) {
        return;
    }

    function onDownloadItem() {
        if (!caseId) return;

        const caseItem = dataCases.find(
            ({ id }) => String(id) === String(caseId)
        );
        if (!caseItem) return;

        exportDetailedCases({
            data: [caseItem],
            isAll: true,
            name: 'Case',
            querySchemas,
            schema,
            tenants,
            userId
        });
    }

    const onReopenCase = async () => {
        await reopenCase(caseId);
    };

    return (
        <div className={styles.case}>
            {showExportModal && (
                <ExportModal
                    hasPDFButton={true}
                    isCreatingPDF={isCreatingPDF}
                    isOpen={showExportModal}
                    onClose={onHideExportModal}
                    onExportExcel={() =>onDownloadItem(data) }
                    onExportPDF={() => setIsCreatingPDF(true)}
                    title="Export Case"
                />
            )}

            <WithPageTitle className={styles.withPageTitle} title={cases?.activityCase?.type}>
                <CaseActions caseId={cases.id} caseRecord={cases} isClosed={cases.closed} onReopenCase={onReopenCase} />

                <AddFromCaseButton
                    caseId={caseId}
                    caseRecord={cases}
                />

                <SubmitButton onClick={onShowExportModal}>
                    <DownloadIcon fill="white" />
                </SubmitButton>
            </WithPageTitle>

            <TabToggle
                className={styles.tabToggle}
                items={tabs}
                selectedTab="details"
            >
                {selectedTab => {
                    const TabComponent = tabMap[selectedTab];

                    return <TabComponent />;
                }}
            </TabToggle>
        </div>
    );
};

export default Case;
