import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { addPolicySectionModal } = state;

const { addPolicySectionModalAtom } = addPolicySectionModal.atoms;

const MUTATE_ADD_POLICY_SECTION = tenantSchema => gql`
mutation addPolicySection($description: String!, $file: String!, $sectionName: String!, $riskLevel: String!) {
    insert_${tenantSchema}_sections(objects: {
        description: $description,
        file: $file,
        name: $sectionName,
        riskLevel: $riskLevel
    }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_LINK_POLICY_SECTION = tenantSchema => gql`
mutation addPolicySectionLink($sectionId: Int!, $policyId: Int!) {
    insert_${tenantSchema}_policy_sections(objects: {
        section_id: $sectionId,
        policy_id: $policyId
    }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_INSERT_NEW_CATEGORY = tenantSchema => gql`
mutation addCategory($label: String!) {
    insert_${tenantSchema}_categories_one(object: {label: $label}) {
      id
    }
  }
`;

const MUTATE_INSERT_SECTION_CATEGORY = tenantSchema => gql`
mutation addSectionCategory($categoryId: Int!, $sectionId: Int!) {
    insert_${tenantSchema}_section_category_one(object: {category_id: $categoryId, section_id: $sectionId}) {
      id
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_ADD_POLICY_SECTION, providedSchema);
    const mutationLink = useGraphQL(MUTATE_LINK_POLICY_SECTION, providedSchema);
    const mutationNewCategory = useGraphQL(MUTATE_INSERT_NEW_CATEGORY, providedSchema);
    const mutationNewSectionCategory = useGraphQL(MUTATE_INSERT_SECTION_CATEGORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const addPolicySection = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const { existingCategories, newCategories } = variables;
            const consolidatedCategories = [...existingCategories]
            for(const nc of newCategories){
              const ncidRes = await mutationNewCategory({label:nc});
              const ncid = ncidRes?.data[`insert_${usedSchema}_categories_one`]?.id
              consolidatedCategories.push({label:nc, value:ncid});
            }
            const selectedCategoryIds = consolidatedCategories.map((obj) => obj.value);

            const res = await mutation(variables);

            const sectionId =
                res?.data[`insert_${usedSchema}_sections`]?.returning[0]
                    ?.id;

            variables['sectionId'] = sectionId;

            const linkRes = await mutationLink(variables);

            const linkId =
                linkRes?.data[`insert_${usedSchema}_policy_sections`]?.returning[0]
                    ?.id;

            for(const scid of selectedCategoryIds){
                await mutationNewSectionCategory({categoryId:scid, sectionId});
            }

            set(addPolicySectionModalAtom, linkId);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addPolicySectionModalAtom, '');
    });

    return [useRecoilValue(addPolicySectionModalAtom), addPolicySection, reset];
};

export default useData;
