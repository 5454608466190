import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import AddButton from 'components/AddButton';

import useTranslation from './hooks/useTranslation';

const AddActivityTypeButton = ({ className = '' }) => {
    const { ADD_ACTIVITY_TYPE } = useTranslation();

    const { ADD_ACTIVITY_TYPE: ADD_ACTIVITY_TYPE_URL } = useConfig();

    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(ADD_ACTIVITY_TYPE_URL);
    }, [ADD_ACTIVITY_TYPE_URL, navigate]);

    return (
        <AddButton
            className={className}
            onClick={handleClick}
            text={ADD_ACTIVITY_TYPE}
        />
    );
};

export default AddActivityTypeButton;
