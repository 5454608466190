import { atom } from 'recoil';

const updateGroupMembersAtom = atom({
    default: '',
    key: 'updateGroupMembers'
})


const state = {
    atoms: {
        updateGroupMembersAtom
    }
};

export default state;
