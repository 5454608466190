import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('caseManagement');

    return {
        CASE_MANAGEMENT: t('caseManagement')
    };
};

export default useTranslation;
