import { atom } from 'recoil';

const marketingRequestMetaPanelAtom = atom({
    default: '',
    key: 'marketingRequestMetaPanel'
});

const state = {
    atoms: {
        marketingRequestMetaPanelAtom
    }
};

export default state;
