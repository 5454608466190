import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import AddPolicyButton from 'components/AddPolicyButton';
import DocumentManagementSideLetterTab from 'components/DocumentManagementSideLetterTab';
import DocumentManagementPolicyTab from 'components/DocumentManagementPolicyTab';
import DocumentManagementSectionsTab from 'components/DocumentManagementSectionsTab';
import DocumentManagementRepositoryTab from 'components/DocumentManagementRepositoryTab';
import TabToggle from 'components/TabToggle';
import WithPageTitle from 'components/WithPageTitle';
import WithDownloadButtonDocumentManagement from 'components/WithDownloadButtonDocumentManagement';
import {useGetAllRepositoriesData} from 'components/DocumentManagementRepositoryTab/hooks/useAllRepositoryData';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DocumentManagement = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTab = searchParams.get('selected') || 'policy';

    const allRepositoriesData = useGetAllRepositoriesData();

    const { DOCUMENT_MANAGEMENT, REPOSITORY, SECTIONS, SIDE_LETTERS, POLICY } =
        useTranslation();

    const tabMap = {
        policy: () => <DocumentManagementPolicyTab />,
        sections: () => <DocumentManagementSectionsTab />,
        sideLetters: () => <DocumentManagementSideLetterTab />,
        repository: () => <DocumentManagementRepositoryTab />
    };

    const policyAddMap = useRef({
        default: ({className, isDisabled }) => (
            <AddPolicyButton
                className={className}
                isDisabled={isDisabled}
            />
        )
    });

    const tabs = useRef([
        { label: POLICY, value: 'policy' },
        { label: SIDE_LETTERS, value: 'sideLetters' },
        { label: SECTIONS, value: 'sections' },
        { label: REPOSITORY, value: 'repository' }
    ]).current;

    const ButtonComponent = policyAddMap.current['default'];

    const isDisabled = false;

    const handleTabChange = (newTab) => {
        setSearchParams({ selected: newTab });
    };

    return (
        <div className={styles.documentManagement}>
            <WithPageTitle title={DOCUMENT_MANAGEMENT}>
                {selectedTab === 'repository' ? (
                    <WithDownloadButtonDocumentManagement data={allRepositoriesData} name={'Document Management - Repository'} >
                        <ButtonComponent
                            className={styles.addPolicyButton}
                            isDisabled={isDisabled}
                        />
                    </WithDownloadButtonDocumentManagement>
                ) : (
                    <ButtonComponent
                        className={styles.addPolicyButton}
                        isDisabled={isDisabled}
                    />
                )}
            </WithPageTitle>

            <TabToggle
                className={styles.tabToggle}
                items={tabs}
                onTabChange={handleTabChange}
                selectedTab={selectedTab}
            >
                {selectedTab => {
                    const TabComponent = tabMap[selectedTab];

                    return <TabComponent />;
                }}
            </TabToggle>
        </div>
    );
};

export default DocumentManagement;
