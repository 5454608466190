export const queryKeys = {
    actvities: {
        getActivities: ({ activityName, limit, offset, schemas = [] }) => [
            'activities',
            'getActivities',
            activityName,
            limit,
            offset,
            ...schemas
        ],
        getActivitiesFilters: schemas => [
            'activities',
            'getActivitiesFilters',
            ...schemas
        ],
        getActivitiesPaginator: ({
            activityAssignees = [],
            activityDueDateFrom,
            activityDueDateTo,
            activityName,
            activityRecurrence = [],
            activityReviewers = [],
            activityStatus = [],
            activityTypes = [],
            entityName = [],
            isAssignedToMe,
            isFlagged,
            limit,
            offset,
            schemas,
            userId
        }) => [
            'activities',
            'getActivitiesPaginator',
            ...schemas,
            ...activityAssignees,
            ...activityReviewers,
            limit,
            offset,
            activityName,
            activityDueDateFrom,
            activityDueDateTo,
            ...activityTypes,
            entityName,
            ...activityStatus,
            ...activityRecurrence,
            isAssignedToMe,
            isFlagged,
            userId
        ]
    }
};
