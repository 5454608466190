import GroupPill from 'components/GroupPill';
import Pill from 'components/Pill';
import UserPill from 'components/UserPill';

import styles from './styles.module.scss';

const pillMap = {
    default: Pill,
    group: GroupPill,
    user: UserPill
};

const PillGroup = props => {
    const { className = '', items = [] } = props;

    if (items.length > 0 && (typeof items[0] === 'string' || items[0] instanceof String)){
        return (
            <div className={`${className} ${styles.pillGroup}`}>
                {items.map((item, index) => (
                    <Pill className={styles.pill} key={item + index} label={item} />
                ))}
            </div>
        );
    }else{
        return (
            <div className={`${className} ${styles.pillGroup}`}>
                {items.map((item, index) => {
                    const PillComponent = pillMap[item.type.toLowerCase()] || pillMap.default;
                    return(
                        <PillComponent className={styles.pill} key={item?.label + index} label={item?.label} />
                    )
                })}
            </div>
        );
    }
};

export default PillGroup;
