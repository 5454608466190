import { atom } from 'recoil'; 

const activitiesAtom = atom({
    default: '',
    key: 'activities'
});

const activityTypeAtom = atom({
    default: '',
    key: 'activityType'
});

const consultancyUsersAtom = atom({
    default: '',
    key: 'consultancyUsers'
});

const emailAtom = atom({
    default: '',
    key: 'email'
});

const endDateAtom = atom({
    default: '',
    key: 'endDate'
});

const entityAtom = atom({
    default: '',
    key: 'entity'
});

const isAllAtom = atom({
    default: '',
    key: 'isAll'
});

const isFlaggedAtom = atom({
    default: '',
    key: 'isFlagged'
});

const permissionsAtom = atom({
    default: '',
    key: 'permissions'
});

const querySchemasAtom = atom({
    default: [],
    key: 'querySchemas'
})

const reviewAtom = atom({
    default: '',
    key: 'review'
});

const riskLabelAtom = atom({
    default: '',
    key: 'riskLabel'
});

const schemaAtom = atom({
    default: '',
    key: 'schema'
});

const schemaDescriptionAtom = atom({
    default: '',
    key: 'schemaDescription'
});

const startDateAtom = atom({
    default: '',
    key: 'startDate'
});

const tenantUsersAtom = atom({
    default: '',
    key: 'tenantUsers'
});

const tenantsAtom = atom({
    default: '',
    key: 'tenants'
});

const userIdAtom = atom({
    default: '',
    key: 'userId'
});

const userAtom = atom({
    default: '',
    key: 'user'
});

const userSetSchemaAtom = atom({
    default: '',
    key: 'userSetSchema'
});

const state = {
    atoms: {
        activitiesAtom,
        activityTypeAtom,
        consultancyUsersAtom,
        emailAtom,
        endDateAtom,
        entityAtom,
        isAllAtom,
        isFlaggedAtom,
        permissionsAtom,
        querySchemasAtom,
        reviewAtom,
        riskLabelAtom,
        schemaAtom,
        schemaDescriptionAtom,
        startDateAtom,
        tenantUsersAtom,
        tenantsAtom,
        userAtom,
        userIdAtom,
        userSetSchemaAtom
    }
};

export default state;
