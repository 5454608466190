function getRecurrenceLoopCount(recurrence) {
    const loopCount = recurrence === 'none' ? 0 :
    recurrence === 'daily' ? 14 :
    recurrence === 'weekly' ? 52 :
    recurrence === 'biweekly' ? 26 :
    recurrence === 'monthly' ? 12 :
    recurrence === 'quarterly' ? 4 :
    recurrence === 'semi_annual' ? 2 :
    recurrence === 'annually' ? 1 : 0;
    return loopCount;
}

export default getRecurrenceLoopCount