import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { repositoriesListAtom } = state.repositoryList.atoms;

const usePoliciesList = () => [
    useRecoilValue(repositoriesListAtom),
    useSetRecoilState(repositoriesListAtom)
];

export default usePoliciesList;