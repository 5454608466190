import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('buildPolicy');

    return {
        BUILD_NEW_POLICY: t('buildPolicy')
    };
};

export default useTranslation;
