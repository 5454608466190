import { Droppable, Draggable } from "react-beautiful-dnd";
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';
import MoveIcon from "components/icons/MoveIcon";
import SearchInput from "components/SearchInput";
import Title from "components/Title";
import PanelTitle from "components/PageTitle";
import AddSectionButton from "components/AddSectionButton";

const BuildPolicyMetaPanel = ({
    className = ''
}) => {
    return (
        <ContentPanel className={`${className} ${styles.addFormMetaPanel}`}>
            <PanelBody className={styles.panelBody}>
                <PanelTitle text={'Sections'} />

                <Droppable droppableId={'formElements'} key={'formElements'}>
                    {(provided, _snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <SearchInput />

                                <Draggable
                                    draggableId={'test1'}
                                    index={1}
                                    key={'test1'}
                                >
                                    {(provided, _snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>
                                                    
                                                    <Title
                                                        text={'Test Section'}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                <Draggable
                                    draggableId={'test2'}
                                    index={2}
                                    key={'test2'}
                                >
                                    {(provided, _snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>
                                                    
                                                    <Title
                                                        text={'Test Section 2'}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable> 

                <AddSectionButton />
            </PanelBody>
        </ContentPanel>
    );
};

export default BuildPolicyMetaPanel;
