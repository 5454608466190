import { useRecoilCallback } from 'recoil';
import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';

const MUTATE_DELETE_DOCUMENT_CATEGORY = tenantSchema => gql`
mutation deleteDocumentCategory($categoryId: Int!, $documentId: Int!) {
  delete_${tenantSchema}_document_category(where: {_and: {category_id: {_eq: $categoryId}, document_id: {_eq: $documentId}}}) {
    returning {
      id
    }
  }
}
`;

const useRemoveCategory = (providedSchema) => {
    const mutationNewDocumentCategory = useGraphQL(MUTATE_DELETE_DOCUMENT_CATEGORY, providedSchema);

    const removeCategory = useRecoilCallback(() => variables => {
        const runMutation = async () => {
            const { documentId, removedCategory } = variables;

            for(const category of removedCategory){
              await mutationNewDocumentCategory({categoryId:category?.value, documentId});
            }
        };

        runMutation();
    });

    return [removeCategory];
};

export default useRemoveCategory;
