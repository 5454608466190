import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('fileUpload');

    return {
        ADDITIONAL: t('additional'),
        DESCRIPTION: t('description'),
        TITLE: t('title')
    };
};

export default useTranslation;
