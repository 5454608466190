// import { useEffect } from 'react';

import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { policy } = state;

const { policyAttachmentAtom } = policy.atoms;


const MUTATE_POLICY_VERSION_ATTACHMENT = tenantSchema => gql`
    mutation updateDocumentVersionAttachment($versionId: Int!, $filename: String!) {
        update_${tenantSchema}_document_version(where: {id: {_eq: $versionId}}, _set: {file_name: $filename}) {
            returning {
              id
            }
          }
        }        
`;

const MUTATE_POLICY_VERSION_HISTORY = tenantSchema => gql`
    mutation insertDocumentVersionHistory($versionId: Int!, $color: String = "yellow", $message: String = "changed a dropdown", $userId: Int!) {
        insert_${tenantSchema}_document_version_history(
            objects: {
                version_id: $versionId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const UPDATE_ATTACHMENT = tenantSchema => gql`
    mutation updateDocumentVersionAttachment($id: Int!, $versionNumber: Int!, $filename: String!) {
        update_${tenantSchema}_document_version(where: {id: {_eq: $id}, version_number: {_eq: $versionNumber}}, _set: {file_name: $filename}) {
            returning {
              id
            }
          }
        }        
`;

const DELETE_ATTACHMENT = tenantSchema => gql`
    mutation updateDocumentVersionAttachment($id: Int!, $versionNumber: Int!) {
        update_${tenantSchema}_document_version(where: {id: {_eq: $id}, version_number: {_eq: $versionNumber}}, _set: {deleted: true}) {
            returning {
              id
            }
          }
        }        
`;

const useAttachments = (providedSchema) => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const mutation = useGraphQL(MUTATE_POLICY_VERSION_ATTACHMENT, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_POLICY_VERSION_HISTORY, providedSchema);
    const updateAttachmentMutation = useGraphQL(UPDATE_ATTACHMENT, providedSchema);
    const deleteAttachmentMutation = useGraphQL(DELETE_ATTACHMENT, providedSchema);

    const createAttachment = useRecoilCallback(({ set }) => attachments => {
        const runMutation = async () => {
            for (let [key, value] of Object.entries(attachments.files)) {
                if(!value?.isUploadError){
                    await mutation({
                        filename: key,
                        versionId: attachments?.id
                    });
                    await mutationActivityHistory({
                        message: `uploaded new file: ${key}`,
                        userId,
                        versionId: attachments?.id
                    })
                }
            }

            set(policyAttachmentAtom, true);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(policyAttachmentAtom, false);
    });

    const updateAttachment = async params => {
        const { filename, id, versionNumber } = params;
        await updateAttachmentMutation({ filename, id, versionNumber });

        await mutationActivityHistory({
            message: `renamed file: ${filename}`,
            userId,
            versionId: id,
        })
    };

    const deleteAttachment = async params => {
        const { filename, id, versionNumber } = params;
        await deleteAttachmentMutation({ id, versionNumber });

        await mutationActivityHistory({
            message: `deleted file: ${filename}`,
            userId,
            versionId: id,
        })
    };


    return [
        useRecoilValue(policyAttachmentAtom),
        createAttachment,
        reset,
        updateAttachment,
        deleteAttachment
    ];
};

export default useAttachments;
