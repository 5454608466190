import axios from "axios";

// import getFile from 'utilities/getFile';

async function sendEmail(sendTo, subject, headerIn, openingTextIn, explanatoryTextIn, buttonsIn, key) {
  // const schema = localStorage.getItem('schema');
  const originUrl = window.location.origin;
  const currentUrl = (originUrl.includes('regtechsolutions.com')) ? 'regtechsolutions.com' : 'skematic.com';
  const urlString = '';//await getFile(schema, "logo.png");
  await axios.get(process.env.REACT_APP_EMAIL_URL, {
    headers: { api_auth: `Bearer ${key}` },
    params: {
      buttons_in: JSON.stringify(buttonsIn),
      explanatory_text_in: explanatoryTextIn,
      header_in: headerIn,
      logo_url: urlString ? urlString : '',
      opening_text_in: openingTextIn,
      send_to: sendTo,
      sending_url: currentUrl,
      subject,
    }
  }
  );
  
}

export default sendEmail;