const arraysEqual = (a, b) => { 
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    const aCopy = [...a];
    const bCopy = [...b];
    aCopy.sort();
    bCopy.sort();
    for (var i = 0; i < aCopy.length; ++i) {
      if (aCopy[i] !== bCopy[i]) return false;
    }
    return true;
}

export default arraysEqual;