import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('highRiskPill');

    return {
        HIGH: t('high')
    };
};

export default useTranslation;
