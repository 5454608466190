import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('editPerson');

    return {
        EDIT_PERSON: t('editPerson')
    };
};

export default useConstants;
