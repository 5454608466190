// import titleCase from "utilities/titleCase";
import formatDate from 'utilities/formatDate';

const parseDataForReport = async (cases) => {
    const headers = ['Case Type', 'Status', 'Case Name', 'Due Date', 'Days Outstanding', 'Assigned To', 'Date Closed', 'Risk Label', 'Users Involved', 'Entities'];
    const rows = [];

    function addRow(data, status){
        const row = [];
        row.push(data?.caseType ? data?.caseType : "");
        row.push(status);
        row.push(data?.caseName ? data?.caseName : "");
        row.push(data?.dueDate ? data?.dueDate : "");
        row.push(data?.createdDate ? (Math.round((new Date().getTime() - new Date(data?.createdDate).getTime())/(1000 * 3600 * 24))) : "");
        row.push(data?.assignedTo ? data?.assignedTo.reduce((accumulator, user) => {
            accumulator.push(`${user?.firstName} ${user?.lastName}`);
            return accumulator;
        }, []).join(", ") : "");
        row.push(data?.closedDate ? formatDate(new Date(data?.closedDate)) : "");
        row.push(data?.riskLabel ? data?.riskLabel : "");
        row.push(data?.usersInvolved ? data?.usersInvolved.reduce((accumulator, user) => {
            accumulator.push(`${user?.firstName} ${user?.lastName}`);
            return accumulator;
        }, []).join(", ") : "");
        row.push(data?.entities && Array.isArray(data?.entities) && data?.entities.length > 0 ? data?.entities.join(', ') : "");
        rows.push(row);
    }

    const closedCases = cases.filter(
        ({ closed }) => closed
    );

    const notClosedCases = cases.filter(
        ({ closed }) => !closed
    );

    const overdueCases = notClosedCases.filter(
        ({ dueDate }) => new Date(dueDate) < new Date()
    );

    const pendingCases = notClosedCases.filter(
        ({ dueDate }) => new Date(dueDate) >= new Date()
    );

    const sortedClosedCases = closedCases.sort(function(a,b){
        return new Date(a.sortDate) - new Date(b.sortDate);
    });

    const sortedOverdueCases = overdueCases.sort(function(a,b){
        return new Date(a.sortDate) - new Date(b.sortDate);
    });

    const sortedPendingCases = pendingCases.sort(function(a,b){
        return new Date(a.sortDate) - new Date(b.sortDate);
    });

    for(const o of sortedOverdueCases){
        addRow(o, 'Overdue');
    }

    for(const p of sortedPendingCases){
        addRow(p, 'In Progress');
    }

    for(const c of sortedClosedCases){
        addRow(c, 'Complete');
    }
    const morphedData = {headers, rows}
    return morphedData
}

export default parseDataForReport