import CountPanel from 'components/CountPanel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const OverdueCountPanel = ({ className = '', count = 0 }) => {
    const { OVERDUE } = useTranslation();

    return (
        <CountPanel
            className={`${className} ${styles.overdueCountPanel}`}
            count={count}
            text={OVERDUE}
        />
    );
};

export default OverdueCountPanel;
