import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecPolicy = (tenantSchema) => gql`
query getPolicy {
  	${tenantSchema}_group {
      id
      name
    }
  	tenant_user {
      id
      user_${tenantSchema} {
        first_name
        last_name
      }
    }
  }
`;

const useData = policyId => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(querySpecPolicy(schema, policyId), {
        fetchPolicy: 'no-cache'
    });
};

export default useData;
