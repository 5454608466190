import { useEffect, useState } from 'react';

import AdministrationMarketingSidebar from 'components/AdministrationMarketingSidebar';
import AdministrationPanel from 'components/AdministrationPanel';
import FormSettingsTable from 'components/FormSettingsTable';
import WorkflowSettingsTable from 'components/WorkflowSettingsTable';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useData from './hooks/useData';

import normalizeData from './data';
import styles from './styles.module.scss';

const AdministrationMarketingTab = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const { loading, error, data } = useData();

    const [activeIndex, setActiveIndex] = useState(0);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });

            if (normalizedData) {
                setSettings(normalizedData);
            };
        };
    }, [data, schema, setSettings]);
    
    if (loading) { return `Error! ${error}` }
    if (error) { return `Error! ${error}` };

    const tabsContent = [
        <FormSettingsTable items={settings.forms} />,
        <WorkflowSettingsTable items={settings.workflows} />
    ];

    return (
        <AdministrationPanel className={styles.administrationMarketingTab}>
            <AdministrationMarketingSidebar
                activeIndex={activeIndex}
                onClick={setActiveIndex}
            />

            {tabsContent[activeIndex]}
        </AdministrationPanel>
    );
};

export default AdministrationMarketingTab;
