const translations = {
    company: 'Company',
    dateIntroduced: 'Date Introduced',
    documentName: 'Document Name',
    referenceName: 'Attached To',
    referenceType: 'Classification',
    type: 'Type',
    uploadBy: 'Upload By'
};

export default translations;
