import StandoutText from 'components/StandoutText';
import PositiveBarListItem from 'components/PositiveBarListItem';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const MenuListItem = ({
    className = '',
    icon: Icon,
    isActive = false,
    isCollapsed = false,
    onClick,
    text = ''
}) => {
    const handleClick = event => {
        if (isFunction(onClick)) {
            onClick(event);
        }
    };

    const activeClass = isActive ? styles.isActive : '';

    return (
        <PositiveBarListItem
            className={`${activeClass} ${className} ${styles.menuListItem}`}
            isActive={isActive}
            onClick={handleClick}
        >
            {Icon && <Icon />}

            {!isCollapsed && <StandoutText text={text} />}
        </PositiveBarListItem>
    );
};

export default MenuListItem;
