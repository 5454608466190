import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { caseDetailsPanel } = state;

const { updateCreatedByAtom } = caseDetailsPanel.atoms;

const MUTATE = tenantSchema => gql`
mutation deleteCaseUsersInvolved($caseId: Int!) {
    delete_${tenantSchema}_case_user_involved(where: {case_id: {_eq: $caseId}}) {
      returning {
        id
      }
    }
  }
`;


const MUTATE_UPDATE = tenantSchema => gql`
mutation mutateCaseUsersInvolved($userId: Int!, $caseId: Int!) {
    insert_${tenantSchema}_case_user_involved(objects: {case_id: $caseId, user_id: $userId}) {
        returning {
          id
        }
      }
    }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insertCaseHistoryDropdown($caseId: Int!, $color: String = "yellow", $message: String = "changed the users involved", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutationDelete = useGraphQL(MUTATE, providedSchema);
    const mutationInsert = useGraphQL(MUTATE_UPDATE, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveCreatedBy = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
              await mutationDelete(variables);

              if(variables?.users.length > 0){
                var res = {};
                for(const userId of variables?.users){
                    res = await mutationInsert({...variables, userId});
                }
                const newUsersInvolved = res?.data[`insert_${usedSchema}_case_user_involved`].returning[0]?.id;

                await mutationActivityHistory({...variables, userId:variables?.changeUserId});

                set(updateCreatedByAtom, newUsersInvolved);
            }else{
                set(updateCreatedByAtom, variables?.changeUserId);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateCreatedByAtom, '');
    });

    return [useRecoilValue(updateCreatedByAtom), saveCreatedBy, reset];
};

export default useData;
