import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { addActivityType } = state;

const { addActivityTypeAtom } = addActivityType.atoms;

const MUTATE_ACTIVITY_TYPE = tenantSchema => gql`
    mutation insertActivityType (
        $name: String!,
        $riskLevel: String,
    ) {
        insert_${tenantSchema}_activity_type(
            objects: {
                name: $name,
                risk_label: $riskLevel,
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_UPDATE_ACTIVITY_TYPE = tenantSchema => gql`
    mutation updateActivityType (
        $id: Int!,
        $name: String!,
        $riskLevel: String,
    ) {
        update_${tenantSchema}_activity_type(
            where: {id: {_eq: $id}},
            _set: {
                name: $name,
                risk_label: $riskLevel,
            }
        ) {
            affected_rows
        }
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_ACTIVITY_TYPE, providedSchema);
    const mutationUpdate = useGraphQL(MUTATE_UPDATE_ACTIVITY_TYPE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ?? schema;

    const createActivityType = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const activityTypeId = res?.data[`insert_${usedSchema}_activity_type`].returning[0]?.id;
            
            set(addActivityTypeAtom, activityTypeId);
        };

        runMutation();
    });

    const updateActivityType = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            await mutationUpdate(variables);
            set(addActivityTypeAtom, variables.id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addActivityTypeAtom, '');
    });

    return [useRecoilValue(addActivityTypeAtom), createActivityType, updateActivityType, reset];
};

export default useData;
