import { useCallback, useState } from 'react';

import CreateLogEntryForm from 'components/CreateLogEntryForm';
import PanelTitle from 'components/PanelTitle';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import SplitContainer from 'components/SplitContainer';
import StandoutText from 'components/StandoutText';
import WithLabel from 'components/WithLabel';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const CreateLogEntryModal = ({
    activityName,
    activityType,
    caseName,
    caseType,
    onClose,
    onSave,
    toClose = true,
}) => {
    const { ACTIVITY_NAME,
        ACTIVITY_TYPE,
        CASE_NAME,
        CASE_TYPE,
        CLOSE_AND_CREATE_LOG_ENTRY,
        CREATE_LOG_ENTRY 
    } = useTranslation();

    const [_errorMessage, setErrorMessage] = useState();

    const handleModalClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                return onClose(event);
            }
        },
        [onClose]
    );

    const handleSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                handleModalClose();

                return;
            }

            if (isFunction(onSave)) {
                onSave({ form });
            }
        },
        [handleModalClose, onSave]
    );

    return (
        <Modal className={styles.createLogEntryModal} isOpen={true} onClose={handleModalClose}>
            <PanelBody>
                <PanelTitle
                    className={styles.panelTitle}
                    text={CREATE_LOG_ENTRY}
                />

                {(activityName || caseName || activityType || caseType) &&
                    <SplitContainer className={styles.activityItems}>
                        {(activityName || caseName) && 
                            <WithLabel
                                className={styles.withLabel}
                                text={`${activityName ? ACTIVITY_NAME : CASE_NAME}:`}
                            >
                                <StandoutText text={activityName ? activityName : caseName} />
                            </WithLabel>
                        }

                        {(activityType ||  caseType) &&
                            <WithLabel
                                className={styles.withLabel}
                                text={`${activityType ? ACTIVITY_TYPE : CASE_TYPE}:`}
                            >
                                <StandoutText text={activityType ? activityType : caseType} />
                            </WithLabel>
                        }
                    </SplitContainer>
                }

                <CreateLogEntryForm
                    onCancel={handleModalClose}
                    onSave={handleSave}
                    saveText={toClose ? CLOSE_AND_CREATE_LOG_ENTRY : CREATE_LOG_ENTRY}
                />
            </PanelBody>
        </Modal>
    );
};

export default CreateLogEntryModal;
