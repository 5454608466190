import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('archivedPolicyInformation');

    return {
        EFFECTIVE_DATE: t('effectiveDate'),
        POLICY_NAME: t('policyName')
    };
};

export default useTranslation;
