import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('closeCaseDirectButton');

    return {
        CLOSE_CASE: t('closeCase'),
        CLOSED: t('closed')
    };
};

export default useTranslation;
