import sendEmail from './sendEmail';

async function sendMentionedInCommentEmail(comment, email, link, name, _schema, schemaDescription, user, key){
  const siteName = window.location.origin.includes('skematic') ? 'Skematic' : 'Beacon';
  const subject = name ? `${user} mentioned you in a comment on ${siteName}. ${name} - ${schemaDescription}` : `${user} mentioned you in a comment on ${siteName}. - ${schemaDescription}`;
  const headerIn = `${user} mentioned you in a comment.`;
  const openingTextIn = comment;
  const explanatoryTextIn = '';
  const buttonUrl = `${window.location.origin}${link}`
  const buttons = [{'link': buttonUrl, 'style': 'solid', 'text': 'View the Mention Here'}]
  sendEmail(email, subject, headerIn, openingTextIn, explanatoryTextIn, buttons, key);
}

export default sendMentionedInCommentEmail;