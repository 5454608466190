import { Link as OriginalLink } from 'react-router-dom';

import styles from './styles.module.scss';

const Link = ({ className = '', newTab, text, to }) => {
    const linkTo = /^\//.test(to) ? to : { pathname: to };

    return (
        <OriginalLink
            className={`${className} ${styles.bodyText}`}
            target={newTab ? '_blank' : undefined}
            to={linkTo}
        >
            {text}
        </OriginalLink>
    );
};

export default Link;