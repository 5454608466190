import ContentPanel from 'components/ContentPanel';

import styles from './styles.module.scss';

const MetaPanel = ({ children, className }) => (
    <ContentPanel className={`${className} ${styles.metaPanel}`}>
        {children}
    </ContentPanel>
);

export default MetaPanel;
