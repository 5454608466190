import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateGroupDescription } = state;

const { updateGroupDescriptionAtom } = updateGroupDescription.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateGroupDescription(
  $text: String!, 
  $groupId: Int!
  ) {
    update_${tenantSchema}_group(
      where: {
        id: {_eq: $groupId}
      }, 
      _set: {description: $text}
    ) {
      returning {
        description
      }
    }
  }
`;

// const MUTATE_GROUP_HISTORY = tenantSchema => gql`
//     mutation insert_group_history_dropdown($groupId: Int!, $color: String = "yellow", $message: String = "changed the group name") {
//         insert_${tenantSchema}_group_history(
//             objects: {
//                 group_id: $groupId,
//                 color: $color,
//                 message: $message,
//             }
//         ) {
//             returning {
//                 id
//             }
//         }
//     }  
// `;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);
    // const mutationGroupHistory = useGraphQL(MUTATE_GROUP_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveGroupDescription = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if(variables?.text !== ''){
              const res = await mutation(variables);

              const newDescription = res?.data[`update_${usedSchema}_group`].returning[0]?.description;

            //   await mutationGroupHistory(variables);

              set(updateGroupDescriptionAtom, newDescription);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateGroupDescriptionAtom, '');
    });

    return [useRecoilValue(updateGroupDescriptionAtom), saveGroupDescription, reset];
};

export default useData;
