import { atom } from 'recoil';

const closeActivityAndAddLogEntryMenuListItemAtom = atom({
    default: '',
    key: 'closeActivityAndAddLogEntryMenuListItem'
});

const state = {
    atoms: {
        closeActivityAndAddLogEntryMenuListItemAtom
    }
};

export default state;
