import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
    ${tenantSchema}_case(where: {closed: {_eq: false}}) {
        caseTypeByCaseType {
            name
            id
        }
    }
`;

const meQuery = tenantSchema => `
${tenantSchema}_case(where: {closed: {_eq: false}, tenant_user: {id: {_eq: $userId}}}) {
        caseTypeByCaseType {
            name
            id
        }
    }
`;

const useData = () => {
    const { useIsAllFilter, useSchema, useTenants, useUserId } = useGlobalStateHooks();
    const [isAll] = useIsAllFilter();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();

    const variables = isAll ? false : `$userId: Int!`;
    const customGqlContainer = isAll ? false : true;
    const queryToRun = isAll ? generateQuery(query, schema, tenants) : generateQuery(meQuery, schema, tenants, variables, customGqlContainer);
    const options = isAll ? { fetchPolicy: 'no-cache' } : { fetchPolicy: 'no-cache', variables: { userId }};
    return useQuery(queryToRun, options);
};

export default useData;
