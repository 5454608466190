import Checkbox from 'components/Checkbox';

const WithCheckbox = props => {
    const { children, className = '', isChecked, isDisabled, onChange } = props;

    return (
        <>
            <Checkbox
                className={className}
                isChecked={isChecked}
                isDisabled={isDisabled}
                onChange={onChange}
            />

            {children}
        </>
    );
};

export default WithCheckbox;
