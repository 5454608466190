import { memo } from 'react';

import HistoryEntry from 'components/HistoryEntry';

import styles from './styles.module.scss';

const HistoryEntries = ({ className = '', items = [] }) => {
    return (
        <div className={`${className} ${styles.historyEntries}`}>
            {items.map(({color, createdAt, date, text}) => 
                <HistoryEntry 
                    color={color}
                    createdAt={createdAt}
                    date={date}
                    key={`${date}_${text}`} 
                    text={text} 
                />
            )}
        </div>
    );
}

export default memo(HistoryEntries);
