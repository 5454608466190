import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';

const MUTATE_INSERT_MARKETING_REQUEST_FORM = tenantSchema => gql`
    mutation insert_marketing_request_form($formId: Int!, $marketingRequestId: Int!) {
        insert_${tenantSchema}_marketing_request_form(
            objects: {
                form_id: $formId,
                marketing_request_id: $marketingRequestId
            }
        ) {
            returning {
                id
            }
        }
    }
`;

export const useInsertMarketingRequestForm = providedSchema => {
    const insertMarketingFormRequestMutation = useGraphQL(
        MUTATE_INSERT_MARKETING_REQUEST_FORM,
        providedSchema
    );

    const insertMarketingRequestForm = async ({
        formId,
        marketingRequestId
    }) => {
        return insertMarketingFormRequestMutation({
            formId,
            marketingRequestId
        });
    };

    return insertMarketingRequestForm;
};
