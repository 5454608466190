import { useQuery } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import generateQueryFromDict from 'utilities/generateQueryFromDict';

const query = (tenantSchema, userGQL) => `
        ${tenantSchema}_marketing_request(where: {status: {_eq: "draft"}}) {
            request_name
            due_date
            expected_use_date
            marketing_request_assigned_tos(where: {status: {_neq: "inactive"}}) {
              status
              group {
                id
                name
              }
              tenant_user {
                id
                ${userGQL}
              }
            }
          }
`;

const useData = () => {
    const { useQuerySchemas, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
    var userGQL = '';
    if(querySchemas.length > 0){
        for(const s of querySchemas){
            userGQL += `user_${s.value} {
              first_name
              last_name
            }`
        }
    }else if(usedSchema){
        userGQL += `user_${usedSchema} {
          first_name
          last_name
        }`
    }
    return useQuery(generateQueryFromDict({query, schema:usedSchema, tenants, userGQL}), { fetchPolicy: 'no-cache' });
};

export default useData;
