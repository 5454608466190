import { atom } from 'recoil';

const caseDetailsAtom = atom({
    default: [],
    key: 'caseDetails'
});

const caseAttachmentAtom = atom({
    default: [],
    key: 'caseAttachment'
});

const state = {
    atoms: {
        caseAttachmentAtom,
        caseDetailsAtom,
    }
};

export default state;
