import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { caseFilterAtom } = state.cases.atoms;

const useCaseFilter = () => [
    useRecoilValue(caseFilterAtom),
    useSetRecoilState(caseFilterAtom)
];

export default useCaseFilter;