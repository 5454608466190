import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const PolarAreaChart = ({ className = '', data = {}, title }) => {
    const options = {
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        indexAxis: 'y' ,
        plugins: {
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: title,
          },
        },
        responsive: true,
    };
    return (
        <Bar className={className} data={data} options={options} />
    );
};

export default PolarAreaChart;