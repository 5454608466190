import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { groupDetailsAtom } = state.groupDetails.atoms;

const useGroupDetails = () => [
    useRecoilValue(groupDetailsAtom),
    useSetRecoilState(groupDetailsAtom)
];

export default useGroupDetails;
