import { atom } from 'recoil';

const activityTypesAtom = atom({
    default: '',
    key: 'activityTypes'
});

const state = {
    atoms: {
        activityTypesAtom
    }
};

export default state;
