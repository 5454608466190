import { atom } from 'recoil';

const formsAtom = atom({
    default: [],
    key: 'forms'
});

const state = {
    atoms: {
        formsAtom
    }
};

export default state;
