import { useCallback, useEffect, useState } from 'react';

import MetaPanel from 'components/MetaPanel';
import PanelTitle from 'components/PageTitle';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';

import useTranslation from './hooks/useTranslation';
import FormComponent from 'components/FormComponent';

const FormMetaPanel = props => {
    const { className = '', form = {}, loading } = props;
    const { FORM_PREVIEW } = useTranslation();

    const { questions } = form;

    const [selections, setSelections] = useState({});

    const handleUpdateSelections = useCallback((questionName, selected) => {
        const modifiedSelections = {...selections};
        modifiedSelections[questionName] = selected;
        setSelections(modifiedSelections);
    }, [selections, setSelections]);

    useEffect(() => {
    }, [selections])

    return (
        <MetaPanel className={className}>
            <PanelBody className={styles.panelBody} loading={loading}>
                <PanelTitle text={FORM_PREVIEW} />
                
                {questions && questions.map(({ question, /*required*/ }) => {
                    if(question?.formComponent === "newForm"){
                        return(
                            <FormComponent
                                handleUpdateSelections={handleUpdateSelections}
                                question={question}
                                questions={questions}
                                selections={selections}
                            />
                        );
                    }else{
                        return(<></>);
                    }
                })}

            </PanelBody>
        </MetaPanel>
    );
};

export default FormMetaPanel;
