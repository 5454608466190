import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import DropdownItem from 'components/DropdownItem';
import Input from 'components/Input';
import InputWithLabel from 'components/InputWithLabel';
import DropdownMenu from 'components/DropdownMenu';
import Select from 'components/Select';

import useConstants from './hooks/useTranslation';
import usePopperState from 'hooks/usePopperState';

import styles from './styles.module.scss';

import arrayRemove from 'utilities/arrayRemove';

const GroupDropdownMultiSelect = forwardRef((props, ref) => {
    const { SELECT } = useConstants();

    const {
        className = '',
        items = [],
        label = '',
        onChange,
        onSubmit,
        onValid,
        placeholder = SELECT,
        returnObject = false,
        selected = []
    } = props;

    const errorMessage = useRef('');

    const [selectedLabel, setSelectedLabel] = useState([]);
    const [selectedIndexList, setSelectedIndexList] = useState([]);

    useEffect(() => {
        if (placeholder !== SELECT) {
            setSelectedLabel([placeholder]);
        }
    },[setSelectedLabel, SELECT, placeholder]);

    useEffect(() => {
        if (selected.length !== selectedIndexList.length) {
            const newSelectedIndexArray = selected.reduce(
                (accumulator, selection) => {
                    const foundValue = items.findIndex(item => item.value === selection)
                    if (foundValue >= 0) {
                            accumulator.push(foundValue)
                    }
                    return accumulator;
                }, []
            );

            const newIndexArray = [...new Set([...newSelectedIndexArray, ...selectedIndexList])]; //[23, 0]

            const newLabelArray = [];
            for (const i of newIndexArray){
                newLabelArray.push(items[i]?.label);
            }

            setSelectedIndexList(newIndexArray);
            setSelectedLabel(newLabelArray);
        }
    }, [items, selected, selectedIndexList])

    // items - array of objs: {label: 'Executive', type: 'group', value: 'group 1'} // array length of 24, ex. {label: 'Executive', type: 'group', value: 'group 1'}
    // item values are in format 'type id'
    // selected - array of all chosen selections, // ['user 110', 'group 1']
    // selectedIndexList - array of items indices of all selected users/groups

    // useEffect(() => {
    //     if(Array.isArray(selected) && Array.isArray(selectedIndexList)){ // if selected and selectedIndexList are arrays (always)
    //     // if selected changes, the length should change
    //     // if (selected.length  > 1) {
    //     // if (Array.isArray(selected) && selected.length !== selectedIndexList.length) {

    //         // creating an array of all items obj values ('type id')
    //         const newSelectedArray = selected.reduce(
    //             (accumulator, selection) => {
    //                 const foundValue = items.findIndex(item => item.value === selection)
    //                 if (foundValue >= 0) {
    //                         accumulator.push(foundValue)
    //                 }
    //                 return accumulator;
    //             }, []
    //         )

    //         const newIndexArray = [...new Set([...newSelectedArray, ...selectedIndexList])]; //[23, 0]

    //         const newLabelArray = [];
    //         for (const i of newIndexArray){
    //             newLabelArray.push(items[i]?.label);
    //         }

    //         setSelectedIndexList(newIndexArray);
    //         setSelectedLabel(newLabelArray);
    //     }else if (Array.isArray(selected)){
    //         setSelectedIndexList(selected);
    //     }else if (Array.isArray(selectedIndexList)){ // ???
    //         if(selected !== ''){
    //             const newSelectedIndex = items.indexOf(selected);
    //             if (newSelectedIndex){ 
    //                 setSelectedIndexList(selectedIndexList.push(selectedIndexList)); // want to push newSelectedIndex?
    //             }
    //         }
    //     }else{
    //         setSelectedIndexList([]);
    //     }
    // }, [items, selected, selectedIndexList, setSelectedIndexList]);

    const { handleClick, isOpen, onClose, referenceElement } = usePopperState();

    const handleChange = useCallback(
        (indexArray) => {
            const valuesArray = [];
            for (const i of indexArray){
                valuesArray.push(items[i]?.value)
            }
            onChange(valuesArray);
        },
        [onChange, items]
    );

    const handleBlur = useCallback(
        event => {
            if (
                !event?.relatedTarget?.classList.contains(styles.dropdownMenu)
            ) {
                onClose();
            }
        },
        [onClose]
    );

    const handleDropdownItemClick = useCallback(
        index => () => {
            if(selectedIndexList.includes(index)){
                const newSelectedIndex = arrayRemove(selectedIndexList, index);
                const newSelectedLabel = arrayRemove(selectedLabel, items[index]?.label);
                setSelectedIndexList(newSelectedIndex);
                setSelectedLabel(newSelectedLabel);

                const newValuesArray = [];
                for (const i of newSelectedIndex){
                    if (returnObject){
                        newValuesArray.push(items[i]);
                    }else{
                        newValuesArray.push(items[i]?.value);
                    }
                }
                onValid?.(newValuesArray);
                handleChange(newSelectedIndex)
            }else{
                const newSelectedIndex = [...selectedIndexList, index];
                const newSelectedLabel = [...selectedLabel, items[index]?.label]
                setSelectedIndexList(newSelectedIndex);
                setSelectedLabel(newSelectedLabel);

                const newValuesArray = [];
                for (const i of newSelectedIndex){
                    if (returnObject){
                        newValuesArray.push(items[i]);
                    }else{
                        newValuesArray.push(items[i]?.value);
                    }
                }
                onValid?.(newValuesArray);
                handleChange(newSelectedIndex)
            }
        },

        [handleChange, items, onValid, returnObject, selectedIndexList, selectedLabel, setSelectedIndexList, setSelectedLabel]
    );

    useEffect(() => {
        const currentReference = ref?.current;

        const handleSubmit = () => {
            const inputValue = currentReference?.value;

            handleChange({ target: { value: inputValue } });
            onSubmit?.(inputValue, errorMessage.current);
        };

        currentReference?.addEventListener('submit', handleSubmit);

        return () => {
            currentReference?.removeEventListener('submit', handleSubmit);
        };
    }, [errorMessage, handleChange, onSubmit, ref]);

    useEffect(() => {
        if (selectedIndexList.length === 0) {
            return;
        }
    }, [selectedIndexList])

    return (
        <InputWithLabel
            className={`${className} ${styles.dropdown}`}
            text={label}
        >
            <Select
                items={items}
                onBlur={handleBlur}
                onClick={handleClick}
                ref={ref}
                value={selectedLabel}
            />

            <Input
                className={`${className} ${styles.input}`}
                isReadOnly={true}
                onBlur={handleBlur}
                onClick={handleClick}
                placeholder={placeholder}
                value={selectedLabel}
            />

            <DropdownMenu
                className={styles.dropdownMenu}
                isOpen={isOpen}
                onClose={onClose}
                referenceElement={referenceElement}
            >
                {items.map(({ label, type, value }, index) => {
                    const isSelected = selectedIndexList.includes(index);
                    return (
                    <DropdownItem
                        isSelected={isSelected}
                        key={`${label}${index}`}
                        onClick={handleDropdownItemClick(index)}
                        text={label}
                        type={type}
                        value={value}
                    />
                )})}
            </DropdownMenu>
        </InputWithLabel>
    );
});

export default GroupDropdownMultiSelect;
