import { atom } from 'recoil';

const requestRejectModalAtom = atom({
    default: [],
    key: 'requestRejecteModal'
});

const state = {
    atoms: {
        requestRejectModalAtom
    }
};

export default state;
