const translations = {
    activityTypes: 'Activity Types',
    additionalSettings: 'Additional Settings',
    administration: 'Administration',
    caseTypes: 'Case Types',
    emailManagement: 'Email Management',
    groups: 'Groups',
    logTypes: 'Log Types',
    marketing: 'Marketing',
    roles: 'Roles'
};

export default translations;
