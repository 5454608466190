import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { requestsAtom } = state.requests.atoms;

const useRequests = () => [
    useRecoilValue(requestsAtom),
    useSetRecoilState(requestsAtom)
];

export default useRequests;