import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EditableDatePickerWithLabel from 'components/EditableDatePickerWithLabel';
import EditableMultiSelectWithLabel from 'components/EditableMultiSelectWithLabel';
import EntityTags from 'components/EntityTags';
import ImportantLinkInNewTab from 'components/ImportantLinkInNewTab';
import MetaPanel from 'components/MetaPanel';
import PanelBody from 'components/PanelBody';
import SectionWithBorder from 'components/SectionWithBorder';
import SelectUsersDrawer from 'components/SelectUsersDrawer';
import Title from 'components/Title';
import UserList from 'components/UserList';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useAddCaseUser from './hooks/useAddCaseUser';
import useDeleteCaseUser from './hooks/useDeleteCaseUser';
import useData from './hooks/useData';
import useEntities from './hooks/useEntities';
import useTranslation from './hooks/useTranslation';

import useUpdateCreatedBy from './hooks/useUpdateCreatedBy';
import useUpdateEndDate from './hooks/useUpdateEndDate';
import useUpdateEntities from './hooks/useUpdateEntities';
import useUpdateStartDate from './hooks/useUpdateStartDate';
import useUpdateUpdateUsersInvolved from './hooks/useUpdateUpdateUsersInvolved';

import styles from './styles.module.scss';

import normalizeEntityData from './dataEntities';
import TextWithLabel from 'components/TextWithLabel';
import titleCase from 'utilities/titleCase';

const CaseMetaPanel = ({ className = '', caseSchema, cases = {} }) => {
    const {
        activityLinkages,
        entities: caseEntities, 
        caseLinkages,
        caseName, 
        createdBy,
        closed, 
        dueDate, 
        id,
        logLinkages,
        startDate, 
        users: caseUsers,
        usersInvolved = []
    } = cases;

    const { ACTIVITY_ROUTE, CASE_ROUTE, LOG_ROUTE, INTERNAL_SERVER_ERROR } = useConfig();

    const navigate = useNavigate();

    const { useConsultancyUsers, useTenants, useUserId } = useGlobalStateHooks();

    const [consultancyUsers] = useConsultancyUsers();
    const [tenants] = useTenants();
    const [userId] = useUserId();

    const [entities, setEntities] = useEntities();

    const [deletedCaseUser, deleteCaseUser, resetDeleteCaseUser] = useDeleteCaseUser(caseSchema);
    const [savedCaseUser, saveCaseUser, resetSaveCaseUser] = useAddCaseUser(caseSchema);
    const [updatedEntities, updateEntities, resetUpdateEntities] = useUpdateEntities(caseSchema);
    const [updatedCreatedBy, updateCreatedBy, resetUpdateCreatedBy] = useUpdateCreatedBy(caseSchema);
    const [updatedUsersInvolved, updateUsersInvolved, resetUpdateUsersInvolved] = useUpdateUpdateUsersInvolved(caseSchema);
    const [updatedEndDate, updateEndDate, resetUpdateEndDate] = useUpdateEndDate(caseSchema);
    const [updatedStartDate, updateStartDate, resetUpdateStartDate] = useUpdateStartDate(caseSchema);

    const [_errorMessage, setErrorMessage] = useState();
    const [isEditingEntities, setIsEditingEntities] = useState(false);
    const [selectedEntityLabels, setSelectedEntityLabels] = useState();
    const [selectedEntityValues, setSelectedEntityValues] = useState();
    const [startDateFilter, setStartDateFilter] = useState(startDate);
    const [endDateFilter, setEndDateFilter] = useState(dueDate);

    const {
        ASSIGNED_TO,
        CASE_CREATED_DATE,
        COMPANY,
        CREATED_BY,
        DUE_DATE,
        USERS_INVOLVED,
        //VISIBLE_BY
    } = useTranslation();

    const { error, data } = useData(caseSchema);

    useEffect(() => {
        if (data) {
            const normalizedEntityData = normalizeEntityData({ data, schema:caseSchema });

            setEntities(normalizedEntityData);
        }
    }, [data, caseSchema, setEntities]);

    const handleStartDateChange = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId: id,
                    userId: userId
                }
            );
            formObject.text = new Date(formObject.text)

            updateStartDate(formObject);
        }, [id, updateStartDate, userId]
    );

    useEffect(() => {
        if (!entities) {
            return;
        }

        const selectedEntityInitialArray = [];
        for (const caseEntity of caseEntities) {
            selectedEntityInitialArray.push(entities
                .find(entity => entity.label === caseEntity)
            );
        }

        const selectedValues = selectedEntityInitialArray
            .filter(value => value !== undefined)
            .map(({ value }) => value);

        const selectedLabels = selectedEntityInitialArray
            .filter(value => value !== undefined)
            .map(({ label }) => label);

        setSelectedEntityValues(selectedValues)
        setSelectedEntityLabels(selectedLabels)

    }, [caseEntities, entities])

    useEffect(() => {
        if (!updatedStartDate) {
            return;
        }

        setStartDateFilter(updatedStartDate);
        resetUpdateStartDate();
    }, [resetUpdateStartDate, updatedStartDate]);

    const handleEndDateChange = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId: id,
                    userId: userId
                }
            );
            formObject.text = new Date(formObject.text)

            

            updateEndDate(formObject);
    }, [id, updateEndDate, userId]);

    useEffect(() => {
        if (!updatedEndDate) {
            return;
        }

        setEndDateFilter(updatedEndDate);
        resetUpdateEndDate();
    }, [resetUpdateEndDate, updatedEndDate]);

    const saveCaseUsers = useCallback(
        ({ userId }) => {

            const formObject = {
                caseId: id,
                caseName,
                dueDate,
                userId: userId ? userId : null
            }
            if (caseUsers?.map(({ id }) => id)?.includes(userId)) {
                deleteCaseUser(formObject);
            } else {
                saveCaseUser(formObject);             
            }
        }, [caseName, caseUsers, deleteCaseUser, dueDate, id, saveCaseUser]
    );

    useEffect(() => {
        if (!savedCaseUser) {
            return;
        }
        window.location.reload();
    }, [resetSaveCaseUser, savedCaseUser]);

    useEffect(() => {
        if (!deletedCaseUser) {
            return;
        }
        window.location.reload();
    }, [deletedCaseUser, resetDeleteCaseUser])

    const saveCreatedBy = useCallback(({ userId }) => {
        const formObject = {
            caseId: id,
            caseName,
            dueDate,
            userId: userId
        }

        updateCreatedBy(formObject);
    }, [caseName, dueDate, id, updateCreatedBy]);

    const saveUsersInvolved = useCallback(({ userId:usersInvolvedId }) => {
        const usersInvolvedIds = usersInvolved.reduce((accumulator, user) => {
            accumulator.push(user?.id)
            return accumulator
        },[]);
        const newUsers = usersInvolvedIds.includes(usersInvolvedId) ? [...usersInvolvedIds].filter(item => item !== usersInvolvedId) : [...usersInvolvedIds, usersInvolvedId]
        const formObject = {
            caseId: id,
            caseName,
            changeUserId: userId,
            dueDate,
            users: newUsers
        }

        updateUsersInvolved(formObject);
    }, [caseName, dueDate, id, updateUsersInvolved, userId, usersInvolved]);

    useEffect(() => {
        if (!updatedCreatedBy) {
            return;
        }
    }, [resetUpdateCreatedBy, updatedCreatedBy]);

    useEffect(() => {
        if (!updatedUsersInvolved) {
            return;
        }
        window.location.reload();
    }, [resetUpdateUsersInvolved, updatedUsersInvolved]);

    const handleEntities = useCallback(() => {
        setIsEditingEntities(true);
    }, []);

    const handleEntitySave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId: id,
                    userId: userId
                }
            );
            formObject['entities'] = JSON.parse(formObject.selected)

            updateEntities(formObject);
        }, [id, updateEntities, userId]
    );

    useEffect(() => {
        if (!updatedEntities) {
            return;
        }

        setIsEditingEntities(false);
        setSelectedEntityValues(updatedEntities);

        const selectedEntityValuesArray = [];
        for (const updatedEntityValue of updatedEntities) {
            selectedEntityValuesArray.push(entities
                .find(entity => entity.value === updatedEntityValue)
            );
        }

        const selectedLabels = selectedEntityValuesArray
            .map(({ label }) => label);

        setSelectedEntityLabels(selectedLabels);

        resetUpdateEntities();

    }, [entities, isEditingEntities, resetUpdateEntities, updatedEntities, setSelectedEntityValues]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);

        return;
    }

    return (
        <MetaPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <EditableDatePickerWithLabel
                    date={startDateFilter}
                    hasEdit={!closed}
                    onSave={handleStartDateChange}
                    text={CASE_CREATED_DATE}
                />

                <EditableDatePickerWithLabel
                    date={endDateFilter}
                    hasEdit={!closed}
                    onSave={handleEndDateChange}
                    text={DUE_DATE}
                />

                <SelectUsersDrawer 
                    onChange={saveCaseUsers} 
                    selectedUsers={caseUsers} 
                    users={consultancyUsers ? consultancyUsers.filter(item => item.tenants.includes(caseSchema)) : []}
                >
                    {({ toggle }) => (
                        <UserList
                            className={styles.list}
                            hasEdit={!closed}
                            key={ASSIGNED_TO}
                            onEditClick={toggle}
                            title={ASSIGNED_TO}
                            users={caseUsers}
                        />
                    )}
                </SelectUsersDrawer>

                {/* <SelectUsersDrawer
                    selectedUsers={usersInvolved}
                    users={consultancyUsers ? consultancyUsers.filter(item => item.tenants.includes(caseSchema)) : []}
                >
                    {({ toggle }) => (
                        <UserList
                            className={styles.list}
                            onEditClick={toggle}
                            title={USERS_INVOLVED}
                            users={usersInvolved}
                        />
                    )}
                </SelectUsersDrawer> */}

                <SelectUsersDrawer 
                    onChange={saveCreatedBy} 
                    selectedUsers={[createdBy]} 
                    users={consultancyUsers ? consultancyUsers.filter(item => item.tenants.includes(caseSchema)) : []}>
                    {({ toggle }) => (
                        <UserList
                            className={styles.list}
                            hasEdit={!closed}
                            key={CREATED_BY}
                            onEditClick={toggle}
                            title={CREATED_BY}
                            users={[createdBy]}
                        />
                    )}
                </SelectUsersDrawer>

                {/* <SelectUsersDrawer
                    selectedUsers={visibleBy}
                    users={consultancyUsers ? consultancyUsers.filter(item => item.tenants.includes(caseSchema)) : []}
                >
                    {({ toggle }) => (
                        <UserList
                            className={styles.list}
                            onEditClick={toggle}
                            title={VISIBLE_BY}
                            users={visibleBy}
                        />
                    )}
                </SelectUsersDrawer> */}

                {!isEditingEntities &&
                <EntityTags
                    className={styles.entityTags}
                    hasEdit={!closed}
                    items={(selectedEntityLabels ?? [])}
                    onEditClick={handleEntities}
                />                    
                }

                {isEditingEntities &&
                <EditableMultiSelectWithLabel
                    className={styles.editingEntities}
                    editOn={true}
                    hasEdit={!closed}
                    items={entities}
                    label={'Entity Tag'}
                    onClose={() => setIsEditingEntities(false)}
                    onSave={handleEntitySave}
                    selected={selectedEntityValues}
                />
                }

                <SelectUsersDrawer 
                    onChange={saveUsersInvolved} 
                    selectedUsers={usersInvolved} 
                    users={consultancyUsers ? consultancyUsers.filter(item => item.tenants.includes(caseSchema)) : []}>
                    {({ toggle }) => (
                        <UserList
                            className={styles.list}
                            key={USERS_INVOLVED}
                            onEditClick={toggle}
                            title={USERS_INVOLVED}
                            users={usersInvolved}
                        />
                    )}
                </SelectUsersDrawer>

                {tenants.length > 1 &&
                    <TextWithLabel className={styles.entityTags} label={COMPANY} text={caseSchema ? titleCase(caseSchema) : '-'} />
                }

                {activityLinkages.length > 0 &&
                    <SectionWithBorder className={styles.sectionWithBorder}>
                        <Title className={styles.withLabel} text={`Linked Activities`} />
                        
                        {activityLinkages.map((activityLink) => (
                            <ImportantLinkInNewTab
                                openNewTab={activityLink}
                                text={`${activityLink?.name}`}
                                to={ACTIVITY_ROUTE.replace(':activityId', activityLink?.id).replace(':activitySchema', caseSchema)}
                            />
                        ))}

                    </SectionWithBorder>
                }

                {caseLinkages.length > 0 &&
                    <SectionWithBorder className={styles.sectionWithBorder}>
                            <Title className={styles.withLabel} text={`Linked Cases`} />

                            {caseLinkages.map((caseLink) => (
                                <ImportantLinkInNewTab
                                    className={styles.importantLinkInNewTab}
                                    openNewTab={caseLink}
                                    text={`${caseLink?.name}`}
                                    to={CASE_ROUTE.replace(':caseId', caseLink?.id).replace(':caseSchema', caseSchema)}
                                />
                            ))}
                    </SectionWithBorder>
                }

                {logLinkages.length > 0 &&
                    <SectionWithBorder className={styles.sectionWithBorder}>
                            <Title className={styles.withLabel} text={`Linked Logs`} />

                            {logLinkages.map((logLink) => {
                                return (
                                <ImportantLinkInNewTab
                                    className={styles.importantLinkInNewTab}
                                    openNewTab={logLink}
                                    text={`${logLink?.name}`}
                                    to={LOG_ROUTE.replace(':logId', logLink?.id).replace(':caseSchema', caseSchema)}
                                />
                            )})}
                    </SectionWithBorder>
                }
            </PanelBody>
        </MetaPanel>
    );
};

export default CaseMetaPanel;
