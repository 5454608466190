import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('complianceLogEntry');

    return {
        BACK: t('back'),
        COMPLIANCE_LOG_ENTRY: t('complianceLogEntry')
    };
};

export default useTranslation;
