import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('deleteAttachmentModal');

    return {
        CANCEL: t('cancel'),
        YOU_ARE_ABOUT_TO_DELETE_ATTACHMENT: t(
            'youAreAboutToDeleteAttachment'
        )
    };
};

export default useTranslation;
