import { useCallback, useState } from 'react';

import CancelSaveButtons from 'components/CancelSaveButtons';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import PolicySectionForm from 'components/PolicySectionForm';

import useForm from 'hooks/useForm';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const PolicySectionModal = ({ categories, onClose, onSave, setSectionCategories, title }) => {
    

    const [_errorMessage, setErrorMessage] = useState();

    const form = useForm({
        categories: '',
        description: '',
        riskLevel: '',
        sectionName: ''
    });

    const { handleForm } = form;

    const handleCancel = useCallback(() => {
        
        if (isFunction(onClose)) {
            onClose(false);
        }
    }, [onClose]);

    const handleSave = useCallback(() => {
        

        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }

        if (isFunction(onSave)) {
            onSave({ form });
        }
    }, [form, handleForm, onSave]);

    return (
        <Modal isOpen onClose={onClose}>
            <PanelBody>
                <PanelTitle className={styles.panelTitle} text={title} />

                <PolicySectionForm categories={categories} form={form} setSectionCategories={setSectionCategories} />

                <CancelSaveButtons
                    onCancel={handleCancel}
                    onSave={handleSave}
                />
            </PanelBody>
        </Modal>
    );
};

export default PolicySectionModal;
