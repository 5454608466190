import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createCaseModal');

    return {
        ASSIGNED_TO: t('assignedTo'),
        CASE_CREATION_REASON: t('caseCreationReason'),
        CASE_NAME: t('caseName'),
        CASE_TYPE: t('caseType'),
        CREATE_CASE: t('createCase'),
        DUE_DATE: t('dueDate'),
        ENTER_CASE_NAME: t('enterCaseName'),
        POLICY_REFERENCE: t('policyReference'),
        RISK_LEVEL_CASE: t('riskLevelCase')
    };
};

export default useTranslation;
