import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { addPerson } = state;

const { addPersonAtom } = addPerson.atoms;

const MUTATE_LOG_ENTRY = (/*tenantSchema*/) => gql`
    mutation mutateTest {
        __typename ## Placeholder value
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_LOG_ENTRY, providedSchema);

    const createLogEntry = useRecoilCallback((/* {  set } */) => variables => {
        const runMutation = async () => {
            await mutation({
                variables
            });

            
            
            // set(createLogEntryAtom, WHATEVER_YOU_WANT_TO_PUT_IN_GLOBAL_STATE);
        };

        runMutation();
    });

    return [useRecoilValue(addPersonAtom), createLogEntry];
};

export default useData;
