import { useQuery } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import generateQueryFromDict from 'utilities/generateQueryFromDict';

const query = (tenantSchema, userGQL, usersGQL) => `
    ${tenantSchema}_case {
        activity {
            name
            id
        }

        case_name

        caseTypeByCaseType {
            name
            id
        }
        created_date
        closed
        closed_date
        due_date
        id
        risk_color
        risk_label

        creation_reason
        proposed_solution
        resolution

        case_entities {
            entity {
                name
            }
        }
        
        originated
        
        user_cases {
            tenant_user {
                id
                ${usersGQL}
            }
        }
        case_user_involveds {
          tenant_user {
            id
            ${userGQL}
          }
        }
    }
`;

const useData = () => {
    const { useQuerySchemas, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
    var userGQL = '';
    var usersGQL = '';
    if(querySchemas.length > 0){
        for(const s of querySchemas){
            usersGQL += `users_${s.value} {
                first_name
                last_name
                profile_photo
            }`
            userGQL += `user_${s.value} {
                first_name
                last_name
                profile_photo
            }`
        }
    }else if(usedSchema){
        usersGQL += `users_${usedSchema} {
            first_name
            last_name
            profile_photo
        }`
        userGQL += `user_${usedSchema} {
            first_name
            last_name
            profile_photo
        }`
    }
    const generatedQuery = generateQueryFromDict({query, schema:usedSchema, tenants, userGQL, usersGQL});
    return useQuery(generatedQuery, { fetchPolicy: 'no-cache' });
};

export default useData;
