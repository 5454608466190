import { forwardRef } from 'react';

import styles from './styles.module.scss';

const Form = forwardRef(({ children, className = '' }, ref) => (
    <form className={`${className} ${styles.form}`} ref={ref}>
        {children}
    </form>
));

export default Form;
