import PreviousButton from 'components/PreviousButton';

import styles from './styles.module.scss';

const NextButton = ({ className = '', isDisabled = false, onClick }) => (
    <PreviousButton
        className={`${className} ${styles.nextButton}`}
        isDisabled={isDisabled}
        onClick={onClick}
    />
);

export default NextButton;
