const kebabCase = text => text.toLowerCase().replace(/\s/g, '-');

const normalizeDropdown = items =>
    items.map(item => {
        if (typeof item === 'string') {
            return {
                label: item,
                value: kebabCase(item)
            };
        }

        return item;
    });

const normalizeDropdowns = (items, selections) =>
    items.reduce((accumulator, item) => {
        const dropdownCopy = { ...item };

        dropdownCopy.options = normalizeDropdown(dropdownCopy.options);

        if (!('id' in dropdownCopy)) {
            dropdownCopy.id = kebabCase(dropdownCopy.title);
        }
        dropdownCopy.selectedIndex = selections[dropdownCopy.id]
        accumulator.push(dropdownCopy);

        return accumulator;
    }, []);
    
const resolveActivities = logActivities =>
    logActivities.reduce((accumulator, activities) => {
        const { name, risk_label, closed_date, due_date, start_date, instructions, id } =
            activities.activity;

        accumulator.push({
            closedDate: closed_date,
            dueDate: due_date,
            id,
            instructions,
            name,
            riskLabel: risk_label,
            startDate: start_date
        });

        return accumulator;
    }, []);

const resolveCases = logCases =>
    logCases.reduce((accumulator, cases) => {
        const {
            caseTypeByCaseType,
            closed_date,
            risk_label,
            due_date,
            created_date,
            creation_reason,
            id
        } = cases.case;

        accumulator.push({
            closedDate: closed_date,
            createDate: created_date,
            creationReason: creation_reason,
            dueDate: due_date,
            id,
            name: caseTypeByCaseType.name,
            riskLabel: risk_label
        });

        return accumulator;
    }, []);

const resolveEntities = logEntities =>
    logEntities.reduce((accumulator, entities) => {
        const { name } = entities.entity;

        accumulator.push(name);

        return accumulator;
    }, []);

const resolvePolicies = logPolicies =>
    logPolicies.reduce((accumulator, policies) => {
        const { id:documentId, name:documentName } = policies.document;
        const { id:sectionId, name:sectionName } = policies.section || { id:null, name:null };

        accumulator.push({
            documentId,
            documentName,
            sectionId,
            sectionName
        });

        return accumulator;
    }, []);

const resolveTypes = logTypes =>
    logTypes.reduce((accumulator, types) => {
        const { name, id, dropdown } = types.log_type;

        accumulator.push({
            dropdown,
            id,
            name
        });

        return accumulator;
    }, []);

const resolveUsers = logUsers =>
    logUsers.reduce((accumulator, users) => {
        const { tenant_user: tenantUser } = users;

        const id = tenantUser.id;
        const user = tenantUser[`user_${localStorage.schema}`];

        accumulator.push({
            firstName: user.first_name,
            id,
            lastName: user.last_name
        });

        return accumulator;
    }, []);

const normalizeData = ({ data, schema }) =>
    data[`${schema}_log`].reduce((accumulator, logs) => {
        const {
            created_date: createdDate,
            date_of_occurrence: dateOfOccurrence,
            description,
            dropdown_selections: dropdownSelections,
            id: logId,
            log_activities: logActivities,
            risk_label: riskLabel,
            tenant_user: logCreatedBy,
            log_cases: logCases,
            log_entities: logEntities,
            log_policies: logPolicies,
            log_types: logTypes,
            log_user_involveds: logUsersInvolved,
            log_visible_tos: logVisibleTo
        } = logs;

        const { name: logType, dropdown } = logTypes[0].log_type;

        const activities = resolveActivities(logActivities);
        const cases = resolveCases(logCases);
        const entities = resolveEntities(logEntities);
        const policies = resolvePolicies(logPolicies);
        const types = resolveTypes(logTypes);
        const usersInvolved = resolveUsers(logUsersInvolved);
        const visibleTo = resolveUsers(logVisibleTo);

        const createdBy = {
            firstName:
                logCreatedBy[`users_${localStorage.schema}`][0].first_name,
            id: logCreatedBy.id,
            lastName: logCreatedBy[`users_${localStorage.schema}`][0].last_name
        };

        const entry = {
            category: logType,
            createdAt: createdDate,
            createdBy: {
                avatar: `/UserImages/${createdBy.id}.png`,
                firstName: createdBy.firstName,
                id: createdBy.id,
                lastName: createdBy.lastName,
                type: 'user'
            },
            description,
            dropdownSelections: dropdownSelections,
            dropdowns: (Array.isArray(dropdown)) ? normalizeDropdowns(dropdown, dropdownSelections) : [],
            id: logId,
            occuredOn: dateOfOccurrence,
            riskLevel: riskLabel
        };

        accumulator = {
            activities,
            cases,
            entities,
            entry,
            id: logId,
            name: logType,
            policies,
            riskLabel,
            types,
            usersInvolved,
            visibleTo
        };
        return accumulator;
    }, {});

export default normalizeData;
