import { useCallback, useState } from 'react';

import ActionMenuItem from 'components/ActionMenuItem';
import ConnectorIcon from 'components/icons/ConnectorIcon';
import CreateLogEntryModal from 'components/CreateLogEntryModal';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

const CloseAndAddLogEntryMenuListItem = ({
    activityName,
    activityRecord,
    activityType,
    caseName,
    caseType,
    onClose,
    onSave,
    toClose = true,
}) => {
    const { ADD_LOG_ENTRY, CLOSE_AND_ADD_LOG_ENTRY } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsModalOpen(!isModalOpen);
    }, [isModalOpen, setIsModalOpen]);

    const handleModalClose = useCallback(() => {
        if (isFunction(onClose)) {
            onClose();
        }

        setIsModalOpen(false);
    }, [onClose, setIsModalOpen]);

    const handleSave = useCallback(
        ({ form }) => {
            if (isFunction(onSave)) {
                onSave({ form });
            }

            setIsModalOpen(false);
        },
        [onSave, setIsModalOpen]
    );

    return (
        <>
            <ActionMenuItem
                icon={<ConnectorIcon />}
                onClick={handleClick}
                text={toClose ? CLOSE_AND_ADD_LOG_ENTRY : ADD_LOG_ENTRY}
            />

            {isModalOpen && (
                <CreateLogEntryModal
                    activityName={activityName}
                    activityRecord={activityRecord}
                    activityType={activityType}
                    caseName={caseName}
                    caseType={caseType}
                    onClose={handleModalClose}
                    onSave={handleSave}
                    toClose={toClose}
                />
            )}
        </>
    );
};

export default CloseAndAddLogEntryMenuListItem;
