import { gql } from '@apollo/client';
import { client } from 'graphql-client';

const lockActivityFormsFromActivityMutationGql = tenantSchema => gql`
		mutation mutateSetCurrentActivityForm($activityId: Int!, $locked: Boolean!) {
				update_${tenantSchema}_activity_forms(where: {activity:{id: {_eq: $activityId}}}, _set: {locked: $locked}) {
					returning {
						id,
						locked,
					}
				}
		}
`;

const setLockActivityFormsFromActivity = async ({
    activityId,
    locked,
    tenantSchema
}) => {
    return client.mutate({
        fetchPolicy: 'no-cache',
        mutation: lockActivityFormsFromActivityMutationGql(tenantSchema),
        variables: { activityId, locked }
    });
};

const setLockedActivityFormsFromActivity = async ({
    activityId,
    tenantSchema
}) => {
    return setLockActivityFormsFromActivity({
        activityId,
        locked: true,
        tenantSchema
    });
};

const setUnlockActivityFormsFromActivity = async ({
    activityId,
    tenantSchema
}) => {
    return setLockActivityFormsFromActivity({
        activityId,
        locked: false,
        tenantSchema
    });
};

export const useLockActivityFormsFromActivity = () => {
    return {
        setLockedActivityFormsFromActivity,
        setUnlockActivityFormsFromActivity
    };
};
