import { useCallback, useState } from 'react';

import Buttonize from 'components/Buttonize';
import CheckIcon from 'components/icons/CheckIcon';
import CloseIcon from 'components/icons/CloseIcon';
import PenIcon from 'components/icons/PenIcon';
import TextInput from 'components/TextInput';
// import TextWithLabel from 'components/TextWithLabel';

import useForm from 'hooks/useForm';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';
import ImportantText from 'components/ImportantText';

const EditableTextNoLabel = ({ className = '', label, onSave, text = '' }) => {
    const [isEditing, setIsEditing] = useState(false);

    const form = useForm({
        text: ''
    });

    // useEffect(() => {
    //     if (!text) {
    //         // setIsEditing(true);
    //         return;
    //     }

    //     setDisplayText(text);
    //     // setIsEditing(false);
    // }, [setIsEditing, text])

    const { handleSubmit, handleValid } = form;

    const handleCancel = useCallback(() => {
        setIsEditing(false);
    }, []);

    const handleEdit = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleSave = useCallback(() => {
        if (isFunction(onSave)) {
            onSave({ form });
        }
        // setDisplayText(text);
        setIsEditing(false);
    }, [form, onSave, setIsEditing]);

    return (
        <div className={`${className} ${styles.editableText}`}>
            {!isEditing && (
                <>
                    <ImportantText
                        className={styles.bodyText}
                        label={label}
                        // text={text}
                        text={text}
                    />

                    <Buttonize
                        className={styles.buttonize}
                        onClick={handleEdit}
                    >
                        <PenIcon />
                    </Buttonize>
                </>
            )}

            {isEditing && (
                <>
                    <TextInput
                        className={styles.textBox}
                        label={label}
                        onChange={handleValid('text')}
                        onSubmit={handleSubmit('text')}
                        // value={text}
                        value={text}
                    />

                    <Buttonize
                        className={styles.closeIcon}
                        onClick={handleCancel}
                    >
                        <CloseIcon />
                    </Buttonize>

                    <Buttonize
                        className={styles.checkIcon}
                        onClick={handleSave}
                    >
                        <CheckIcon />
                    </Buttonize>
                </>
            )}
        </div>
    );
};

export default EditableTextNoLabel;
