import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('policyForm');

    return {
        ADD_DESCRIPTION: t('addDescription'),
        CATEGORIES: t('categories'),
        DESCRIPTION: t('description'),
        EFFECTIVE_DATE: t('effectiveDate'),
        LAST_REVIEW_DATE: t('lastReviewDate'),
        PARSE_CONTENTS: t('parseContents'),
        PARSE_FILE: t('parseFile'),
        SECTION_PREFIX: t('sectionPrefix'),
        SECTION_SUFFIX: t('sectionSuffix'),
        SELECT_DOCUMENT: t('selectDocument'),
        UPLOAD: t('upload')
    };
};

export default useTranslation;
