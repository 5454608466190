import { useCallback, useRef, useState } from 'react';

const useForm = initialData => {
    const formKeys = Object.keys(initialData);

    const [errorMessage, setErrorMessage] = useState('');
    const formErrors = useRef({});
    const formData = useRef(initialData);

    const setFormData = useCallback(mutatedFormData => {
        formData.current = mutatedFormData;
    }, []);

    const setFormErrors = useCallback(mutatedFormErrors => {
        formErrors.current = mutatedFormErrors;
    }, []);

    const buildFormData = useCallback(
        formValues =>
            formKeys.reduce((form, formKey) => {
                if (formKey === 'assignee-') {
                    var i = 1;
                    while (true) {
                        const variableKey = `${formKey}${i}`;
                        if (!Object.keys(formValues).includes(variableKey)) {
                            break;
                        }
                        if (formValues[variableKey].constructor !== Array){
                            form.append(variableKey, formValues[variableKey]);
                        } else {
                            form.append(variableKey, JSON.stringify(formValues[variableKey]));
                        }
                        i++;
                    }
                } else {
                    if (formValues[formKey].constructor !== Array){
                        form.append(formKey, formValues[formKey]);
                    } else {
                        form.append(formKey, JSON.stringify(formValues[formKey]));
                    }
                }
                return form;
            }, new FormData()),
        [formKeys]
    );

    const handleError = useCallback(
        formField => errorMessage => {
            const updatedFormData = { ...formData.current };
            const updatedFormErrors = { ...formErrors.current };

            updatedFormData[formField] = '';
            updatedFormErrors[formField] = errorMessage;

            setFormData(updatedFormData);
            setFormErrors(updatedFormErrors);
        },
        [formData, formErrors, setFormData, setFormErrors]
    );

    const handleForm = useCallback(() => {
        const errors = Object.values(formErrors.current);
        const hasErrors = errors.filter(value => typeof value === 'string');

        if (hasErrors.length) {
            setErrorMessage(
                hasErrors.length > 1
                    ? 'Please correct the errors on this form.'
                    : hasErrors[0]
            );

            return errorMessage;
        }

        return buildFormData(formData.current);
    }, [buildFormData, errorMessage, formData, formErrors]);

    const handleSubmit = useCallback(
        formField => (_, error) => {
            if (error) {
                handleError(formField)(error);
            }
        },
        [handleError]
    );

    const handleValid = useCallback(
        formField => fieldValue => {
            const updatedFormData = { ...formData.current };
            const updatedFormErrors = { ...formErrors.current };

            

            updatedFormErrors[formField] = false;
            updatedFormData[formField] = fieldValue;

            

            setFormData(updatedFormData);
            setFormErrors(updatedFormErrors);
        },
        [formData, formErrors, setFormData, setFormErrors]
    );

    const handleChange = useCallback(
        formField => newValue => {
            

            handleValid(formField)(newValue);
        },
        [handleValid]
    );

    return {
        handleChange,
        handleError,
        handleForm,
        handleSubmit,
        handleValid
    };
};

export default useForm;
