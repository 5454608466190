import Avatars from 'components/Avatars';
import BodyText from 'components/BodyText';

import styles from './styles.module.scss';

const AvatarsWithText = ({ assigned, className = '', section }) => {
    const users = assigned.reduce(
        (accumulator, { firstName, id, lastName, groupStatus}) => {
            accumulator.push({
                id: id,
                name:`${firstName} ${lastName}`,
                negative: groupStatus==='inprogress' && section==='overdue' ? false : false,
                positive: groupStatus==='complete' ? true : false,
                warning: false
            });
            return accumulator
        }, [])
    const images = assigned.reduce(
        (accumulator, { id }) => {
            accumulator.push(id);
            return accumulator
        }, [])
    const names = []
    for (const user of users){
        names.push(user.name)
    }
    const text = names.join(", ") || '';
    return(
        <div className={`${className} ${styles.avatarWithText}`}>
            <Avatars images={images} users={users} />

            <BodyText text={text} />
        </div>
    );
};

export default AvatarsWithText;
