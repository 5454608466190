import { useState } from 'react';

export const useVariables = defaultState => {
    const [variables, setVariables] = useState(defaultState);

    const setVariableValue = key => value =>
        setVariables(prevData => ({
            ...prevData,
            [key]: value
        }));

    return [variables, setVariableValue, setVariables];
};
