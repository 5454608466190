const normalizeData = ({ data, schema }) =>
    data[`${schema}_activity_type`].reduce((accumulator, group) => {
        const {   
            id,
            name,
            risk_label: riskLevel,
            visible_bies: visibleBy
        } = group;

        

        const visibleByArray = visibleBy.reduce((accumulator, assigned) => {
            const type = assigned?.group ? "group" : "user";
            const userId = assigned?.group ? assigned?.group?.id : assigned?.tenant_user[`users_${schema}`][0]?.tenant_user_id;
            const userName = assigned?.group ? `${assigned?.group?.name} Group` : `${assigned?.tenant_user[`users_${schema}`][0]?.first_name} ${assigned?.tenant_user[`users_${schema}`][0]?.last_name}`;
            accumulator.push({id: userId, label:userName, type});
            return accumulator;
        }, []);

        accumulator.push({
            id,
            name,
            riskLevel,
            visibleTo: visibleByArray
        });

        return accumulator;
    }, []);

export default normalizeData;
