import { useEffect, useState } from 'react'; 

// import AsideLink from 'components/AsideLink';
import Carousel from 'components/Carousel';
// import ComplianceLogTypeStatistics from 'components/ComplianceLogTypeStatistics';
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';

import translations from './constants';

import ActivitiesStatistics from 'components/ActivitiesStatistics';

import styles from './styles.module.scss';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
// import useActivities from 'components/SummarizedActivities/hooks/useActivities';
// import useConfig from 'hooks/useConfig';

const GlancePanel = ({ className = '' }) => {
    const { useActivities, useIsAllFilter, useIsFlaggedFilter, useUserId } = useGlobalStateHooks();
    const { GLANCE } = translations();
    // const { PROGRAM_OVERVIEW } = useConfig();

    const [activities] = useActivities();
    const [isAll] = useIsAllFilter();
    const [isFlagged] = useIsFlaggedFilter();
    const [userId] = useUserId();

    const [filteredCompleteActivitiesLength, setFilteredCompleteActivities] = useState();
    const [filteredInProgressActivitiesLength, setFilteredInProgressActivities] = useState();
    const [filteredUpcomingActivitiesLength, setFilteredUpcomingActivities] = useState();
    const [filteredOverdueActivitiesLength, setFilteredOverdueActivities] = useState();
    const [hasCompletedItems, setHasCompletedItems] = useState();
    const [hasOverdueItems, setHasOverdueItems] = useState();
    const [hasInProgressItems, setHasInProgressItems] = useState();
    const [hasUpcomingItems, setHasUpcomingItems] = useState();
    const [completeActivities, setCompletedItems] = useState();
    const [overdueActivities, setOverdueItems] = useState();
    const [inProgressActivities, setInProgressItems] = useState();
    const [upcomingActivities, setUpcomingItems] = useState();

    useEffect(() => {
        const {
            completed,
            inProgress,
            overdue,
            upcoming
        } = activities;

        setCompletedItems(completed);
        setInProgressItems(inProgress);
        setOverdueItems(overdue);
        setUpcomingItems(upcoming);
    }, [activities, setCompletedItems, setOverdueItems, setInProgressItems, setUpcomingItems])
    
    useEffect(() => {
        if (completeActivities) {
            const dateFilteredCompleteActivities = (completeActivities) ? completeActivities.filter(function (a) { return new Date(a.closedDate) >= new Date(new Date().setDate(new Date().getDate() - 60))}) : [];
               const flagFilteredCompleteActivities = !isFlagged
                ? dateFilteredCompleteActivities.filter(
                    ({ flagged }) => flagged
                )
                : dateFilteredCompleteActivities;

            const filteredCompleteActivities = isAll 
                ? flagFilteredCompleteActivities 
                : flagFilteredCompleteActivities.filter(
                    ({ assigned, isHierarchical, reviewer }) => (!isHierarchical && assigned.some(({ id }) => id === Number(userId)) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') || 
                    (isHierarchical && assigned.some(({ id }) => id === Number(userId)) && (assigned.filter(x => x.id === Number(userId))[0].assignmentNumber === 1 || assigned.filter(y => y.assignmentNumber < assigned.filter(x => x.id === Number(userId))[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                    (assigned.every(x => x.groupStatus === 'complete') && reviewer.some(({ id }) => id === Number(userId))) ||
                    (assigned.every(x => x.groupStatus === 'complete') && reviewer.every(x => x.groupStatus === 'complete'))
                );

            setFilteredCompleteActivities(filteredCompleteActivities.length);
            setHasCompletedItems(filteredCompleteActivities?.length > 0);
        }
    }, [isAll, isFlagged, completeActivities, setFilteredCompleteActivities, setHasCompletedItems, userId]);
    
    useEffect(() => {
        if (inProgressActivities) {            
            const flagFilteredInProgressActivities = !isFlagged
                ? inProgressActivities.filter(
                    ({ flagged }) => flagged
                )
                : inProgressActivities;

            const filteredInProgressActivities = isAll 
                ? flagFilteredInProgressActivities 
                : flagFilteredInProgressActivities.filter(
                    ({ assigned, isHierarchical, reviewer }) => (!isHierarchical && assigned.some(({ id }) => id === Number(userId)) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') || 
                    (isHierarchical && assigned.some(({ id }) => id === Number(userId)) && (assigned.filter(x => x.id === Number(userId))[0].assignmentNumber === 1 || assigned.filter(y => y.assignmentNumber < assigned.filter(x => x.id === Number(userId))[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                    (assigned.every(x => x.groupStatus === 'complete') && reviewer.some(({ id }) => id === Number(userId)))
                );

            setFilteredInProgressActivities(filteredInProgressActivities.length);
            setHasInProgressItems(filteredInProgressActivities?.length > 0);
        }
    }, [isAll, isFlagged, inProgressActivities, setFilteredInProgressActivities, setHasInProgressItems, userId]);

    useEffect(() => {
        if (upcomingActivities){
            const dateFilteredUpcomingActivities = (upcomingActivities) ? upcomingActivities.filter(function (a) { return new Date(a.dueDate) <= new Date(new Date().setDate(new Date().getDate() + 45))}) : [];           
            const flagFilteredUpcomingActivities = !isFlagged
                ? dateFilteredUpcomingActivities.filter(
                    ({ flagged }) => flagged
                )
                : dateFilteredUpcomingActivities;

            const filteredUpcomingActivities = isAll 
                ? flagFilteredUpcomingActivities 
                : flagFilteredUpcomingActivities.filter(
                    ({ assigned, isHierarchical, reviewer }) => (!isHierarchical && assigned.some(({ id }) => id === Number(userId)) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') || 
                    (isHierarchical && assigned.some(({ id }) => id === Number(userId)) && (assigned.filter(x => x.id === Number(userId))[0].assignmentNumber === 1 || assigned.filter(y => y.assignmentNumber < assigned.filter(x => x.id === Number(userId))[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                    (assigned.every(x => x.groupStatus === 'complete') && reviewer.some(({ id }) => id === Number(userId)))
                );

            setFilteredUpcomingActivities(filteredUpcomingActivities.length);
            setHasUpcomingItems(filteredUpcomingActivities?.length > 0);
        }
    }, [isAll, isFlagged, upcomingActivities, setFilteredUpcomingActivities, setHasUpcomingItems, userId]);

    useEffect(() => {
        if (overdueActivities){
            const flagFilteredOverdueActivities = !isFlagged
                ? overdueActivities.filter(
                    ({ flagged }) => flagged
                )
                : overdueActivities;

            const filteredOverdueActivities = isAll 
            ? flagFilteredOverdueActivities 
            : flagFilteredOverdueActivities.filter(
                ({ assigned, isHierarchical, reviewer }) => (!isHierarchical && assigned.some(({ id }) => id === Number(userId)) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') || 
                (isHierarchical && assigned.some(({ id }) => id === Number(userId)) && (assigned.filter(x => x.id === Number(userId))[0].assignmentNumber === 1 || assigned.filter(y => y.assignmentNumber < assigned.filter(x => x.id === Number(userId))[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                (assigned.every(x => x.groupStatus === 'complete') && reviewer.some(({ id }) => id === Number(userId)))
            );

            setFilteredOverdueActivities(filteredOverdueActivities.length);
            setHasOverdueItems(filteredOverdueActivities?.length > 0);
        }
    }, [isAll, isFlagged, overdueActivities, setFilteredOverdueActivities, setHasOverdueItems, userId]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (hasCompletedItems || hasInProgressItems || hasUpcomingItems || hasOverdueItems) {
            console.log('not loading')
            setIsLoading(false);
        }
    }, [hasCompletedItems, hasInProgressItems, hasUpcomingItems, hasOverdueItems, setIsLoading]);

    return (
        <ContentPanel className={`${className} ${styles.glancePanel}`}>
            <PanelBody className={styles.panelBody} isLoading={isLoading}>
                <PanelTitle text={GLANCE} />

                {/* <AsideLink
                    className={styles.asideLink}
                    text="View Program Overview"
                    to={PROGRAM_OVERVIEW}
                /> */}

                <Carousel
                    className={styles.carousel}
                    items={[
                        <ActivitiesStatistics
                            completeCount={filteredCompleteActivitiesLength}
                            inProgressCount={filteredInProgressActivitiesLength}
                            overdueCount={filteredOverdueActivitiesLength}
                            setIsLoading={setIsLoading}
                            upcomingCount={filteredUpcomingActivitiesLength}
                        />,
                        // <ComplianceLogTypeStatistics />,
                        // 'Third slide'
                    ]}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default GlancePanel;


