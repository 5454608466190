import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('dropdownWithTags');

    return {
        SELECT: t('select')
    };
};

export default useConstants;
