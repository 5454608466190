import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { casesAtom } = state.cases.atoms;

const useCases = () => [
    useRecoilValue(casesAtom),
    useSetRecoilState(casesAtom)
];

export default useCases;