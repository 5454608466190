import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('allMeToggle');

    return {
        ALL: t('all'),
        ME: t('me')
    };
};

export default useTranslation;
