import { forwardRef } from 'react';

import Input from 'components/Input';
import Tags from 'components/Tags';

import styles from './styles.module.scss';

const InputWithTags = forwardRef((props, ref) => {
    const {
        className = '',
        items = [],
        onBlur,
        onClick,
        onTagClose,
        placeholder = '',
        value
    } = props;

    

    return (
        <div
            className={`${className} ${styles.inputWithTags}`}
            onClick={onClick}
        >
            <Tags items={items} onClose={onTagClose} />

            <Input
                className={styles.input}
                onBlur={onBlur}
                placeholder={placeholder}
                ref={ref}
                type="text"
                value={value}
            />
        </div>
    );
});

export default InputWithTags;
