import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { logsAtom } = state.logs.atoms;

const useLogs = () => [
    useRecoilValue(logsAtom),
    useSetRecoilState(logsAtom)
];

export default useLogs;