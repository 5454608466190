import { GraphQLClient } from 'graphql-request';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

export const gqlSortTypes = {
    ASC: new EnumType('asc'),
    DESC: new EnumType('desc')
};

export const buildGqlQuery = ({ query = () => ({}) }) => {
    const queryJSON = query();
    const jsonToQuery = jsonToGraphQLQuery(queryJSON, { pretty: true });
    return jsonToQuery;
};

export const gqlClient = ({ accessToken, url }) => {
    return new GraphQLClient(url, {
        headers: {
            ...(accessToken && {
                authorization: `Bearer ${accessToken}`
            })
        }
    });
};

export const gqlMutations = ({ name, mutations = [] }) => {
    return {
        mutation: {
            __name: name,
            ...mutations.filter(Boolean).reduce((acc, mutation) => {
                return { ...acc, ...mutation };
            }, {})
        }
    };
};
