import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('deleteActivityModal');

    return {
        ARE_YOU_SURE: t('areYouSure'),
        CANCEL: t('cancel'),
        DELETE: t('delete'),
        DELETE_ACTIVITY: t('deletingActivity')
    };
};

export default useTranslation;
