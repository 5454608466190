import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addVersionLink');

    return {
        ADD_VERSION: t('addVersion')
    };
};

export default useTranslation;
