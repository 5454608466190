import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('withdrawModal');

    return {
        NO_CANCEL: t('noCancel'),
        YES_WITHDRAW: t('yesWithdraw'),
        YOU_ARE_ABOUT_TO_WITHDRAW: t('youAreAboutToWithdraw')
    };
};

export default useTranslation;
