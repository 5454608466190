import { useQuery } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import generateQueryFromDict from 'utilities/generateQueryFromDict';

const query = (tenantSchema, userGQL, usersGQL) => `
  ${tenantSchema}_activity(where: {_or: [{deleted: {_is_null: true}}, {deleted: {_eq: false}}]}) {
    id
    is_hierarchical
    due_date
    start_date
    name
    closed
    closed_date
    instructions
    dropdown_selections
    user_activities {
      assignment_type
      tenant_user {
        id
        ${usersGQL}
      }
      status
      user_id
    }
    activityLinkagesByLinkActivityId {
      activity {
        due_date
        start_date
        name
        closed_date
        instructions
        user_activities {
          assignment_type
          tenant_user {
            id
            ${usersGQL}
          }
          status
          user_id
        }
      }
    }
    activity_linkages {
      activity {
        due_date
        start_date
        name
        closed_date
        instructions
        user_activities {
          assignment_type
          tenant_user {
            id
            ${usersGQL}
          }
          status
          user_id
        }
      }
    }
    activity_attachments {
      name
      file
    }
    activity_entities {
      entity {
        name
      }
    }
    activity_forms(where: {
			current: {_eq:true}
		}) {
      assignment
      assignment_type
			current
			locked
      due_date
			id
      form {
        name
        notes
        form_questions {
          question {
            id
            name
          }
          activity_form_answers {
            activity_id
            question_id
            answer
            tenant_user {
              id
              ${userGQL}
            }
          }
        }
        is_active
				created_by
      }
    }
    activity_histories {
      message
      tenant_user {
        id
        ${userGQL}
      }
    }
    activity_policies {
      document_section_id
      document {
        description
        created_by
        name
        policy_sections {
          section {
            name
            description
            file
            id
          }
        }
      }
      section {
          description
          id
          name
          riskLevel
      }
    }
    activity_recurrences {
      activity_recurrence {
        recurrence_type
      }
    }
    activity_regulatory_references {
      regulatory_reference {
        name
        description
        is_active
        created_by
        approval_date
      }
    }
    activity_type {
      name
      dropdown
    }
    cases {
      case_name
      case_type
      created_date
      creation_reason
      created_by
      due_date
      closed
      closed_date
    }
    comments {
      created_at
      text
      user_comments {
        tenant_user {
          ${userGQL}
        }
      }
    }
    log_activities {
      log {
        created_by
        created_date
        date_of_occurrence
        description
        log_type
      }
    }
  }
`;

const useData = () => {
  const { useQuerySchemas, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
  const [querySchemas] = useQuerySchemas();
  const [schema] = useSchema();
  const [tenants] = useTenants();
  const [userSetSchema] = useUserSetSchema();
  const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
  var userGQL = '';
  var usersGQL = '';
  if(querySchemas.length > 0){
      for(const s of querySchemas){
          usersGQL += `users_${s.value} {
              first_name
              last_name
          }`
          userGQL += `user_${s.value} {
              first_name
              last_name
          }`
      }
  }else if(usedSchema){
      usersGQL += `users_${usedSchema} {
          first_name
          last_name
      }`
      userGQL += `user_${usedSchema} {
          first_name
          last_name
      }`
  }
  const queryStatement = generateQueryFromDict({query, schema:usedSchema, tenants, userGQL, usersGQL});
  return useQuery(queryStatement, { fetchPolicy: 'no-cache' });
};

export default useData;
