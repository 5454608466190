import { useCallback, useEffect, useState } from 'react';

import PolicySectionModal from 'components/PolicySectionModal';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

const AddPolicySectionModal = ({ categories, id, onClose, providedSchema }) => {
    

    const [addedPolicySection, addPolicySection, reset] = useData(providedSchema);

    const [_errorMessage, setErrorMessage] = useState();
    const [sectionCategories, setSectionCategories] = useState([]);

    const { ADD_POLICY_SECTION } = useTranslation();

    const handleModalClose = useCallback(() => {
        

        if (isFunction(onClose)) {
            onClose(false);
        }
    }, [onClose]);

    const handleSave = useCallback(
        ({ form }) => {
            

            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                handleModalClose();

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {}
            );

            var newCategories = [];
            var existingCategories = [];

            formObject['policyId'] = id;
            formObject['sectionCategories'] = sectionCategories;
            for(const category of sectionCategories){
                if(category?.__isNew__){
                    newCategories.push(category?.value);
                }else{
                    existingCategories.push(category)
                }
            }
            formObject['newCategories'] = newCategories;
            formObject['existingCategories'] = existingCategories;
            //TODO: remove as soon as file uploader is introduced
            formObject['file'] = 'coming_soon.pdf';

            addPolicySection(formObject);
            
        },
        [addPolicySection, handleModalClose, id, sectionCategories]
    );

    useEffect(() => {

        if (!addedPolicySection) {
            return;
        }

        reset();
        window.location.reload();

        handleModalClose();
    }, [addedPolicySection, handleModalClose, reset]);

    return (
        <PolicySectionModal
            categories={categories}
            onClose={handleModalClose}
            onSave={handleSave}
            setSectionCategories={setSectionCategories}
            title={ADD_POLICY_SECTION}
        />
    );
};

export default AddPolicySectionModal;
