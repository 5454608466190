import { atom } from 'recoil';

const updatePublishedVersionAtom = atom({
    default: '',
    key: 'updatePublishedVersion'
});

const state = {
    atoms: {
        updatePublishedVersionAtom
    }
};

export default state;
