import { atom, atomFamily } from 'recoil';

const caseDetailsPanelAtom = atom({
    default: '',
    key: 'caseDetailsPanel'
});

const caseDetailsPanelDropdownsAtom = atom({
    default: [],
    key: 'caseDetailsPanelDropdowns'
});

const caseUserAtom = atom({
    default: '',
    key: 'caseUser'
})

const updateActionPlanAtom = atom({
    default: '',
    key: 'updateActionPlan'
});

const updateCaseEndDateAtom  = atom({
    default: '',
    key: 'updateCaseEndDate'
});

const updateCaseStartDateAtom  = atom({
    default: '',
    key: 'updateCaseStartDateAtom'
});

const updateCaseEntityAtom = atom({
    default: '',
    key: 'updateCaseEntity'
});

const updateCaseNameAtom = atom({
    default: '',
    key: 'updateCaseName'
});

const updateCreatedByAtom = atom({
    default: '',
    key: 'updateCreatedBy'
});

const updateProposedSolutionAtom = atom({
    default: '',
    key: 'updateProposedSolution'
});

const updateResolutionAtom = atom({
    default: '',
    key: 'updateResolution'
});

const updateRiskLevelAtom = atom({
    default: '',
    key: 'updateRiskLevel'
});

const caseDetailsPanelDropdownsAtomFamily = atomFamily({
    default: {
        id: undefined,
        options: [],
        selectedIndex: undefined,
        title: ''
    },
    key: ''
});

const state = {
    atomFamilies: {
        caseDetailsPanelDropdownsAtomFamily
    },

    atoms: {
        caseDetailsPanelAtom,
        caseDetailsPanelDropdownsAtom,
        caseUserAtom,
        updateActionPlanAtom,
        updateCaseEndDateAtom,
        updateCaseEntityAtom,
        updateCaseNameAtom,
        updateCaseStartDateAtom,
        updateCreatedByAtom,
        updateProposedSolutionAtom,
        updateResolutionAtom,
        updateRiskLevelAtom,
    }
};

export default state;