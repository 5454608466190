import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const queryAllLogTypes = logTypesSchema => gql`
    query getLogTypes {
        ${logTypesSchema}_log_types(order_by: {name: asc}) {
            id
            name
            dropdown
            risk_label
        }
    }
`;

const useData = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(queryAllLogTypes(schema), { fetchPolicy: 'no-cache' });
};

export default useData;


// import { useQuery, gql } from '@apollo/client';

// import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

// const queryAllLogTypes = logTypesSchema => gql`
//     query getLogTypes {
//         ${logTypesSchema}_log {
//             id
//             log_type
//             log_visible_tos {
//                 tenant_user {
//                     username
//                 }
//             }            
//             risk_label
//         }
//     }
// `;

// const useData = () => {
//     const { useSchema } = useGlobalStateHooks();

//     const [schema] = useSchema();

//     return useQuery(queryAllLogTypes(schema), { fetchPolicy: 'no-cache' });
// };

// export default useData;
