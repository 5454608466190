import { useState } from 'react';

import Buttons from 'components/Buttons';
import DocumentsModalWithTitle from 'components/DocumentsModalWithTitle';
import Editor from 'components/Editor';

import useForm from 'hooks/useForm';

import useTranslation from './hooks/useTranslation';

const ConfirmRequestUpdateModal = ({ children, onClose }) => {
    const { REQUEST_UPDATES_FROM_SUBMITTER } = useTranslation();

    const [editorState, setEditorState] = useState(`<p></p>`);

    const form = useForm({
        updates: ''
    });

    const { handleValid } = form;

    return (
        <DocumentsModalWithTitle
            onClose={onClose}
            title={REQUEST_UPDATES_FROM_SUBMITTER}
        >
            <Editor
                html={editorState}
                onChange={setEditorState}
                onValid={handleValid('updates')}
            />

            <Buttons>{children(form)}</Buttons>
        </DocumentsModalWithTitle>
    );
};

export default ConfirmRequestUpdateModal;
