import { atom } from 'recoil';

const closeCaseAndCreateNewActivityMenuListItemAtom = atom({
    default: '',
    key: 'closeCaseAndCreateNewActivityMenuListItem'
});

const state = {
    atoms: {
        closeCaseAndCreateNewActivityMenuListItemAtom
    }
};

export default state;
