import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('versionHistory');

    return {
        VERSION_HISTORY: t('versionHistory')
    };
};

export default useTranslation;
