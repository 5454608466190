import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('comments');

    return {
        COMMENTS: t('comments')
    };
};

export default useTranslation;
