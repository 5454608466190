import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createPolicyVersionModal');

    return {
        CANCEL: t('cancel'),
        CREATE_NEW_VERSION: t('createNewVersion'),
        CREATE_VERSION: t('createVersion'),
        TEXT: t('text')
    };
};

export default useTranslation;
