import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';

const MarketingRequestActions = props => {
    const { className = '', onCancel } = props;

    return (
        <Buttons className={className}>
            <CancelButton onClick={onCancel} />
        </Buttons>
    );
};

export default MarketingRequestActions;
