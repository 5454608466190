import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('loginPanel');

    return {
        LOGIN: t('login'),
        LOGIN_DESCRIPTION: t('loginDescription'),
        SUBMIT: t('submit'),
        USERNAME: t('username')
    };
};

export default useConstants;
