import { atom, atomFamily } from 'recoil';

const activityDetailsPanelAtom = atom({
    default: '',
    key: 'activityDetailsPanel'
});

const activityDetailsPanelDropdownsAtom = atom({
    default: [],
    key: 'activityDetailsPanelDropdowns'
});

const activityDetailsPanelAttachmentsAtom = atom({
    default: [],
    key: 'activityDetailsPanelAttachments'
});

const activityDetailsDropdownChangingAtom = atom({
    default: [],
    key: 'activityDetailsDropdownChanging'
});

const selectedRegulatoryReferencesAtom = atom({
    default: "",
    key: 'selectedRegulatoryReferences'
});

const updateInstructionsAtom = atom({
    default: "",
    key: 'updateInstructions'
});

const updateActivityNameAtom = atom({
    default: "",
    key: 'updateActivityName'
});

const updatePolicyReferenceAtom = atom({
    default: "",
    key: 'updatePolicyReference'
});

const updateRegulatoryReferenceAtom = atom({
    default: "",
    key: 'updateRegulatoryReference'
});

const activityDetailsPanelDropdownsAtomFamily = atomFamily({
    default: {
        id: undefined,
        options: [],
        selectedIndex: undefined,
        title: ''
    },
    key: ''
});

const state = {
    atomFamilies: {
        activityDetailsPanelDropdownsAtomFamily
    },

    atoms: {
        activityDetailsDropdownChangingAtom,
        activityDetailsPanelAtom,
        activityDetailsPanelAttachmentsAtom,
        activityDetailsPanelDropdownsAtom,
        selectedRegulatoryReferencesAtom,
        updateActivityNameAtom,
        updateInstructionsAtom,
        updatePolicyReferenceAtom,
        updateRegulatoryReferenceAtom,
    }
};

export default state;
