import ActionMenu from 'components/ActionMenu2';
import CloseActivityAndAddLogEntryMenuListItem from 'components/CloseActivityAndAddLogEntryMenuListItem';
import CloseActivityAndCreateCaseMenuListItem from 'components/CloseActivityAndCreateCaseMenuListItem';
import CloseActivityAndCreateNewActivityMenuListItem from 'components/CloseActivityAndCreateNewActivityMenuListItem';
import CloseActivityMenuListItem from 'components/CloseActivityMenuListItem';

const CloseActivityMenu = ({
    activityId,
    activityRecord,
    activitySchema,
    children,
    onClick,
    riskLabel
}) => (
    <ActionMenu
        renderReferenceElement={({ handleClick }) => children(handleClick)}
    >
        {onPopperClose => (
            <>
                <CloseActivityMenuListItem
                    activityId={activityId}
                    activitySchema={activitySchema}
                    onClick={onPopperClose}
                />

                <CloseActivityAndCreateNewActivityMenuListItem
                    activityId={activityId}
                    activityName={activityRecord?.name}
                    activitySchema={activitySchema}
                    onClick={onPopperClose}
                />

                <CloseActivityAndCreateCaseMenuListItem
                    activityId={activityId}
                    activityRecord={activityRecord}
                    activitySchema={activitySchema}
                    onClick={onPopperClose}
                    onSave={onClick}
                    riskLabel={riskLabel}
                />

                <CloseActivityAndAddLogEntryMenuListItem
                    activityId={activityId}
                    activityRecord={activityRecord}
                    activitySchema={activitySchema}
                    onClick={onPopperClose}
                    onSave={onClick}
                />
            </>
        )}
    </ActionMenu>
);

export default CloseActivityMenu;
