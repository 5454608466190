const normalizeGroupData = ({ data, schema }) =>
    data[`${schema}_group_user`].reduce(
        (accumulator, userGroups) => {
            const {
                group_id: groupId
            } = userGroups;

            accumulator.push(groupId);

            return accumulator;
        }, []
    );

export default normalizeGroupData;
