const schemaData = ({ data, schema, userId }) => {
    const requests = data[`${schema}_marketing_request`];

    const users = (element) => {
        return (element?.tenant_user?.id === parseInt(userId))
    }
    
    const assigned = requests ? requests.filter(obj => {return obj?.marketing_request_assigned_tos.some(users)}).length : 0;
    const open = requests ? requests.length : 0;
    const review = open-assigned;

    const newData = {
        assigned,
        open,
        review
    };

    return newData;
};

const normalizeData = ({ data, schema, tenants, userId }) => {
    if(Array.isArray(tenants) && tenants.length > 0){
        const tenantData = [];
        for(const t of tenants){
            if(t){
                tenantData.push(schemaData({ data, schema:t, userId }));
            }
        }
        const consolidatedTenantData = {assigned:0, open:0, review:0};
        for (const d of tenantData){
            if(d){
                consolidatedTenantData.assigned += d.assigned;
                consolidatedTenantData.open += d.open;
                consolidatedTenantData.review += d.review;
            }
        }
        return consolidatedTenantData;
    }else{
        return schemaData({ data, schema, userId });
    }
}

export default normalizeData;
