import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('marketingRequestDetailsPanel');

    return {
        INSTRUCTIONS: t('instructions'),
        REQUEST_DETAILS: t('requestDetails'),
        VERSION: t('version')
    };
};

export default useTranslation;
