import { atom } from 'recoil';

const completedActivityOverdueDetailedAtom = atom({
    default: '',
    key: 'completedActivityOverdueDetailed'
});

const state = {
    atoms: {
        completedActivityOverdueDetailedAtom
    }
};

export default state;
