import ActionMenuItem from 'components/ActionMenuItem';
import DropdownMenu from 'components/DropdownMenu';

import usePopperState from 'hooks/usePopperState';

const ActionMenu = props => {
    const {
        className = '',
        items = [],
        placement,
        renderReferenceElement
    } = props;

    const { handleClick, isOpen, onClose, referenceElement } = usePopperState();

    return (
        <>
            {renderReferenceElement({ handleClick, isOpen })}

            <DropdownMenu
                className={className}
                isOpen={isOpen}
                onClose={onClose}
                placement={placement}
                referenceElement={referenceElement}
            >
                {items.map(({ icon, onClick, text }, index) => (
                    <ActionMenuItem
                        icon={icon}
                        key={text + index}
                        onClick={() => {
                            onClick();
                            onClose();
                        }}
                        text={text}
                    />
                ))}
            </DropdownMenu>
        </>
    );
};

export default ActionMenu;
