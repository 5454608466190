import { forwardRef, useCallback, useState } from 'react';

import CloseAndCompleteActivityMenu from 'components/CloseAndCompleteActivityMenu';
import SubmitButton from 'components/SubmitButton';

import useTranslation from './hooks/useTranslation';

const CloseActivityButton = forwardRef(
    (
        { activityId, activityRecord, activitySchema, className = '', groups, isDisabled = false, referencedUsers, riskLabel, userId },
        ref
    ) => {
        

        const { CLOSE_ACTIVITY, CLOSED } = useTranslation();

        const [isButtonDisabled, setIsButtonDisabled] = useState(isDisabled);

        const handleClick = useCallback(() => {
            setIsButtonDisabled(true);
        }, [setIsButtonDisabled]);

        return (
            <CloseAndCompleteActivityMenu
                activityId={activityId}
                activityRecord={activityRecord}
                activitySchema={activitySchema}
                groups={groups}
                onClick={handleClick}
                referencedUsers={referencedUsers}
                riskLabel={riskLabel}
                userId={userId}
            >
                {handleClick => (
                    <SubmitButton
                        className={className}
                        isDisabled={isButtonDisabled}
                        onClick={handleClick}
                        ref={ref}
                        text={isDisabled ? CLOSED : CLOSE_ACTIVITY}
                    />
                )}
            </CloseAndCompleteActivityMenu>
        );
    }
);

export default CloseActivityButton;
