import { useState } from 'react';

import ArchivedPolicyInformation from 'components/ArchivedPolicyInformation';
import AttachmentListItem from 'components/AttachmentListItem';
import ContentPanel from 'components/ContentPanel';
import DropdownMultiSelectWithLabel from 'components/DropdownMultiSelectWithLabel';
import Editor from 'components/Editor';
import FileUpload from 'components/FileUpload';
import PanelBody from 'components/PanelBody';
import PolicyInformation from 'components/PolicyInformation';
import Title from 'components/Title';
import VisibleToDropdown from 'components/VisibleToDropdown';
import WithLabel from 'components/WithLabel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const componentMap = {
    archived: ArchivedPolicyInformation,
    default: PolicyInformation
};

const PolicyDetailsPanel = props => {
    const {
        attachmentOnDelete,
        attachmentOnUpdate,
        categories,
        className = '',
        description,
        document,
        effectiveDate,
        form,
        handleFileUpload,
        name,
        providedSchema,
        selectedCategories,
        setCategories,
        status,
        visibleTo
    } = props;

    const { ADD_DESCRIPTION, CATEGORIES, DESCRIPTION, DETAILS, POLICY_DOCUMENT } =
        useTranslation();

    const [attachmentName, setAttachmentName] = useState(document?.name);
    const [editorState, setEditorState] = useState(description);
    const [showAttachment, setShowAttachment] = useState(true);

    const { handleValid } = form;

    const PolicyInformationComponent =
        componentMap[status] || componentMap.default;

    const attachmentOnDeleteOverride = async (params) => {
        await attachmentOnDelete(params);
        setShowAttachment(false);
    }

    const attachmentOnUpdateOverride = async (params) => {
        await attachmentOnUpdate(params);
        setAttachmentName(params.newName);
    }

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <Title className={styles.title} text={DETAILS} />

                <PolicyInformationComponent
                    className={styles.policyInformation}
                    date={effectiveDate}
                    form={form}
                    name={name}
                />
                
                <DropdownMultiSelectWithLabel
                    change={setCategories}
                    label={CATEGORIES}
                    options={categories}
                    selectedOptions={selectedCategories}
                />

                <VisibleToDropdown
                    className={styles.visibleTo}
                    items={visibleTo}
                    key={`${name}.visibleTo`}
                    onChange={handleValid('visibleTo')}
                />

                <WithLabel className={styles.description} text={DESCRIPTION}>
                    <Editor
                        html={editorState}
                        key={`${name}.editorState`}
                        onChange={setEditorState}
                        onValid={handleValid('description')}
                        placeholder={ADD_DESCRIPTION}
                    />
                </WithLabel>

                {(document.name && showAttachment) ?
                    <WithLabel className={styles.fileInput} text={POLICY_DOCUMENT}>
                        <AttachmentListItem
                            hasEditPermission={true} // TODO - once permissions are ready, update this
                            key={`${name}.attachmentListItem`}
                            name={attachmentName}
                            onDelete={attachmentOnDeleteOverride}
                            onUpdate={attachmentOnUpdateOverride}
                            providedSchema={providedSchema}
                            uploaded_timestamp={document.uploadedTimestamp}
                            url={document.url}
                        />
                    </WithLabel>
                    :
                    <FileUpload
                        className={styles.fileUpload}
                        filesList={handleFileUpload}
                        persist={false}
                        providedSchema={providedSchema}
                    />
                }
            </PanelBody>
        </ContentPanel>
    );
};

export default PolicyDetailsPanel;
