import { sanitize } from 'dompurify';
import { useMemo } from 'react';
import replaceUserReferences from 'utilities/replaceUserReferences';

import styles from './styles.module.scss';

const HtmlContent = props => {
    const { className = '', html, sanitizeHtml=true } = props;

    const sanitizedHtml = useMemo(() => {
        return sanitizeHtml ? replaceUserReferences(sanitize(html)) : replaceUserReferences(html);
    }, [html, sanitizeHtml]);

    return (
        <div
            className={`${className} ${styles.htmlContent}`}
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        />
    );
};

export default HtmlContent;
