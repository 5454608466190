import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('requestStatus');

    return {
        ASSIGNED_TO_ME: t('assignedToMe'),
        OPEN_REQUESTS: t('openRequests'),
        UNDER_REVIEW: t('underReview')
    };
};

export default useTranslation;
