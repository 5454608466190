import AddActivityButton from 'components/AddActivityButton';
import WithDownloadButton from 'components/WithDownloadButtonActivities';
import WithPageTitle from 'components/WithPageTitle';

import translations from './constants';

import CheckMissingPermissions from 'components/CheckMissingPermissions';
import CheckPermissions from 'components/CheckPermissions';
import styles from './styles.module.scss';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import {
    optimisticUpdateActivity,
    useCloseActivity,
    useGetActivitiesPaginator,
} from 'queries/activities.query';
import { useVariables } from 'hooks/variables.hook';
import { ActivitiesPanel } from './components/activities-panel.component';
import {
    useFlagActivity,
    useUnflagActivity
} from 'queries/activity-flags.query';
import { toDateString } from 'utilities/common.util';

const initActivityFilters = () => ({
    activityAssignees: [],
    activityDueDateFrom: toDateString(
        new Date(new Date().setDate(new Date().getDate() - 30))
    ),
    activityDueDateTo: toDateString(
        new Date(new Date().setDate(new Date().getDate() + 90))
    ),
    activityName: undefined,
    activityRecurrence: [],
    activityReviewers: [],
    activityStatus: [],
    activityTypes: [],
    entityName: [],
    isAssignedToMe: true,
    isFlagged: false
});

const searchLimit = 100;

const Activities = () => {
    const { ACTIVITIES } = translations();
    const { useTenants, useUserId } = useGlobalStateHooks();
    const [schemas] = useTenants();
    const [userIdStr] = useUserId();
    const userId = parseInt(userIdStr);
    const { mutateAsync: flagActivity } = useFlagActivity();
    const { mutateAsync: unflagActivity } = useUnflagActivity();
    const { mutateAsync: closeActivity } = useCloseActivity();

    const filtersState = useVariables(initActivityFilters);
    const [filters] = filtersState;

    const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
        useGetActivitiesPaginator({
            ...filters,
            limit: searchLimit,
            schemas,
            userId
        });

    const onToggleFlag = async ({ activity, flagged }) => {
        const { id, schema } = activity;
        optimisticUpdateActivity({
            activityId: id,
            queryPrams: {
                ...filters,
                limit: searchLimit,
                schemas,
                userId
            },
            updateValues: { flagged }
        });
        let action = flagged ? flagActivity : unflagActivity;
        await action({ activityId: id, schema, userId });
    };

    const onCompleteActivity = async activity => {
        const { completable, id, schema } = activity;
        optimisticUpdateActivity({
            activityId: id,
            queryPrams: {
                ...filters,
                limit: searchLimit,
                schemas,
                userId
            },
            updateValues: {
                close_date: new Date(),
                closed: true,
                status: 'completed'
            }
        });

        await closeActivity({
            activityId: id,
            completable,
            schema,
            userId
        });
    };

    const isMultiTenant = schemas.length > 1;
    const activities = data?.pages?.map(page => page).flat() || [];

    return (
        <div className={styles.activities}>
            <WithPageTitle title={ACTIVITIES}>
                    <WithDownloadButton filters={filters} hasPDFexport={false} name={'Activities'}>
                        <CheckPermissions permission={'activity_add'}>
                            <AddActivityButton />
                        </CheckPermissions>
                    </WithDownloadButton>
            </WithPageTitle>

            <CheckPermissions permission={'activity_view'}>
                <ActivitiesPanel
                    activities={activities}
                    className={styles.activitiesPanel}
                    filtersState={filtersState}
                    hasNextPage={hasNextPage}
                    isLoading={isFetchingNextPage}
                    isMultiTenant={isMultiTenant}
                    onCompleteItem={onCompleteActivity}
                    onLoadMore={() => !isFetching && fetchNextPage()}
                    onToggleFlagItem={onToggleFlag}
                    schemas={schemas}
                />
            </CheckPermissions>

            <CheckMissingPermissions
                permission={[
                    'activity_add',
                    'activity_delete',
                    'activity_edit',
                    'activity_view'
                ]}
                text={'activity'}
            />
        </div>
    );
};

export default Activities;
