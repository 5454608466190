import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addActivityType');

    return {
        ACTIVITY_TYPE_NAME: t('activityTypeName'),
        ADD_NEW_ACTIVITY_TYPE: t('addActivityType'),
        RISK_LABEL: t('riskLabel'),
    };
};

export default useTranslation;
