import formatDate from 'utilities/formatDate';
import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, querySchemas, schema }) =>
    data[`${schema}_case`].reduce((accumulator, cases) => {
        const {
            activity,
            case_name: caseName,
            caseTypeByCaseType: caseType,
            closed,
            closed_date: closedDate,
            created_date: createdDate,
            creation_reason: creationReason,
            due_date: caseDueDate,
            id: caseId,
            proposed_solution: proposedSolution,
            resolution,
            risk_color: riskColor,
            risk_label: riskLabel,
            case_entities: caseEntities,
            originated,
            user_cases: caseUsers,
            case_user_involveds: caseUsersInvolved
        } = cases;

        const entities =
            caseEntities.length > 0
                ? caseEntities.reduce((accumulator, entity) => {
                      const { name } = entity.entity;

                      accumulator.push(name);

                      return accumulator;
                  }, [])
                : [];

        const users = caseUsers.reduce(
            (accumulator, { tenant_user: tenantUser }) => {
                if(querySchemas){
                    for(const s of querySchemas){
                        const users = tenantUser[`users_${s.value}`];
                        const id = tenantUser.id;

                        if(users && users.length > 0){
                            const {
                                first_name: firstName,
                                last_name: lastName,
                                profile_photo: profilePhoto
                            } = users[0];

                            accumulator.push({
                                firstName,
                                id,
                                lastName,
                                profilePhoto
                            });
                        }
                    }
                }else{
                    const users = tenantUser[`users_${schema}`];
                        const id = tenantUser.id;

                        if(users && users.length > 0){
                            const {
                                first_name: firstName,
                                last_name: lastName,
                                profile_photo: profilePhoto
                            } = users[0];

                            accumulator.push({
                                firstName,
                                id,
                                lastName,
                                profilePhoto
                            });
                        }
                }

                return accumulator;
            },
            []
        );

        const usersInvolved = caseUsersInvolved ? caseUsersInvolved.reduce(
            (accumulator, { tenant_user: tenantUser }) => {
                if(querySchemas){
                    for(const s of querySchemas){
                        console.log(s)
                        const user = tenantUser[`user_${s.value}`];
                        const id = tenantUser[`id`];
                        const firstName = user?.first_name;
                        const lastName = user?.last_name;
                        const profilePhoto = user?.profile_photo;

                        if(firstName){
                            accumulator.push({
                                firstName,
                                id,
                                lastName,
                                profilePhoto
                            });
                        }
                    }
                }else{
                    const user = tenantUser[`user_${schema}`];
                    const id = tenantUser[`id`];
                    const firstName = user?.first_name;
                    const lastName = user?.last_name;
                    const profilePhoto = user?.profile_photo;

                    if(firstName){
                        accumulator.push({
                            firstName,
                            id,
                            lastName,
                            profilePhoto
                        });
                    }
                }

                return accumulator;
            },
            []
        ) : [];

        accumulator.push({
            activityId: activity?.id || undefined,
            activityName: activity?.name || undefined,
            assignedTo: users,
            caseName,
            caseSchema: schema,
            caseType: caseType?.name || undefined,
            closed,
            closedDate,
            createdDate,
            creationReason,
            dueDate: formatDate(new Date(caseDueDate)),
            entityTags: entities,
            id: caseId,
            originated,
            proposedSolution,
            resolution,
            riskColor,
            riskLabel,
            sortDate: caseDueDate,
            usersInvolved
        });

        accumulator = accumulator.sort((objA, objB) => {
            if(objA.sortDate > objB.sortDate) return 1;
            if(objA.sortDate < objB.sortDate) return -1;
            if(objA.caseType > objB.caseType) return 1;
            if(objA.caseType < objB.caseType) return -1;
            if(objA.caseName > objB.caseName) return 1;
            if(objA.caseName < objB.caseName) return -1;
            return 0;
        });

        return accumulator;
    }, []);

const normalizeData = ({ data, querySchemas, schema, tenants }) => normalizeListData({ data, querySchemas, schema, schemaData, tenants });

export default normalizeData;
