import Input from 'components/Input';

import { ReactComponent as SearchIcon } from './search.svg';

import styles from './styles.module.scss';

const SearchInput = props => {
    const { className = '', onChange, placeholder = '' } = props;

    return (
        <label className={`${className} ${styles.searchInput}`}>
            <Input
                className={styles.input}
                onChange={onChange}
                placeholder={placeholder}
                type="search"
            />

            <SearchIcon className={styles.icon} />
        </label>
    );
};

export default SearchInput;
