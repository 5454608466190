// import { useState } from 'react';
import { useEffect, useState } from 'react';

// import AdminCaseManagement from 'components/AdminCaseManagement';
import AdministrationPanel from 'components/AdministrationPanel';
import AdministrationAdditionalSettingsSidebar from 'components/AdministrationAdditionalSettingsSidebar';
// import AdminLogCategorySettings from 'components/AdminLogCategorySettings';
import AdminMasterEmailAddress from 'components/AdminMasterEmailAddress';
import AdminRiskMonitoring from 'components/AdminRiskMonitoring';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useData from './hooks/useData';

import normalizeData from './data';
import styles from './styles.module.scss';

const AdministrationAdditionalSettingsTab = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    // const { cases, isRiskMonitoringActive, logCategories, masterEmail } =
    //     useData();
    const { loading, error, data } = useData();

    const [activeIndex, setActiveIndex] = useState(0);
    const [additionalSettings, setAdditionalSettings] = useState({});

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });

            if (normalizedData) {
                setAdditionalSettings(normalizedData);
            };
        };
    }, [data, schema, setAdditionalSettings]);

    if (loading) { return `Loading...` }
    if (error) { return `Error! ${error}` };

    const tabsContent = [
        <AdminMasterEmailAddress email={additionalSettings.masterEmail} />,
        <AdminRiskMonitoring isActive={additionalSettings.isRiskMonitoringActive} />,
        // <AdminLogCategorySettings items={additionalSettings.logCategories} />,
        // <AdminCaseManagement cases={additionalSettings.cases} />
    ];

    return (
        <AdministrationPanel
            className={styles.administrationAdditionalSettingsTab}
        >
            <AdministrationAdditionalSettingsSidebar
                activeIndex={activeIndex}
                onClick={setActiveIndex}
            />

            {tabsContent[activeIndex]}
        </AdministrationPanel>
    );
};

export default AdministrationAdditionalSettingsTab;
