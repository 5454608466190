import styles from './styles.module.scss';
import FileUploadThumbnail from 'components/FileUploadThumbnail';

const ImageUploadThumbnail = ({
    filename,
    isUploadError = false,
    isUploading = false,
    onRemove,
    url,
    validationErrors = []
}) => (
    <FileUploadThumbnail
        filename={filename}
        isUploadError={isUploadError}
        isUploading={isUploading}
        onRemove={onRemove}
        url={url}
        validationErrors={validationErrors}
    >
        <img alt={filename} className={styles.image} src={url} />
    </FileUploadThumbnail>
);

export default ImageUploadThumbnail;
