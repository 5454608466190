// import { useCallback } from 'react';

// import AllMeToggle from 'components/AllMeToggle';
import PanelHeader from 'components/PanelHeader';
import PanelTitle from 'components/PanelTitle';
// import ViewAllLink from 'components/ViewAllLink';

// import useConfig from 'hooks/useConfig';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const FormsHeader = ({ className = '' }) => {
    // const { FORMS_MANAGEMENT_ROUTE } = useConfig();
    const { FORMS } = useTranslation();

    // const handleToggle = useCallback(isOn => {
    //     
    // }, []);

    return (
        <PanelHeader className={`${className} ${styles.formsHeader}`}>
            <PanelTitle className={styles.panelTitle} text={FORMS} />

            {/* <ViewAllLink
                className={styles.viewAllLink}
                to={FORMS_MANAGEMENT_ROUTE}
            /> */}

            {/* <AllMeToggle
                className={styles.allMeToggle}
                onToggle={handleToggle}
            /> */}
        </PanelHeader>
    );
};

export default FormsHeader;
