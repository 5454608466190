import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const queryAllActivityTypes = activityTypeSchema => gql`
    query getActivityTypes {
        ${activityTypeSchema}_activity_type(order_by: {name: asc}) {
            id
            dropdown
            name
            risk_label
            visible_bies {
              group {
                id
                name
              }
            }
        }
    }
`;

const useData = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(queryAllActivityTypes(schema), { fetchPolicy: 'no-cache' });
};

export default useData;
