import { useCallback, useState } from 'react';

const useVariableForm = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({});

    const buildFormData = useCallback(
        formData =>
            Object.keys(formData).reduce((form, formKey) => {
                form.append(formKey, formData[formKey]);
                return form;
            }, new FormData()),
        []
    );

    const handleError = useCallback(
        formField => errorMessage => {
            const updatedFormData = { ...formData };
            const updatedFormErrors = { ...formErrors };

            updatedFormData[formField] = '';
            updatedFormErrors[formField] = errorMessage;

            setFormData(updatedFormData);
            setFormErrors(updatedFormErrors);
        },
        [formData, formErrors]
    );

    const handleForm = useCallback(() => {
        const errors = Object.values(formErrors);
        const hasErrors = errors.filter(value => typeof value === 'string');

        if (hasErrors.length) {
            setErrorMessage(
                hasErrors.length > 1
                    ? 'Please correct the errors on this form.'
                    : hasErrors[0]
            );

            return errorMessage;
        }

        return buildFormData(formData || {});
    }, [buildFormData, errorMessage, formData, formErrors]);

    const handleSubmit = useCallback(
        formField => (_, error) => {
            if (error) {
                handleError(formField)(error);
            }
        },
        [handleError]
    );

    const handleValid = useCallback(
        formField => fieldValue => {
            const updatedFormData = { ...formData };
            const updatedFormErrors = { ...formErrors };
            updatedFormErrors[formField] = false;
            updatedFormData[formField] = fieldValue;
            setFormData(updatedFormData);
            setFormErrors(updatedFormErrors);
        },
        [formData, formErrors]
    );

    const handleValidPre = (field, value) => {
        const updatedFormData = { ...formData };
        const updatedFormErrors = { ...formErrors };
        updatedFormErrors[field] = false;
        updatedFormData[field] = value;
        setFormData(updatedFormData);
        setFormErrors(updatedFormErrors);
    };

    const handleValidPreBulk = (obj) => {
        const updatedFormData = { ...formData };
        const updatedFormErrors = { ...formErrors };
        for (const [key, value] of Object.entries(obj)) {
            updatedFormErrors[key] = false;
            updatedFormData[key] = value;
        }
        setFormData(updatedFormData);
        setFormErrors(updatedFormErrors);
    };

    const handleChange = useCallback(
        formField => newValue => {
            
            handleValid(formField)(newValue);
        },
        [handleValid]
    );

    return {
        handleChange,
        handleError,
        handleForm,
        handleSubmit,
        handleValid,
        handleValidPre,
        handleValidPreBulk
    };
};

export default useVariableForm;
