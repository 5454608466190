import Button from 'components/Button';
import InverseCloseIcon from 'components/icons/InverseCloseIcon';

import styles from './styles.module.scss';

const CloseButton = ({ className = '', onClick }) => (
    <Button className={`${className} ${styles.closeButton}`} onClick={onClick}>
        <InverseCloseIcon className={className} />
    </Button>
);

export default CloseButton;
