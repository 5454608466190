import normalizeListData from "utilities/normalizeListData";
import formatDate from "utilities/formatDate";

const schemaData = ({ data, schema }) =>
    data[`${schema}_case`].reduce((accumulator, caseItem) => {
        const {
            case_name: name,
            caseTypeByCaseType: caseTypeObject,
            closed,
            // closed_date: closedDateString,
            // tenant_user: createdByObject,
            created_date: createdDateString,
            // creation_reason: creationReason,
            due_date: dueDateString,
            // originated,
            risk_label: riskLabel,
            user_cases: userCasesArray
        } = caseItem;

        const userCases = userCasesArray.reduce((accumulator, userCase) => {
            const { group, tenant_user: tenantUser } = userCase;
            if (group !== null){
                accumulator.push({firstName: group?.name, id: group?.id, label: `${group?.name} Group`, lastName: 'Group', type: 'group', value:`${group?.name} Group`});
            } else if (tenantUser !== null){
                const userId = tenantUser?.id;
                const user = tenantUser[`user_${schema}`];
                const userName = `${user?.first_name} ${user?.last_name}`
                accumulator.push({firstName: user?.first_name, id: userId, label: userName, lastName: user?.last_name, type: 'user', value:userName});
            }
            return accumulator;
        },[]);

        // const createdById = createdByObject?.id;
        // const createdByFirstName = createdByObject ? createdByObject[`user_${schema}`]?.first_name : createdByObject;
        // const createdByLastName = createdByObject ? createdByObject[`user_${schema}`]?.last_name : createdByObject;
        // const createdByUserName = createdByObject ? `${createdByFirstName} ${createdByLastName}` : createdByObject;
        // const createdBy = {firstName: createdByFirstName, id: createdById, label: createdByUserName, lastName: createdByLastName, type: 'user', value:createdByUserName}

        // const closedDate = closedDateString ? formatDate(new Date(closedDateString)) : closedDateString;
        const dueDate = dueDateString ? formatDate(new Date(dueDateString)) : dueDateString;
        const createdDate = createdDateString ? formatDate(new Date(createdDateString)) : createdDateString;

        const closedStatus = closed ? 'Closed' : 'Open';
        // eslint-disable-next-line
        accumulator.push({name, caseType: caseTypeObject?.name, closed: closedStatus, createdDate, dueDate, riskLabel, userCases});

        return accumulator;
    }, []);

const normalizeData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeData;
