import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecActivity = (tenantSchema, userId) => gql`
query MyQuery {
    consultancy(where: {schema: {_eq: "${tenantSchema}"}}) {
      schema
      description
      tenants(order_by: {consultancyByTenantId: {description: asc}}) {
        consultancyByTenantId {
          id
          schema
          description
        }
      }
      tenantsByTenantId(where: {consultancy: {tenants: {consultancyByTenantId: {schema: {_eq: "${tenantSchema}"}}}}}) {
        consultancy {
          id
          schema
          description
        }
      }
    }
    consultancy_users(where: {user_id: {_eq: ${userId}}}) {
      tenant_id
      consultancy_users_consultancy {
        name
      }
      consultancy_users_tenant {
        name
      }
    }
  }
  
  
`;

const useData = () => {
    const { useSchema, useUserId } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [userId] = useUserId();

    return useQuery(querySpecActivity(schema, userId), {
        fetchPolicy: 'no-cache'
    });
};

export default useData;
