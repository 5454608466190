import { atom } from 'recoil';

const completedActivityUpcomingDetailedAtom = atom({
    default: '',
    key: 'completedActivityUpcomingDetailed'
});

const state = {
    atoms: {
        completedActivityUpcomingDetailedAtom
    }
};

export default state;
