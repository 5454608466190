import BodyText from 'components/BodyText';
import { classNames } from 'utilities/common.util';
import styles from './styles.module.scss';

const colorStyles = {
    green: styles.isActive,
    orange: styles.isActiveOrange
};

const SlideToggle = ({
    color = 'green',
    isActive = true,
    className = '',
    onToggle
}) => {
    return (
        <div
            className={classNames(
                className,
                styles.slideToggle,
                isActive ? colorStyles[color] : undefined
            )}
            onClick={onToggle}
        >
            <div
                className={classNames(
                    className,
                    styles.knob,
                    isActive ? colorStyles[color] : undefined
                )}
            ></div>
        </div>
    );
};

export const Toggle = ({
    className = '',
    color = 'green',
    disableStyle,
    disableText = '',
    enableStye,
    enableText = '',
    isActive,
    onToggle
}) => {
    return (
        <div className={classNames(className, styles.toggleContainer)}>
            <BodyText
                className={classNames(
                    disableStyle,
                    styles.toggleText,
                    !isActive ? styles.toggleIsActive : undefined
                )}
                text={disableText}
            />

            <SlideToggle
                color={color}
                isActive={isActive}
                onToggle={onToggle}
            />

            <BodyText
                className={classNames(
                    enableStye,
                    styles.toggleText,
                    isActive ? styles.toggleIsActive : undefined
                )}
                text={enableText}
            />
        </div>
    );
};
