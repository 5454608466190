import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import AddCaseButton from 'components/AddCaseButton';
import CaseManagementPanel from 'components/CaseManagementPanel';
import WithDownloadButtonCase from 'components/WithDownloadButtonCase';
import WithPageTitle from 'components/WithPageTitle';


import useTranslation from './hooks/useTranslation';


import { useCaseManagementData } from './hooks/useCaseData';
import styles from './styles.module.scss';

const CaseManagement = () => {
    const { loading, error, data } = useCaseManagementData();

    const { CASE_MANAGEMENT } = useTranslation();

    const { ADD_CASE_ENTRY_ROUTE } = useConfig();

    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const caseTypeFilter = urlParams.get('filter')

    const handleAdd = useCallback(() => {
        navigate(ADD_CASE_ENTRY_ROUTE);
    }, [ADD_CASE_ENTRY_ROUTE, navigate]);

    if(loading){}
    if(error){}

    return (
        <div className={styles.case}>
            <WithPageTitle title={CASE_MANAGEMENT}>
                <WithDownloadButtonCase data={data} hasPDFexport={false} name={'Cases'} >
                    <AddCaseButton
                        className={styles.addLogEntryButton}
                        onClick={handleAdd}
                    />
                </WithDownloadButtonCase>
            </WithPageTitle>

            <CaseManagementPanel caseManagementData={data} caseTypeFilter={caseTypeFilter}  />
        </div>
    );
};

export default CaseManagement;
