const getCount = countNames =>
    countNames.reduce((accumulator, name) => {
        if (!(name in accumulator)) {
            accumulator[name] = 0;
        }

        accumulator[name]++;

        return accumulator;
    }, {});

function getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++ ) {
        color += letters[Math.round(Math.random() * 15)];
    }
    return color;
}

function genDonutData(listValues, useRiskColors=false){
    const backgroundColors = ['#00AB55', '#2D99FF', '#FFE700', '#826AF9'];
    const riskColors = {'critical':'#eb5b5e', 'high': '#f2994a', 'low': '#00ab55', 'medium': '#3565e1', 'neutral': '#e6e8f0'}
    const textColors = ['#fff', '#fff', '#000', '#fff'];
    const newData = [];

    for (const [i, [key, value]] of Object.entries(Object.entries(listValues))) {
        newData.push({
            color: (!useRiskColors) ?
                (i < backgroundColors.length) ? backgroundColors[i] : getRandomColor() :
                (key.toLowerCase() in riskColors) ? riskColors[key.toLowerCase()] : riskColors['neutral'],
            text: (key) ? key.replace(/\w\S*/g,function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : 'Neutral',
            textColor: (!useRiskColors) ? ((i < textColors.length) ? textColors[i] : textColors[0]) : (key.toLowerCase() in riskColors) ? '#fff' : '#000',
            value: Number(value)
        });
    }

    return newData;
}

const normalizeData = entries => {
    const types = [];
    const risks = [];
    for (const entry of entries){
        types.push(entry?.type)
        risks.push(entry?.riskLabel)
    }

    const typeCount = getCount(types);
    const riskCount = getCount(risks);

    const typeData = genDonutData(typeCount);
    const riskData = genDonutData(riskCount, true);

    return {risks: riskData, types: typeData};
};

export default normalizeData;