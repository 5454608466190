import { forwardRef, useCallback, useState } from 'react';

import AddFromActivityMenu from 'components/AddFromActivityMenu'
import SubmitButton from 'components/SubmitButton';

import useTranslation from './hooks/useTranslation';

const AddFromActivityButton = forwardRef(
    (
        { activityId, activityRecord, activitySchema, className = '', isDisabled = false, riskLabel },
        ref
    ) => {
        const { ADD_ITEM, ADDED_ITEM } = useTranslation();

        const [isButtonDisabled, setIsButtonDisabled] = useState(isDisabled);

        const handleClick = useCallback(() => {
            setIsButtonDisabled(true);
        }, [setIsButtonDisabled]);

        return (
            <AddFromActivityMenu
                activityId={activityId}
                activityRecord={activityRecord}
                activitySchema={activitySchema}
                onClick={handleClick}
                riskLabel={riskLabel}
            >
                {handleClick => (
                    <SubmitButton
                        className={className}
                        isDisabled={isButtonDisabled}
                        onClick={handleClick}
                        ref={ref}
                        text={isDisabled ? ADDED_ITEM : ADD_ITEM}
                    />
                )}
            </AddFromActivityMenu>
        );
    }
);

export default AddFromActivityButton;
