import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ComplianceEntryActivities from 'components/ComplianceEntryActivities';
import ComplianceEntryCases from 'components/ComplianceEntryCases';
import ComplianceEntryDetailsPanel from 'components/ComplianceEntryDetailsPanel';
import ComplianceEntryPolicies from 'components/ComplianceEntryPolicies';
import ComplianceLogEntryMetaPanel from 'components/ComplianceLogEntryMetaPanel';
import HollowButton from 'components/HollowButton';
import WithPageTitle from 'components/WithPageTitle';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useLogDetails from './hooks/useLogDetails';
import useTranslation from './hooks/useTranslation';
import useData from './hooks/useData';

import styles from './styles.module.scss';

import normalizeData from './data';

const ComplianceLogEntry = () => {
    const { BACK, COMPLIANCE_LOG_ENTRY } = useTranslation();

    const { COMPLIANCE_LOG_ROUTE, INTERNAL_SERVER_ERROR } = useConfig();

    const params = useParams();

    const { entryId, logSchema } = params;

    const { useSchema, useUserId } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [userId] = useUserId();

    const [log, setLog] = useLogDetails();

    const navigate = useNavigate();

    const navigateToComplianceLog = useCallback(() => {
        navigate(COMPLIANCE_LOG_ROUTE);
    }, [COMPLIANCE_LOG_ROUTE, navigate]);

    const { error, data } = useData(entryId, logSchema);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema: logSchema,
                user: userId
            });

            setLog(normalizedData);
        }
    }, [data, logSchema, schema, setLog, userId]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);

        return;
    }

    const { activities, cases, entry, policies } = log || {};

    if (!entry) {
        return null;
    }

    

    return (
        <div className={styles.complianceLogEntry}>
            <WithPageTitle className={styles.head} title={COMPLIANCE_LOG_ENTRY}>
                <HollowButton onClick={navigateToComplianceLog} text={BACK} />
            </WithPageTitle>

            <ComplianceEntryDetailsPanel entry={entry} logSchema={logSchema} />

            {cases && <ComplianceEntryCases items={cases} logSchema={logSchema} />}

            {activities && <ComplianceEntryActivities items={activities} logSchema={logSchema} />}

            {policies && <ComplianceEntryPolicies items={policies} logSchema={logSchema} />}

            <ComplianceLogEntryMetaPanel className={styles.meta} entry={log} logSchema={logSchema} />
        </div>
    );
};

export default ComplianceLogEntry;
