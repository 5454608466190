const translations = {
    description: 'Description',
    documents: 'Documents',
    editSection: 'Section Details',
    effectiveDate: 'Effective Date',
    published: 'Published',
    riskLabel: 'Risk Label',
    sectionName: 'Section Name',
    selectedCategories: 'Selected Categories',
    status: 'Status',
    version: 'Version'
};

export default translations;
