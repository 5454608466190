import { memo, useCallback, useEffect, useState } from 'react';

import TextInput from 'components/TextInput';
import RadioButton from 'components/RadioButton';
import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const RadioButtonWithTextInput = ({
    className = '',
    id,
    isChecked,
    isDisabled = false,
    handleTextChange,
    name = '',
    onChange,
    value = ''
}) => {
    const [changeValue, setChangeValue] = useState();
   
    useEffect(() => {
        setChangeValue(isChecked);
    }, [isChecked, setChangeValue]);

    const handleChange = useCallback((event) => {
        if (onChange && isFunction(onChange)) {
            onChange(event);
        }
    }, [onChange]);

    const handleClick = useCallback((event) => {
        setChangeValue(!changeValue);
        handleChange(event);
    }, [changeValue, handleChange, setChangeValue]);

    return (
        <div
            className={`${className} ${styles.radioButtonWithLabel}`}
            onClick={handleClick}
        >
            <RadioButton
                isChecked={changeValue}
                isDisabled={isDisabled}
                name={name}
                value={value}
            />

            <TextInput className={styles.label} id={id} onChange={handleTextChange} value={value} />
        </div>
    );
};

export default memo(RadioButtonWithTextInput);
